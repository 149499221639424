import React from "react";
import {
  getAllBusiness,
  getBusinessById,
  putBusiness,
  createBusiness,
  getBusinessType,
  getAllBusinessOwnerAdmin,
  getBusinessOwnerAdminById,
  updateOwner,
  getAllBusinessTypeForAdmin,
  getBusinessByIdForAdmin,
  updateBusinessForAdmin,
  getAuthUrl,
  registerUrl,
  checkConnectGoogle,
  disconnectGoogle
} from "../services/BusinessService";
import { observable, decorate } from "mobx";
import { reactLocalStorage } from "reactjs-localstorage";
import csc from "country-state-city";
import moment from "moment";
import {
  getEmployeeLicense,
  createLicense,
  getEmployeeLicenseById,
  updateLicense,
  deleteLicense
} from "../services/LicenseService";
import VisibilityIcon from '@material-ui/icons/Visibility';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CommonStore from "./CommonStore";
import commonStore from "./CommonStore";

class BusinessStore {
  /* CSC */
  states = "";
  setCountryId = value => {
    this.countryId = value;
  };
  setStateId = value => {
    this.states = value;
  };

  //Form Table
  formValueTransformer = {
    link: data => {
      return (
        <span
          style={{ color: "#01953A", cursor: "pointer" }}
          onClick={() => {
            let textArea = document.createElement("textarea");
            textArea.value = data.link;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("Copy");
            textArea.remove();
            commonStore.showSuccessMessage("Link Copied")
          }}
        >
          <FileCopyIcon /> Copy Link
        </span>
      )
    },
    preview: data => {
      return (
        <span
          style={{ color: "#01953A", cursor: "pointer" }}
          onClick={() => {
            window.open(data.preview)
          }}
        >
          <VisibilityIcon /> Preview Form
        </span>
      )
    }
  };
  formArray = [];
  formColumn = [
    { name: "formName", title: "Form Name" },
    {
      name: "preview", title: " ",
      valueTransformer: this.formValueTransformer["preview"]
    },
    {
      name: "link", title: " ",
      valueTransformer: this.formValueTransformer["link"]
    }
  ];
  formColumnWidths = [
    { columnName: "formName", width: 200 },
    { columnName: "preview", width: 200 },
    { columnName: "link", width: 200 }
  ];

  //License Table
  businessLicenseArray = [];
  businessLicenseColumns = [
    {
      name: "number",
      title: "License Number"
    },
    {
      name: "state",
      title: "State of Registration"
    },
    {
      name: "date",
      title: "Registration Date"
    }
  ];
  businessLicenseColumnWidths = [
    {
      columnName: "number",
      width: 200
    },
    {
      columnName: "state",
      width: 200
    },
    {
      columnName: "date",
      width: 200
    }
  ];
  isLoading = false;
  businessArray = [];
  editedData = {};
  currentBusiness = "";
  businessType = [];
  url = ""
  link = { value: "link", name: "+ Add New Business" };

  setEditedData = editedData => {
    this.editedData = {
      ...this.editedData,
      ...editedData
    };
  };
  initEditedData = () => {
    this.editedData = {};
  };

  getAllBusiness = async () => {
    const { data, error } = await getAllBusiness();

    if (data) {
      let tempBusinessArray = data.map((data, index) => {
        const value = data._id ? data._id : "-";
        const name = data.name ? data.name : "-";

        return {
          ...data,
          value,
          name
        };
      });
      tempBusinessArray.push(this.link);
      this.businessArray = tempBusinessArray;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        CommonStore.showErrorMessage(this.errorMessage);
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getBusinessById = async businessId => {
    const { data, error } = await getBusinessById(businessId);

    if (data) {
      const countryArray = csc.getAllCountries();
      const country = countryArray.find(
        country => country.name === data.country
      );

      let countryStateCity = {
        country: { label: country.name, value: country.isoCode }
      };
      if (data.country === "United States") {
        const stateArray = csc.getStatesOfCountry(String(country.isoCode));
        const state = stateArray.find(state => state.name === data.state);

        // const cityArray = csc.getCitiesOfState(String(state.id));
        // const city = cityArray.find(city => city.name === data.city);

        countryStateCity = {
          ...countryStateCity,
          businessType: data.businessType ? data.businessType : "",
          state: state
            ? { label: state.name, value: state.isoCode }
            : { label: "", value: "" },
          // city: { label: city.name, value: city.id }
        };
      }

      let temp = []

      for (let i = 0; i < 2; i++) {
        temp.push(
          {
            formName: `Form ${i + 1}`,
            preview: `https://admin.worksprings.com/formpreview?type=${i + 1}&name=${data.name}`,
            link: `https://admin.worksprings.com/createcontact?type=${i + 1}&id=${data._id}&name=${data.name}`
          }
        )
      }

      this.formArray = temp;

      const newData = {
        ...data,
        ...countryStateCity,
        url: data._id ? `admin.worksprings.com/createcontact?id=${data._id}&name=${data.name}` : ""
      };
      this.setEditedData(newData);
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        CommonStore.showErrorMessage(this.errorMessage);
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  putBusiness = async (values, businessId) => {
    const businessDTO = {
      businessName: values.name,
      businessType: values.businessType,
      address1: values.address1,
      address2: values.address2,
      postalCode: values.postalCode,
      city: values.city && values.city.label ? values.city.label : values.city,
      state:
        values.state && values.state.label ? values.state.label : values.state,
      country:
        values.country && values.country.label
          ? values.country.label
          : values.country,
      phoneNumber: values.phoneNumber,
      website: values.website,
      logo: values.logo,
      timezone: values.timezone,
      isBLM: values.isBLM
    };

    const { data, error } = await putBusiness(businessDTO, businessId);

    if (data) {
      await new Promise((resolve, reject) => {
        reactLocalStorage.setObject("currentState", data && data.state);

        CommonStore.showSuccessMessage("Data updated!");

        setTimeout(resolve, 500);
      });
      return true;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        CommonStore.showErrorMessage(this.errorMessage);
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }

      return false;
    }
  };

  createBusiness = async (
    businessName,
    businessType,
    address1,
    address2,
    city,
    state,
    postalCode,
    country,
    phoneNumber,
    website,
    logo,
    timezone
  ) => {
    city = city && city.label ? city.label : city;
    state = state && state.label ? state.label : state;
    country = country && country.label ? country.label : country;
    this.isLoading = true;
    const { data, error } = await createBusiness({
      businessName,
      businessType,
      address1,
      address2,
      country,
      state,
      city,
      postalCode,
      phoneNumber,
      website,
      logo,
      timezone
    });
    this.isLoading = false;
    if (data) {
      this.currentBusiness = data._id;
      let businessArray = reactLocalStorage.getObject("businessArray");

      let tempBusinessArray = [{ ...data, value: data._id, name: data.name }];
      businessArray.map(array => {
        tempBusinessArray.push(array);
        return true;
      });
      this.businessArray = tempBusinessArray;
      reactLocalStorage.setObject("businessArray", this.businessArray);
      reactLocalStorage.setObject(
        "huntingCurrentBusiness",
        this.currentBusiness
      );
      window.location.href = "/businesses/" + data._id + "/dashboard";
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getBusinessType = async () => {
    const { data, error } = await getBusinessType();

    if (data) {
      this.businessType = data.map(data => {
        const name = data.name ? data.name : "";
        const value = data._id ? data._id : "";
        return {
          name,
          value
        };
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getEmployeeLicense = async employeeId => {
    this.isLoading = true;
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    const { data, error } = await getEmployeeLicense({
      businessId: JSON.parse(businessId),
      employeeId: employeeId
    });
    if (data) {
      this.isLoading = false;
      this.businessLicenseArray = data
        ? data.map(data => {
          const number = data.number ? data.number : "";
          const date = data.expiredDate
            ? moment(data.expiredDate).format("MMM DD, YYYY")
            : "";
          const state = data.state ? data.state : "";

          return {
            ...data,
            number,
            date,
            state
          };
        })
        : [];
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  // licenseForValidation = async employeeId => {
  //   // this.isLoading = true;
  //   let businessId = reactLocalStorage.get("huntingCurrentBusiness");

  //   const { data, error } = await getEmployeeLicense({
  //     businessId: JSON.parse(businessId),
  //     employeeId: employeeId
  //   });
  //   if (data) {
  //     // this.isLoading = false;
  //     let a = data.length
  //       ? data.map(data => {
  //           const number = data.number ? data.number : "";
  //           const date = data.expiredDate
  //             ? moment(data.expiredDate).format("MMM DD, YYYY")
  //             : "";
  //           const state = data.state ? data.state : "";

  //           return {
  //             ...data,
  //             number,
  //             date,
  //             state
  //           };
  //         })
  //       : [];
  //     return { license: a ? a : [] };
  //   } else {
  //     // this.isLoading = false;
  //     if (error.data) {
  //       this.errorMessage = error.data.message;
  //       if (this.errorMessage === "Forbidden.") {
  //         commonStore.showErrorMessage(
  //           "You don't have any permission to View employee's data"
  //         );
  //         return { license: [] };
  //       } else {
  //         commonStore.showErrorMessage(this.errorMessage);
  //         return { license: [] };
  //       }
  //     } else {
  //       commonStore.showErrorMessage(
  //         "Something went wrong. Please try again later"
  //       );
  //       return { license: [] };
  //     }
  //   }
  // };

  createLicense = async ({ value, history }) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const { data, error } = await createLicense(value, businessId);
    if (data) {
      this.isLoading = false;

      let temp = JSON.parse(localStorage.getItem("license"));
      temp.push(data);

      // let ownerData = reactLocalStorage.get("account")
      //   ? JSON.parse(reactLocalStorage.get("account"))
      //   : "";
      // let { license } = await this.licenseForValidation(
      //   ownerData ? ownerData._id : ""
      // );
      // let licenseState = license.map(licenses => {
      //   return licenses.state;
      // });
      // await localStorage.setItem(
      //   "isStateMatch",
      //   licenseState.includes("Wyoming")
      // );

      // localStorage.setItem("license", JSON.stringify(temp));
      // window.location.href =
      //   "/businesses/" + businessId + "/businessInformation";

      localStorage.setItem("license", JSON.stringify(temp));
      history.push(`/businesses/${businessId}/businessInformation`);
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getBusinessLicenseById = async licenseId => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const { data, error } = await getEmployeeLicenseById({
      businessId: businessId,
      licenseId: licenseId
    });
    this.isLoading = false;

    if (data) {
      let state = csc.getStatesOfCountry("US");

      let tempState = state.map(data => {
        return data.name;
      });

      let tempId = tempState.indexOf(data.state);

      let stateId = state[tempId].isoCode;

      this.setEditedData({
        number: data.number ? data.number : "",
        expiredDate: data.expiredDate ? data.expiredDate : "",
        state: { label: data.state, value: String(stateId) }
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  updateLicense = async ({ value, history, licenseId }) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const { data, error } = await updateLicense(value, businessId, licenseId);
    if (data) {
      this.isLoading = false;
      CommonStore.showSuccessMessage("License Updated");

      let temp = JSON.parse(localStorage.getItem("license"));
      for (let i = 0; i < temp.length; i++) {
        if (temp[i]._id === data._id) {
          temp[i].state = data.state;
        }
      }

      // let temp2 = temp.map((tempData, idx) => {
      //   if (tempData._id === data._id) {
      //     tempData.state = data.state;
      //     return tempData;
      //   }
      // });
      // temp.push(data);
      // console.log(toJS(temp));

      // let ownerData = reactLocalStorage.get("account")
      //   ? JSON.parse(reactLocalStorage.get("account"))
      //   : "";
      // let { license } = await this.licenseForValidation(
      //   ownerData ? ownerData._id : ""
      // );
      // if (license) {
      //   let licenseState = license.map(licenses => {
      //     return licenses.state;
      //   });
      //   await localStorage.setItem(
      //     "isStateMatch",
      //     licenseState.includes("Wyoming")
      //   );
      // }

      localStorage.setItem("license", JSON.stringify(temp));
      history.push(`/businesses/${businessId}/businessInformation`);
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  deleteLicense = async ({ history, licenseId }) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const { data, error } = await deleteLicense(businessId, licenseId);
    if (data) {
      this.isLoading = false;
      CommonStore.showSuccessMessage("License Deleted");
      let temp = JSON.parse(localStorage.getItem("license"));
      let temp2 = [];
      // eslint-disable-next-line array-callback-return
      temp.filter(tempData => {
        if (tempData._id !== data._id) {
          temp2.push(tempData);
        }
      });
      // temp.push(data);

      // let ownerData = reactLocalStorage.get("account")
      //   ? JSON.parse(reactLocalStorage.get("account"))
      //   : "";
      // let { license } = await this.licenseForValidation(
      //   ownerData ? ownerData._id : ""
      // );
      // let licenseState = license.map(licenses => {
      //   return licenses.state;
      // });
      // await localStorage.setItem(
      //   "isStateMatch",
      //   licenseState.includes("Wyoming")
      // );

      localStorage.setItem("license", JSON.stringify(temp));
      history.push(`/businesses/${businessId}/businessInformation`);

      // localStorage.setItem("session", JSON.stringify(temp2));
      // window.location.href =
      //   "/businesses/" + businessId + "/businessInformation";
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getAuthUrl = async () => {

    const { data, error } = await getAuthUrl();

    if (data) {
      window.open(data);
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  registerUrl = async (value, history) => {

    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    const { status } = await registerUrl({ code: value });

    if (status !== "Error") {
      window.close();
      window.location.href = (`/businesses/${businessId}/dashboard`)
    } else {
      this.isLoading = false;
      commonStore.showErrorMessage("Already Logged In");
    }

  }

  checkConnectGoogle = async () => {
    this.isLoading = true
    const { data } = await checkConnectGoogle();
    this.isLoading = false

    if (data) {
      this.setEditedData({
        loggedToGoogle: data
      })
    }
  }

  disconnectGoogle = async () => {
    await disconnectGoogle();
    window.location.reload()
  }

  setIsLoading = bool => {
    this.isLoading = bool;
  };

  //-----------------------------------------------------------Super Admin-------------------------------------------------------------
  countryId = "";
  stateId = "";
  cityId = "";
  pageSize = 10;
  currentPage = 0;
  totalData = 0;

  //------Owner List--------
  adminBusinessOwnerArray = [];
  adminBusinessOwnerColumn = [
    {
      name: "id",
      title: "Owner Id"
    },
    {
      name: "name",
      title: "Owner Name"
    },
    {
      name: "email",
      title: "Email"
    }
  ];
  adminBusinessOwnerColumnWidths = [
    {
      columnName: "id",
      width: 300
    },
    {
      columnName: "name",
      width: 300
    },
    {
      columnName: "email",
      width: 300
    }
  ];

  //------Business List--------
  adminBusinessArray = [];
  adminBusinessColumn = [
    {
      name: "businessId",
      title: "Business ID"
    },
    {
      name: "businessName",
      title: "Business Name"
    },
    {
      name: "businessType",
      title: "Business Type"
    }
  ];
  adminBusinessColumnWidths = [
    {
      columnName: "businessId",
      width: 300
    },
    {
      columnName: "businessName",
      width: 300
    },
    {
      columnName: "businessType",
      width: 300
    }
  ];

  setCurrentAdminPage = value => {
    this.currentPage = value;
    this.getAllBusinessOwnerAdmin();
  };

  getAllBusinessOwnerAdmin = async () => {
    this.isLoading = true;
    const { data, error } = await getAllBusinessOwnerAdmin({
      pageLimit: this.pageSize,
      currentPage: this.currentPage + 1
    });
    this.isLoading = false;

    if (data) {
      this.adminBusinessOwnerArray = data.employees.map(data => {
        const id = data._id ? data._id : "-";
        const name = data.name ? data.name : "-";
        const email = data.email ? data.email : "-";
        return {
          ...data,
          id,
          name,
          email
        };
      });

      this.totalData = data.totalData;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        CommonStore.showErrorMessage(this.errorMessage);
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getBusinessOwnerAdminById = async businessId => {
    this.isLoading = true;
    const { data, error } = await getBusinessOwnerAdminById(businessId);
    this.isLoading = false;

    if (data) {
      this.setEditedData({
        firstName: data.firstName ? data.firstName : "",
        lastName: data.lastName ? data.lastName : "",
        id: data._id ? data._id : "",
        email: data.email ? data.email : ""
      });

      this.adminBusinessArray = data.business.map(data => {
        const businessId = data._id ? data._id : "";
        const businessName = data.name ? data.name : "";
        const businessType = data.businessType ? data.businessType.name : "";

        return {
          businessId,
          businessName,
          businessType
        };
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        CommonStore.showErrorMessage(this.errorMessage);
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  updateOwner = async (id, value, history) => {
    this.isLoading = true;
    const { data, error } = await updateOwner(id, value);
    this.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Owner Updated!");
      history.push("/admin/businessowner");
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        CommonStore.showErrorMessage(this.errorMessage);
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getAllBusinessTypeForAdmin = async () => {
    const { data, error } = await getAllBusinessTypeForAdmin();

    if (data) {
      this.businessType = data.map(data => {
        const name = data.name ? data.name : "";
        const value = data._id ? data._id : "";
        return {
          name,
          value
        };
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        CommonStore.showErrorMessage(this.errorMessage);
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getBusinessByIdForAdmin = async id => {
    this.isLoading = true;

    const { data, error } = await getBusinessByIdForAdmin(id);

    this.isLoading = false;

    if (data) {
      const countryArray = csc.getAllCountries();
      const country = countryArray.find(
        country => country.name === data.country
      );

      let countryStateCity = {
        country: { label: country.name, value: country.isoCode }
      };
      if (data.country === "United States") {
        const stateArray = csc.getStatesOfCountry(String(country.isoCode));
        const state = stateArray.find(state => state.name === data.state);

        // const cityArray = csc.getCitiesOfState(String(state.id));
        // const city = cityArray.find(city => city.name === data.city);

        countryStateCity = {
          ...countryStateCity,
          businessType: data.businessType ? data.businessType : "",
          state: {
            label: state ? state.name : "",
            value: state ? state.isoCode : ""
          },
          city: data.city ? data.city : ""
        };
      }

      const newData = {
        ...data,
        ...countryStateCity
      };
      this.setEditedData(newData);
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        CommonStore.showErrorMessage(this.errorMessage);
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  updateBusinessForAdmin = async (id, values) => {
    this.isLoading = true;
    const businessDTO = {
      businessName: values.name,
      businessType: values.businessType,
      address1: values.address1,
      address2: values.address2,
      postalCode: values.postalCode,
      city: values.city ? values.city : "",
      state:
        values.state && values.state.label ? values.state.label : values.state,
      country:
        values.country && values.country.label
          ? values.country.label
          : values.country,
      phoneNumber: values.phoneNumber,
      website: values.website,
      logo: values.logo,
      timezone: values.timezone
    };
    const { data, error } = await updateBusinessForAdmin(id, businessDTO);

    this.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Update Success!");
      this.getBusinessByIdForAdmin(id);
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        CommonStore.showErrorMessage(this.errorMessage);
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };
}
decorate(BusinessStore, {
  businessArray: observable,
  editedData: observable,
  businessType: observable,
  currentBusiness: observable,
  isLoading: observable,
  adminBusinessOwnerArray: observable,
  countryId: observable,
  stateId: observable,
  cityId: observable,
  adminBusinessArray: observable,
  businessLicenseArray: observable,
  pageSize: observable,
  currentPage: observable,
  totalData: observable,
  url: observable
});
export default new BusinessStore();
