import axios from "../helper/Axios.js";
import { adminUrl, superUrl } from "../config/config.js";

export async function getAllCustomer({
  businessId,
  pageLimit,
  currentPage,
  search,
}) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/customer?page=${currentPage}&limit=${pageLimit}&search=${search}`,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getCustomerById(businessId, customerId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/customer/${customerId}`,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function postCustomer({
  businessId,
  firstName,
  lastName,
  email,
  businessName,
  address1,
  address2,
  birthDate,
  city,
  state,
  postalCode,
  country,
  phoneNumber,
  emergencyContactFirstName,
  emergencyContactLastName,
  emergencyContactPhoneNumber,
  note,
  clientId,
  tagNumber,
  licenseType,
  huntingLicense,
  dietaryRestriction,
  medicalRestriction,
  licensePaperwork,
  voucherPaperwork,
  IDFG,
  isOffered,
  isGuest,
  alsNumber,
  gender,
  height1,
  height2,
  weight,
  fitnessLevel,
  stateWildlifeAccount,
  stateWildlifeLogin,
  stateWildlifePassword,
  stateWildlifeLicenseNumber,
  legalToCarryFireArms,
  horsemanshipSkillLevel,
  huntingUnitNumber,
  customerType,
  isJuniorHunter,
  // isWildlifeDonation
}) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/customer`,
      data: {
        firstName,
        lastName,
        email,
        businessName,
        address1,
        address2,
        birthDate,
        city,
        state,
        postalCode,
        country,
        phoneNumber,
        emergencyContactFirstName,
        emergencyContactLastName,
        emergencyContactPhoneNumber,
        note,
        clientId,
        tagNumber,
        licenseType,
        huntingLicense,
        dietaryRestriction,
        medicalRestriction,
        licensePaperwork,
        voucherPaperwork,
        IDFG,
        isOffered,
        isGuest,
        alsNumber,
        gender,
        height1,
        height2,
        weight,
        fitnessLevel,
        stateWildlifeAccount,
        stateWildlifeLogin,
        stateWildlifePassword,
        stateWildlifeLicenseNumber,
        legalToCarryFireArms,
        horsemanshipSkillLevel,
        huntingUnitNumber,
        customerType,
        isJuniorHunter,
        // isWildlifeDonation
      },
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function updateCustomer({
  businessId,
  customerId,
  firstName,
  lastName,
  email,
  businessName,
  address1,
  address2,
  city,
  state,
  postalCode,
  country,
  phoneNumber,
  emergencyContactFirstName,
  emergencyContactLastName,
  emergencyContactPhoneNumber,
  note,
  clientId,
  birthDate,
  tagNumber,
  licenseType,
  huntingLicense,
  dietaryRestriction,
  medicalRestriction,
  licensePaperwork,
  voucherPaperwork,
  IDFG,
  isOffered,
  isGuest,
  alsNumber,
  gender,
  height1,
  height2,
  weight,
  fitnessLevel,
  stateWildlifeAccount,
  stateWildlifeLogin,
  stateWildlifePassword,
  stateWildlifeLicenseNumber,
  legalToCarryFireArms,
  horsemanshipSkillLevel,
  huntingUnitNumber,
  customerType,
  isJuniorHunter,
  // isWildlifeDonation
}) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/customer/${customerId}`,
      data: {
        firstName,
        lastName,
        email,
        businessName,
        address1,
        address2,
        birthDate,
        city,
        state,
        postalCode,
        country,
        phoneNumber,
        emergencyContactFirstName,
        emergencyContactLastName,
        emergencyContactPhoneNumber,
        note,
        clientId,
        tagNumber,
        licenseType,
        huntingLicense,
        dietaryRestriction,
        medicalRestriction,
        licensePaperwork,
        voucherPaperwork,
        IDFG,
        isOffered,
        isGuest,
        alsNumber,
        gender,
        height1,
        height2,
        weight,
        fitnessLevel,
        stateWildlifeAccount,
        stateWildlifeLogin,
        stateWildlifePassword,
        stateWildlifeLicenseNumber,
        legalToCarryFireArms,
        horsemanshipSkillLevel,
        huntingUnitNumber,
        customerType,
        isJuniorHunter,
        // isWildlifeDonation
      },
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function deleteCustomer(businessId, customerId) {
  try {
    let response = await axios.Delete({
      url: `${adminUrl}/business/${businessId}/customer/${customerId}`,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getMessage(businessId, customerId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/message/customer/${customerId}`,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function sendMessage(value, businessId, customerId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/message/customer/${customerId}`,
      data: value,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function sendMessageWhenEmpty(value, businessId, customerId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/customer/${customerId}/sms`,
      data: value,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getCustomerNotification(businessId, customerId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/notification/customer/${customerId}`,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function offerSubscription(businessId, customerId, value) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/customer/${customerId}/subscribe`,
      data: value,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

//-----------------------------------------------------------Super Admin--------------------------------------------------------------

export async function getAllCustomerForAdmin({
  businessId,
  pageLimit,
  currentPage,
  search,
}) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/customers?business=${businessId}&page=${currentPage}&limit=${pageLimit}&search=${search}`,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getCustomerByIdForAdmin(customerId) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/customer/${customerId}`,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function updateCustomerForAdmin({
  customerId,
  firstName,
  lastName,
  email,
  businessName,
  address1,
  address2,
  city,
  state,
  postalCode,
  country,
  phoneNumber,
  emergencyContactFirstName,
  emergencyContactLastName,
  emergencyContactPhoneNumber,
  note,
  gender,
  height1,
  height2,
  weight,
  fitnessLevel,
  stateWildlifeAccount,
  stateWildlifeLogin,
  stateWildlifePassword,
  stateWildlifeLicenseNumber,
  legalToCarryFireArms,
  horsemanshipSkillLevel,
  huntingUnitNumber,
  customerType,
  isJuniorHunter,
  // isWildlifeDonation
}) {
  try {
    let response = await axios.Put({
      url: `${superUrl}/customer/${customerId}`,
      data: {
        firstName,
        lastName,
        email,
        businessName,
        address1,
        address2,
        city,
        state,
        postalCode,
        country,
        phoneNumber,
        emergencyContactFirstName,
        emergencyContactLastName,
        emergencyContactPhoneNumber,
        note,
        gender,
        height1,
        height2,
        weight,
        fitnessLevel,
        stateWildlifeAccount,
        stateWildlifeLogin,
        stateWildlifePassword,
        stateWildlifeLicenseNumber,
        legalToCarryFireArms,
        horsemanshipSkillLevel,
        huntingUnitNumber,
        customerType,
        isJuniorHunter,
        // isWildlifeDonation
      }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}
