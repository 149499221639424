/* eslint-disable array-callback-return */
import { observable, action, decorate } from "mobx";
import {
  getAllReport,
  createReport,
  deleteReport,
  createContactYearReport,
  createCustomerYearReport,
  createCustomerStatusReport,
  createBookingReport,
} from "../services/ReportService";
import deleteWarning from "./DeleteWarningStore";
import momentTimezone from "moment-timezone";
import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
import { Col } from "reactstrap";
import React from "react";
import download from "../assets/download.svg";
import waiting from "../assets/waiting.svg";
import trashCan from "../assets/trash.svg";
import commonStore from "./CommonStore";

class ReportStore {
  editedData = { menu: "BLM" };
  limit = 10;
  page = 0;
  previewArray = [];
  state = "";
  reportArray = [];
  blmReportArray = [];
  previewTotals = [];
  isLoading = false;
  totalCount = 0;
  valueTransformer = {
    url: (data) => {
      if (data.url) {
        return (
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <a href={data.url}>
              <img src={download} alt="" style={{ cursor: "pointer" }} />
            </a>
          </Col>
        );
      } else {
        return (
          <Col style={{ display: "flex", justifyContent: "flex-end" }}>
            <img src={waiting} alt="" style={{ cursor: "pointer" }} />
          </Col>
        );
      }
    },
    deleteData: (data) => {
      return (
        <Col style={{ display: "flex", justifyContent: "flex-start" }}>
          <img
            src={trashCan}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              this.setEditedData({ id: data.id });
              deleteWarning.showWarning();
              // this.deleteForm(data._id);
            }}
          />
        </Col>
      );
    },
    status: (data) => {
      let style = {
        padding: "5px",
        color: "white",
        borderRadius: "5px",
        width: "100px",
        textAlign: "center",
      };
      if (data.status === "RESERVATION")
        style = { ...style, background: "#1665D8" };
      else if (data.status === "COMPLETED")
        style = { ...style, background: "#DD3719" };
      else if (data.status === "BOOKED")
        style = { ...style, background: "#2EAD40" };
      else if (data.status === "DEPOSIT")
        style = { ...style, background: "#1E90FF" };
      return <div style={style}>{data.status}</div>;
    },
  };

  wyomingColumns = [
    { title: "Start Date", name: "startDate" },
    { title: "End Date", name: "endDate" },
    {
      title: "Guide",
      name: "guide",
      valueTransformer: this.valueTransformer["guide"],
    },
    {
      title: " ",
      name: "url",
      valueTransformer: this.valueTransformer["url"],
    },
    {
      title: " ",
      name: "deleteData",
      valueTransformer: this.valueTransformer["deleteData"],
    },
    { title: "Report ID", name: "id" },
  ];
  wyomingColumnWidths = [
    { width: 150, columnName: "startDate" },
    { width: 150, columnName: "endDate" },
    { width: 100, columnName: "guide" },
    { width: 200, columnName: "url" },
    { width: 100, columnName: "deleteData" },
    { width: 200, columnName: "id" },
  ];

  previewColumns = [
    { title: "Full Name", name: "fullName" },
    { title: "City", name: "city" },
    { title: "State", name: "state" },
    {
      title: "Hunting License",
      name: "huntingLicense",
    },
    { title: "Species", name: "species" },
    { title: "Area", name: "area" },
    { title: "License Type", name: "licenseType" },
    { title: "Hunt Date", name: "huntDate" },
    { title: "Kill Date", name: "killDate" },
    { title: "Kill Area", name: "killArea" },
    { title: "Sex", name: "sex" },
    { title: "Guide Name", name: "guide" },
    { title: "Paying", name: "paying" },
  ];
  previewColumnWidths = [
    { width: 200, columnName: "fullName" },
    { width: 100, columnName: "city" },
    { width: 100, columnName: "state" },
    { width: 100, columnName: "url" },
    {
      width: 100,
      columnName: "huntingLicense",
    },
    { width: 100, columnName: "species" },
    { width: 100, columnName: "area" },
    { width: 100, columnName: "licenseType" },
    { width: 100, columnName: "huntDate" },
    { width: 100, columnName: "killDate" },
    { width: 100, columnName: "killArea" },
    { width: 100, columnName: "sex" },
    { width: 100, columnName: "guide" },
    { width: 100, columnName: "paying" },
  ];

  blmPreviewColumns = [
    { title: "Trip Start Date", name: "tripStartDate" },
    { title: "Trip End Date", name: "tripEndDate" },
    { title: "Number of Clients", name: "numberOfClients" },
    { title: "Number Client of Days", name: "clientDays" },
    {
      title: "Number of Guides",
      name: "numberOfGuide",
    },
    { title: "Area or Routes Used", name: "areaOrRoutes" },
    { title: "Gross Receipts", name: "grossReceipts" },

    {
      title: "Pre/Post Trip Lodging Deductions",
      name: "tripLodgingDeductions",
    },
    {
      title: "Pre/Post Trip Transportation Deductions",
      name: "tripTransportationDeductions",
    },
    { title: "% Time Spent on Public Land", name: "timeSpentOnPublicLand" },
  ];
  blmPreviewColumnWidths = [
    { width: 200, columnName: "tripStartDate" },
    { width: 200, columnName: "tripEndDate" },
    { width: 200, columnName: "numberOfClients" },

    { width: 200, columnName: "clientDays" },
    { width: 200, columnName: "numberOfGuide" },
    {
      width: 200,
      columnName: "areaOrRoutes",
    },
    { width: 200, columnName: "grossReceipts" },

    { width: 200, columnName: "tripLodgingDeductions" },
    { width: 200, columnName: "tripTransportationDeductions" },
    { width: 200, columnName: "timeSpentOnPublicLand" },
  ];

  blmColumns = [
    { title: "Start Date", name: "startDate" },
    { title: "End Date", name: "endDate" },
    {
      title: " ",
      name: "url",
      valueTransformer: this.valueTransformer["url"],
    },
    {
      title: " ",
      name: "deleteData",
      valueTransformer: this.valueTransformer["deleteData"],
    },
    { title: "Report ID", name: "id" },
  ];
  blmColumnWidths = [
    { width: 150, columnName: "startDate" },
    { width: 150, columnName: "endDate" },
    { width: 200, columnName: "url" },
    { width: 100, columnName: "deleteData" },
    { width: 200, columnName: "id" },
  ];
  customerYearColumn = [
    { title: "Created Date", name: "createdDate" },
    {
      title: " ",
      name: "url",
      valueTransformer: this.valueTransformer["url"],
    },
    {
      title: " ",
      name: "deleteData",
      valueTransformer: this.valueTransformer["deleteData"],
    },
    { title: "Report ID", name: "_id" },
  ];
  customerYearColumnWidth = [
    { width: 200, columnName: "createdDate" },
    { width: 200, columnName: "_id" },
    { width: 200, columnName: "url" },
    { width: 100, columnName: "deleteData" },
  ];

  customerYearPreviewColumn = [
    { title: "First Name", name: "firstName" },
    { title: "Last Name", name: "lastName" },
    { title: "Service", name: "huntType" },
    { title: "Service year", name: "year" },
  ];
  customerYearPreviewColumnWidths = [
    { width: 200, columnName: "firstName" },
    { width: 200, columnName: "lastName" },
    { width: 200, columnName: "huntType" },
    { width: 200, columnName: "year" },
  ];

  contactYearPreviewColumn = [
    { title: "First Name", name: "firstName" },
    { title: "Last Name", name: "lastName" },
    { title: "Service year", name: "year" },
  ];
  contactYearPreviewColumnWidths = [
    { width: 200, columnName: "firstName" },
    { width: 200, columnName: "lastName" },
    { width: 200, columnName: "year" },
  ];

  customerStatusPreviewColumn = [
    { title: "First Name", name: "firstName" },
    { title: "Last Name", name: "lastName" },
    { title: "Status", name: "bookingStatus" },
  ];
  customerStatusPreviewColumnWidths = [
    { width: 200, columnName: "firstName" },
    { width: 200, columnName: "lastName" },
    { width: 200, columnName: "bookingStatus" },
  ];

  reservationYearColumn = [
    { title: "Start Year", name: "startDate" },
    { title: "End Year", name: "endDate" },
    {
      title: " ",
      name: "url",
      valueTransformer: this.valueTransformer["url"],
    },
    {
      title: " ",
      name: "deleteData",
      valueTransformer: this.valueTransformer["deleteData"],
    },
    { title: "Report ID", name: "id" },
  ];

  reservationYearColumnWidths = [
    { width: 150, columnName: "startDate" },
    { width: 150, columnName: "endDate" },
    { width: 200, columnName: "url" },
    { width: 100, columnName: "deleteData" },
    { width: 200, columnName: "id" },
  ];

  reservationYearPreviewColumn = [
    { title: "Customer Name", name: "customer" },
    { title: "Service Type", name: "serviceType" },
    { title: "Service", name: "service" },
    { title: "Service Year", name: "serviceYear" },
    {
      title: "Status",
      name: "status",
      valueTransformer: this.valueTransformer["status"],
    },
  ];

  reservationYearPreviewColumnWidth = [
    { width: 300, columnName: "customer" },
    { width: 300, columnName: "serviceType" },
    { width: 300, columnName: "service" },
    { width: 250, columnName: "serviceYear" },
    { width: 250, columnName: "status" },
  ];

  setEditedData = (newData) => {
    this.editedData = {
      ...this.editedData,
      ...newData,
    };
  };

  setLoading = (value) => {
    this.isLoading = value;
  };

  setReportState = (value) => {
    this.state = value;
  };

  setCurrentPage = (value) => {
    this.page = value;
    this.getAllReport({ state: this.state });
  };

  getAllReport = async ({ state }) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.isLoading = true;
    const { data, error } = await getAllReport({
      businessId: businessId,
      pageLimit: this.limit,
      currentPage: this.page + 1,
      guide: this.editedData.guide ? this.editedData.guide.value : "",
      startDate: this.editedData.startDate,
      endDate: this.editedData.endDate,
      startDateBooking: this.editedData.startDateBooking
        ? this.editedData.startDateBooking
        : "",
      endDateBooking: this.editedData.endDateBooking
        ? this.editedData.endDateBooking
        : "",
      docType: state,
    });
    this.isLoading = false;

    if (data) {
      if (state === "WYOMING") {
        this.reportArray = data.reports.map((data) => {
          const id = data._id ? data._id : "";
          const startDate = data.startDate
            ? moment(data.startDate).format("MMM DD, YYYY")
            : "";
          const endDate = data.endDate
            ? moment(data.endDate).format("MMM DD, YYYY")
            : "";
          const guide = data.guide
            ? `${data.guide.firstName} ${data.guide.lastName}`
            : "All Guide";
          const url = data.csvLink ? data.csvLink : "";
          const deleteData = data._id ? data._id : "";

          return {
            ...data,
            id,
            startDate,
            endDate,
            guide,
            url,
            deleteData,
          };
        });
        this.totalCount = data.totalData;
      } else if (state === "BLM") {
        this.reportArray = data.reports.map((data) => {
          const id = data._id ? data._id : "";
          const startDate = data.startDate
            ? moment(data.startDate).format("MMM DD, YYYY")
            : "";
          const endDate = data.endDate
            ? moment(data.endDate).format("MMM DD, YYYY")
            : "";

          const url = data.csvLink ? data.csvLink : "";
          const deleteData = data._id ? data._id : "";

          return {
            ...data,
            id,
            startDate,
            endDate,
            url,
            deleteData,
          };
        });
        this.totalCount = data.totalData;
      } else if (state === "CUSTOMERYEAR") {
        this.reportArray = data.reports.map((report) => {
          return {
            ...report,
            url: report.csvLink,
            id: report._id,
            createdDate: moment(report.createdDate).format("MMM DD, YYYY"),
          };
        });
        this.totalCount = data.totalData;
      } else if (state === "CONTACTYEAR") {
        this.reportArray = data.reports.map((report) => {
          return {
            ...report,
            url: report.csvLink,
            id: report._id,
            createdDate: moment(report.createdDate).format("MMM DD, YYYY"),
          };
        });
        this.totalCount = data.totalData;
      } else if (state === "CUSTOMERSTATUS") {
        this.reportArray = data.reports.map((report) => {
          return {
            ...report,
            url: report.csvLink,
            id: report._id,
            createdDate: moment(report.createdDate).format("MMM DD, YYYY"),
          };
        });
        this.totalCount = data.totalData;
      } else if (state === "BOOKING") {
        this.reportArray = data.reports.map((report) => {
          const startDate = new Date(report.startDate);
          const endDate = new Date(report.endDate);
          return {
            ...report,
            startDate: startDate.getFullYear(),
            endDate: endDate.getFullYear(),
            url: report.csvLink,
            id: report._id,
          };
        });
        this.totalCount = data.totalData;
      }
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View Report's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  createReport = async (values, state) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    const { data, error } = await createReport({
      businessId: businessId,
      data: values,
    });

    if (data) {
      this.setEditedData({ isOpenCreateReport: false });

      this.setEditedData({ isOpenNotification: true });

      data.bookingData.splice(-1);

      this.previewArray = [];

      if (state === "Wyoming") {
        data.bookingData.map((bookingData) => {
          const customerFirstName = bookingData.businessCustomer
            ? bookingData.businessCustomer.firstName
            : "";
          const customerLastName = bookingData.businessCustomer
            ? bookingData.businessCustomer.lastName
            : "";
          const state = bookingData.businessCustomer
            ? bookingData.businessCustomer.state
            : "";
          const city = bookingData.businessCustomer
            ? bookingData.businessCustomer.city
            : "";
          const huntingLicense =
            bookingData.businessCustomer &&
            bookingData.businessCustomer.customFieldBusinessCustomer
              ? bookingData.businessCustomer.customFieldBusinessCustomer
                  .huntingLicense
              : "";
          const licenseType =
            bookingData.businessCustomer &&
            bookingData.businessCustomer.customFieldBusinessCustomer
              ? bookingData.businessCustomer.customFieldBusinessCustomer
                  .licenseType
              : "";
          const startDate = bookingData.activitySession
            ? bookingData.activitySession.startTime
            : "";
          const endDate = bookingData.activitySession
            ? bookingData.activitySession.endDate
            : "";
          const guideFirstName = bookingData.guide
            ? bookingData.guide.firstName
            : "";
          const guideLastName = bookingData.guide
            ? bookingData.guide.lastName
            : "";
          const paying = bookingData.customFieldBooking
            ? bookingData.customFieldBooking.paying
            : "";
          const generalData = {
            fullName: customerFirstName + " " + customerLastName,
            state: state,
            city: city,
            huntingLicense: huntingLicense,
            licenseType: licenseType ? licenseType.join(", ") : "",
            guide: guideFirstName + " " + guideLastName,
            huntDate:
              moment(startDate).format("MM/DD/YY") +
              " - " +
              moment(endDate).format("MM/DD/YY"),
            paying: paying ? "Yes" : "No",
          };

          if (
            bookingData.customFieldBooking &&
            bookingData.customFieldBooking.huntDetail.length !== 0
          ) {
            bookingData.customFieldBooking &&
              bookingData.customFieldBooking.huntDetail.map((huntData) => {
                const species = huntData.speciesTaken
                  ? huntData.speciesTaken
                  : "";
                const area = huntData.speciesArea ? huntData.speciesArea : "";
                const killDate = huntData.dateTaken ? huntData.dateTaken : "";
                const sex = huntData.sex ? huntData.sex : "";
                const killArea = huntData.killArea ? huntData.killArea : "";

                const huntDetailData = {
                  species: species ? species.join(", ") : "",
                  area: area ? area.join(", ") : "",
                  killDate: moment(killDate).format("MM/DD/YY"),
                  sex: sex,
                  killArea: killArea,
                };

                const allData = {
                  ...generalData,
                  ...huntDetailData,
                };

                this.previewArray.push(allData);
              });
          } else {
            this.previewArray.push(generalData);
          }
        });
      } else {
        data.bookingData.map((data) => {
          const tripStartDate = data.startTime ? data.startTime : "";
          const tripEndDate = data.endTime ? data.endTime : "";
          const remaining = data.remaining ? data.remaining : "";
          const maxCapacity = data.maxCapacity ? data.maxCapacity : "";
          const price = data.price ? data.price : "";
          const clientDays = data.customFieldActivitySession
            ? data.customFieldActivitySession.clientDays
            : "";
          const numberOfGuide = data.numberOfGuides ? data.numberOfGuides : 0;
          const areaOrRoutes = data.customFieldActivitySession
            ? data.customFieldActivitySession.areaOrRoutes
            : "";
          const tripLodgingDeductions = data.customFieldActivitySession
            ? data.customFieldActivitySession.tripLodgingDeductions
            : "";
          const tripTransportationDeductions = data.customFieldActivitySession
            ? data.customFieldActivitySession.tripTransportationDeductions
            : "";
          const timeSpentOnPublicLand = data.customFieldActivitySession
            ? data.customFieldActivitySession.timeSpentOnPublicLand
            : "";
          const grossReceipts = price * remaining;
          const allData = {
            tripStartDate: moment(tripStartDate).format("MM/DD/YY"),
            tripEndDate: moment(tripEndDate).format("MM/DD/YY"),
            numberOfClients: maxCapacity - remaining,
            clientDays,
            numberOfGuide,
            areaOrRoutes,
            grossReceipts: `$${grossReceipts
              .toFixed(2)
              .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`,
            tripLodgingDeductions,
            tripTransportationDeductions,
            timeSpentOnPublicLand,
          };

          this.previewArray.push(allData);
        });

        this.previewTotals = [
          {
            tripStartDate: "Totals :",
            grossReceipts: data.sumGrossReceipt
              ? `$${data.sumGrossReceipt
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`
              : 0,
            tripLodgingDeductions: data.sumTripLodgingDeductions
              ? `$${data.sumTripLodgingDeductions
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`
              : 0,
            tripTransportationDeductions: data.sumTripTransportationDeductions
              ? `$${data.sumTripTransportationDeductions
                  .toFixed(2)
                  .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}`
              : 0,
          },
        ];

        this.previewArray = [...this.previewArray, ...this.previewTotals];
      }

      // console.log(toJS(this.previewArray));

      this.getAllReport({ state: this.state });
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View Report's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  deleteReport = async (reportId) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.isLoading = true;
    const { data, error } = await deleteReport({
      businessId: businessId,
      reportId: reportId,
    });
    this.isLoading = false;

    if (!data) {
      this.getAllReport({ state: this.state });
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View Report's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  createCustomerYearReport = async (year) => {
    this.loading = true;
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    const startDate = new Date(year, 0, 1);
    const endDate = new Date(year, 12, 1);

    const value = {
      startDate: moment(startDate).format("YYYY-MM-DDThh:mm:ss.000Z"),
      endDate: moment(endDate).format("YYYY-MM-DDThh:mm:ss.000Z"),
      year: String(year),
      tz: momentTimezone.tz.guess(),
    };

    const { data, error } = await createCustomerYearReport(
      value,
      JSON.parse(businessId)
    );
    this.previewArray = [];

    if (data) {
      this.setEditedData({ isOpenCreateReport: false });

      this.setEditedData({ isOpenNotification: true });

      data &&
        data.bookingData.map((bookingData) => {
          return this.previewArray.push(bookingData);
        });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View Report's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }

    this.loading = false;
  };

  createContactYearReport = async (year) => {
    this.loading = true;
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    const startDate = new Date(year, 0, 1);
    const endDate = new Date(year, 12, 1);

    const value = {
      startDate: moment(startDate).format("YYYY-MM-DDThh:mm:ss.000Z"),
      endDate: moment(endDate).format("YYYY-MM-DDThh:mm:ss.000Z"),
      year: String(year),
      tz: momentTimezone.tz.guess(),
    };

    const { data, error } = await createContactYearReport(
      value,
      JSON.parse(businessId)
    );

    this.previewArray = [];

    if (data) {
      this.setEditedData({ isOpenCreateReport: false });

      this.setEditedData({ isOpenNotification: true });

      data &&
        data.bookingData.map((bookingData) => {
          return this.previewArray.push(bookingData);
        });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View Report's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }

    this.loading = false;
  };

  createCustomerStatusReport = async (value) => {
    this.loading = true;
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    const { data, error } = await createCustomerStatusReport(
      { status: value, tz: momentTimezone.tz.guess() },
      JSON.parse(businessId)
    );
    this.previewArray = [];

    if (data) {
      this.setEditedData({ isOpenCreateReport: false });

      this.setEditedData({ isOpenNotification: true });

      data &&
        data.bookingData.map((bookingData) => {
          return this.previewArray.push(bookingData);
        });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View Report's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }

    this.loading = false;
  };

  createReservationYearReport = async (year) => {
    this.loading = true;
    if (year.startDate > year.endDate) {
      this.loading = false;
      return commonStore.showErrorMessage(
        "End year must be greater than start year"
      );
    }
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");
    const startDate = new Date(year.startDate, 0, 1);
    const endDate = new Date(year.endDate, 12, 0);
    const value = {
      startDate: moment(startDate).format("YYYY-MM-DDTHH:mm:ss.000Z"),
      endDate: moment(endDate).format("YYYY-MM-DDTHH:mm:ss.000Z"),
    };
    const { data, error } = await createBookingReport(
      value,
      JSON.parse(businessId)
    );
    this.previewArray = [];

    if (data) {
      this.setEditedData({ isOpenCreateReport: false });

      this.setEditedData({ isOpenNotification: true });

      data &&
        data.bookingData.map((bookingData) => {
          return this.previewArray.push(bookingData);
        });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View Report's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }

    this.loading = false;
  };
}
decorate(ReportStore, {
  editedData: observable,
  limit: observable,
  page: observable,
  reportArray: observable,
  totalCount: observable,
  isLoading: observable,
  previewArray: observable,
  blmReportArray: observable,
  state: observable,
  previewTotals: observable,

  setCurrentPage: action,
});

export default new ReportStore();
