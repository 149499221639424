import React, { Component } from "react";
import { Col, Row, Card, CardBody } from "reactstrap";
import Text from "../../components/Texts";
import Buttons from "../../components/Buttons";
import { inject, observer } from "mobx-react";

class EmailLogin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            params: ""
        };
    }


    componentDidMount = () => {
        const params = new URLSearchParams(this.props.location.search);
        const tags = params.get("code");
        this.setState({ params: tags })

    }

    render() {
        const { businessStore, history } = this.props;
        const { params } = this.state;

        return (
            <div style={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "50%"
            }}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col lg="12" style={{ textAlign: "center" }}>
                                <Text
                                    text="You are about to connect your account to Google"
                                    textType="h2"
                                    weight="medium"
                                />
                            </Col>
                            <Col lg="6">
                                <Buttons
                                    buttonType={"primary"}
                                    label={"Accept"}
                                    onClick={() => {
                                        businessStore.registerUrl(params, history)
                                    }}
                                    style={{
                                        height: "40px",
                                        marginTop: "10px",
                                        width: "100%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}
                                />
                            </Col>
                            <Col lg="6">
                                <Buttons
                                    buttonType={"delete"}
                                    label={"Decline"}
                                    style={{
                                        height: "40px",
                                        marginTop: "10px",
                                        width: "100%",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis"
                                    }}
                                    onClick={() => {
                                        window.close();
                                    }}
                                />
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        )
    }
}

export default inject("businessStore")(observer(EmailLogin));