import { observable, decorate } from "mobx";
import {
  getAllAdmin,
  getAdminById,
  createAdmin,
  updateAdmin
} from "../services/AdminService";
import commonStore from "./CommonStore";
// import { common } from "@material-ui/core/colors";

class AdminStore {
  editedData = {};
  adminArray = [];
  currentPage = 0;
  pageSize = 10;
  totalCount = 0;
  isLoading = false;
  searchValue = "";
  adminColumns = [
    {
      name: "_id",
      title: "Admin ID"
    },
    {
      name: "name",
      title: "Admin Name"
    },
    {
      name: "email",
      title: "Email"
    }
  ];
  adminColumnWidths = [
    {
      columnName: "_id",
      width: 250
    },
    {
      columnName: "name",
      width: 250
    },
    {
      columnName: "email",
      width: 250
    }
  ];

  setEditedData = editedData => {
    this.editedData = {
      ...this.editedData,
      ...editedData
    };
  };

  setSearchValue = value => {
    this.currentPage = 0;
    this.searchValue = value;
  };

  setCurrentPage = value => {
    this.currentPage = value;
    this.getAllAdmin();
  };

  getAllAdmin = async () => {
    this.isLoading = true;

    const { data, error } = await getAllAdmin({
      page: this.currentPage + 1,
      limit: this.pageSize,
      search: this.searchValue
    });

    this.isLoading = false;

    if (data) {
      this.adminArray = data.activities.map(data => {
        const _id = data._id ? data._id : "";
        const name = data.name ? data.name : "";
        const email = data.email ? data.email : "";

        return {
          ...data,
          _id,
          name,
          email
        };
      });
      this.totalCount = data.totalData;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getAdminById = async adminId => {
    this.isLoading = true;

    const { data, error } = await getAdminById(adminId);

    this.isLoading = false;

    if (data) {
      this.setEditedData({
        name: data.name ? data.name : "",
        email: data.email ? data.email : "",
        isActive: data.isActive ? data.isActive : false
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  createAdmin = async ({ value, history }) => {
    this.isLoading = true;

    const { data, error } = await createAdmin(value);

    this.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Admin Added");
      history.push("/admin/adminlist");
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  updateAdmin = async ({ adminId, value, history }) => {
    this.isLoading = true;

    const { data, error } = await updateAdmin({
      adminId: adminId,
      value: value
    });

    this.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Admin Updated");
      history.push("/admin/adminlist");
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };
}
decorate(AdminStore, {
  editedData: observable,
  adminArray: observable,
  currentPage: observable,
  pageSize: observable,
  totalCount: observable,
  searchValue: observable,
  isLoading: observable
});
export default new AdminStore();
