/* eslint-disable array-callback-return */
import { Col, Row } from "reactstrap";
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
// import _ from "lodash";
import { Formik, FastField, Form, Field } from "formik";
import Text from "../../components/Texts";
// import momentTimezone from "moment-timezone";
import Buttons from "../../components/Buttons";
import { validateFormValuesUsingJoiSchema } from "../../helper/functions";
import FormikInputField from "../../components/Formik/FormikInputField";
import FormikImageUpload from "../../components/Formik/FormikImageUpload";
import FormikAsyncSelect from "../../components/Formik/FormikAsyncSelect";
import FormikSelectField from "../../components/Formik/FormikSelectField";
import FormikPhoneInput from "../../components/Formik/FormikPhoneInput";
import FormikTimezonePicker from "../../components/Formik/FormikTimezonePicker";
import csc from "country-state-city";
import BlockUI from "react-block-ui";
import { Loader } from "react-loaders";
import "react-block-ui/style.css";
import "loaders.css/loaders.min.css";
// import { log } from "util";

const Joi = require("joi-browser");
Joi.objectId = require("joi-objectid")(Joi);

const joiSchema = {
  businessName: Joi.string()
    .label("Business Name")
    .required(),
  address1: Joi.string()
    .label("Business Address")
    .required(),
  city: Joi.label("City").required(),
  state: Joi.label("State").required(),
  postalCode: Joi.label("Postal Code").required(),
  country: Joi.label("Country").required(),
  phoneNumber: Joi.string()
    .label("Phone Number")
    .required(),
  timezone: Joi.string()
    .label("Timezone")
    .required(),
  businessType: Joi.string()
    .label("Business Type")
    .required()
};

class CreateBusiness extends Component {
  submit = async (values, actions) => {
    this.props.authStore.createBusiness(
      values.businessName,
      values.businessType,
      values.address1,
      values.address2,
      values.city ? values.city : "",
      values.state.label ? values.state.label : values.state,
      values.postalCode,
      values.country.label,
      values.phoneNumber,
      values.website,
      values.logo,
      values.timezone
    );

    actions.setSubmitting(false);
  };

  componentDidMount = () => {
    const { businessStore } = this.props;
    businessStore.getBusinessType();
  };

  render() {
    const { authStore, businessStore } = this.props;
    const { authLoading } = authStore;
    const { businessType } = businessStore;
    const countries = csc.getAllCountries();
    let arrayCountries = countries.map(data => {
      const { name, isoCode } = data;
      return {
        label: `${name}`,
        value: `${isoCode}`
      };
    });

    // let timezone = momentTimezone.tz.names();

    // let timezoneArray = timezone.map(data => {
    //   return {
    //     label: data,
    //     value: data
    //   };
    // });

    const temp = [arrayCountries[232]];

    arrayCountries.map(array => {
      temp.push(array);
    });

    arrayCountries = temp;

    arrayCountries.splice(233, 1);

    const initialValues = {
      logo: "",
      name: "",
      businessType: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      country: "",
      postalCode: "",
      phoneNumber: "",
      website: "",
      timezone: ""
    };

    return (
      <Formik
        initialValues={initialValues}
        validate={values =>
          validateFormValuesUsingJoiSchema({ values, joiSchema })
        }
        validateOnChange={false}
        enableReinitialize={true}
        onSubmit={this.submit}
        component={({ values, handleSubmit, setFieldValue, isSubmitting }) => {
          return (
            <div
              className="animated fadeIn app "
              style={{
                backgroundColor: "white",
                display: "flex",
                alignItems: "center"
              }}
            >
              <BlockUI
                tag="div"
                blocking={authLoading}
                loader={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Loader
                      active
                      type={"ball-spin-fade-loader"}
                      color="#1665d8"
                    />
                  </div>
                }
              >
                <Form>
                  <Row>
                    <Col
                      lg="12"
                      style={{
                        marginTop: "150px",
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <Text
                        text="Create Account"
                        textType="h1"
                        weight="medium"
                      />
                    </Col>
                    <Col
                      lg="12"
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      <Text
                        text="Create your first business unit. You can also add another business unit in the future."
                        textType="body1"
                        weight="regular"
                        required=" *"
                      />
                    </Col>
                    <Col
                      lg="12"
                      style={{
                        marginTop: "15px",
                        display: "flex",
                        justifyContent: "center",
                        padding: " 0 30%"
                      }}
                    >
                      <Row>
                        <Col lg="6" style={{ marginTop: "50px" }}>
                          <Text
                            text="Logo"
                            textType="caption"
                            weight="medium"
                          />
                          <FastField
                            name={"logo"}
                            folderPath={"Worksprings/business/logo"}
                            component={FormikImageUpload}
                          />
                        </Col>
                        <Col lg="6" />
                        <Col lg="6" style={{ marginTop: "10px" }}>
                          <Text
                            text="Business Name"
                            textType="caption"
                            weight="medium"
                            required=" *"
                          />
                          <FastField
                            name={"businessName"}
                            id="outlined-name"
                            variant="outlined"
                            className="w-100"
                            placeholder={"Business Name"}
                            component={FormikInputField}
                          />
                        </Col>
                        <Col lg="6" style={{ marginTop: "10px" }}>
                          <Text
                            text="Business Type"
                            textType="caption"
                            weight="medium"
                            required=" *"
                          />
                          <FastField
                            name={"businessType"}
                            id="outlined-name"
                            variant="outlined"
                            className="w-100"
                            options={businessType}
                            component={FormikSelectField}
                          />
                        </Col>{" "}
                        <Col lg="6" style={{ marginTop: "10px" }}>
                          <Text
                            text="Business Address 1"
                            textType="caption"
                            weight="medium"
                            required=" *"
                          />
                          <FastField
                            name={"address1"}
                            id="outlined-name"
                            variant="outlined"
                            className="w-100"
                            placeholder={"Business Address 1"}
                            component={FormikInputField}
                          />
                        </Col>
                        <Col lg="6" style={{ marginTop: "10px" }}>
                          <Text
                            text="Business Address 2"
                            textType="caption"
                            weight="medium"
                          />
                          <FastField
                            name={"address2"}
                            id="outlined-name"
                            variant="outlined"
                            className="w-100"
                            placeholder={"Business Address 2"}
                            component={FormikInputField}
                          />
                        </Col>
                        <Col lg="6" style={{ marginTop: "10px" }}>
                          <Text
                            text="Country"
                            textType="caption"
                            weight="medium"
                            required=" *"
                          />
                          <Field
                            name={"country"}
                            options={arrayCountries}
                            styles={{
                              control: base => ({
                                ...base,
                                height: 40,
                                minHeight: 40
                              })
                            }}
                            className={"zIndexku"}
                            component={FormikAsyncSelect}
                          />
                        </Col>
                        <Col lg="6" md="6" style={{ marginTop: "10px" }}>
                          <Text
                            text="State "
                            textType="caption"
                            weight="medium"
                            required=" *"
                          />
                          {values.country.label !== "United States" ? (
                            <Field
                              name={"state"}
                              value={typeof values.state === 'string' ? values.state : values.state.label}
                              id="outlined-name"
                              variant="outlined"
                              className="w-100"
                              placeholder={"State"}
                              component={FormikInputField}
                            />
                          ) : (
                            <Field
                              name={"state"}
                              state={true}
                              value={typeof values.state === 'string' ? { label: values.state, value: values.state } : values.state}
                              countryId={String(values.country.value)}
                              className={"zIndexku1"}
                              styles={{
                                control: base => ({
                                  ...base,
                                  height: 40,
                                  minHeight: 40
                                })
                              }}
                              component={FormikAsyncSelect}
                            />
                          )}
                        </Col>
                        <Col lg="6" md="6" style={{ marginTop: "10px" }}>
                          <Text
                            text="City "
                            textType="caption"
                            weight="medium"
                            required=" *"
                          />
                          {/* {values.country.label !== "United States" ? ( */}
                          <Field
                            name={"city"}
                            id="outlined-name"
                            variant="outlined"
                            className="w-100"
                            placeholder={"City"}
                            component={FormikInputField}
                          />
                          {/* ) : (
                            <Field
                              name={"city"}
                              city={true}
                              className={"zIndexku2"}
                              stateId={String(values.state.value)}
                              isDisabled={!values.state.value}
                              styles={{
                                control: base => ({
                                  ...base,
                                  height: 40,
                                  minHeight: 40
                                })
                              }}
                              component={FormikAsyncSelect}
                            />
                          )} */}
                        </Col>
                        <Col lg="6" style={{ marginTop: "10px" }}>
                          <Text
                            text="Zip / Postal Code"
                            textType="caption"
                            weight="medium"
                            required=" *"
                          />
                          <FastField
                            name={"postalCode"}
                            id="outlined-name"
                            variant="outlined"
                            className="w-100"
                            placeholder={"Zip / Postal Code"}
                            component={FormikInputField}
                          />
                        </Col>
                        <Col lg="6" style={{ marginTop: "10px" }}>
                          <Text
                            text="Phone Number"
                            textType="caption"
                            weight="medium"
                            required=" *"
                          />
                          <FastField
                            name={"phoneNumber"}
                            defCountry={"us"}
                            component={FormikPhoneInput}
                          />
                        </Col>
                        <Col lg="6" style={{ marginTop: "10px" }}>
                          <Text
                            text="Website"
                            textType="caption"
                            weight="medium"
                          />
                          <FastField
                            name={"website"}
                            id="outlined-name"
                            variant="outlined"
                            className="w-100"
                            placeholder={"Website"}
                            component={FormikInputField}
                          />
                        </Col>
                        <Col lg="6" style={{ marginTop: "10px" }}>
                          <Text
                            text="Timezone"
                            textType="caption"
                            weight="medium"
                            required=" *"
                          />
                          <Field
                            name={"timezone"}
                            component={FormikTimezonePicker}
                          />
                        </Col>
                        <Col lg="12" style={{ marginTop: "10px" }}>
                          <Buttons
                            buttonType={"secondary"}
                            label={"Create Account"}
                            type="submit"
                            style={{
                              height: "40px",
                              marginTop: "20px",
                              width: "100%",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              marginBottom: "100px"
                            }}
                            disabled={isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
              </BlockUI>
            </div>
          );
        }}
      />
    );
  }
}
export default inject("businessStore", "authStore")(observer(CreateBusiness));
