import commonStore from "./CommonStore";
import {
  signIn,
  register,
  forgotPassword,
  resetPassword,
  verifyEmail,
  signOut,
  adminLogin
} from "../services/AuthService";
import notificationStore from "./NotificationStore";
import { createBusiness } from "../services/BusinessService";
import businessStore from "./BusinessStore";
import {
  setCookie,
  getCookie,
  deleteAllCookies,
  deleteAllCookiesAdmin
} from "../helper/cookie";
import { askForPermissionToReceiveNotification } from "../push-notification";
import { observable, action, decorate, computed } from "mobx";
import { reactLocalStorage } from "reactjs-localstorage";

const Joi = require("joi-browser");
Joi.objectId = require("joi-objectid")(Joi);

class AuthStore {
  currentToken = null;
  currentBusiness = null;
  currentState = null;
  accountData = null;
  permission = null;
  badgeCounter = 0;
  authLoading = false;
  role = {};
  link = { value: "link", name: "+ Add New Account" };
  businessArray = [];
  isSuperAdmin = null;

  editedData = {};

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  badgeCounterIncrement = () => {
    this.badgeCounter = this.badgeCounter + 1;
  };

  constructor() {
    const currentBusinessString = reactLocalStorage.get(
      "huntingCurrentBusiness"
    );
    if (currentBusinessString) {
      this.currentBusiness = JSON.parse(currentBusinessString);
    }
    const currentTokenString = getCookie("hunting_current_token");
    if (currentTokenString) {
      this.currentToken = currentTokenString;
    }
  }

  signIn = async ({ email, password, history }) => {
    this.authLoading = true;
    let { data, error } = await signIn({
      email,
      password
    });

    if (data) {
      if (
        data.employee &&
        data.employee.business &&
        data.employee.business.length === 0
      ) {
        let token;
        if (!localStorage.getItem("notification-token")) {
          token = await askForPermissionToReceiveNotification();
        }
        this.accountData = {
          _id: data.employee && data.employee._id ? data.employee._id : "",
          firstName:
            data.employee && data.employee.firstName
              ? data.employee.firstName
              : "",
          lastName:
            data.employee && data.employee.lastName
              ? data.employee.lastName
              : "",
          isOwner:
            data.employee && data.employee.isOwner ? data.employee.isOwner : "",
          createdDate:
            data.employee && data.employee.createdDate
              ? data.employee.createdDate
              : "",
          updatedDate:
            data.employee && data.employee.updatedDate
              ? data.employee.updatedDate
              : "",
          email: data.employee && data.employee.email ? data.employee.email : ""
        };

        reactLocalStorage.setObject("account", this.accountData);

        this.currentToken = data && data.token;
        setCookie("hunting_current_token", this.currentToken, 86400000);

        let notificationToken = {
          token: token
        };

        this.authLoading = false;

        history.push(`/createBusiness`);

        await notificationStore.setToken(notificationToken);
      } else {
        const {
          employee: { isOwner }
        } = data;

        let token;
        if (!localStorage.getItem("notification-token")) {
          token = await askForPermissionToReceiveNotification();
        }
        this.accountData = {
          _id: data.employee && data.employee._id ? data.employee._id : "",
          firstName:
            data.employee && data.employee.firstName
              ? data.employee.firstName
              : "",
          lastName:
            data.employee && data.employee.lastName
              ? data.employee.lastName
              : "",
          isOwner,
          createdDate:
            data.employee && data.employee.createdDate
              ? data.employee.createdDate
              : "",
          updatedDate:
            data.employee && data.employee.updatedDate
              ? data.employee.updatedDate
              : "",
          email: data.employee && data.employee.email ? data.employee.email : ""
        };

        this.currentBusiness = data && data.employee.business[0]._id;
        this.currentToken = data && data.token;
        this.currentState = data && data.employee.business[0].state;

        reactLocalStorage.setObject(
          "huntingCurrentBusiness",
          this.currentBusiness
        );
        reactLocalStorage.setObject("currentState", this.currentState);

        if (data.employee && data.employee.isOwner === false) {
          this.permission = data.employee.role && data.employee.role.permission;
          reactLocalStorage.setObject("permission", this.permission);
        }
        reactLocalStorage.setObject("account", this.accountData);
        await setCookie("hunting_current_token", this.currentToken, 86400000);

        let notificationToken = {
          token: token
        };

        let tempBusinessArray = data.employee.business.map((data, index) => {
          const value = data._id ? data._id : "-";
          const name = data.name ? data.name : "-";

          return {
            ...data,
            value,
            name
          };
        });
        tempBusinessArray.push(this.link);
        this.businessArray = tempBusinessArray;

        if (data.employee && data.employee.isOwner === true) {
          // const licenseState = data.employee.licenses.map(data => {
          //   return data.state
          // })

          localStorage.setItem(
            "license",
            data.employee.licenses ? JSON.stringify(data.employee.licenses) : []
          );
        } else if (data.employee && data.employee.isOwner === false) {
          localStorage.setItem(
            "employeeLicense",
            data.employee.licenses ? JSON.stringify(data.employee.licenses) : []
          );
        }

        reactLocalStorage.setObject("businessArray", this.businessArray);
        reactLocalStorage.setObject(
          "currentBusinessName",
          data && data.employee.business[0].name
        );

        this.authLoading = false;

        history.push(`/businesses/${this.currentBusiness}/dashboard`);

        await notificationStore.setToken(notificationToken);
      }
    } else {
      this.authLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;

        if (this.errorMessage === "Email not confirmed yet") {
          history.push(`/verifyEmail?email=${email}`);
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  register = async ({ firstName, lastName, email, password }) => {
    this.authLoading = true;
    let { data, error } = await register({
      firstName,
      lastName,
      email,
      password
    });
    this.authLoading = false;

    if (data) {
      await new Promise((resolve, reject) => {
        commonStore.showSuccessMessage("Verify your email to proceed");
        setTimeout(resolve, 1000);
      });
      window.location.href = "/login";
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  createBusiness = async (
    businessName,
    businessType,
    address1,
    address2,
    city,
    state,
    postalCode,
    country,
    phoneNumber,
    website,
    logo,
    timezone
  ) => {
    city = city && city.label ? city.label : city;
    state = state && state.label ? state.label : state;
    country = country && country.label ? country.label : country;
    this.authLoading = true;
    const { data, error } = await createBusiness({
      businessName,
      businessType,
      address1,
      address2,
      city,
      state,
      postalCode,
      country,
      phoneNumber,
      website,
      logo,
      timezone
    });
    this.authLoading = false;
    if (data) {
      this.currentBusiness = data._id;
      reactLocalStorage.setObject(
        "huntingCurrentBusiness",
        this.currentBusiness
      );
      let tempBusinessArray = [{ ...data, value: data._id, name: data.name }];
      tempBusinessArray.push(this.link);
      this.businessArray = tempBusinessArray;
      reactLocalStorage.setObject("businessArray", this.businessArray);
      reactLocalStorage.setObject("currentBusinessName", data && data.name);
      reactLocalStorage.setObject("currentState", data && data.state);
      await businessStore.getEmployeeLicense(
        data.employee && data.employee._id ? data.employee._id : ""
      );

      localStorage.setItem(
        "license",
        JSON.stringify(businessStore.businessLicenseArray)
      );
      window.location.href = "/businesses/" + data._id + "/dashboard";
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  forgotPassword = async email => {
    let { data, error } = await forgotPassword(email);

    if (data) {
      commonStore.showSuccessMessage("Email Sent !");
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  resetPassword = async (password, token) => {
    let { data, error } = await resetPassword(password, token);

    if (!data) {
      await new Promise((resolve, reject) => {
        commonStore.showSuccessMessage(
          "Password has been successfully changed "
        );
        setTimeout(resolve, 7000);
      });
      window.location.href = "/login";
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  verifyEmail = async email => {
    this.authLoading = true;
    let { data, error } = await verifyEmail(email);
    this.authLoading = false;

    if (data) {
      await new Promise((resolve, reject) => {
        commonStore.showSuccessMessage("Email verification has been sent");
        setTimeout(resolve, 1000);
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  goToRegister = history => {
    history.push("/register");
  };

  get isAuthenticated() {
    return !!this.currentBusiness && !!this.currentToken;
  }

  signOut = async () => {
    let token = {
      token: localStorage.getItem("notification-token")
        ? localStorage.getItem("notification-token")
        : ""
    };

    await signOut(token);

    await reactLocalStorage.clear();
    localStorage.clear();
    await deleteAllCookies();
    this.currentBusiness = null;
    this.currentToken = null;
    localStorage.setItem("loginType", "Business");
    window.location.href = "/login";
  };

  //-----------------------------------------------------------Super Admin------------------------------------------------------------

  adminLogin = async ({ email, password, history }) => {
    this.authLoading = true;
    let { data, error } = await adminLogin({
      email,
      password
    });

    if (data) {
      this.currentToken = data && data.token;
      setCookie("hunting_current_token", this.currentToken, 86400000);
      localStorage.setItem("admin", data && data.admin.adminType);

      history.push(`/admin/dashboard`);
      this.authLoading = false;
    } else {
      this.authLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Email not confirmed yet") {
          history.push(`/verifyEmail?email=${email}`);
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  get isAdminAuthenticated() {
    return !!this.currentToken;
  }

  signOutAdmin = async () => {
    localStorage.clear();
    this.currentToken = null;
    await deleteAllCookiesAdmin();
    // await deleteCookiesByName("hunting_current_token");
    // localStorage.setItem("loginType", "Admin");
    // setTimeout(console.log("asd"), 10000);
    window.location.href = "/admin/login";
  };
}
decorate(AuthStore, {
  currentBusiness: observable,
  currentToken: observable,
  currentState: observable,
  authLoading: observable,
  editedData: observable,
  businessArray: observable,
  role: observable,
  permission: observable,
  isSuperAdmin: observable,
  signIn: action,
  isAuthenticated: computed,
  signOut: action,
  signOutAdmin: action,
  badgeCounter: observable
});
export default new AuthStore();
