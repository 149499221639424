import React, { Component } from "react";
import { FormText } from "reactstrap";
import formStore from "../../stores/FormStore";
import close from "../../assets/close.svg";
import commonStore from "../../stores/CommonStore";
import LoadingCircular from "../../components/LoadingCircular";
// import Buttons from "../../components/Buttons";
import Text from "../../components/Texts";
import Dropzone from "react-dropzone";
import classNames from "classnames";
import { Col, Row } from "reactstrap";
// import { toJS } from "mobx";

class FormikFileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  deleteImg = (name, setFieldValue) => {
    setFieldValue(name, "");
    this.setState({ isLoading: false });
  };

  handleDrop = async (accepted, rejected, files, setFieldValue, name) => {
    this.setState({ accepted, rejected });
    if (accepted.length > 0) {
      const file = accepted[0];

      let reader = new FileReader();
      reader.onloadend = async () => {
        this.setState({ isLoading: true });

        const { data, error } = await formStore.uploadFile(file);
        if (data) {

          setFieldValue(name, data);
          formStore.setEditedData({
            fileName: `${data.fileName.split(".", 1)}`,
            name: data.fileName

            // form: formStore.editedData.form
            //   ? formStore.editedData.form
            //   : `${data.fileName.split(".", 1)}`
          });

          // await this.setState({ fileName: data.fileName });
        } else {
          if (error.data) {
            this.errorMessage = error.data.message;
            if (this.errorMessage === "Forbidden.") {
              commonStore.showErrorMessage(
                "You don't have any permission to View employee's data"
              );
            } else {
              commonStore.showErrorMessage(this.errorMessage);
            }
          } else {
            commonStore.showErrorMessage(
              "Something went wrong. Please try again later"
            );
          }
        }
      };
      reader.readAsDataURL(accepted[0]);
    }
  };

  render() {
    const {
      field: { name, value },
      form: { errors, setFieldValue },
      formText
    } = this.props;
    const isError = !!errors && !!errors[name];
    const image = value;

    const { isLoading } = this.state;
    return (
      <React.Fragment>
        {!image ? (
          <Dropzone
            onDrop={(accepted, rejected, files) =>
              this.handleDrop(accepted, rejected, files, setFieldValue, name)
            }
            multiple={false}
            maxFileSize="10000"
          >
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div
                  {...getRootProps()}
                  className={classNames(
                    "dropzone",
                    {
                      "dropzone--isActive": isDragActive
                    },
                    "upload-box2"
                  )}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p />
                  ) : (
                      <div className="upload-container">
                        {!!isLoading && <LoadingCircular />}
                        {!isLoading && (
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <Text
                              textType="body2"
                              text="Drag & drop your file here, or"
                              style={{ textAlign: "center" }}
                            />
                            <Text
                              textType="body2"
                              text="Choose file from your computer"
                              style={{
                                textAlign: "center",
                                color: "#01953A",
                                textDecoration: "underline",
                                cursor: "pointer"
                              }}
                              onClick={this.handleDrop}
                            />
                          </div>
                        )}
                      </div>
                    )}
                </div>
              );
            }}
          </Dropzone>
        ) : (
            <Row
              style={{
                backgroundColor: "#f5f5f5",
                backgroundSize: "cover",
                height: "30px"
              }}
            >
              <Col
                lg="10"
                md="10"
                sm="10"
                xs="10"
                style={{
                  whiteSpace: "nowrap",
                  alignItems: "center",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  padding: "unset",
                  width: "100px",
                  height: "100%"
                }}
              >
                <Text
                  textType="caption"
                  text={formStore.editedData.name}
                  style={{ verticalAlign: "sub" }}
                />
              </Col>
              <Col
                lg="2"
                md="2"
                sm="2"
                xs="2"
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "unset"
                }}
              >
                <img
                  src={close}
                  alt=""
                  style={{ width: "10px", cursor: "pointer" }}
                  onClick={() => this.deleteImg(name, setFieldValue)}
                />
              </Col>
              {/* <span>{fileName}</span>
            <div
              style={{
                width: "fit-content",
                position: "absolute",
                bottom: 5,
                right: 5
              }}
            >
              <i
                className="cui-trash icons font-2xl d-block mt-4"
                style={{
                  color: "#ffffff",
                  backgroundColor: "#a10006"
                }}
              />
            </div> */}
            </Row>
          )}
        {/* <span className="upload-image-alert">
          Max file 10MB {this.handleDrop.imageName}
        </span> */}

        {!!isError && <p className="text-danger">{errors[name]}</p>}
        {!!formText && <FormText>{formText}</FormText>}
      </React.Fragment>
    );
  }
}

export default FormikFileUpload;
