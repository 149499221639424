import {
  postHuntType,
  getAllHuntType,
  getHuntTypeById,
  putHuntType,
  deleteHuntType,
  getAllHuntTypeForAdmin,
  getHuntTypeByIdForAdmin,
  putHuntTypeForAdmin
} from "../services/HuntService";
import tabStore from "./TabStore";
import { observable, action, decorate } from "mobx";
import { reactLocalStorage } from "reactjs-localstorage";
import CommonStore from "./CommonStore";
import huntSession from "./HuntSessionStore";

class HuntTypeStore {
  isLoading = false;
  editedData = {};
  currentPage = 0;
  pageSize = 50;
  searchValue = "";
  deleteLoading = false;
  totalCount = 0;
  huntArray = [];
  columns = [
    { name: "name", title: "Service Type" },
    { name: "activityID", title: "Service Type ID" }
  ];
  columnWidths = [
    { columnName: "name", width: 200 },
    { columnName: "activityID", width: 200 }
  ];

  setEditedData = editedData => {
    this.editedData = {
      ...this.editedData,
      ...editedData
    };
  };

  setHuntArray = value => {
    this.huntArray = value;
  };

  setSearchValue = value => {
    this.currentPage = 0;
    this.searchValue = value;
  };

  setInitialData = () => {
    this.huntArray = [];
  };

  initialCurrentPage = value => {
    this.currentPage = 0;
  };

  setPageLimit = value => {
    this.pageSize = value;
  };

  setCurrentPage = businessId => value => {
    this.currentPage = value;
    this.getAllHuntType(businessId);
  };

  setIsLoading = boolStatus => {
    this.isLoading = boolStatus;
  };

  getAllHuntType = async () => {
    this.isLoading = true;
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    const { data, error } = await getAllHuntType({
      businessId: JSON.parse(businessId),
      currentPage: this.currentPage + 1,
      pageLimit: this.pageSize,
      search: this.searchValue
    });

    if (data) {
      this.isLoading = false;
      this.huntArray = data.activities
        ? data.activities.map(data => {
          const _id = data._id ? data._id : "";
          const activityID = data.activityID ? data.activityID : "";
          const name = data.name ? data.name : "";

          return {
            ...data,
            _id,
            activityID,
            name
          };
        })
        : [];

      this.totalCount = data.totalData;
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "You don't have any permission to View Hunt Type's data"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getHuntTypeById = async (businessId, huntId) => {
    this.isLoading = true;
    const { data, error } = await getHuntTypeById(businessId, huntId);
    this.isLoading = false;
    if (data) {
      this.setEditedData({
        name: data.name,
        price: String(data.price),
        activityID: data.activityID,
        imageUrl: data.imageUrl
      });
      huntSession.setEditedData({ price: String(data.price) });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "You don't have any permission to View Hunt Type's data"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  postHuntType = async values => {
    const huntTypeDTO = {
      name: values.huntName,
      price: values.price,
      imageUrl: values.imageUrl
    };
    this.isLoading = true;
    const currentBusiness = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    const { data, error } = await postHuntType(huntTypeDTO, currentBusiness);
    this.isLoading = false;
    if (data) {
      await new Promise((resolve, reject) => {
        CommonStore.showSuccessMessage("Data added!");
        setTimeout(resolve, 500);
      });
      return true;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }

      return false;
    }
  };

  putHuntType = async ({ values, huntId }) => {
    const huntTypeDTO = {
      name: values.huntName,
      price: Number(values.price),
      imageUrl: values.imageUrl
    };

    this.isLoading = true;
    const currentBusiness = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    const { data, error } = await putHuntType(
      huntTypeDTO,
      currentBusiness,
      huntId
    );
    this.isLoading = false;
    if (data) {
      await new Promise((resolve, reject) => {
        CommonStore.showSuccessMessage("Data Updated!");
        setTimeout(resolve, 500);
      });
      return true;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
      return false;
    }
  };

  deleteHuntType = async huntId => {
    const currentBusiness = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.deleteLoading = true;
    const { error } = await deleteHuntType(currentBusiness, huntId);
    this.deleteLoading = false;

    if (!error) {
      await new Promise((resolve, reject) => {
        CommonStore.showSuccessMessage("Data Deleted!");
        setTimeout(resolve, 500);
      });
      window.location.href = `/businesses/${currentBusiness}/hunt`;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
      return false;
    }
  };

  //-----------------------------------------------------------Super Admin--------------------------------------------------------------

  setCurrentAdminPage = value => {
    this.currentPage = value;
    this.getAllHuntTypeForAdmin(this.editedData.businessId);
  };

  getAllHuntTypeForAdmin = async id => {
    this.isLoading = true;

    const { data, error } = await getAllHuntTypeForAdmin({
      businessId: id,
      currentPage: this.currentPage + 1,
      pageLimit: this.pageSize,
      search: this.searchValue
    });

    if (data) {
      this.isLoading = false;
      this.huntArray = data.activities
        ? data.activities.map(data => {
          const _id = data._id ? data._id : "";
          const activityID = data.activityID ? data.activityID : "";
          const name = data.name ? data.name : "";

          return {
            ...data,
            _id,
            activityID,
            name
          };
        })
        : [];

      this.totalCount = data.totalData;
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "You don't have any permission to View Hunt Type's data"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getHuntTypeByIdForAdmin = async (businessId, huntId) => {
    this.isLoading = true;
    const { data, error } = await getHuntTypeByIdForAdmin(businessId, huntId);
    this.isLoading = false;
    if (data) {
      this.setEditedData({
        name: data.name,
        price: String(data.price),
        activityID: data.activityID,
        imageUrl: data.imageUrl ? data.imageUrl : ""
      });
      huntSession.setEditedData({ price: String(data.price) });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        CommonStore.showErrorMessage(this.errorMessage);
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  putHuntTypeForAdmin = async ({
    values,
    huntId,
    history,
    businessId,
    ownerId
  }) => {
    const huntTypeDTO = {
      name: values.huntName,
      price: Number(values.price)
    };

    this.isLoading = true;

    const { data, error } = await putHuntTypeForAdmin(huntTypeDTO, huntId);
    this.isLoading = false;
    if (data) {
      await new Promise((resolve, reject) => {
        CommonStore.showSuccessMessage("Data Updated!");
        tabStore.setActiveTab("Hunt Type");
        history.push(
          "/admin/businessowner/ownerdetail/" +
          ownerId +
          "/business/" +
          businessId
        );
      });
      return true;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
      return false;
    }
  };
}

decorate(HuntTypeStore, {
  editedData: observable,
  huntArray: observable,
  isLoading: observable,
  deleteLoading: observable,
  huntAdminArray: observable,
  totalCount: observable,
  pageSize: observable,
  currentPage: observable,
  searchValue: observable,

  postHuntType: action,
  getAllHuntType: action,
  putHuntType: action,
  getHuntTypeById: action
});
export default new HuntTypeStore();
