import * as firebase from "firebase/app";
import "firebase/firebase-messaging";
// import messaging from "firebase/firebase-messaging"

import notificationStore from "../src/stores/NotificationStore";

export const initializeFirebase = () => {
  const config = {
    messagingSenderId: "601458897486"
  };
  firebase.initializeApp(config);

  let messaging;

  // we need to check if messaging is supported by the browser
  if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
  }

  if ("serviceWorker" in navigator) {
    window.addEventListener("load", async () => {
      const registration = await navigator.serviceWorker.register(
        "/firebase-messaging-sw.js",
        {
          updateViaCache: "none"
        }
      );
      messaging.useServiceWorker(registration);
    });
  }

  onMessage(messaging);
  // navigator.serviceWorker
  //   .register("./serviceWorker")
  //   .then(registration => {
  //     firebase.messaging().useServiceWorker(registration);
  //   })
  //   .catch(err => console.log(err));
};

export const onMessage = messaging => {
  if (messaging) {
    if (window.location.pathname.includes("/businesses") === true) {
      messaging.onMessage(payload => {
        notificationStore.getAllNotification();
      });
    }
  }
};

export const askForPermissionToReceiveNotification = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();
    localStorage.setItem("notification-token", token);

    // console.log("aaaa");

    return token;
  } catch (error) {
    console.error(error);
  }
};
