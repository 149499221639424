import React, { Component } from "react";
import FileViewer from "react-file-viewer";
import { inject, observer } from "mobx-react";
import Table from "../../components/MainListComponent";
import postRobot from "post-robot";

class CustomerSideUpload extends Component {
  state = {
    url: "",
    ext: "",
    preview: [],
    expires: "",
    signature: "",
  };

  componentDidMount = () => {
    const params = new URLSearchParams(this.props.location.search);
    let file = params.get("file");
    let ext = params.get("ext");
    const expires = params.get("Expires");
    const signature = params.get("Signature");
    const reportPreview = params.get("reportpreview");
    const state = params.get("state");

    this.setState({
      url: file,
      ext: ext,
      reportPreview: reportPreview,
      state: state,
      expires: expires,
      signature,
    });
    postRobot.once("getPreview", (event) => {
      // Have it return some data to the calling window

      this.setState({ preview: event.data });
    });
  };

  render() {
    const { url, ext, reportPreview, state, expires, signature } = this.state;
    const { reportStore } = this.props;
    const {
      previewColumns,
      previewColumnWidths,
      blmPreviewColumns,
      blmPreviewColumnWidths,
      customerYearPreviewColumn,
      customerYearPreviewColumnWidths,
      contactYearPreviewColumn,
      contactYearPreviewColumnWidths,
      customerStatusPreviewColumn,
      customerStatusPreviewColumnWidths,
      reservationYearPreviewColumn,
      reservationYearPreviewColumnWidth,
    } = reportStore;

    if (reportPreview) {
      if (state === "Wyoming") {
        return (
          <div
            className="containter-fluid"
            style={{ backgroundColor: "black", height: "1000px" }}
          >
            <div className="animated fadeIn" style={{ marginTop: "-20px" }}>
              <Table
                columns={previewColumns}
                rows={this.state.preview}
                columnWidths={previewColumnWidths}
                // onRowClicked={this.goToUpdate}
              />
            </div>
          </div>
        );
      } else if (state === "BLM") {
        return (
          <div
            className="containter-fluid"
            style={{ backgroundColor: "black", height: "1000px" }}
          >
            <div className="animated fadeIn" style={{ marginTop: "-20px" }}>
              <Table
                columns={blmPreviewColumns}
                rows={this.state.preview}
                columnWidths={blmPreviewColumnWidths}
                // onRowClicked={this.goToUpdate}
              />
            </div>
          </div>
        );
      } else if (state === "CustomerYear") {
        return (
          <div
            className="containter-fluid"
            style={{ backgroundColor: "black", height: "1000px" }}
          >
            <div className="animated fadeIn" style={{ marginTop: "-20px" }}>
              <Table
                columns={customerYearPreviewColumn}
                rows={this.state.preview}
                columnWidths={customerYearPreviewColumnWidths}
                // onRowClicked={this.goToUpdate}
              />
            </div>
          </div>
        );
      } else if (state === "ContactYear") {
        return (
          <div
            className="containter-fluid"
            style={{ backgroundColor: "black", height: "1000px" }}
          >
            <div className="animated fadeIn" style={{ marginTop: "-20px" }}>
              <Table
                columns={contactYearPreviewColumn}
                rows={this.state.preview}
                columnWidths={contactYearPreviewColumnWidths}
                // onRowClicked={this.goToUpdate}
              />
            </div>
          </div>
        );
      } else if (state === "CustomerStatus") {
        return (
          <div
            className="containter-fluid"
            style={{ backgroundColor: "black", height: "1000px" }}
          >
            <div className="animated fadeIn" style={{ marginTop: "-20px" }}>
              <Table
                columns={customerStatusPreviewColumn}
                rows={this.state.preview}
                columnWidths={customerStatusPreviewColumnWidths}
                // onRowClicked={this.goToUpdate}
              />
            </div>
          </div>
        );
      } else if (state === "ReservationYear") {
        return (
          <div
            className="containter-fluid"
            style={{ backgroundColor: "black", height: "1000px" }}
          >
            <div className="animated fadeIn" style={{ marginTop: "-20px" }}>
              <Table
                columns={reservationYearPreviewColumn}
                rows={this.state.preview}
                columnWidths={reservationYearPreviewColumnWidth}
                // onRowClicked={this.goToUpdate}
              />
            </div>
          </div>
        );
      }
    } else {
      if (ext === "pdf") {
        return (
          <center
            style={{
              backgroundColor: "black",
              height: "800px",
            }}
          >
            <FileViewer fileType={ext} filePath={url} />
          </center>
        );
      } else {
        return (
          <div
            style={{
              backgroundColor: "black",
              height: "800px",
            }}
          >
            {expires && signature ? (
              <FileViewer
                fileType={ext}
                filePath={`${url}${expires}${signature}`}
              />
            ) : (
              <FileViewer fileType={ext} filePath={url} />
            )}
          </div>
        );
      }
    }
  }
}
export default inject("reportStore")(observer(CustomerSideUpload));
