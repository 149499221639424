import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function getAllNotification({ businessId, limit, read }) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/notification?limit=${limit}&read=${read}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function readNotification({ businessId, value }) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/notification/read`,
      data: value
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function setToken(token) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/set-token`,
      data: token
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}
