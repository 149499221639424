import authStore from "./AuthStore";
import bookingStore from "./BookingStore";
import commonStore from "./CommonStore";
import dashboardStore from "./DashboardStore";
import employeeStore from "./EmployeeStore";
import customerStore from "./CustomerStore";
import huntTypeStore from "./HuntTypeStore";
import huntSessionStore from "./HuntSessionStore";
import addBusinessModalStore from "./AddBusinessModalStore";
import huntSessionModalStore from "./HuntSessionModalStore";
import businessStore from "./BusinessStore";
import cloudinaryStore from "./CloudinaryStore";
import deleteWarningStore from "./DeleteWarningStore";
import changePasswordStore from "./ChangePasswordStore";
import bookingSessionCardStore from "./BookingSessionCardStore";
import bookingConfirmationModalStore from "./BookingConfirmationModalStore";
import errorMessageModalStore from "./ErrorMessageModalStore";
import contactStore from "./ContactStore";
import tabStore from "./TabStore";
import uploadFileModalStore from "./UploadFileModalStore";
import formStore from "./FormStore";
import notificationStore from "./NotificationStore";
import adminStore from "./AdminStore";
import reportStore from "./ReportStore";
import communicationStore from "./CommunicationStore";
import webformStore from "./WebformStore";
import settingStore from "./SettingStore";

export default {
  authStore,
  bookingStore,
  commonStore,
  dashboardStore,
  employeeStore,
  customerStore,
  huntTypeStore,
  huntSessionStore,
  addBusinessModalStore,
  businessStore,
  cloudinaryStore,
  huntSessionModalStore,
  deleteWarningStore,
  changePasswordStore,
  errorMessageModalStore,
  bookingSessionCardStore,
  bookingConfirmationModalStore,
  contactStore,
  tabStore,
  settingStore,
  uploadFileModalStore,
  formStore,
  notificationStore,
  adminStore,
  reportStore,
  communicationStore,
  webformStore,
};
