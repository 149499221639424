import React, { Component } from 'react';
import { inject, observer } from "mobx-react";
import { Row, Col } from "reactstrap";
import Text from "../../components/Texts";
import Button from "../../components/Buttons";
import BlockUI from "react-block-ui";
import { Loader } from "react-loaders";
import "react-block-ui/style.css";
import "loaders.css/loaders.min.css";
import Notification from "../../components/Modals/Notification";
import WorkSpringLogo from "../../assets/Worksprings_Logo.svg";
import GreyLogo from "../../assets/workspringslogo_grey.png";
import Textfield from '../../components/Textfield';

class Unsubscribe extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isNotif: false,
      businessId: "",
      businessLogo: ""
    }
  }

  componentDidMount = () => {
    const params = new URLSearchParams(this.props.location.search);

    const tags = params.get("businessId");
    const logo = params.get("businessLogo");
    this.setState({ businessId: tags, businessLogo: logo })
  }

  render() {
    const { communicationStore } = this.props;
    const { editedData, unsubscribe, setEditedData, triggeredEmailLoading } = communicationStore;

    return (
      <div style={{ height: "100vh", minHeight: "100vh", display: "flex", alignItems: "center" }}>
        <BlockUI
          tag="div"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%"
          }}
          blocking={triggeredEmailLoading}
          loader={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Loader active type={"ball-spin-fade-loader"} color="#1665d8" />
            </div>
          }
        >
          <Row>
            <Col lg="12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              {this.state.businessLogo ?
                <img src={this.state.businessLogo} alt="" />
                :
                <img src={WorkSpringLogo} alt="" style={{ width: "180px", height: "32px" }} />
              }
            </Col>
            <Col lg="12" style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
              <Text
                text="Unsubscribe from our mailing list"
                textType="h2"
                weight="medium"
                style={{ color: "#005BB9" }}
              />
            </Col>
            <Col lg="12" style={{ display: "flex", justifyContent: "center", marginTop: "40px" }}>
              <Textfield
                name="email"
                className="w-50"
                value={editedData.email}
                placeholder="Enter your email address"
                onChange={e => {
                  setEditedData({ [e.target.name]: e.target.value })
                }}
              />
            </Col>
            <Col lg="12" style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
              <Button
                buttonType="primary"
                label={"Confirm"}
                disabled={!editedData.email}
                style={{
                  height: "40px",
                  width: "50%",
                }}
                onClick={async () => {
                  const { data } = await unsubscribe({ email: editedData.email, business: this.state.businessId });
                  if (data) {
                    this.setState({ isNotif: true });
                  }
                }}
              />
            </Col>
            {this.state.businessLogo &&
              <Col lg="12" style={{ display: "flex", justifyContent: "center", marginTop: "70px" }}>
                <img src={GreyLogo} alt="" style={{ width: "111px", height: "19px" }} />
              </Col>
            }
          </Row>
        </BlockUI>
        <Notification
          successSign
          isOpen={this.state.isNotif}
          title="Thank You"
          message="You have been unsubscribed from our mailing list"
          close={() => {
            this.setState({ isNotif: false })
          }}
        />
      </div>
    )
  }
}

export default inject("communicationStore")(observer(Unsubscribe))