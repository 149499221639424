import axios from "../helper/Axios.js";
import { adminUrl, superUrl } from "../config/config.js";

export async function getAllBusiness() {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getBusinessById(businessId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function putBusiness(businessDTO, businessId) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}`,
      data: {
        ...businessDTO
      }
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}
export async function createBusiness({
  businessName,
  businessType,
  address1,
  address2,
  city,
  state,
  postalCode,
  country,
  phoneNumber,
  website,
  logo,
  timezone
}) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business`,
      data: {
        businessName,
        businessType,
        address1,
        address2,
        city,
        state,
        postalCode,
        country,
        phoneNumber,
        website,
        logo,
        timezone
      }
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getBusinessType() {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/businessType`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getAuthUrl() {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/integration/gmail/access`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function disconnectGoogle() {
  try {
    let response = await axios.Delete({
      url: `${adminUrl}/integration/gmail`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function registerUrl(data) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/integration/gmail/access`,
      data: data
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function checkConnectGoogle() {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/integration/check/google`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

//{{ url_admin_local  }}/integration/check/google
//{{ url_admin_local  }}/integration/gmail/access
// {{ url_admin_local  }}/integration/gmail/access

//-----------------------------------------------------------Super Admin--------------------------------------------------------------

export async function getAllBusinessOwnerAdmin({ pageLimit, currentPage }) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/owners?page=${currentPage}&limit=${pageLimit}`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getBusinessOwnerAdminById(id) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/owner/${id}`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function updateOwner(id, value) {
  try {
    let response = await axios.Put({
      url: `${superUrl}/owner/${id}`,
      data: value
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getAllBusinessTypeForAdmin() {
  try {
    let response = await axios.Get({
      url: `${superUrl}/businesstype`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getBusinessByIdForAdmin(id) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/business/${id}`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function updateBusinessForAdmin(id, value) {
  try {
    let response = await axios.Put({
      url: `${superUrl}/business/${id}`,
      data: value
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}
