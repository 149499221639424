import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function getEmployeeCPR({ businessId, employeeId }) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/employee/${employeeId}/cpr`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getEmployeeCPRById({ businessId, cprId }) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/cpr/${cprId}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function createCPR(value, businessId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/cpr`,
      data: value
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function updateCPR(value, businessId, cprId) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/cpr/${cprId}`,
      data: value
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function deleteCPR(businessId, cprId) {
  try {
    let response = await axios.Delete({
      url: `${adminUrl}/business/${businessId}/cpr/${cprId}`
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}
