import { observable, decorate } from "mobx";
import {
  getAllNotification,
  readNotification,
  setToken
} from "../services/NotificationService";
// import React from "react";
import { reactLocalStorage } from "reactjs-localstorage";
import commonStore from "./CommonStore";

class NotificationStore {
  editedData = {};
  notificationArray = [];
  notificationPageArray = [];
  unreadData = 0;
  loading = false;

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setLoading = value => {
    this.loading = value;
  };

  setNotificationArray = () => {
    this.notificationArray = [];
  };

  getAllNotification = async () => {
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");
    const { data, error } = await getAllNotification({
      businessId: JSON.parse(businessId),
      limit: 5,
      read: "All"
    });

    if (data) {
      this.notificationArray = data.notification;

      this.unreadData = data.returnedDataStatus;
    } else {
      this.loading = false;
      if (error && error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getAllNotificationPage = async () => {
    this.loading = true;
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    const { data, error } = await getAllNotification({
      businessId: JSON.parse(businessId),
      limit: 10,
      read: "All"
    });
    this.loading = false;

    if (data) {
      this.notificationPageArray = data.notification;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  readNotification = async value => {
    this.isLoading = true;
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    const { data, error } = await readNotification({
      businessId: JSON.parse(businessId),
      value: value
    });
    if (data) {
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  setToken = async value => {
    const { data, error } = await setToken(value);

    if (data) {
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };
}
decorate(NotificationStore, {
  editedData: observable,
  notificationArray: observable,
  unreadData: observable,
  notificationPageArray: observable,
  loading: observable
});
export default new NotificationStore();
