/* eslint-disable array-callback-return */
import { Col, Row } from "reactstrap";
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Formik, FastField, Form, Field } from "formik";
import Text from "../../components/Texts";
import workspringsGrey from "../../assets/workspringslogo_grey.png"
import Buttons from "../../components/Buttons";
import { validateFormValuesUsingJoiSchema } from "../../helper/functions";
import FormikInputField from "../../components/Formik/FormikInputField";
import FormikTextArea from "../../components/Formik/TextArea";
import FormikPhoneInput from "../../components/Formik/FormikPhoneInput";
import FormikAsyncSelect from "../../components/Formik/FormikAsyncSelect";
import BlockUI from "react-block-ui";
import { Loader } from "react-loaders";
import "react-block-ui/style.css";
import "loaders.css/loaders.min.css";
// import { log } from "util";

const Joi = require("joi-browser");
Joi.objectId = require("joi-objectid")(Joi);

const joiSchema = {
  email: Joi.string()
    .label("Email")
    .required()
    .email({ minDomainAtoms: 2 }),
}

class CreateContactFromLink extends Component {
  constructor(props) {
    super(props)

    this.state = {
      businessName: "",
      businessId: "",
      formType: "",
      formId: "",
    }
  }

  submit = async (values, actions) => {
    const { webformStore } = this.props;
    const { businessId } = this.state;
    const { postContactWithoutToken, /*webformFields*/ } = webformStore;
    // const { businessId } = this.state;
    // let customFields = [];
    let value = {};
    // for (let i = 0; i < Object.keys(values).length; i++) {
    //   let temp = {};

    //   if (!webformFields.includes(Object.keys(values)[i])) {
    //     temp[Object.keys(values)[i]] = values[Object.keys(values)[i]]
    //     customFields.push(temp)
    //   } else {
    //     value[Object.keys(values)[i]] = values[Object.keys(values)[i]]
    //   }
    // }
    value = {
      ...values,
      country: values.country.label,
      state: values.state && values.state.label ? values.state.label : values.state,
      // custom: customFields
    }
    // console.log(value);

    await postContactWithoutToken({ value, businessId });
    // actions.setSubmitting(false);
  };

  componentDidMount = async () => {
    const { location, webformStore } = this.props;
    const { getSingleWebform, getAllCountry, setEditedData } = webformStore

    setEditedData({ done: false })
    // const { getBusinessById } = businessStore;
    const params = new URLSearchParams(location.search);
    const businessId = params.get("id");
    const businessName = params.get("name");
    const formId = params.get("formId");

    await getAllCountry();
    await getSingleWebform({ businessId, webformId: formId })

    // console.log(formType);
    this.setState({ businessName, businessId, formId })
    // // await getBusinessById(tags)
  }

  render() {
    const { businessName, } = this.state;
    const { webformStore } = this.props
    const { editedData, webformLoading, countryList, formikInitialValues } = webformStore;

    return (
      <div
        style={{ height: "auto", minHeight: "100vh", display: "flex", alignItems: "center" }}
      >
        <BlockUI
          tag="div"
          blocking={webformLoading}
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            height: "100%",
            alignItems: "center",
          }}
          loader={
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%"
              }}
            >
              <Loader
                active
                type={"ball-spin-fade-loader"}
                color="#1665d8"
              />
            </div>
          }
        >
          {!editedData.done &&
            <Formik
              initialValues={formikInitialValues}
              validate={values =>
                validateFormValuesUsingJoiSchema({ values, joiSchema })
              }
              validateOnChange={false}
              enableReinitialize={true}
              onSubmit={this.submit}
              component={({ values, handleSubmit, setFieldValue, isSubmitting }) => {
                return (
                  <Form style={{ width: "750px" }}>
                    <Row>
                      <Col lg="12"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          paddingTop: "20px"
                        }}
                      >
                        <Text
                          text={`${businessName} Contact Form`}
                          textType="h1"
                          weight="medium"
                        />
                      </Col>
                      <Col lg="12"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Text
                          text="Complete this form and we'll be in touch with you soon"
                          textType="body1"
                        />
                      </Col>
                      <Col
                        lg="12"
                        style={{
                          marginTop: "15px",
                          // display: "flex",
                          // justifyContent: "center",
                        }}
                      >
                        <Row>
                          {editedData.email &&
                            <Col lg="6" md="6" sm="6" style={{ marginBottom: "20px" }}>
                              <Text
                                text="Email Address"
                                textType="caption"
                                weight="medium"
                                required=" *"
                              />
                              <FastField
                                name={"email"}
                                id="outlined-name"
                                variant="outlined"
                                className="w-100"
                                placeholder={"Email Address"}
                                component={FormikInputField}
                              />
                            </Col>
                          }
                          {editedData.firstName && editedData.lastName && <Col lg="6" md="6" sm="6" /> }

                          {editedData.firstName &&
                            <Col lg="6" md="6" sm="6" style={{ marginBottom: "20px" }}>
                              <Text
                                text="First Name"
                                textType="caption"
                                weight="medium"
                                // required=" *"
                              />
                              <FastField
                                name={"firstName"}
                                id="outlined-name"
                                variant="outlined"
                                className="w-100"
                                placeholder={"First Name"}
                                component={FormikInputField}
                              />
                            </Col>
                          }
                          {editedData.lastName && 
                            <Col lg="6" md="6" sm="6" style={{ marginBottom: "20px" }}>
                              <Text
                                text="Last Name"
                                textType="caption"
                                weight="medium"
                                // required=" *"
                              />
                              <FastField
                                name={"lastName"}
                                id="outlined-name"
                                variant="outlined"
                                className="w-100"
                                placeholder={"Last Name"}
                                component={FormikInputField}
                              />
                            </Col>
                          }
                          {editedData.phone &&
                            <Col lg="6" md="6" sm="6" style={{ marginBottom: "20px" }}>
                              <Text
                                text="Phone Number "
                                textType="caption"
                                weight="medium"
                              />
                              <FastField
                                name={"phone"}
                                defCountry={"us"}
                                placeholder="Phone Number"
                                component={FormikPhoneInput}
                              />
                            </Col>
                          }
                          {editedData.address1 &&
                            <Col lg="6" md="6" style={{ marginBottom: "20px" }}>
                              <Text
                                text="Address 1 "
                                textType="caption"
                                weight="medium"
                              />
                              <FastField
                                name={"address1"}
                                id="outlined-name"
                                variant="outlined"
                                className="w-100"
                                placeholder={"Address 1"}
                                component={FormikInputField}
                              />
                            </Col>
                          }
                          {editedData.address2 &&
                            <Col lg="6" md="6" style={{ marginBottom: "20px" }}>
                              <Text
                                text="Address 2 "
                                textType="caption"
                                weight="medium"
                              />
                              <FastField
                                name={"address2"}
                                variant="outlined"
                                id="outlined-name"
                                className="w-100"
                                placeholder={"Address 2"}
                                component={FormikInputField}
                              />
                            </Col>
                          }

                          {editedData.country &&
                            <Col lg="6" md="6" style={{ marginBottom: "20px" }}>
                              <Text
                                text="Country "
                                textType="caption"
                                weight="medium"
                              />
                              <Field
                                name={"country"}
                                options={countryList}
                                styles={{
                                  control: base => ({
                                    ...base,
                                    height: 40,
                                    minHeight: 40
                                  })
                                }}
                                className={"zIndexku"}
                                component={FormikAsyncSelect}
                              />
                            </Col>
                          }

                          {editedData.state && values.country.label !== "United States" &&
                            <Col lg="6" md="6" style={{ marginBottom: "20px" }}>
                              <Text
                                text="State "
                                textType="caption"
                                weight="medium"
                              />
                              <Field
                                name={"state"}
                                id="outlined-name"
                                defaultValue=""
                                variant="outlined"
                                className="w-100"
                                placeholder={"State"}
                                component={FormikInputField}
                              />
                            </Col>
                          }
                          {editedData.state && values.country.label === "United States" &&
                            <Col lg="6" md="6" style={{ marginBottom: "20px" }}>
                              <Text
                                text="State "
                                textType="caption"
                                weight="medium"
                              />
                              <Field
                                name={"state"}
                                state={true}
                                countryId={String(values.country.value)}
                                styles={{
                                  control: base => ({
                                    ...base,
                                    height: 40,
                                    minHeight: 40
                                  })
                                }}
                                className={"zIndexku1"}
                                component={FormikAsyncSelect}
                              />
                            </Col>
                          }
                          {editedData.city &&
                            <Col lg="6" md="6" style={{ marginBottom: "20px" }}>
                              <Text
                                text="City "
                                textType="caption"
                                weight="medium"
                              />
                              <Field
                                name={"city"}
                                id="outlined-name"
                                variant="outlined"
                                className="w-100"
                                placeholder={"City"}
                                // disabled={!values.state}
                                component={FormikInputField}
                              />
                            </Col>
                          }
                          {editedData.postalCode &&
                            <Col lg="6" md="6" style={{ marginBottom: "20px" }}>
                              <Text
                                text="Zip / Postal Code "
                                textType="caption"
                                weight="medium"
                              />
                              <FastField
                                name={"postalCode"}
                                id="outlined-name"
                                variant="outlined"
                                className="w-100"
                                placeholder={"Postal Code"}
                                component={FormikInputField}
                              />
                            </Col>
                          }
                          {/* {editedData.custom && editedData.custom.map(item => {
                            let fieldName = item.split(' ').map(word =>
                              word.charAt(0).toLowerCase() + word.substring(1)
                            ).join('_');
                            return (
                              <Col lg="6" md="6" style={{ marginBottom: "20px" }}>
                                <Text
                                  text={item}
                                  textType="caption"
                                  weight="medium"
                                />
                                <FastField
                                  name={fieldName}
                                  id="outlined-name"
                                  variant="outlined"
                                  className="w-100"
                                  placeholder={item}
                                  component={FormikInputField}
                                />
                              </Col>
                            )
                          })} */}
                          {editedData.note &&
                            <Col lg="12" style={{ marginBottom: "20px" }}>
                              <Text
                                text="Message"
                                textType="caption"
                                weight="medium"
                              />
                              <FastField
                                name={"note"}
                                id="outlined-name"
                                variant="outlined"
                                className="w-100"
                                placeholder={"Message"}
                                component={FormikTextArea}
                              />
                            </Col>
                          }

                          <Col lg="12" style={{ marginBottom: "10px", display: "flex", justifyContent: "center" }}>
                            <Buttons
                              buttonType={"primary"}
                              label={"Submit"}
                              type="submit"
                              style={{
                                height: "40px",
                                marginTop: "20px",
                                width: "75%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            // disabled={isSubmitting}
                            />
                          </Col>
                          <Col lg="12">
                            <Text
                              text="When you submit your message, we'll send occasional news, updates, special offers, and surveys via email. We will not sell or distribute your emails to any third party at anytime"
                              textType="caption"
                              weight="medium"
                            />
                          </Col>
                          <Col lg="12" style={{ display: "flex", justifyContent: "center", marginTop: "40px", paddingBottom: "20px" }}>
                            <img src={workspringsGrey} alt="" />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Form>
                );
              }}
            />
          }
          {!!editedData.done &&
            <Row>
              <Col lg="12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Text
                  text="Thank you!"
                  textType="h2"
                  weight="medium"
                  style={{ color: "#005BB9", fontWeight: 'bold' }}
                />
              </Col>
            </Row>
          }
        </BlockUI >

      </div>
    );
  }
}
export default inject("webformStore")(observer(CreateContactFromLink));
