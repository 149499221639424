import React, { Component } from "react";
import { Provider } from "mobx-react";
import { Route, Router, Switch, withRouter } from "react-router-dom";
import { createBrowserHistory } from "history";
import { createGenerateClassName } from "@material-ui/core/styles";
import JssProvider from "react-jss/lib/JssProvider";
import "typeface-roboto";
import "./App.scss";

// CoreUI Icons Set
import "@coreui/icons/css/coreui-icons.min.css";
// Import Font Awesome Icons Set
import "font-awesome/css/font-awesome.min.css";
// Import Simple Line Icons Set
import "simple-line-icons/css/simple-line-icons.css";
// Import Main styles for this application
import "./scss/style.css";

import { DefaultLayout } from "./containers";
import { Login, Register, Page404, Page500, AdminLogin } from "./views/Pages";
import CreateBusiness from "./views/Business/CreateBusiness";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import ResetPassword from "./views/ForgotPassword/ResetPassword";
import VerifyEmail from "./views/Verfiy/VerifyEmail";
import CustomerSideUpload from "./views/FormCustomer/CustomerSideUpload";
import AppSnackbar from "./components/AppSnackbar";
import PreviewFile from "./views/Preview/PreviewFile";
import EmailLogin from "./views/EmailLogin/EmailLogin";
import Unsubscribe from "./views/Unsubscribe/Unsubscribe";
import PostSubscribe from "./views/Communication/PostSubscribe/PostSubscribe";
import CreateContactFromLink from "./views/Contact/CreateContactFromLink";
import PreviewForm from "./views/Contact/PreviewForm";
import stores from "./stores";

//-----------------------------------------------------------Super Admin------------------------------------------------------------

// import AdminLogin from "./views/Pages/Login/AdminLogin";

class App extends Component {
  render() {
    const Root = () => (
      <Switch>
        <Route exact path="/login" name="Login Page" component={Login} />
        <Route
          exact
          path="/register"
          name="Register Page"
          component={Register}
        />
        <Route
          exact
          path="/createBusiness"
          name="Create Business"
          component={CreateBusiness}
        />
        <Route
          exact
          path="/forgotPassword"
          name="Forgot Password"
          component={ForgotPassword}
        />
        <Route
          exact
          path="/resetPassword"
          name="Reset Password"
          component={ResetPassword}
        />
        <Route
          exact
          path="/verifyEmail"
          name="Verify Email"
          component={VerifyEmail}
        />
        <Route
          path="/customerform"
          name="Customer Form"
          component={CustomerSideUpload}
        />
        <Route
          path="/emaillogin"
          name="Email Login"
          component={EmailLogin}
        />
        <Route
          path="/unsubscribe"
          name="Unsubscribe"
          component={Unsubscribe}
        />
        <Route
          path="/subscribe"
          name="Thank You"
          component={PostSubscribe}
        />
        <Route
          path="/createcontact"
          name="Create Contact"
          component={CreateContactFromLink}
        />
        <Route
          path="/formpreview"
          name="Preview Form"
          component={PreviewForm}
        />
        <Route path="/admin/login" name="Admin Login" component={AdminLogin} />
        <Route path="/preview" name="Preview" component={PreviewFile} />
        <Route exact path="/404" name="Page 404" component={Page404} />
        <Route exact path="/500" name="Page 500" component={Page500} />
        <Route path="/" name="Home" component={DefaultLayout} />
      </Switch>
    );

    const generateClassName = createGenerateClassName({
      dangerouslyUseGlobalCSS: true
    });

    const browserHistory = createBrowserHistory();
    const RouterW = withRouter(Root);

    return (
      <JssProvider generateClassName={generateClassName}>
        <Provider {...stores}>
          <React.Fragment>
            <AppSnackbar />
            <Router history={browserHistory}>
              <RouterW />
            </Router>
          </React.Fragment>
        </Provider>
      </JssProvider>
    );
  }
}

export default App;
