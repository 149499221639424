/* eslint-disable eqeqeq */
import React, { Component } from "react";
import { Button } from "reactstrap";

class Buttons extends Component {
  render() {
    const { ...props } = this.props;
    return (
      <React.Fragment>
        {this.props.buttonType == "primary" && (
          <Button
            className="primary"
            onClick={this.props.onClick}
            disabled={this.props.disabled}
            data-tip={this.props.dataTip}
            type={this.props.type}
            {...props}
            // style={this.props.style}
          >
            {this.props.label}
          </Button>
        )}

        {this.props.buttonType == "secondary" && (
          <Button
            className="secondary"
            style={this.props.style}
            onClick={this.props.onClick}
            data-tip={this.props.dataTip}
            disabled={this.props.disabled}
            type={this.props.type}
          >
            {this.props.label}
          </Button>
        )}

        {this.props.buttonType == "delete" && (
          <Button
            className="delete"
            onClick={this.props.onClick}
            disabled={this.props.disabled}
            type={this.props.type}
            data-tip={this.props.dataTip}
            style={this.props.style}
          >
            {this.props.label}
          </Button>
        )}

        {this.props.buttonType == "disabled" && (
          <Button
            className="disabled"
            onClick={this.props.onClick}
            disabled
            type={this.props.type}
            style={this.props.style}
          >
            {this.props.label}
          </Button>
        )}

        {this.props.buttonType == "default" && (
          <Button
            className="default"
            onClick={this.props.onClick}
            type={this.props.type}
            style={this.props.style}
          >
            {this.props.label}
          </Button>
        )}
      </React.Fragment>
    );
  }
}
export default Buttons;
