import React, { Component } from "react";
import { Col, InputGroup, Row } from "reactstrap";
import { inject, observer } from "mobx-react";
import WorkSpringLogo from "../../../assets/Worksprings_Logo.svg";
import FacebookLogo from "../../../assets/facebook (1).svg";
import GoogleLogo from "../../../assets/google.svg";
import LinkedInLogo from "../../../assets/linkedin (1).svg";
// import ReactTooltip from "react-tooltip";
import { Formik, Field, FastField, Form } from "formik";
import FormikInputField from "../../../components/Formik/FormikInputField";
import FormikPassword from "../../../components/Formik/FormikPassword";
import Buttons from "../../../components/Buttons";
import Text from "../../../components/Texts";
import { validateFormValuesUsingJoiSchema } from "../../../helper/functions";
import { Redirect } from "react-router-dom";
import BlockUI from "react-block-ui";
import { Loader } from "react-loaders";
import "react-block-ui/style.css";
import "loaders.css/loaders.min.css";

const Joi = require("joi-browser");
Joi.objectId = require("joi-objectid")(Joi);

const joiSchema = {
  email: Joi.string()
    .label("Email")
    .required()
    .email({ minDomainAtoms: 2 }),
  password: Joi.string()
    .label("Password")
    .required()
};

class Login extends Component {
  state = {
    showPassword: false
  };

  componentDidMount = () => {
    localStorage.setItem("loginType", "Business");
  };

  login = values => {
    const { history } = this.props;
    const { email, password } = values;
    this.props.authStore.signIn({ email, password, history });
  };

  forgetPassword = values => {
    const { history } = this.props;
    history.push("/forgotPassword");
  };

  createNew = () => {
    const { history, authStore } = this.props;
    authStore.goToRegister(history);
    history.push("/register");
  };

  // onChange = ({ e, setFieldValue }) => {
  //   const { name, value } = e.target;

  //   console.log(value);
  //   setFieldValue(name, value);
  // };

  render() {
    if (this.props.authStore.isAuthenticated) {
      return <Redirect to="/" />;
    }
    const initialValues = {
      email: "",
      password: ""
    };

    const { authStore } = this.props;
    const { authLoading } = authStore;
    return (
      <Formik
        initialValues={initialValues}
        validate={values =>
          validateFormValuesUsingJoiSchema({ values, joiSchema })
        }
        validateOnChange={false}
        enableReinitialize={true}
        onSubmit={this.login}
        component={({ values, handleSubmit, setFieldValue }) => {
          return (
            <div>
              <BlockUI
                tag="div"
                blocking={authLoading}
                loader={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Loader
                      active
                      type={"ball-spin-fade-loader"}
                      color="#1665d8"
                    />
                  </div>
                }
              >
                <Form>
                  <Row>
                    <Col lg="12" md="12" sm="12" xs="12" className="loginCol">
                      <img
                        src={WorkSpringLogo}
                        alt=""
                        style={{
                          marginTop: "100px"
                        }}
                      />
                    </Col>
                    <Col lg="12" md="12" sm="12" xs="12" className="loginCol">
                      <Text
                        text="Login"
                        textType="h1"
                        weight="medium"
                        style={{ marginTop: "50px" }}
                      />
                    </Col>
                    <Col lg="12" md="12" sm="12" xs="12" className="loginCol">
                      <Text
                        text="Please login using your email"
                        textType="body1"
                        weight="regular"
                        style={{ marginTop: "10px" }}
                      />
                    </Col>
                    <Col lg="12">
                      <Row>
                        <Col lg="12" style={{ marginTop: "20px" }}>
                          <InputGroup className="loginCol">
                            <FastField
                              name={"email"}
                              id="outlined-name"
                              onChange={e => {
                                const { target } = e;
                                setFieldValue("email", target.value.trim())
                              }}
                              variant="outlined"
                              placeholder={"Email"}
                              style={{ width: "326px" }}
                              component={FormikInputField}
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="12" style={{ marginTop: "10px" }}>
                          <InputGroup className="loginCol">
                            <Field
                              name={"password"}
                              data-tip="React-tooltip"
                              id="outlined-adornment-password"
                              variant="outlined"
                              disabled={
                                !values.email || values.email.length === 0
                              }
                              placeholder={"Password"}
                              inputType={"password"}
                              component={FormikPassword}
                              style={{ width: "326px" }}
                            />
                          </InputGroup>
                        </Col>
                      </Row>
                    </Col>

                    <Col lg="12" md="12" sm="12" xs="12" className="loginCol">
                      <span
                        style={{
                          fontSize: "12px",
                          fontWeight: 400,
                          textDecoration: "underline",
                          cursor: "pointer",
                          color: "#01953A",
                          marginLeft: "-230px",
                          marginTop: "10px"
                        }}
                        className="loginText"
                        onClick={this.forgetPassword}
                      >
                        Forgot Password?
                      </span>
                    </Col>
                    <Col lg="12" md="12" sm="12" xs="12" className="loginCol">
                      <Buttons
                        buttonType={
                          !!values.email &&
                            !!values.password &&
                            values.password.length >= 8
                            ? "primary"
                            : "disabled"
                        }
                        type="submit"
                        label={"Login"}
                        style={{
                          height: "40px",
                          width: "326px",
                          marginTop: "20px"
                        }}
                      />
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Col lg="12" md="12" sm="12" xs="12" className="loginCol">
                    <Buttons
                      buttonType={"secondary"}
                      label={"Create New Account"}
                      style={{
                        height: "40px",
                        width: "326px",
                        marginTop: "15px"
                      }}
                      type={"button"}
                      onClick={this.createNew}
                    />
                  </Col>
                  <Col
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    className="loginCol"
                    style={{ marginTop: "35px", display: "none" }}
                  >
                    <p className="pa">
                      <span>or Login with</span>
                    </p>
                  </Col>
                  <Col
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    className="socmedCol"
                    style={{ display: "none" }}
                  >
                    <img
                      src={GoogleLogo}
                      alt=""
                      style={{
                        margin: "10px"
                      }}
                    />
                    <img
                      src={FacebookLogo}
                      alt=""
                      style={{
                        margin: "10px"
                      }}
                    />
                    <img
                      src={LinkedInLogo}
                      alt=""
                      style={{
                        margin: "10px"
                      }}
                    />
                  </Col>
                </Row>
              </BlockUI>
            </div>
          );
        }}
      />
    );
  }
}

export default inject("authStore")(observer(Login));
// export default Login;
