import axios from "axios";
import https from "https";
import { getCookie } from "./cookie";

const agent = new https.Agent({
  rejectUnauthorized: false
});

const queryParser = params => {
  let queryParams = "";
  for (let key in params) {
    if (!queryParams) {
      queryParams = queryParams.concat(`?${key}=${params[key]}`);
    } else {
      queryParams = queryParams.concat(`&${key}=${params[key]}`);
    }
  }
  return queryParams;
};

const getToken = () => {
  let token = "";

  if (getCookie("hunting_current_token")) {
    token = getCookie("hunting_current_token");
  }
  return token;
};

const defaultHeaders = {
  "Content-Type": "application/json"
  // 'Accept': 'application/json'
};
const headerPdf = {
  "Content-Type": "application/pdf"
  //Accept: "application/json"
};
const headerFile = {
  "Content-Type": "multipart/form-data"
  //Accept: "application/json"
};

const Axios = {
  Get: ({ url, params }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url + queryParser(params),
          method: "GET",
          headers: {
            ...defaultHeaders,
            token: getToken()
          },
          httpsAgent: agent
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  GetPdf: ({ url, params }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url + queryParser(params),
          method: "GET",
          responseType: "blob",
          headers: {
            ...headerPdf,
            //             header('Access-Control-Allow-Origin: *');
            // header('Access-Control-Allow-Methods: GET, POST, PATCH, PUT, DELETE, OPTIONS');
            // header('Access-Control-Allow-Headers: Origin, Content-Type, X-Auth-Token');
            token: getToken(),
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "*",
            "Access-Control-Allow-Headers": "*"
          }
        })
        .then(response => {
          let blob = new Blob([response.data], { type: "application/pdf" }),
            url = window.URL.createObjectURL(blob);
          window.open(url);
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  Post: ({ url, params, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url,
          method: "POST",
          headers: {
            ...defaultHeaders,
            token: getToken()
          },
          data: data,
          httpsAgent: agent
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  PostFile: ({ url, params, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url,
          method: "POST",
          headers: {
            ...headerFile,
            token: getToken()
          },
          data
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  Put: ({ url, params, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url + queryParser(params),
          method: "PUT",
          headers: {
            ...defaultHeaders,
            token: getToken()
          },
          data
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  Patch: ({ url, params, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url + queryParser(params),
          method: "PATCH",
          headers: {
            ...defaultHeaders,
            token: getToken()
          },
          data
        })
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  Delete: ({ url, params, data }) => {
    return new Promise((resolve, reject) => {
      axios
        .request({
          url: url + queryParser(params),
          method: "DELETE",
          headers: {
            ...defaultHeaders,
            token: getToken()
          },
          data
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

export default Axios;
