import { action, decorate, observable } from "mobx";

class TabStore {
  activeTab = ""; //labelName

  setActiveTab = newActiveTab => {
    this.activeTab = newActiveTab;
  };
}

decorate(TabStore, {
  activeTab: observable,
  setActiveTab: action
});

export default new TabStore();
