// import { getDashboard } from "../services/DashboardService";
import { getBookingStatusCount } from "../services/BookingService";
import { observable, action, decorate } from "mobx";
import commonStore from "./CommonStore";

class DashboardStore {
  editedData = {
    view: 'month',
    huntType: "All",
    employee: "All",
    screenWidth: window.innerWidth
  };
  errorMessage = "";
  huntTypeEnum = [];
  employeeEnum = [];
  getLoading = false;
  calendarLoading = false;

  viewEnum = [
    { name: "Month", value: "month" },
    { name: "Week", value: "week" },
    { name: "Day", value: "day" },
  ];

  setEditedData = updatedData => {
    this.editedData = {
      ...this.editedData,
      ...updatedData
    };
  };

  setHuntTypeEnum = value => {
    this.huntTypeEnum = value;
  };

  setEmployeeEnum = value => {
    this.employeeEnum = value;
  };

  setLoadingCalendar = value => {
    this.calendarLoading = value;
  };

  clearData = () => {
    this.editedData = {};
  };

  getBookingStatusCount = async businessId => {
    const { data, error } = await getBookingStatusCount(businessId);

    if (data) {
      this.setEditedData({
        reservation: data[2].RESERVATION ? data[2].RESERVATION : "0",
        booked: data[0].BOOKED ? data[0].BOOKED : "0",
        completed: data[3].COMPLETED ? data[3].COMPLETED : "0",
        deposit: data[1].DEPOSIT ? data[1].DEPOSIT : "0",
        reminder: data[4].REMINDER ? data[4].REMINDER : "0",
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getDashboard = async () => {
    // this.getLoading = true;
    // let { data, error } = await getDashboard();
    // if (data) {
    //   this.editedData = data;
    //   this.getLoading = false;
    // } else {
    //   this.getLoading = false;
    //   if (error.data) {
    //     this.errorMessage = error.data.message;
    //     commonStore.showErrorMessage(this.errorMessage);
    //   } else {
    //     commonStore.showErrorMessage(
    //       "Something went wrong. Please try again later"
    //     );
    //   }
    // }
  };
}

decorate(DashboardStore, {
  editedData: observable,
  errorMessage: observable,
  getLoading: observable,
  calendarLoading: observable,
  huntTypeEnum: observable,
  employeeEnum: observable,

  setEditedData: action,
  getDashboard: action
});

export default new DashboardStore();
