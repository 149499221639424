import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = {
  root: {
    fontFamily: "Roboto",
    fontSize: "14px",
    height: "40px",
    backgroundColor: "white"
  },
  input: {
    padding: "12px"
  }
};

class Textfield extends Component {
  render() {
    const { classes, ...props } = this.props;

    return (
      <TextField
        name={this.props.name}
        value={this.props.value}
        onChange={this.props.onChange}
        style={this.props.style}
        variant="outlined"
        onKeyPress={this.props.onKeyPress}
        className={this.props.className}
        placeholder={this.props.placeholder}
        InputProps={{
          classes: {
            root: classes.root,
            input: classes.input
          },
          endAdornment: this.props.endAdornment && <InputAdornment position="end">{this.props.endAdornment}</InputAdornment>
        }}
        {...props}
      />
    );
  }
}
export default withStyles(styles)(Textfield);
