/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from "prop-types";
import React, { Component } from "react";
// import DropDown from "../../components/DropDown";
import DashboardImage from "../../assets/nav menu/dashboard.svg";
import HuntImage from "../../assets/nav menu/hunts.svg";
import EmployeeImage from "../../assets/nav menu/employee.svg";
import BookingImage from "../../assets/nav menu/bookings.svg";
import FormImage from "../../assets/nav menu/forms.svg";
import CustomerImage from "../../assets/nav menu/customer.svg";
import ReportImage from "../../assets/nav menu/report.svg";
import ContactImage from "../../assets/nav menu/contacts.svg";
import AdminImage from "../../assets/nav menu/admin.svg";
import BusinessImage from "../../assets/nav menu/business.svg";
import CommunicationImage from "../../assets/nav menu/communication.svg";
// import AddBusinessModal from "../../components/Modals/AddBusinessModal";
import { inject, observer } from "mobx-react";
// import momentTimezone from "moment-timezone";
import Text from "../../components/Texts";
// import moment from "moment";
import commonStore from "../../stores/CommonStore";
import { reactLocalStorage } from "reactjs-localstorage";
// import _ from "lodash";
// import { toJS } from "mobx";

class SideBar extends Component {
  state = {
    dropdownValue: "",
    isStateMatch: JSON.parse(localStorage.getItem("isStateMatch")) || false
  };

  // changeDropDownItem = async event => {
  //   const { name, value } = event.target;
  //   const {
  //     addBusinessModalStore,
  //     authStore,
  //     huntTypeStore,
  //     dashboardStore,
  //     employeeStore,
  //     huntSessionStore,
  //     contactStore,
  //     customerStore,
  //     bookingStore
  //     // notificationStore
  //   } = this.props;

  //   let { currentBusiness } = authStore;
  //   if (value === "link") {
  //     addBusinessModalStore.openModal();
  //   } else {
  //     currentBusiness = value;
  //     authStore.setEditedData({
  //       [name]: value
  //     });
  //     authStore.setEditedData({ wholeLoading: true });
  //     huntTypeStore.setInitialData();
  //     employeeStore.setInitialData();
  //     huntSessionStore.setInitialData();
  //     contactStore.setInitialData();
  //     customerStore.setInitialData();
  //     bookingStore.setInitialData();
  //     dashboardStore.setHuntTypeEnum([]);
  //     reactLocalStorage.setObject("huntingCurrentBusiness", currentBusiness);

  //     const date = new Date();
  //     const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
  //     const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 1);

  //     const params = {
  //       start: moment(firstDay).format("YYYY-MM-DDTHH:mm:ss.000"),
  //       end: moment(lastDay).format("YYYY-MM-DDTHH:mm:ss.000"),
  //       tz: momentTimezone.tz.guess()
  //     };

  //     dashboardStore.setEditedData({
  //       huntType: "All",
  //       employee: "All",
  //       view: "month"
  //     });

  //     const today = new Date();
  //     today.setHours(0, 0, 0);

  //     huntTypeStore.setHuntArray([]);

  //     const { businessStore } = this.props;
  //     businessStore.getBusinessById(value);

  //     dashboardStore.getBookingStatusCount(value);
  //     dashboardStore.setLoadingCalendar(true);
  //     huntTypeStore.getAllHuntType();
  //     employeeStore.getAllEmployee();

  //     await huntSessionStore.getMonthlySessionQuota(params, value);
  //     const { huntArray } = huntTypeStore;
  //     const { dataArray } = employeeStore;

  //     const employeeOptSelect = dataArray.reduce(
  //       (result, employee) => {
  //         const employeeOpt = {
  //           name: _.get(employee, "name", ""),
  //           value: _.get(employee, "_id", "")
  //         };

  //         result.push(employeeOpt);
  //         return result;
  //       },
  //       [{ name: "All", value: "All" }]
  //     );

  //     const huntTypeOptSelect = huntArray.reduce(
  //       (result, huntType) => {
  //         const huntOpt = {
  //           name: _.get(huntType, "name", ""),
  //           value: _.get(huntType, "_id", "")
  //         };

  //         result.push(huntOpt);
  //         return result;
  //       },
  //       [{ name: "All", value: "All" }]
  //     );

  //     dashboardStore.setEmployeeEnum(employeeOptSelect);
  //     dashboardStore.setHuntTypeEnum(huntTypeOptSelect);

  //     let { huntSessionQuotaArray } = huntSessionStore;
  //     huntSessionStore.setEventArray([]);
  //     let tes =
  //       huntSessionQuotaArray &&
  //       huntSessionQuotaArray.reduce((result, each) => {
  //         if (each.availableActivity.length > 0) {
  //           let a = each;

  //           a.availableActivity.map(data => {
  //             const event = {
  //               ...data,
  //               title: data.name,
  //               start: data.startTime,
  //               end: data.endTime
  //             };
  //             result.push(event);
  //             return true;
  //           });
  //         }

  //         return result;
  //       }, []);

  //     huntSessionStore.setTempArray(tes);
  //     this.setState({ dates: event });

  //     tes.filter(data => {
  //       if (
  //         dashboardStore.editedData.huntType === "All" &&
  //         dashboardStore.editedData.employee === "All"
  //       ) {
  //         huntSessionStore.eventArray.push(data);
  //       } else if (
  //         data.activity._id === dashboardStore.editedData.huntType &&
  //         dashboardStore.editedData.employee === "All"
  //       ) {
  //         huntSessionStore.eventArray.push(data);
  //       } else if (
  //         data.employee._id === dashboardStore.editedData.employee &&
  //         dashboardStore.editedData.huntType === "All"
  //       ) {
  //         huntSessionStore.eventArray.push(data);
  //       } else if (
  //         data.employee._id === dashboardStore.editedData.employee &&
  //         data.activity._id === dashboardStore.editedData.huntType
  //       ) {
  //         huntSessionStore.eventArray.push(data);
  //       }
  //       return true;
  //     });
  //     dashboardStore.setLoadingCalendar(false);
  //     authStore.setEditedData({ wholeLoading: false });

  //     this.props.pushProps(`/businesses/${currentBusiness}/dashboard`);
  //   }
  // };

  goToPage = event => {
    const { name } = event.target;
    const { history, bookingStore, employeeStore } = this.props;
    const { editedData } = employeeStore;
    const { employeeCreatePermission, status } = editedData;
    const currentBusiness = reactLocalStorage.get("huntingCurrentBusiness")
      ? JSON.parse(reactLocalStorage.get("huntingCurrentBusiness"))
      : "";

    if (name === "Dashboard") {
      history.push("/businesses/" + currentBusiness + "/dashboard");
    } else if (name === "huntType") {
      history.push("/businesses/" + currentBusiness + "/hunt");
    } else if (name === "Employee") {
      event.currentTarget.parentElement.classList.toggle("open");
      history.push("/businesses/" + currentBusiness + "/employees");
    } else if (name === "reservation") {
      bookingStore.setStatus("");
      history.push("/businesses/" + currentBusiness + "/reservation");
    } else if (name === "customer") {
      history.push("/businesses/" + currentBusiness + "/customer");
    } else if (name === "report") {
      history.push("/businesses/" + currentBusiness + "/report");
    } else if (name === "AddEmployee") {
      if (status === true) {
        history.push(
          "/businesses/" + currentBusiness + "/employees/addEmployee"
        );
      } else {
        if (employeeCreatePermission === true) {
          history.push(
            "/businesses/" + currentBusiness + "/employees/addEmployee"
          );
        } else {
          history.push("/businesses/" + currentBusiness + "/employees");
          commonStore.showErrorMessage(
            "You don't have permission to do this action"
          );
        }
      }
    } else if (name === "CreateRole") {
      history.push(
        "/businesses/" + currentBusiness + "/employees/employeeRole"
      );
    } else if (name === "contact") {
      history.push("/businesses/" + currentBusiness + "/contact");
    } else if (name === "document") {
      history.push("/businesses/" + currentBusiness + "/document");
    }
    // else if (name === "Email") {
    //   history.push("/businesses/" + currentBusiness + "/email");
    // }
    else if (name === "triggeredEmail") {
      history.push("/businesses/" + currentBusiness + "/scheduled");
    } else if (name === "Reminder") {
      history.push("/businesses/" + currentBusiness + "/reminder");
    } else if (name === "communication") {
      event.currentTarget.parentElement.classList.toggle("open");
    } else if (name === "AdminDashboard") {
      history.push("/admin/dashboard");
    } else if (name === "AdminBusiness") {
      history.push("/admin/businessowner");
    } else if (name === "adminList") {
      history.push("/admin/adminlist");
    }

    if (window.innerWidth < 576) {
      document.body.classList.toggle("sidebar-show");
    }
  };

  componentDidMount = async () => {
    const { authStore, employeeStore } = this.props;
    const { currentBusiness } = authStore;
    // let { businessArray } = authStore;
    let status = reactLocalStorage.get("account")
      ? JSON.parse(reactLocalStorage.get("account"))
      : "";
    employeeStore.setEditedData({
      status: status.isOwner
    });
    authStore.setEditedData({ status: status.isOwner });

    // if (window.location.pathname.includes("/admin") === false) {
    //   const { licenseForValidation } = businessStore;
    //   let ownerData = reactLocalStorage.get("account")
    //     ? JSON.parse(reactLocalStorage.get("account"))
    //     : "";
    //   let { license } = await licenseForValidation(
    //     ownerData ? ownerData._id : ""
    //   );
    //   if (license) {
    //     let licenseState = license.map(licenses => {
    //       return licenses.state;
    //     });
    //     await localStorage.setItem(
    //       "isStateMatch",
    //       licenseState.includes("Wyoming")
    //     );
    //     await this.setState({ isStateMatch: licenseState.includes("Wyoming") });
    //   }
    // }

    // setTimeout(() => {
    // const license = JSON.parse(localStorage.getItem("license"));
    // let licenseState = license.map(license => {
    //   return license.state;
    // });
    // }, 3000);
    let permission = {};

    if (status.isOwner === false) {
      permission = JSON.parse(reactLocalStorage.get("permission"));
      employeeStore.setEditedData({
        employeeCreatePermission: permission.employee.create
      });
    }

    // businessArray = JSON.parse(reactLocalStorage.get("businessArray"));
    reactLocalStorage.get("huntingCurrentBusiness", currentBusiness);

    authStore.setEditedData({ businessUnit: currentBusiness });
  };

  render() {
    let pathname = this.props.location.pathname;
    // let className = pathname ? "nav-link active" : "nav-link";
    let { authStore } = this.props;
    const { editedData } = authStore;
    const { status } = editedData;
    const businessName = reactLocalStorage.get("currentBusinessName")
      ? JSON.parse(reactLocalStorage.get("currentBusinessName"))
      : "";
    // const { isStateMatch } = this.state;
    // const businessEditedData = businessStore.editedData;
    // const state = JSON.parse(reactLocalStorage.get("currentState"));
    // let { businessArray } = authStore;
    // let businessArray = [];
    // businessArray = reactLocalStorage.get("businessArray")
    //   ? JSON.parse(reactLocalStorage.get("businessArray"))
    //   : [];

    return (
      <div
        className="sidebar"
        style={{ background: "#FFF", borderRight: " solid 1px #EAEDF3" }}
      >
        <nav className="sidebar-nav" style={{ position: "unset" }}>
          {status === false ? (
            <div />
          ) : pathname.includes("/admin") === false ? (
            <div style={{ marginTop: "20px", padding: "10px" }}>
              <span
                style={{
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  fontWeight: 500,
                  color: "#9EA0A5"
                }}
              >
                Account
              </span>

              {/* <DropDown
                name="businessUnit"
                // label={"Business Unit"}
                value={editedData.businessUnit}
                onChange={this.changeDropDownItem}
                itemArray={businessArray}
              />
              <AddBusinessModal /> */}
              <br />
              <div style={{ marginTop: "10px" }}>
                <Text textType="body2" text={businessName} weight="medium" />
              </div>
            </div>
          ) : (
                <div />
              )}
          {pathname.includes("/admin") === false ? (
            <ul className="nav">
              <li className="nav-title">
                <span>MENU</span>
              </li>
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className={
                    pathname.includes("dashboard")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  onClick={this.goToPage}
                  name="Dashboard"
                >
                  {pathname.includes("dashboard") && (
                    <img
                      style={{ marginBottom: "3px", paddingRight: "7px" }}
                      src={DashboardImage}
                      alt=""
                    />
                  )}
                  {!pathname.includes("dashboard") && (
                    <img
                      style={{
                        marginBottom: "3px",
                        paddingLeft: "5px",
                        paddingRight: "7px"
                      }}
                      src={DashboardImage}
                      alt=""
                    />
                  )}
                  {/* <span style={{ paddingLeft: "7px" }}>Dashboard</span> */}
                  Dashboard
                </a>
              </li>
              <li
                className="nav-item nav-dropdown"
                style={{ cursor: "pointer", marginRight: "1px" }}
              >
                <a
                  className={
                    pathname.includes("employees") &&
                      !pathname.includes("addEmployee") &&
                      !pathname.includes("employeeRole")
                      ? "nav-link nav-dropdown-toggle active"
                      : "nav-link nav-dropdown-toggle"
                  }
                  onClick={this.goToPage}
                  name="Employee"
                >
                  {pathname.includes("employees") && (
                    <img
                      style={{ marginBottom: "3px", paddingRight: "7px" }}
                      src={EmployeeImage}
                      alt=""
                    />
                  )}
                  {!pathname.includes("employees") && (
                    <img
                      style={{
                        marginBottom: "3px",
                        paddingLeft: "5px",
                        paddingRight: "7px"
                      }}
                      src={EmployeeImage}
                      alt=""
                    />
                  )}
                  Employees
                </a>

                <ul className="nav-dropdown-items">
                  <li className="nav-item" style={{ cursor: "pointer" }}>
                    <a
                      className={
                        pathname.includes("employeeRole")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={this.goToPage}
                      name="CreateRole"
                    >
                      Create Role
                    </a>
                  </li>
                  <li className="nav-item" style={{ cursor: "pointer" }}>
                    <a
                      className={
                        pathname.includes("addEmployee")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={this.goToPage}
                      name="AddEmployee"
                    >
                      Add Employee
                    </a>
                  </li>
                </ul>
              </li>

              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className={
                    pathname.includes("hunt") ? "nav-link active" : "nav-link"
                  }
                  onClick={this.goToPage}
                  name="huntType"
                >
                  {pathname.includes("hunt") && (
                    <img
                      style={{ marginBottom: "3px", paddingRight: "7px" }}
                      src={HuntImage}
                      alt=""
                    />
                  )}
                  {!pathname.includes("hunt") && (
                    <img
                      style={{
                        marginBottom: "3px",
                        paddingLeft: "5px",
                        paddingRight: "7px"
                      }}
                      src={HuntImage}
                      alt=""
                    />
                  )}
                  Services
                </a>
              </li>
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className={
                    pathname.includes("customer")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  onClick={this.goToPage}
                  name="customer"
                >
                  {pathname.includes("customer") && (
                    <img
                      style={{ marginBottom: "3px", paddingRight: "7px" }}
                      src={CustomerImage}
                      alt=""
                    />
                  )}
                  {!pathname.includes("customer") && (
                    <img
                      style={{
                        marginBottom: "3px",
                        paddingLeft: "5px",
                        paddingRight: "7px"
                      }}
                      src={CustomerImage}
                      alt=""
                    />
                  )}
                  Customers
                </a>
              </li>
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className={
                    pathname.includes("reservation")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  onClick={this.goToPage}
                  name="reservation"
                >
                  {pathname.includes("reservation") && (
                    <img
                      style={{ marginBottom: "3px", paddingRight: "7px" }}
                      src={BookingImage}
                      alt=""
                    />
                  )}
                  {!pathname.includes("reservation") && (
                    <img
                      style={{
                        marginBottom: "3px",
                        paddingLeft: "5px",
                        paddingRight: "7px"
                      }}
                      src={BookingImage}
                      alt=""
                    />
                  )}
                  Reservations
                </a>
              </li>
              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className={
                    pathname.includes("report") ? "nav-link active" : "nav-link"
                  }
                  onClick={this.goToPage}
                  name="report"
                >
                  {pathname.includes("report") && (
                    <img
                      alt=""
                      style={{ marginBottom: "3px", paddingRight: "7px" }}
                      src={ReportImage}
                    />
                  )}
                  {!pathname.includes("report") && (
                    <img
                      style={{
                        marginBottom: "3px",
                        paddingLeft: "5px",
                        paddingRight: "7px"
                      }}
                      src={ReportImage}
                      alt=""
                    />
                  )}
                  Reports
                </a>
              </li>

              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className={
                    pathname.includes("contact")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  onClick={this.goToPage}
                  name="contact"
                >
                  {pathname.includes("contact") && (
                    <img
                      alt=""
                      style={{ marginBottom: "3px", paddingRight: "7px" }}
                      src={ContactImage}
                    />
                  )}
                  {!pathname.includes("contact") && (
                    <img
                      style={{
                        marginBottom: "3px",
                        paddingLeft: "5px",
                        paddingRight: "7px"
                      }}
                      src={ContactImage}
                      alt=""
                    />
                  )}
                  Contacts
                </a>
              </li>

              <li className="nav-item" style={{ cursor: "pointer" }}>
                <a
                  className={
                    pathname.includes("document")
                      ? "nav-link active"
                      : "nav-link"
                  }
                  onClick={this.goToPage}
                  name="document"
                >
                  {pathname.includes("document") && (
                    <img
                      alt=""
                      style={{ marginBottom: "3px", paddingRight: "7px" }}
                      src={FormImage}
                    />
                  )}
                  {!pathname.includes("document") && (
                    <img
                      style={{
                        marginBottom: "3px",
                        paddingLeft: "5px",
                        paddingRight: "7px"
                      }}
                      src={FormImage}
                      alt=""
                    />
                  )}
                  Documents
                </a>
              </li>
              <li
                className="nav-item nav-dropdown"
                style={{ cursor: "pointer", marginRight: "1px" }}
              >
                <a
                  className="nav-link nav-dropdown-toggle"
                  onClick={this.goToPage}
                  name="communication"
                >
                  <img
                    style={{
                      marginBottom: "3px",
                      paddingLeft: "5px",
                      paddingRight: "7px"
                    }}
                    src={CommunicationImage}
                    alt=""
                  />
                  Communications
                </a>

                <ul className="nav-dropdown-items">
                  {/* <li className="nav-item" style={{ cursor: "pointer" }}>
                    <a
                      className={
                        pathname.includes("email")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={this.goToPage}
                      name="Email"
                    >
                      Email
                    </a>
                  </li> */}

                  <li className="nav-item" style={{ cursor: "pointer" }}>
                    <a
                      className={
                        pathname.includes("scheduled")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={this.goToPage}
                      name="triggeredEmail"
                    >
                      Scheduled Email
                    </a>
                  </li>
                  <li className="nav-item" style={{ cursor: "pointer" }}>
                    <a
                      className={
                        pathname.includes("reminder")
                          ? "nav-link active"
                          : "nav-link"
                      }
                      onClick={this.goToPage}
                      name="Reminder"
                    >
                      Reminders
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          ) : (
              <ul className="nav">
                <li className="nav-title">
                  <span>MENU</span>
                </li>
                <li className="nav-item" style={{ cursor: "pointer" }}>
                  <a
                    className={
                      pathname.includes("/admin/dashboard")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    onClick={this.goToPage}
                    name="AdminDashboard"
                  >
                    {pathname.includes("/admin/dashboard") && (
                      <img
                        alt=""
                        style={{ marginBottom: "3px", paddingRight: "7px" }}
                        src={DashboardImage}
                      />
                    )}
                    {!pathname.includes("/admin/dashboard") && (
                      <img
                        style={{
                          marginBottom: "3px",
                          paddingLeft: "5px",
                          paddingRight: "7px"
                        }}
                        src={DashboardImage}
                        alt=""
                      />
                    )}
                    {/* <span style={{ paddingLeft: "7px" }}>Dashboard</span> */}
                    Dashboard
                </a>
                </li>
                <li
                  className="nav-item nav-dropdown"
                  style={{ cursor: "pointer", marginRight: "1px" }}
                >
                  <a
                    className={
                      pathname.includes("/admin/businessowner")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    onClick={this.goToPage}
                    name="AdminBusiness"
                  >
                    {pathname.includes("/admin/businessowner") && (
                      <img
                        alt=""
                        style={{ marginBottom: "3px", paddingRight: "7px" }}
                        src={BusinessImage}
                      />
                    )}
                    {!pathname.includes("/admin/businessowner") && (
                      <img
                        style={{
                          marginBottom: "3px",
                          paddingLeft: "5px",
                          paddingRight: "7px"
                        }}
                        src={BusinessImage}
                        alt=""
                      />
                    )}
                    Business Owners
                </a>
                </li>

                <li className="nav-item" style={{ cursor: "pointer" }}>
                  <a
                    className={
                      pathname.includes("adminlist")
                        ? "nav-link active"
                        : "nav-link"
                    }
                    onClick={this.goToPage}
                    name="adminList"
                  >
                    {pathname.includes("adminlist") && (
                      <img
                        style={{ marginBottom: "3px", paddingRight: "7px" }}
                        src={AdminImage}
                        alt=""
                      />
                    )}
                    {!pathname.includes("adminlist") && (
                      <img
                        style={{
                          marginBottom: "3px",
                          paddingLeft: "5px",
                          paddingRight: "7px"
                        }}
                        src={AdminImage}
                        alt=""
                      />
                    )}
                    Admin
                </a>
                </li>
              </ul>
            )}
        </nav>

        <div style={{ padding: "10px" }}>
          <span
            style={{
              fontFamily: "Roboto",
              fontSize: "14px",
              fontWeight: 500
            }}
          >
            Need Help?
          </span>
          <br />
          <span
            style={{
              fontFamily: "Roboto",
              fontSize: "14px"
            }}
          >
            Call{" "}
            <a href={`tel:206-487-4530`} style={{ textAlign: "center" }}>
              206-487-4530
            </a>{" "}
          </span>
        </div>
      </div>
    );
  }
}
SideBar.contextTypes = {
  router: PropTypes.object
};
export default inject(
  "addBusinessModalStore",
  "businessStore",
  "authStore",
  "employeeStore",
  "huntTypeStore",
  "dashboardStore",
  "huntSessionStore",
  "customerStore",
  "contactStore",
  "bookingStore",
  "notificationStore"
)(observer(SideBar));
