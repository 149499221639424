// const apiUrl = "http://192.168.86.23:8080/api/v1";
// const adminUrl = "http://192.168.86.23:8080/business/v1";
// module.exports = { apiUrl, adminUrl };

// const apiUrl = "https://hunting-platform.herokuapp.com/api/v1";
// // const adminUrl = "https://hunting-platform.herokuapp.com/admin/v1";

let apiUrl;
let adminUrl;
let uploadUrl;
let superUrl;

if (process.env.REACT_APP_STAGE === "production") {
  apiUrl = "https://api.worksprings.com/api/v1";
  adminUrl = "https://api.worksprings.com/business/v1";
  uploadUrl = "https://api.worksprings.com";
  superUrl = "https://api.worksprings.com/admin/v1";
} else if (process.env.REACT_APP_STAGE === "staging") {
  apiUrl = "https://staging-api.worksprings.com/api/v1";
  adminUrl = "https://staging-api.worksprings.com/business/v1";
  uploadUrl = "https://staging-api.worksprings.com";
  superUrl = "https://staging-api.worksprings.com/admin/v1";
} else {
  apiUrl = "http://10.8.0.30:8081/api/v1";
  adminUrl = "http://10.8.0.30:8081/business/v1";
  superUrl = "http://10.8.0.30:8081/admin/v1";
  uploadUrl = "https://staging-api.worksprings.com";
  // apiUrl = "https://staging-api.worksprings.com/api/v1";
  // adminUrl = "https://staging-api.worksprings.com/business/v1";
  // uploadUrl = "https://staging-api.worksprings.com";
  // superUrl = "https://staging-api.worksprings.com/admin/v1";
}

module.exports = { apiUrl, adminUrl, uploadUrl, superUrl };
