import { observable, decorate } from "mobx";
// import { reactLocalStorage } from "reactjs-localstorage";
import moment from "moment";
// import CommonStore from "./CommonStore";

class BookingSessionCardStore {
  isLoading = false;
  calendarSelectedDate = moment(new Date()).format("dddd, DD MMMM YYYY");

  editedData = {};

  setEditedData = editedData => {
    this.editedData = {
      ...this.editedData,
      ...editedData
    };
  };

  setIsLoading = boolStatus => {
    this.isLoading = boolStatus;
  };

  setCalendarSelectedDate = date => {
    this.calendarSelectedDate = moment(date).format("dddd, DD MMMM YYYY");
  };
}

decorate(BookingSessionCardStore, {
  editedData: observable,
  isLoading: observable,
  calendarSelectedDate: observable
});
export default new BookingSessionCardStore();
