import React from "react";
import { observable, decorate } from "mobx";
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from "@material-ui/icons/Delete";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import commonStore from "./CommonStore";
import { getAllWebform, deleteWebform } from "../services/WebformService"
import { getBusinessById, putBusiness } from "../services/BusinessService";
import { reactLocalStorage } from "reactjs-localstorage";
import DeleteStore from '../stores/DeleteWarningStore';

class SettingStore {
  editedData = {
    isBasePriceTaxed: false,
    isHunterFeeTaxed: false,
    isFishermanFeeTaxed: false,
    salesTax: '',
    hunterFee: '',
    fishermanFee: '',
    observerFee: '',
    wildlifeDonationFee: '',
    juniorHunterFee: '',
    webformId: ""
  };
  webformLoading = false;
  pageLoading = false;

  //Form
  formValueTransformer = {
    link: data => {
      return (
        <span
          style={{ color: "#01953A", cursor: "pointer" }}
          onClick={e => {
            e.stopPropagation()
            let textArea = document.createElement("textarea");
            textArea.value = data.link;
            document.body.appendChild(textArea);
            textArea.select();
            document.execCommand("Copy");
            textArea.remove();
            commonStore.showSuccessMessage("Link Copied")
          }}
        >
          <FileCopyIcon /> Copy Link
        </span>
      )
    },
    preview: data => {
      return (
        <span
          style={{ color: "#01953A", cursor: "pointer" }}
          onClick={e => {
            e.stopPropagation()
            window.open(data.preview)
          }}
        >
          <VisibilityIcon /> Preview Form
        </span>
      )
    },
    delete: data => {
      return (
        <span
          style={{ color: "#01953A", cursor: "pointer" }}
          onClick={async e => {
            e.stopPropagation();
            this.setEditedData({ webformId: data.id })
            DeleteStore.showWarning();
          }}
        >
          <DeleteIcon />
        </span>
      )
    }
  };
  formArray = [];
  formColumn = [
    { name: "formName", title: "Form Name" },
    {
      name: "preview", title: " ",
      valueTransformer: this.formValueTransformer["preview"]
    },
    {
      name: "link", title: " ",
      valueTransformer: this.formValueTransformer["link"]
    },
    {
      name: "delete", title: " ",
      valueTransformer: this.formValueTransformer["delete"]
    },
  ];
  formColumnWidths = [
    { columnName: "formName", width: 200 },
    { columnName: "preview", width: 200 },
    { columnName: "link", width: 200 },
    { columnName: "delete", width: 200 },
  ];

  setEditedData = value => {
    this.editedData = {
      ...this.editedData,
      ...value,
    }
  }

  getAllWebform = async () => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.webformLoading = true;
    const { data, error } = await getAllWebform({ businessId })
    this.webformLoading = false;

    if (data) {
      this.formArray = data.map(item => {
        const businessName = item.business && item.business.name ? item.business.name : "";

        return {
          id: item._id ? item._id : "",
          formName: item.formName ? item.formName : "",
          preview: `https://admin.worksprings.com/formpreview?formId=${item._id}&name=${businessName}`,
          link: `https://admin.worksprings.com/createcontact?formId=${item._id}&id=${item.business._id}&name=${businessName}`,
          delete: item._id ? item._id : "",
        }
      })

    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  getBusinessById = async () => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    const { data, error } = await getBusinessById(businessId);

    if (data) {
      const newData = {
        ...data,
        businessType: data.businessType && data.businessType._id ? data.businessType._id : '',
        salesTax: data.salesTax ? String(data.salesTax) : "0",
        hunterFee: data.hunterFee ? String(data.hunterFee) : "0",
        fishermanFee: data.fishermanFee ? String(data.fishermanFee) : "0",
        observerFee: data.observerFee ? String(data.observerFee) : "0",
        wildlifeDonationFee: data.wildlifeDonationFee ? String(data.wildlifeDonationFee) : "0",
        juniorHunterFee: data.juniorHunterFee ? String(data.juniorHunterFee) : "0",
      };
      this.setEditedData(newData);
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  deleteWebform = async ({ webformId }) => {
    const businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.webformLoading = true;
    const { data, error } = await deleteWebform({ businessId, webformId });
    this.webformLoading = false;

    if (!data) {
      commonStore.showSuccessMessage("Form Deleted")
      this.getAllWebform();
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  putBusiness = async values => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    const businessDTO = {
      businessName: values.name,
      businessType: values.businessType,
      address1: values.address1,
      address2: values.address2,
      postalCode: values.postalCode,
      city: values.city,
      state: values.state,
      country: values.country,
      phoneNumber: values.phoneNumber,
      website: values.website,
      logo: values.logo,
      timezone: values.timezone,
      isBLM: values.isBLM,
      salesTax: values.salesTax,
      // hunterFee: values.hunterFee,
      // fishermanFee: values.fishermanFee,
      hunterFee: 0,
      fishermanFee: 0,
      observerFee: values.observerFee,
      // wildlifeDonationFee: values.wildlifeDonationFee,
      // juniorHunterFee: values.juniorHunterFee,
      // isWildlifeDonationTaxed: values.isWildlifeDonationTaxed,
      isObserverFeeTaxed: values.isObserverFeeTaxed,
      // isFishermanFeeTaxed: values.isFishermanFeeTaxed,
      // isHunterFeeTaxed: values.isHunterFeeTaxed,
      // isJuniorFeeTaxed: values.isJuniorFeeTaxed,
      // isAdditionalFeeTaxed: values.isAdditionalFeeTaxed,
      isBasePriceTaxed: values.isBasePriceTaxed,
    };

    this.pageLoading = true;
    const { data, error } = await putBusiness(businessDTO, businessId);
    this.pageLoading = false;

    if (data) {
      await new Promise((resolve, reject) => {
        commonStore.showSuccessMessage("Data updated!");
        setTimeout(resolve, 500);
      });
      return true;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }

      return false;
    }
  };
}
decorate(SettingStore, {
  editedData: observable,
  formArray: observable,
  pageLoading: observable,
  webformLoading: observable
});

export default new SettingStore();