import React from 'react';
import { observable, decorate } from "mobx";
import { getAllCustomer } from "../services/CustomerService"
import { getAllContact } from "../services/ContactService"
import {
  sendEmail,
  getAllEmail,
  getAllSent,
  uploadFile,
  createTriggeredEmail,
  getTriggeredEmail,
  getSingleTriggeredEmail,
  updateTriggeredEmail,
  deleteTriggeredEmail,
  bulkdeleteTemplate,
  createTemplate,
  deleteTemplate,
  getSingleTemplate,
  getTemplate,
  updateTemplate,
  getCustomerByRange,
  // Subscribe,
  Unsubscribe,
  getReminderSettings,
  setReminderStatus,
  getReminderList,
  deleteReminder,
  bulkDeleteReminder,
  readReminder
} from "../services/CommunicationService"
import trashCan from "../assets/trash.svg"
import deleteStores from "./DeleteWarningStore"
import { checkConnectGoogle } from "../services/BusinessService"
import Switch from "../components/Switch"
// import Checkbox from "../components/Checkbox"
import commonStore from "./CommonStore"
import { reactLocalStorage } from "reactjs-localstorage"
import moment from "moment"

class CommunicationStore {
  editedData = {
    contactDataSelected: [],
    customerDataSelected: [],
    search: "",
    templateValue: "",
    customerRecipientIndex: [],
    contactRecipientIndex: [],
    tabValue: 0,
    totalUniqueOpens: 0,
    // contactTempIndex: [],
    // customerTempIndex: [],

    // constantCustomerArray: [],
    // constantContactArray: [],

    // prevEmailsName: [],
    // prevEmails: [],
    // prevCustomerArray: [],
    reminderSelected: [],
    templateSelected: [],
    isMarketing: false,
    isConfirmation: false,
    logo: false,
    conDate: "",
    conRecipient: "",
    conCC: "",
    conBCC: "",
    conSubject: "",
    isOpenUniqueOpens: false,
    content: []
  };
  isLoading = false;
  emailArray = []
  token = ""
  errorMessage = ""

  column = [{
    name: "name",
    title: "Select All",
    // valueTransformer: this.valueTransformer["name"]
  }]
  columnWidth = [{
    columnName: "name",
    width: 200
  }]

  //Analytics
  analyticsColumn = [
    { name: "email", title: "Email Address" },
    { name: "uniqueOpens", title: "Unique Open" }
  ];
  analyticsColumnWidths = [
    { columnName: "email", width: 250 },
    { columnName: "uniqueOpens", width: 200 }
  ];
  analyticsArray = [];

  //Template
  valueTransformerTemplate = {
    delete: data => {
      return (
        <img
          alt=""
          src={trashCan}
          style={{ cursor: 'pointer' }}
          onClick={async e => {
            e.stopPropagation();
            await this.deleteTemplate(data.id)
          }}
        />
      )
    }
  }

  columnTemplate = [
    { name: "subject", title: "Subject" },
    {
      name: "delete",
      title:
        <img
          alt=""
          src={trashCan}
          style={{ cursor: 'pointer' }}
          onClick={async e => {
            e.stopPropagation();
            if (this.templateArray.length !== 0) {
              const tempTemplateId = this.templateArray.map(item => {
                return item.id
              })

              const ids = tempTemplateId.filter((item, index) => {
                return this.editedData.templateSelected.includes(index)
              });

              const finalData = {
                templateEmailId: [...ids],
              }

              await this.bulkdeleteTemplate(finalData)
            }
          }}
        />,
      valueTransformer: this.valueTransformerTemplate["delete"]
    }
  ];
  columnWidthTemplate = [
    { columnName: "subject", width: 900 },
    { columnName: "delete", width: 200 }
  ];
  templateArray = []
  templateLoading = false
  templateSearch = ""

  //Triggered Email
  valueTransformerTriggered = {
    sent: data => {
      return (
        <div style={{ width: "100px" }}>
          {data.status &&
            <span style={{
              backgroundColor: "#01953A",
              borderRadius: "3px",
              padding: "6px",
              display: "flex",
              justifyContent: "center",
              color: "#fff",
            }} > SENT </span>
          }
          {!data.status &&
            <span style={{
              backgroundColor: "#F7B500",
              borderRadius: "3px",
              padding: "6px",
              display: "flex",
              justifyContent: "center",
              color: "#fff",
            }}>
              SCHEDULED
            </span>
          }
        </div>
      )
    },
    delete: data => {
      return (
        <img
          alt=""
          src={trashCan}
          style={{ cursor: 'pointer' }}
          onClick={async e => {
            e.stopPropagation();
            deleteStores.setEditedData({ toBeDeleted: data.id });
            deleteStores.showWarning();
            // await this.deleteTriggeredEmailOuter(data._id)
          }}
        />
      )
    },
    unique: data => (
      <div style={{
        marginLeft: "40px",
        color: "#01953A",
        width: "100%"
      }}
        onClick={e => {
          e.stopPropagation();
          const temp = Object.keys(data.openedMails);
          // let initialValue = 0

          // const sum = temp.reduce(
          //   (accumulator, currentValue) => accumulator + data.openedMails[currentValue]
          //   , initialValue
          // )

          const final = [...data.recipientArray, ...data.cc, ...data.bcc].map(item => {
            let status = "";
            if (temp.includes(item.toLowerCase())) {
              status = "Opened"
            } else {
              status = "Not Opened"
            }

            return {
              email: item,
              uniqueOpens: status
            }
          })

          const sortedFinal = final ? final.sort((a, b) => {
            return (a.uniqueOpens < b.uniqueOpens) - (a.uniqueOpens > b.uniqueOpens);
          }) : [];

          this.setAnalyticsArray(sortedFinal)
          // this.setEditedData({ isOpenUniqueOpens: true, content: data.openedMails })
          this.setEditedData({ tabValue: 3, totalUniqueOpens: temp.length })

        }}
      >
        <span style={{ fontWeight: "bold" }}>{data.uniqueOpen}</span>
      </div>
    )
  }
  triggeredEmailColumn = [
    { name: "subject", title: "Subjects" },
    { name: "recipient", title: "Recipients" },
    { name: "sendDate", title: "Delivery Schedule" },
    {
      name: "status", title: "Status",
      valueTransformer: this.valueTransformerTriggered["sent"]
    },
    {
      name: "uniqueOpen", title: "Unique Opens",
      valueTransformer: this.valueTransformerTriggered["unique"]
    },
    {
      name: "delete", title: " ",
      valueTransformer: this.valueTransformerTriggered["delete"]
    }
  ];
  triggeredEmailColumnWidths = [
    { columnName: "subject", width: 200 },
    { columnName: "recipient", width: 200 },
    { columnName: "sendDate", width: 200 },
    { columnName: "status", width: 150 },
    { columnName: "uniqueOpen", width: 150 },
    { columnName: "delete", width: 150 }
  ];
  triggeredEmailArray = [];
  triggeredEmailPageSize = 50
  triggeredEmailCurrentPage = 0
  triggeredEmailTotalCount = 0
  triggeredEmailLoading = false
  triggeredEmailSearch = ""
  triggeredEmailStartDate = null
  triggeredEmailEndDate = null

  //Composed Email
  tempCustomer = [];
  tempContact = [];
  combinedList = [];
  templateOption = [];
  combinedArray = [];
  tempCombinedArray = [];

  //Customer
  customerPageSize = 10000
  customerCurrentPage = 0
  customerArray = []
  customerTotalCount = 0
  customerLoading = false

  //Contact
  contactPageSize = 10000
  contactCurrentPage = 0
  contactArray = []
  contactTotalCount = 0
  contactLoading = false

  column = [{
    name: "name",
    title: "Select All",
    // valueTransformer: this.valueTransformer["name"]
  }]
  columnWidth = [{
    columnName: "name",
    width: 200
  }]

  //Reminder
  valueTransformerReminder = {
    activeReminder: data => {
      let color = "";
      if (data.activeReminder === 'Customer Birthday' || data.activeReminder === '60 Days Prior') {
        color = "#01953A";
      } else if (data.activeReminder === '30 Days Prior') {
        color = "#F7B500"
      } else if (data.activeReminder === 'Document Warning') {
        color = "#E02020"
      }

      return (
        <div style={{ width: 500, whiteSpace: 'pre-wrap' }}>
          <span style={{ wordBreak: 'normal', color: color, fontWeight: !data.read && "bold" }}>
            {data.activeReminder}
          </span>
        </div>
      );
    },
    delete: data => {
      return (
        <img
          alt=""
          src={trashCan}
          style={{ cursor: 'pointer' }}
          onClick={async e => {
            e.stopPropagation();
            await this.deleteReminder(data.id)
          }}
        />
      )
    },
    message: data => (
      <span style={{ wordBreak: 'normal', fontWeight: !data.read && "bold" }}>
        {data.message}
      </span>
    )
  };
  reminderPageSize = 10
  reminderCurrentPage = 0
  reminderArray = []
  reminderTotalCount = 0
  reminderLoading = false
  columnReminder = [
    {
      name: "activeReminder", title: "Active Reminder",
      valueTransformer: this.valueTransformerReminder["activeReminder"]
    },
    {
      name: "message", title: "Reminder Message",
      valueTransformer: this.valueTransformerReminder["message"]

    },
    {
      name: "delete",
      title:
        <img alt=""
          src={trashCan}
          style={{ cursor: 'pointer' }} onClick={async e => {
            e.stopPropagation();
            if (this.reminderArray.length !== 0) {
              const tempReminderId = this.reminderArray.map(item => {
                return item.id
              })

              const ids = tempReminderId.filter((item, index) => {
                return this.editedData.reminderSelected.includes(index)
              });

              const finalData = {
                reminderListId: [...ids],
              }

              await this.bulkDeleteReminder(finalData)
            }
          }} />,
      valueTransformer: this.valueTransformerReminder["delete"]
    }
  ];
  columnWidthReminder = [
    { columnName: "checkbox", width: 100 },
    { columnName: "activeReminder", width: 200 },
    { columnName: "message", width: 825 },
    { columnName: "delete", width: 200 }
  ];
  columnDisabledReminder = [
    { columnName: "checkbox", sortingEnabled: false },
    { columnName: "activeReminder", sortingEnabled: false },
    { columnName: "message", sortingEnabled: false },
    { columnName: "delete", sortingEnabled: false }
  ];

  //Reminder Setting
  valueTransformerReminderSetting = {
    message: data => {
      let color = "";
      if (data.id === 1 || data.id === 4) {
        color = "#01953A";
      } else if (data.id === 2) {
        color = "#F7B500"
      } else if (data.id === 3) {
        color = "#E02020"
      }

      return (
        <div style={{ width: 500, whiteSpace: 'pre-wrap' }}>
          <span style={{ wordBreak: 'normal', color: color }}>
            {data.activeReminder}
          </span>
        </div>
      );
    },
    id: data => {
      let label = null;
      if (data.status) {
        label = <span style={{ color: '#01953A' }}>On</span>
      } else {
        label = <span style={{ color: '#E02020' }}>Off</span>
      }
      return (
        <Switch
          name="status"
          value={data.status}
          checked={data.status}
          label={label}
          onClick={async e => {
            e.stopPropagation();
            this.changeStatus(e, data.id)
          }}
        />
      )
    }
  };
  reminderSettingsPageSize = 10
  reminderSettingsCurrentPage = 0
  reminderSettingsArray = []
  reminderSettingsTotalCount = 0
  reminderSettingsLoading = false
  columnReminderSettings = [
    {
      name: "activeReminder", title: "Active Reminder",
      valueTransformer: this.valueTransformerReminderSetting["message"]
    },
    {
      name: "message",
      title: "Reminder Message",
    },
    {
      name: "id", title: " ",
      valueTransformer: this.valueTransformerReminderSetting["id"]
    },
  ];
  columnWidthReminderSettings = [
    { columnName: "activeReminder", width: 200 },
    { columnName: "message", width: 830 },
    { columnName: "id", width: 150 },
  ];
  tempSettingArray = [...this.reminderSettingsArray]

  setEditedData = editedData => {
    this.editedData = {
      ...this.editedData,
      ...editedData
    };
  };

  clearSearch = () => {
    this.editedData.search = "";
  }

  setLoading = value => {
    this.isLoading = value
  }

  changeStatus = async (e, id) => {
    const { value, checked } = e.target

    this.setLoadingReminderSetting(true)
    const { data, error } = await this.setReminderStatus(id, { status: checked })
    this.setLoadingReminderSetting(false)


    if (data) {
      if (value === 'true') {
        for (let i = 0; i < this.reminderSettingsArray.length; i++) {
          if (id === this.reminderSettingsArray[i].id) {
            this.tempSettingArray[i].status = false
            break;
          }
        }
      } else {
        for (let i = 0; i < this.reminderSettingsArray.length; i++) {
          if (id === this.reminderSettingsArray[i].id) {
            this.tempSettingArray[i].status = true
            break;
          }
        }
      }

      this.reminderSettingsArray = this.tempSettingArray

    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  setReminderCurrentPage = value => {
    this.reminderCurrentPage = value;
    this.getReminderList();
  };

  setReminderSettingsCurrentPage = value => {
    this.reminderSettingsCurrentPage = value;
  };

  setInitalEmailArray = () => {
    this.emailArray = []
  }

  setSearch = value => {
    this.triggeredEmailSearch = value
  }

  setSearchTemplate = value => {
    this.templateSearch = value
  }

  setStartDate = value => {
    this.triggeredEmailStartDate = value
  }

  setEndDate = value => {
    this.triggeredEmailEndDate = value
  }

  setTriggeredEmailLoading = value => {
    this.triggeredEmailLoading = value;
  }

  setTriggeredEmailPage = value => {
    this.triggeredEmailCurrentPage = value
    this.getTriggeredEmail();
  }

  setCustomerLoading = value => {
    this.customerLoading = value
  }

  setLoadingReminderSetting = value => {
    this.reminderSettingsLoading = value
  }

  setAnalyticsArray = value => {
    this.analyticsArray = value
  }

  // getUnique = array => {
  //   let uniqueArray = [];

  //   // Loop through array values
  //   for (let value of array) {
  //     if (uniqueArray.indexOf(value) === -1) {
  //       uniqueArray.push(value);
  //     }
  //   }
  //   return uniqueArray;
  // }

  getAllCustomer = async () => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.customerLoading = true;

    const { data } = await getAllCustomer({
      businessId,
      currentPage: this.customerCurrentPage + 1,
      search: this.editedData.search,
      pageLimit: this.customerPageSize,
    })


    if (data) {
      this.customerArray =
        data.customers ?
          data.customers.map(customer => {
            const firstName = customer.firstName ? customer.firstName : "";
            const lastName = customer.lastName ? customer.lastName : "";
            const email = customer.customer ? customer.customer.email : "";
            const subscription = customer.subscription ? customer.subscription : false;

            return {
              name: `${firstName} ${lastName}`,
              email: email,
              firstName: firstName,
              lastName: lastName,
              subscription: subscription,
            }
          })
          : [];
      this.customerTotalCount = data.totalData

      if (this.tempCustomer.length === 0) {
        this.tempCustomer = this.customerArray.map(value => {
          return {
            value: value.email,
            label: value.name
          }
        })

        this.combinedList = [...this.tempCustomer, ...this.tempContact];
      }

      if (this.editedData.search !== "") {
        this.combinedArray = this.tempCombinedArray.filter(item => {
          return ![this.editedData.contactForTemp].includes(item.email)
        })
      }

      this.customerLoading = false;

    } else {
      this.customerLoading = false;

    }
  }

  getAllContact = async () => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.contactLoading = true;

    const { data } = await getAllContact({
      businessId,
      page: this.contactCurrentPage + 1,
      search: this.editedData.search,
      limit: this.contactPageSize,
    })

    if (data) {
      this.contactArray =
        data.contacts ?
          data.contacts.map(contact => {
            const firstName = contact.firstName ? contact.firstName : "";
            const lastName = contact.lastName ? contact.lastName : "";
            const email = contact.email ? contact.email : "";
            const subscription = contact.subscription ? contact.subscription : false;

            return {
              name: `${firstName} ${lastName}`,
              email: email,
              firstName: firstName,
              lastName: lastName,
              subscription: subscription,
            }
          })
          : [];
      this.contactTotalCount = data.totalData

      if (this.tempContact.length === 0) {
        this.tempContact = this.contactArray.map(value => {
          return {
            value: value.email,
            label: value.name
          }
        });
        this.combinedList = [...this.tempCustomer, ...this.tempContact];
      }
      this.contactLoading = false;

    } else {
      this.contactLoading = false;

    }
  }

  sendEmail = async (value) => {

    this.isLoading = true;
    const { data, error } = await sendEmail(value)

    if (data) {
      commonStore.showSuccessMessage("Email has been sent!");
      setTimeout(() => {
        window.location.reload();
      }, 2000)
    } else {
      if (error.data) {
        this.isLoading = false;

        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  sendReplyOrForward = async (value, history) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const { data, error } = await sendEmail(value)

    if (data) {
      commonStore.showSuccessMessage("Email has been sent!");
      history.push("/businesses/" + businessId + "/email");

    } else {
      if (error.data) {
        this.isLoading = false;

        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  getAllEmail = async () => {
    this.token = ""

    this.isLoading = true;
    const { data, error } = await getAllEmail(this.token)
    this.isLoading = false;

    if (data) {
      // commonStore.showSuccessMessage("Email has been sent!");
      let tempData = data.emailData.map(data => {
        let fromTemp = data.payload.headers.find(data => data.name === "From");
        let fromTempFinal = fromTemp.value.includes("<") ? fromTemp.value.substring(
          fromTemp.value.lastIndexOf("<") + 1,
          fromTemp.value.lastIndexOf(">")
        ) : fromTemp.value
        let subjectTemp = data.payload.headers.find(data => data.name === "Subject");
        let dateTemp = data.payload.headers.find(data => data.name === "Date");
        let toTemp = data.payload.headers.find(data => data.name === "To")
        let bodyTemp = ""
        if (data.payload.parts) {
          if (data.payload.parts[1]) {
            bodyTemp = data.payload.parts[1].body ? data.payload.parts[1].body.data : "";
          } else {
            bodyTemp = data.payload.parts[0].body ? data.payload.parts[0].body.data : "";
          }
        } else {
          bodyTemp = data.payload && data.payload.body ? data.payload.body.data : ""
        }

        return {
          from: fromTempFinal,
          subject: subjectTemp.value,
          received: dateTemp.value,
          to: toTemp.value,
          message: (bodyTemp)
        }
      });

      this.emailArray.push(...tempData);
      this.token = data.nextPageToken;
      return { data: tempData };

    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  getAllSent = async () => {

    this.token = ""

    this.isLoading = true;
    const { data, error } = await getAllSent(this.token)
    this.isLoading = false;

    if (data) {
      // commonStore.showSuccessMessage("Email has been sent!");
      let tempData = data.emailData.map(data => {
        let fromTemp = data.payload.headers.find(data => data.name === "From");
        let fromTempFinal = fromTemp.value.includes("<") ? fromTemp.value.substring(
          fromTemp.value.lastIndexOf("<") + 1,
          fromTemp.value.lastIndexOf(">")
        ) : fromTemp.value
        let subjectTemp = data.payload.headers.find(data => data.name === "Subject");
        let dateTemp = data.payload.headers.find(data => data.name === "Date");
        let toTemp = data.payload.headers.find(data => data.name === "To")
        let bodyTemp = ""
        if (data.payload.parts) {
          if (data.payload.parts[1]) {
            bodyTemp = data.payload.parts[1].body ? data.payload.parts[1].body.data : "";
          } else {
            bodyTemp = data.payload.parts[0].body ? data.payload.parts[0].body.data : "";
          }
        } else {
          bodyTemp = data.payload && data.payload.body ? data.payload.body.data : ""
        }

        return {
          from: fromTempFinal,
          subject: subjectTemp.value ? subjectTemp.value : "",
          received: dateTemp.value,
          to: toTemp.value,
          message: (bodyTemp)
        }
      });

      this.emailArray.push(...tempData);
      this.token = data.nextPageToken;

      return { data: tempData };

    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  checkConnectGoogle = async () => {
    this.isLoading = true;
    const { data } = await checkConnectGoogle();
    this.isLoading = false;

    if (data) {
      this.setEditedData({
        loggedToGoogle: data
      })
    }
  }

  uploadFile = async file => {
    const formData = new FormData();

    formData.append("file", file);

    const { data, error } = await uploadFile(formData);

    if (data) {
      return { data };
    } else {
      return { error };
    }
  };

  createTriggeredEmail = async values => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.triggeredEmailLoading = true;

    const { data, error } = await createTriggeredEmail(businessId, values);

    this.triggeredEmailLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Email Saved!");
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  getReminderSettings = async () => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.reminderSettingsLoading = true;
    const { data, error } = await getReminderSettings(businessId);
    this.reminderSettingsLoading = false;

    if (data) {
      this.reminderSettingsArray = data.map(value => {

        return {
          activeReminder: value.reminderName ? value.reminderName : "",
          message: value.message ? value.message : "",
          id: value.id ? value.id : "",
          status: value.status ? value.status : ""
        }
      });

      this.tempSettingArray = [...this.reminderSettingsArray]
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  setReminderStatus = async (id, value) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    const { data, error } = await setReminderStatus(businessId, id, value);
    if (data) {
      return { data }
    } else {
      return { error }
    }
  }

  getReminderList = async () => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.reminderLoading = true;
    const { data, error } = await getReminderList(businessId, this.reminderCurrentPage + 1, 10000);
    this.reminderLoading = false;

    if (data) {
      this.reminderArray = data.populatedData.map(value => {
        return {
          id: value._id ? value._id : "",
          activeReminder: value && value.reminderName ? value.reminderName : '',
          message: value && value.reminderMessage ? value.reminderMessage : '',
          delete: "",
          read: value.read ? value.read : false,
        }
      })

      this.reminderTotalCount = data.totalData;

    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }


  getTriggeredEmail = async () => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.triggeredEmailLoading = true;
    const { data, error } = await getTriggeredEmail(
      businessId,
      this.triggeredEmailSearch,
      this.triggeredEmailStartDate === null ? "" : this.triggeredEmailStartDate,
      this.triggeredEmailEndDate === null ? "" : this.triggeredEmailEndDate,
      this.triggeredEmailCurrentPage + 1,
      this.triggeredEmailPageSize
    );
    this.triggeredEmailLoading = false;

    if (data) {
      this.triggeredEmailArray = data.triggeredFormData.map(item => {
        return {
          id: item._id,
          subject: item.subject,
          recipient: item.recipient ? item.recipient.join(", ") : "",
          recipientArray: item.recipient ? item.recipient : [],
          cc: item.cc ? item.cc : [],
          bcc: item.bcc ? item.bcc : [],
          sendDate: item.sendDate ? moment(item.sendDate).format("MM/DD/YYYY hh:mm A") : "",
          status: item.sent ? item.sent : false,
          uniqueOpen: item.uniqueOpen ? item.uniqueOpen : 0,
          openedMails: item.openedMails ? item.openedMails : [],
          delete: item._id
        }
      });
      this.triggeredEmailTotalCount = data.totalData
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  getSingleTriggeredEmail = async emailId => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.triggeredEmailLoading = true;
    const { data, error } = await getSingleTriggeredEmail(businessId, emailId);
    this.triggeredEmailLoading = false;

    if (data) {
      //Search for the Recipient
      const recipient = data.recipient ? data.recipient : []
      let tempCustomerSelected = [];
      let tempContactSelected = [];
      const tempCustomer = this.customerArray.map(data => {
        return data.email
      })

      const tempContact = this.contactArray.map(data => {
        return data.email
      })

      for (let i = 0; i < recipient.length; i++) {
        if (tempCustomer.includes(recipient[i])) {
          tempCustomerSelected.push(tempCustomer.indexOf(recipient[i]))
        }
        if (tempContact.includes(recipient[i])) {
          tempContactSelected.push(tempContact.indexOf(recipient[i]))
        }
      }
      //-----------------------------

      //Search for the CC
      const cc = data.cc ? data.cc : [];

      let tempCC = this.combinedList.filter(item => {
        return cc.includes(item.value);
      })
      //-----------------------------

      //Search for the BCC
      const bcc = data.bcc ? data.bcc : [];

      let tempBCC = this.combinedList.filter(item => {
        return bcc.includes(item.value);
      })
      //-----------------------------

      this.setEditedData({
        id: data._id,
        subject: data.subject,
        cc: tempCC,
        bcc: tempBCC,
        message: data.content,
        to: data.recipient ? data.recipient : [],
        schedule: data.sendDate ? moment(data.sendDate).format("MM/DD/YYYY HH:mm:ss") : "",
        time: new Date(data.sendDate),
        logo: data.logo ? data.logo : false,
        customerRecipientIndex: tempCustomerSelected,
        contactRecipientIndex: tempContactSelected,
      })
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  updateTriggeredEmail = async (values, emailId) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    const { data, error } = await updateTriggeredEmail(businessId, values, emailId);

    if (data) {
      commonStore.showSuccessMessage("Email Saved!");
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  deleteTriggeredEmail = async (id, history) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.triggeredEmailLoading = true;
    const { data, error } = await deleteTriggeredEmail(businessId, id);
    this.triggeredEmailLoading = false;

    if (!data) {
      commonStore.showSuccessMessage("Email Cancelled!");
      history.push("/businesses/" + businessId + "/scheduled");
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  deleteTriggeredEmailOuter = async (id, history) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.triggeredEmailLoading = true;
    const { data, error } = await deleteTriggeredEmail(businessId, id);
    this.triggeredEmailLoading = false;

    if (!data) {
      commonStore.showSuccessMessage("Email Cancelled!");
      deleteStores.hideWarning();
      this.getTriggeredEmail()
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  getTemplate = async () => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.templateLoading = true;
    const { data, error } = await getTemplate(businessId, this.templateSearch);
    this.templateLoading = false;

    if (data) {
      this.templateArray = data.templateEmailData.map(item => {
        return {
          id: item._id ? item._id : "",
          subject: item.subject ? item.subject : "",
          delete: item._id ? item._id : ""
        }
      })
      let tempOption = data.templateEmailData.map(item => {
        return {
          name: item.subject ? item.subject : "",
          value: { content: item.content ? item.content : "", subject: item.subject ? item.subject : "" },
        }
      })
      this.templateOption = [{ name: "Select from template", value: "", disabled: true }, ...tempOption]

    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  getSingleTemplate = async id => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.templateLoading = true;
    const { data, error } = await getSingleTemplate(businessId, id);
    this.templateLoading = false;

    if (data) {
      this.setEditedData({
        subject: data.subject,
        message: data.content
      })

    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  createTemplate = async (values) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.triggeredEmailLoading = true;
    const { data, error } = await createTemplate(businessId, values);
    this.triggeredEmailLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Template Created!");
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  updateTemplate = async (values, templateId) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.templateLoading = true;
    const { data, error } = await updateTemplate(businessId, values, templateId);
    this.templateLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Template Saved!");
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  deleteTemplate = async (id) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.templateLoading = true;
    const { data, error } = await deleteTemplate(businessId, id);
    this.templateLoading = false;

    if (!data) {
      commonStore.showSuccessMessage("Template Deleted!");
      this.getTemplate();
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  bulkdeleteTemplate = async value => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.templateLoading = true;
    const { data, error } = await bulkdeleteTemplate(businessId, value);
    this.templateLoading = false;

    if (!data) {
      commonStore.showSuccessMessage("Template Deleted!");
      this.getTemplate();
      this.setEditedData({ templateSelected: [] })
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  getCustomerByRange = async (startDate, endDate) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.customerLoading = true;

    const { data } = await getCustomerByRange(
      businessId,
      startDate,
      endDate
    )

    this.customerLoading = false;

    if (data) {

      this.customerArray =
        data ?
          data.map(customer => {
            const firstName =
              customer &&
                customer.businessCustomer &&
                customer.businessCustomer.firstName ? customer.businessCustomer.firstName : "";
            const lastName =
              customer &&
                customer.businessCustomer &&
                customer.businessCustomer.lastName ? customer.businessCustomer.lastName : "";
            const email =
              customer &&
                customer.customer && customer.customer.email ? customer.customer.email : "";
            const subscription = customer.customer ? customer.customer.subscription : false;

            return {
              name: `${firstName} ${lastName}`,
              email: email,
              firstName: firstName,
              lastName: lastName,
              subscription: subscription,
            }
          })
          : [];

      this.customerTotalCount = data.length
      this.combinedArray = this.tempCombinedArray.filter(item => {
        return !this.editedData.contactForTemp.includes(item.email)
      })
    } else {

    }
  }

  // Subscribe = async (value) => {
  //   this.triggeredEmailLoading = true;
  //   const { data } = await Subscribe(value);
  //   this.triggeredEmailLoading = false;

  //   if (data) {

  //     return { data }
  //   } else {
  //     commonStore.showErrorMessage("Error when subscribing")
  //   }
  // }

  unsubscribe = async (values) => {

    this.triggeredEmailLoading = true;
    const { data, error } = await Unsubscribe(values);
    this.triggeredEmailLoading = false;

    if (data) {
      return { data }
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  deleteReminder = async (id) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.reminderLoading = true;
    const { data, error } = await deleteReminder(businessId, id);
    this.reminderLoading = false;

    if (!data) {
      commonStore.showSuccessMessage("Reminder Deleted!")
      this.getReminderList();
      return { data }
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  bulkDeleteReminder = async value => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.reminderLoading = true;
    const { data, error } = await bulkDeleteReminder(businessId, value);
    this.reminderLoading = false;

    if (!data) {
      commonStore.showSuccessMessage("Reminder Deleted!");
      this.getReminderList();
      this.setEditedData({ reminderSelected: [] })
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  readReminder = async (id) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.reminderLoading = true;
    const { data, error } = await readReminder(businessId, id);
    this.reminderLoading = false;

    if (data) {
      this.getReminderList();
      return { data }
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }
}
decorate(CommunicationStore, {
  editedData: observable,
  isLoading: observable,
  emailArray: observable,
  token: observable,
  errorMessage: observable,

  //Analytics
  analyticsArray: observable,

  //Triggered Email
  triggeredEmailArray: observable,
  triggeredEmailPageSize: observable,
  triggeredEmailCurrentPage: observable,
  triggeredEmailTotalCount: observable,
  triggeredEmailLoading: observable,
  triggeredEmailSearch: observable,
  customerRecipientIndex: observable,
  contactRecipientIndex: observable,
  triggeredEmailStartDate: observable,
  triggeredEmailEndDate: observable,

  //Template
  templateArray: observable,
  templateLoading: observable,
  templateSearch: observable,

  //Composed Email
  combinedList: observable,
  templateOption: observable,
  tempContact: observable,
  tempCustomer: observable,

  //Customer
  customerPageSize: observable,
  customerCurrentPage: observable,
  customerSearch: observable,
  customerArray: observable,
  customerTotalCount: observable,
  customerLoading: observable,
  tempCustomerArray: observable,

  //Contact
  contactPageSize: observable,
  contactCurrentPage: observable,
  contactSearch: observable,
  contactArray: observable,
  contactTotalCount: observable,
  contactLoading: observable,
  tempContactArray: observable,

  //Reminder
  reminderPageSize: observable,
  reminderCurrentPage: observable,
  reminderArray: observable,
  reminderTotalCount: observable,
  reminderLoading: observable,

  //Reminder List
  reminderSettingsPageSize: observable,
  reminderSettingsCurrentPage: observable,
  reminderSettingsArray: observable,
  reminderSettingsTotalCount: observable,
  reminderSettingsLoading: observable,
});

export default new CommunicationStore();