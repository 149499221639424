import axios from "../helper/Axios.js";
import { adminUrl, superUrl } from "../config/config.js";

export async function signIn({ email, password }) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/auth/login`,
      data: { email, password }
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function register({ firstName, lastName, email, password }) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/auth/register`,
      data: { firstName, lastName, email, password }
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function forgotPassword(email) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/auth/forgotpassword`,
      data: email
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function resetPassword(password, token) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/auth/resetpassword`,
      data: { password, token }
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function verifyEmail(email) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/auth/resendactivationemail?email=${email}`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function signOut(value) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/auth/logout`,
      data: value
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

//-----------------------------------------------------------Super Admin------------------------------------------------------------

export async function adminLogin({ email, password }) {
  try {
    let response = await axios.Post({
      url: `${superUrl}/login`,
      data: { email, password }
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}
