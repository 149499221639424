import React, { Component } from 'react';
import { Row, Col } from "reactstrap";
// import { inject, observer } from "mobx-react";
import Text from "../../../components/Texts";

class PostSubscribe extends Component {
  // constructor(props) {
  //   super(props)

  //   this.state = {
  //     isShow: false
  //   }
  // }

  // componentDidMount = async () => {
  //   const { communicationStore } = this.props;

  //   const params = new URLSearchParams(this.props.location.search);

  //   const tags = params.get("email");

  //   const { data } = await communicationStore.Subscribe(tags);
  //   if (data) {
  //     this.setState({ isShow: true })
  //   }
  // }

  render() {
    return (
      <div style={{ height: "100vh", minHeight: "100vh", display: "flex", alignItems: "center", justifyContent: "center" }}>
        {/* {this.state.isShow && */}
        <Row>
          <Col lg="12" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Text
              text="Thank you for subscribing!"
              textType="h2"
              weight="medium"
              style={{ color: "#005BB9", fontWeight: 'bold' }}
            />
          </Col>
        </Row>
        {/* } */}
      </div>
    )
  }
}

// export default inject('communicationStore')(observer(PostSubscribe))
export default PostSubscribe;
