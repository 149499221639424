import { action, decorate, observable } from "mobx";

class CommonStore {
  snackbarVariant = "success";
  snackbarMessage = "";
  snackbarShouldOpen = false;
  regionLoading = false;
  verificationDialogShouldOpen = false;

  showAppSnackbar = ({ variant, message }) => {
    this.snackbarMessage = message;
    this.snackbarVariant = variant;
    this.snackbarShouldOpen = true;
  };

  closeAppSnackbar = () => {
    this.snackbarShouldOpen = false;
  };

  showSuccessMessage = message => {
    this.showAppSnackbar({
      variant: "success",
      message
    });
  };

  showErrorMessage = message => {
    if (message && typeof message === "string") {
      this.showAppSnackbar({
        variant: "error",
        message
      });
    }
  };

  showVerificationDialog = () => {
    this.verificationDialogShouldOpen = true;
  };

  closeVerificationDialog = () => {
    this.verificationDialogShouldOpen = false;
  };
}

decorate(CommonStore, {
  regionLoading: observable,
  verificationDialogShouldOpen: observable,
  snackbarVariant: observable,
  snackbarMessage: observable,
  snackbarShouldOpen: observable,

  showSuccessMessage: action,
  showErrorMessage: action,
  showAppSnackbar: action,
  closeAppSnackbar: action,
  showVerificationDialog: action,
  closeVerificationDialog: action
});

export default new CommonStore();
