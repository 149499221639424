import React from "react";
import {
  getAllBooking,
  getBookingById,
  postBooking,
  putBooking,
  deleteBooking,
  getBookingByHuntSession,
  getAllBookingForAdmin,
  getBookingByIdForAdmin,
  putBookingForAdmin,
  addSpecies,
  addPartyMember,
  editPartyMember,
  addDeposit,
  updateDeposit,
  createEvent,
  updateEvent,
  deleteEvent,
} from "../services/BookingService";
import tabStore from "./TabStore";
// eslint-disable-next-line no-unused-vars
import { observable, action, decorate, toJS } from "mobx";
import momentTimezone from "moment-timezone";
import modalStore from "./BookingConfirmationModalStore";
import moment from "moment";
import Button from "../components/Buttons";
import _ from "lodash";
import { validateEditedData } from "../helper/functions";
import CommonStore from "./CommonStore";
import { reactLocalStorage } from "reactjs-localstorage";
import DeleteIcon from "@material-ui/icons/Delete";

const Joi = require("joi-browser");
Joi.objectId = require("joi-objectid")(Joi);

class BookingStore {
  isLoading = false;
  editedData = {
    huntType: "",
    status: "",
    isUpdatePaymentModalOpen: false,
    updatePaymentModalData: {},
    partyArray: [],
    partyOpt: [],
    currLeader: "",
    subTotalFee: 0,
    additionalFee: "",
    tax: 0,
    hunterFee: 0,
    fishermanFee: 0,
    observerFee: 0,
    wildlifeDonation: 0,
    juniorHUnterFee: 0,
    isWildlifeDonationTaxed: false,
    isObserverFeeTaxed: false,
    isFishermanFeeTaxed: false,
    isHunterFeeTaxed: false,
    isJuniorFeeTaxed: false,
    isAdditionalFeeTaxed: false,
    isBasePriceTaxed: false,
    total: 0,
    observers: [],
  };
  bookingArray = [];
  calendarArray = [];
  speciesArray = [];
  wyomingArray = [];
  updatePaymentLoading = false;
  currentPage = 0;
  pageSize = 50;
  statusBook = "";
  calendarLoading = false;
  huntTypeEnum = [];
  employeeEnum = [];
  searchValue = "";
  totalCount = 0;
  deleteLoading = false;
  valueTransformer = {
    status: (data) => {
      let style = {
        padding: "5px",
        color: "white",
        borderRadius: "5px",
        width: "100px",
        textAlign: "center",
      };
      if (data.status === "RESERVATION")
        style = { ...style, background: "#1665D8" };
      else if (data.status === "COMPLETED")
        style = { ...style, background: "#DD3719" };
      else if (data.status === "BOOKED")
        style = { ...style, background: "#2EAD40" };
      else if (data.status === "DEPOSIT")
        style = { ...style, background: "#1E90FF" };
      return <div style={style}>{data.status}</div>;
    },
    startTime: (data) => {
      return moment(new Date(_.get(data, "startTime", ""))).format(
        "MMM D, YYYY"
      );
    },
    endTime: (data) => {
      return moment(new Date(_.get(data, "endTime", ""))).format("MMM D, YYYY");
    },
    speciesTaken: (data) => {
      return typeof data.gameHuntedSpecies === "string"
        ? data.gameHuntedSpecies
        : data.gameHuntedSpecies.join(", ");
    },
  };

  summaryValueTransformer = {
    id: (data) => {
      if (data.id) {
        return (
          <div className="d-flex justify-content-center">
            <DeleteIcon
              onClick={() => {
                let totalDeposit = 0;
                let totalFee = 0;
                let leader = this.editedData.currLeader;
                let tempData = data;
                let tempArray = this.editedData.partyArray;
                let tempObserver = this.editedData.observers;
                let filteredData = [];
                let totalBaseObserver = 0;
                let totalBaseFee = 0;
                let totalDonation = 0;
                let totalBaseDonation = 0;
                let totalTypeFee = 0;
                let totalTypeFeeTaxed = 0;
                let totalObs = 0;
                let totalTax = this.editedData.basePriceRawTax;

                if (data.customerType !== "observer") {
                  if (tempArray.length !== 0) {
                    if (data.leader === true) {
                      if (tempArray.length !== 1) {
                        tempArray[1].leader = true;

                        leader = data.id;
                      } else {
                        leader = "";
                      }
                    }
                  }

                  if (tempData.leader === true) {
                    tempData.leader = false;
                  }

                  const tempOpt = [tempData, ...this.editedData.partyOpt];
                  filteredData = this.editedData.partyArray.filter((item) => {
                    return item.id !== data.id;
                  });

                  // if (filteredData.length !== 1) {
                  //   if (data.leader === true) {
                  //     if (filteredData.length !== 0) {
                  //       filteredData[0].leader = true;
                  //       leader = data.id;
                  //     } else {
                  //       leader = "";
                  //     }
                  //   }
                  // }

                  //Type Fee
                  for (let i = 0; i < filteredData.length; i++) {
                    totalTypeFee = totalTypeFee + filteredData[i].typeFee;
                    totalTypeFeeTaxed = totalTypeFeeTaxed + filteredData[i].typeFeeTaxed
                  }
                  //--------

                  for (let i = 0; i < filteredData.length; i++) {
                    totalBaseFee = totalBaseFee + filteredData[i].baseFeeWithType;
                  }

                  //Donation
                  for (let i = 0; i < filteredData.length; i++) {
                    totalBaseDonation = totalBaseDonation + filteredData[i].baseDonation;
                  }

                  for (let i = 0; i < tempObserver.length; i++) {
                    totalBaseDonation = totalBaseDonation + tempObserver[i].baseDonation;
                  }

                  for (let i = 0; i < filteredData.length; i++) {
                    totalDonation = totalDonation + filteredData[i].donation;
                  }

                  for (let i = 0; i < tempObserver.length; i++) {
                    totalDonation = totalDonation + tempObserver[i].donation;
                  }
                  //-------------

                  for (let i = 0; i < filteredData.length; i++) {
                    totalFee = totalFee + filteredData[i].fee;
                    totalTax = totalTax + filteredData[i].tax
                  }
                  for (let i = 0; i < tempObserver.length; i++) {
                    totalFee = totalFee + tempObserver[i].fee;
                    totalObs = totalObs + tempObserver[i].fee;
                    totalTax = totalTax + tempObserver[i].tax
                  }

                  for (let i = 0; i < this.editedData.deposit.length; i++) {
                    totalDeposit =
                      totalDeposit + Number(this.editedData.deposit[i].amount);
                  }

                  const total = Number(this.editedData.additionalFee) + this.editedData.totalPrice + totalDonation + totalTypeFeeTaxed + totalObs;
                  const remainingBalance = total - totalDeposit;

                  this.setEditedData({
                    partyOpt: tempOpt,
                    partyArray: filteredData,
                    subTotalFee: totalFee,
                    currLeader: leader,
                    total: total,
                    totalDeposit: remainingBalance,
                    serviceFee: totalBaseFee,
                    totalDonation,
                    totalBaseDonation,
                    totalTypeFee,
                    totalTypeFeeTaxed,
                    totalTax,
                    baseTotal: this.editedData.basePrice + Number(this.editedData.additionalFee) + totalBaseDonation + this.editedData.totalBaseObserver + totalTypeFee,
                  });
                } else {
                  let tempOpt = [];
                  if (data.id !== "000000000000000000000000") {
                    tempOpt = [
                      tempData,
                      ...this.editedData.partyOpt,
                    ];
                  } else {
                    tempOpt = [
                      ...this.editedData.partyOpt,
                    ];
                  }

                  filteredData = this.editedData.observers.filter((item) => {
                    return item.name !== data.name;
                  });

                  for (let i = 0; i < filteredData.length; i++) {
                    totalBaseObserver = totalBaseObserver + filteredData[i].baseFee;
                  }

                  //Donations
                  for (let i = 0; i < filteredData.length; i++) {
                    totalBaseDonation = totalBaseDonation + filteredData[i].baseDonation;
                  }

                  for (let i = 0; i < tempArray.length; i++) {
                    totalBaseDonation = totalBaseDonation + tempArray[i].baseDonation;
                  }

                  for (let i = 0; i < filteredData.length; i++) {
                    totalDonation = totalDonation + filteredData[i].donation;
                  }

                  for (let i = 0; i < tempArray.length; i++) {
                    totalDonation = totalDonation + tempArray[i].donation;
                  }
                  //-----------
                  for (let i = 0; i < filteredData.length; i++) {
                    totalFee = totalFee + filteredData[i].fee;
                    totalObs = totalObs + filteredData[i].fee;
                    totalTax = totalTax + filteredData[i].tax;
                  }
                  for (let i = 0; i < tempArray.length; i++) {
                    totalFee = totalFee + tempArray[i].fee;
                    totalTax = totalTax + tempArray[i].tax;
                  }

                  for (let i = 0; i < this.editedData.deposit.length; i++) {
                    totalDeposit =
                      totalDeposit + Number(this.editedData.deposit[i].amount);
                  }

                  const total =
                    this.editedData.totalPrice +
                    Number(this.editedData.additionalFee) +
                    totalDonation + this.editedData.totalTypeFeeTaxed + totalObs;
                  const remainingBalance = total - totalDeposit;

                  this.setEditedData({
                    partyOpt: tempOpt,
                    observers: filteredData,
                    subTotalFee: totalFee,
                    total: total,
                    totalDeposit: remainingBalance,
                    totalBaseObserver,
                    totalBaseDonation,
                    totalDonation,
                    totalTax,
                    baseTotal: this.editedData.basePrice + Number(this.editedData.additionalFee) + totalBaseDonation + totalBaseObserver + this.editedData.totalTypeFee,
                  });
                }
              }}
            />
          </div>
        );
      }
    },
    leader: (data) => {
      if (data.customerType !== "observer") {
        return (
          <Button
            buttonType={"primary"}
            label={"Set as Party Leader"}
            type="button"
            onClick={async () => {
              for (let i = 0; i < this.editedData.partyArray.length; i++) {
                if (this.editedData.partyArray[i].id === data.id) {
                  this.editedData.partyArray[i].leader = true;
                } else if (this.editedData.partyArray[i].id !== data.id) {
                  this.editedData.partyArray[i].leader = false;
                }
              }
              this.setEditedData({ currLeader: data.id });
            }}
          />
        );
      }
    },
    name: (data) => {
      if (data.customerType === "hunter") {
        return (
          <>
            <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
              {data.leader && <span style={{ fontWeight: "bold" }}>{`${data.name} (Leader)`}</span>}
              {!data.leader && <span>{`${data.name}`}</span>}
            </div>
            {this.editedData.hunterBaseFee !== 0 && this.editedData.hunterBaseFee !== "0" &&
              <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                {!!data.leader && <span style={{ fontWeight: "bold" }}>{`${data.name} (Leader)`}</span>}
                {!data.leader && <span>{`${data.name}`}</span>}
              </div>
            }
            {!!data.junior && this.editedData.isJuniorHunter &&
              <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                {!!data.leader && <span style={{ fontWeight: "bold" }}>{`${data.name} (Leader)`}</span>}
                {!data.leader && <span>{`${data.name}`}</span>}
              </div>
            }
          </>
        )
      } else if (data.customerType === "fisherman") {
        return (
          <>
            <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
              {!!data.leader && <span style={{ fontWeight: "bold" }}>{`${data.name} (Leader)`}</span>}
              {!data.leader && <span>{`${data.name}`}</span>}
            </div>
            {this.editedData.fishermanBaseFee !== 0 && this.editedData.fishermanBaseFee !== "0" &&
              <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                {!!data.leader && <span style={{ fontWeight: "bold" }}>{`${data.name} (Leader)`}</span>}
                {!data.leader && <span>{`${data.name}`}</span>}
              </div>
            }
            {!!data.junior && this.editedData.isJuniorHunter &&
              <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                {!!data.leader && <span style={{ fontWeight: "bold" }}>{`${data.name} (Leader)`}</span>}
                {!data.leader && <span>{`${data.name}`}</span>}
              </div>
            }
          </>
        )
      } else if (data.customerType === "observer") {
        return (
          <>
            <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
              {!!data.leader && <span style={{ fontWeight: "bold" }}>{`${data.name} (Leader)`}</span>}
              {!data.leader && <span>{`${data.name}`}</span>}
            </div>
          </>
        )
      } else {
        return (
          <>
            <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
              {!!data.leader && <span style={{ fontWeight: "bold" }}>{`${data.name} (Leader)`}</span>}
              {!data.leader && <span>{`${data.name}`}</span>}
            </div>
            {!!data.junior && this.editedData.isJuniorHunter &&
              <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                {!!data.leader && <span style={{ fontWeight: "bold" }}>{`${data.name} (Leader)`}</span>}
                {!data.leader && <span>{`${data.name}`}</span>}
              </div>
            }
          </>
        )
      }
      // return data.name;
    },
    fee: (data) => {
      return (
        <>
          <span>{Number(data.fee).toFixed(2)} </span>
          <span style={{ color: "#979797", fontStyle: "italic" }}>
            {data.isTaxed && "Tax Included"}
          </span>
        </>
      );
    },
    baseFeeWithType: (data) => {
      const options2 = { style: 'currency', currency: 'USD' };
      const numberFormat2 = new Intl.NumberFormat('en-US', options2);

      if (data.customerType === "hunter") {
        return (
          <>
            <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
              <span>{numberFormat2.format(Number(this.editedData.price).toFixed(2))}</span>
            </div>
            {this.editedData.hunterBaseFee !== 0 && this.editedData.hunterBaseFee !== "0" &&
              <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                <span>{numberFormat2.format(Number(this.editedData.hunterBaseFee).toFixed(2))}</span>
              </div>
            }
            {!!data.junior && this.editedData.isJuniorHunter &&
              <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                <span>{numberFormat2.format(Number(this.editedData.baseJuniorFee).toFixed(2))}</span>
              </div>
            }
          </>
        )
      } else if (data.customerType === "fisherman") {
        return (
          <>
            <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
              <span>{numberFormat2.format(Number(this.editedData.price).toFixed(2))}</span>
            </div>
            {this.editedData.fishermanBaseFee !== 0 && this.editedData.fishermanBaseFee !== "0" &&
              <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                <span>{numberFormat2.format(Number(this.editedData.fishermanBaseFee).toFixed(2))}</span>
              </div>
            }
            {!!data.junior && this.editedData.isJuniorHunter &&
              <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                <span>{numberFormat2.format(Number(this.editedData.baseJuniorFee).toFixed(2))}</span>
              </div>
            }
          </>
        )
      } else if (data.customerType === "observer") {
        return (
          <>
            <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
              <span>{numberFormat2.format(Number(this.editedData.observerBaseFee).toFixed(2))}</span>
            </div>
          </>
        )
      } else {
        return (
          <>
            <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
              <span>{numberFormat2.format(Number(this.editedData.price).toFixed(2))}</span>
            </div>
            {!!data.junior && this.editedData.isJuniorHunter &&
              <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                <span>{numberFormat2.format(Number(this.editedData.baseJuniorFee).toFixed(2))}</span>
              </div>
            }
          </>
        )
      }
    },
    customerType: (data) => {
      // let type = "";

      //Get the Type
      // if (data.customerType === "hunter") {
      //   if (this.editedData.isJuniorHunter) {
      //     if (data.junior) {
      //       type = "Hunter, Youth Hunter";
      //     } else {
      //       type = "Hunter";
      //     }
      //   } else {
      //     type = "Hunter";
      //   }
      // } else if (data.customerType === "fisherman") {
      //   if (this.editedData.isJuniorHunter) {
      //     if (data.junior) {
      //       type = "Fisherman, Youth Hunter";
      //     } else {
      //       type = "Fisherman";
      //     }
      //   } else {
      //     type = "Fisherman";
      //   }
      // } else if (data.customerType === "both") {
      //   if (this.editedData.isJuniorHunter) {
      //     if (data.junior) {
      //       type = "Hunter and Fisherman, Youth Hunter";
      //     } else {
      //       type = "Hunter and Fisherman";
      //     }
      //   } else {
      //     type = "Hunter and Fisherman";
      //   }
      // } else if (data.customerType === "observer") {
      //   type = "Observer";
      // } else {
      //   if (this.editedData.isJuniorHunter) {
      //     if (data.junior) {
      //       type = "Youth Hunter";
      //     } else {
      //       type = "-";
      //     }
      //   } else {
      //     type = "-";
      //   }
      // }
      //-------------------------------------------------

      //Render the Type
      if (data.customerType === "hunter") {
        return (
          <>
            <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
              <span>Service Price</span>
            </div>
            {this.editedData.hunterBaseFee !== 0 && this.editedData.hunterBaseFee !== "0" &&
              <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                <span>Hunter</span>
              </div>
            }
            {!!data.junior && this.editedData.isJuniorHunter &&
              <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                <span>Junior Hunter</span>
              </div>
            }
          </>
        )
      } else if (data.customerType === "fisherman") {
        return (
          <>
            <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
              <span>Service Price</span>
            </div>
            {this.editedData.fishermanBaseFee !== 0 && this.editedData.fishermanBaseFee !== "0" &&
              <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                <span>Fisherman</span>
              </div>
            }
            {!!data.junior && this.editedData.isJuniorHunter &&
              <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                <span>Junior Hunter</span>
              </div>
            }
          </>
        )
      } else if (data.customerType === "observer") {
        return (
          <>
            <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
              <span>Observer</span>
            </div>
          </>
        )
      } else {
        return (
          <>
            <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
              <span>Service Price</span>
            </div>
            {!!data.junior && this.editedData.isJuniorHunter &&
              <div style={{ paddingTop: "30px", paddingBottom: "30px" }}>
                <span>Junior Hunter</span>
              </div>
            }
          </>
        )
      }
    },
  };

  statusEnum = [
    {
      name: "All",
      value: "",
    },
    {
      name: "RESERVATION",
      value: "RESERVATION",
    },
    {
      name: "BOOKED",
      value: "BOOKED",
    },
    {
      name: "DEPOSIT",
      value: "DEPOSIT",
    },
    {
      name: "COMPLETED",
      value: "COMPLETED",
    },
  ];

  columns = [
    {
      name: "status",
      title: "Status",
      valueTransformer: this.valueTransformer["status"],
    },
    { name: "customer", title: "Customer" },
    { name: "huntType", title: "Service Type" },
    { name: "hunt", title: "Service" },
    { name: "maxCapacity", title: "Service Capacity" },
    { name: "remaining", title: "Service Capacity Remaining" },
    {
      name: "startTime",
      title: "Start Date",
      valueTransformer: this.valueTransformer["startTime"],
    },
    {
      name: "endTime",
      title: "End Date",
      valueTransformer: this.valueTransformer["endTime"],
    },
    // { name: "bookingID", title: "Booking ID" }
  ];
  columnWidths = [
    { columnName: "status", width: 150 },
    { columnName: "customer", width: 150 },
    { columnName: "huntType", width: 150 },
    { columnName: "hunt", width: 150 },
    { columnName: "maxCapacity", width: 150 },
    { columnName: "remaining", width: 200 },
    { columnName: "startTime", width: 150 },
    { columnName: "endTime", width: 150 },
    // { columnName: "bookingID", width: 200 }
  ];

  // speciesColumns = [
  //   {
  //     name: "species",
  //     title: "Species"
  //   },
  //   { name: "amount", title: "Amount" },
  //   { name: "date", title: "Date Taken" },
  //   {
  //     name: "antlerPointRight",
  //     title: "Antler Point (Right)"
  //   },
  //   {
  //     name: "antlerPointLeft",
  //     title: "Antler Point (Left)"
  //   }
  // ];
  // speciesColumnWidths = [
  //   { columnName: "species", width: 200 },
  //   { columnName: "amount", width: 200 },
  //   { columnName: "date", width: 200 },
  //   { columnName: "antlerPointRight", width: 200 },
  //   { columnName: "antlerPointLeft", width: 200 }
  // ];

  wyomingColumns = [
    {
      name: "gameHuntedSpecies",
      title: "Game Hunted Species",
      valueTransformer: this.valueTransformer["speciesTaken"],
    },
    { name: "gameHuntedArea", title: "Game Hunted Species Area" },
    { name: "date", title: "Kill Date" },
    {
      name: "killArea",
      title: "Kill Area",
    },
    {
      name: "sex",
      title: "Sex",
    },
  ];
  wyomingColumnWidths = [
    { columnName: "gameHuntedSpecies", width: 200 },
    { columnName: "gameHuntedArea", width: 200 },
    { columnName: "date", width: 200 },
    { columnName: "killArea", width: 200 },
    { columnName: "sex", width: 200 },
  ];

  viewEnum = [
    { name: "Month", value: "month" },
    { name: "Week", value: "week" },
    { name: "Day", value: "day" },
  ];
  tempEnum = [
    {
      name: "Deer",
      value: "deer",
    },
  ];
  allYear = [{ value: "", name: "All Service Year" }];
  year = new Date("2019").getFullYear();
  years = Array.from(new Array(20), (val, index) => index + this.year);
  serviceYear = this.allYear.concat(
    this.years.map((year) => {
      return {
        name: year,
        value: year,
      };
    })
  );

  setEditedData = (editedData) => {
    this.editedData = {
      ...this.editedData,
      ...editedData,
    };
  };

  prepareCreateMode = (calendarSelectedDate) => {
    this.editedData = {
      huntType: "All",
      huntSession: "",
      date: calendarSelectedDate,
    };
  };

  setSearchValue = (value) => {
    this.currentPage = 0;
    this.searchValue = value;
  };

  setCurrentPage = (businessId) => (value) => {
    this.currentPage = value;
    this.getAllBooking(businessId);
  };

  initialCurrentPage = (value) => {
    this.currentPage = 0;
  };

  setHuntTypeEnum = (value) => {
    this.huntTypeEnum = value;
  };

  setStatus = (value) => {
    this.statusBook = value;
  };

  setEmployeeEnum = (value) => {
    this.employeeEnum = value;
  };

  setLoadingCalendar = (value) => {
    this.calendarLoading = value;
  };

  setInitialData = () => {
    this.bookingArray = [];
  };

  filterCurrentPage = () => {
    this.currentPage = 0;
  };

  setIsLoading = (boolStatus) => {
    this.isLoading = boolStatus;
  };

  getAllBooking = async (businessId) => {
    this.isLoading = true;
    const { data, error } = await getAllBooking({
      businessId: businessId,
      currentPage: this.currentPage + 1,
      pageLimit: 1000,
      search: this.searchValue,
      hunt: this.editedData.huntType,
      startDate: this.editedData.firstDay || "",
      endDate: this.editedData.lastDay || "",
      status: this.statusBook,
    });

    if (data) {
      this.bookingArray = data.bookings
        ? data.bookings.map((data) => {
          const _id = _.get(data, "_id", "Empty");
          const bookingID = _.get(data, "_id", "Empty");
          const huntType = _.get(
            data,
            "activitySession.activity.name",
            "Empty"
          );
          const hunt = _.get(data, "activitySession.name", "Empty");
          const maxCapacity = _.get(
            data,
            "activitySession.maxCapacity",
            "Empty"
          );
          const remaining = _.get(data, "activitySession.remaining", "Empty");
          const tempPrice = data.price
            ? "$ " + data.price * data.quantity
            : "";
          const price = tempPrice
            ? tempPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "";
          const status = _.get(data, "status", "Empty");
          const startTime = _.get(data, "activitySession.startTime", "");
          const endTime = _.get(data, "activitySession.endTime", "");
          const customer =
            _.get(data, "businessCustomer.firstName", "") +
            " " +
            _.get(data, "businessCustomer.lastName", "Empty");

          return {
            ...data,
            _id,
            huntType,
            hunt,
            price,
            status,
            startTime,
            endTime,
            customer,
            bookingID,
            maxCapacity,
            remaining,
          };
        })
        : [];

      this.totalCount = data.totalData;
      this.isLoading = false;
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "You don't have any permission to View Booking's data"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getBookingById = async (businessId, bookingId) => {
    // this.speciesArray = [];

    // this.wyomingArray = [];
    const currentState = JSON.parse(reactLocalStorage.get("currentState"));
    const { data, error } = await getBookingById(businessId, bookingId);

    if (data) {
      const price = data.price ? Number(data.price) : 0;
      const quantity = data.quantity ? Number(data.quantity) : 0;
      const totalPrice = price * quantity;
      const isTax =
        data.activitySession && data.activitySession.isSalesTaxActive
          ? data.activitySession.isSalesTaxActive
          : false;
      let tax =
        data._savedBusiness && data._savedBusiness.salesTax
          ? data._savedBusiness.salesTax : data.business && data.business.salesTax
            ? data.business.salesTax : 0;
      const totalPriceTaxed = totalPrice + (totalPrice * tax) / 100;

      let totalDeposit = 0;
      data.deposit &&
        data.deposit.map((data) => {
          return (totalDeposit = totalDeposit + Number(data.amount));
        });
      const partyLeader = data.partyLeader ? data.partyLeader : "";
      const isJuniorHunterFee =
        data.activitySession && data.activitySession.isJuniorHunterFeeActive
          ? data.activitySession.isJuniorHunterFeeActive
          : false;
      const juniorHunterFee =
        isJuniorHunterFee
          ? data._savedBusiness && data._savedBusiness.juniorHunterFee
            ? data._savedBusiness.juniorHunterFee
            : data.business && data.business.juniorHunterFee
              ? data.business.juniorHunterFee : 0 : 0;

      let leaderFee = 0;
      const isPartyLeaderAJunior = partyLeader.isJuniorHunter ? partyLeader.isJuniorHunter : false;
      const isPartyLeaderDonate = partyLeader.isWildlifeDonation ? partyLeader.isWildlifeDonation : false;

      let isMemberTaxed = false;
      let isWildlifeDonationTaxed = false;
      let isObserverFeeTaxed = false;
      let isFishermanFeeTaxed = false;
      let isHunterFeeTaxed = false;
      let isJuniorFeeTaxed = false;
      let isAdditionalFeeTaxed = false;
      let isBasePriceTaxed = false;

      if (data._savedBusiness) {
        isWildlifeDonationTaxed = isTax
          ? data._savedBusiness && data._savedBusiness.isWildlifeDonationTaxed
            ? data._savedBusiness.isWildlifeDonationTaxed
            : false
          : false;
        isObserverFeeTaxed = isTax
          ? data._savedBusiness && data._savedBusiness.isObserverFeeTaxed
            ? data._savedBusiness.isObserverFeeTaxed
            : false
          : false;
        isFishermanFeeTaxed = isTax
          ? data._savedBusiness && data._savedBusiness.isFishermanFeeTaxed
            ? data._savedBusiness.isFishermanFeeTaxed
            : false
          : false;
        isHunterFeeTaxed = isTax
          ? data._savedBusiness && data._savedBusiness.isHunterFeeTaxed
            ? data._savedBusiness.isHunterFeeTaxed
            : false
          : false;
        isJuniorFeeTaxed = isTax
          ? data._savedBusiness && data._savedBusiness.isJuniorFeeTaxed
            ? data._savedBusiness.isJuniorFeeTaxed
            : false
          : false;
        isAdditionalFeeTaxed = isTax
          ? data._savedBusiness && data._savedBusiness.isAdditionalFeeTaxed
            ? data._savedBusiness.isAdditionalFeeTaxed
            : false
          : false;
        isBasePriceTaxed = isTax
          ? data._savedBusiness && data._savedBusiness.isBasePriceTaxed
            ? data._savedBusiness.isBasePriceTaxed
            : false
          : false;
      } else {
        isWildlifeDonationTaxed = isTax ? (data.business && data.business.isWildlifeDonationTaxed
          ? data.business.isWildlifeDonationTaxed
          : false) : false;
        isObserverFeeTaxed = isTax ? (data.business && data.business.isObserverFeeTaxed
          ? data.business.isObserverFeeTaxed
          : false) : false;
        isFishermanFeeTaxed = isTax ? (data.business && data.business.isFishermanFeeTaxed
          ? data.business.isFishermanFeeTaxed
          : false) : false;
        isHunterFeeTaxed = isTax ? (data.business && data.business.isHunterFeeTaxed
          ? data.business.isHunterFeeTaxed
          : false) : false;
        isJuniorFeeTaxed = isTax ? (data.business && data.business.isJuniorFeeTaxed
          ? data.business.isJuniorFeeTaxed
          : false) : false;
        isAdditionalFeeTaxed = isTax ? (data.business && data.business.isAdditionalFeeTaxed
          ? data.business.isAdditionalFeeTaxed
          : false) : false;
        isBasePriceTaxed = isTax ? (data.business && data.business.isBasePriceTaxed
          ? data.business.isBasePriceTaxed
          : false) : false;
      }

      const hunterBaseFee = data._savedBusiness && data._savedBusiness.hunterFee ? data._savedBusiness.hunterFee : data.business && data.business.hunterFee ? data.business.hunterFee : 0;
      const fishermanBaseFee = data._savedBusiness && data._savedBusiness.fishermanFee ? data._savedBusiness.fishermanFee : data.business && data.business.fishermanFee ? data.business.fishermanFee : 0;
      const observerBaseFee = data._savedBusiness && data._savedBusiness.observerFee ? data._savedBusiness.observerFee : data.business && data.business.observerFee ? data.business.observerFee : 0;
      const wildlifeDonationBaseFee = data._savedBusiness && data._savedBusiness.wildlifeDonationFee ? data._savedBusiness.wildlifeDonationFee : data.business && data.business.wildlifeDonationFee ? data.business.wildlifeDonationFee : 0;

      let basePrice = isTax ? !isBasePriceTaxed
        ? price
        : price + (price * tax) / 100
        : price

      let hunterFee = isTax ? !isHunterFeeTaxed
        ? hunterBaseFee
        : hunterBaseFee + (hunterBaseFee * tax) / 100
        : hunterBaseFee

      let fishermanFee = isTax ? !isFishermanFeeTaxed
        ? fishermanBaseFee
        : fishermanBaseFee + (fishermanBaseFee * tax) / 100
        : fishermanBaseFee

      let observerFee = isTax ? !isObserverFeeTaxed
        ? observerBaseFee
        : observerBaseFee + (observerBaseFee * tax) / 100
        : observerBaseFee

      let wildlifeDonationFee = isTax ? !isWildlifeDonationTaxed
        ? wildlifeDonationBaseFee
        : wildlifeDonationBaseFee + (wildlifeDonationBaseFee * tax) / 100
        : wildlifeDonationBaseFee

      let basePriceRawTax = isTax ? !isBasePriceTaxed
        ? 0
        : (totalPrice * tax) / 100
        : 0

      let hunterFeeRawTax = isTax ? !isHunterFeeTaxed
        ? 0
        : (hunterBaseFee * tax) / 100
        : 0

      let fishermanFeeRawTax = isTax ? !isFishermanFeeTaxed
        ? 0
        : (fishermanBaseFee * tax) / 100
        : 0

      let observerFeeRawTax = isTax ? !isObserverFeeTaxed
        ? 0
        : (observerBaseFee * tax) / 100
        : 0

      let wildlifeDonationFeeRawTax = isTax ? !isWildlifeDonationTaxed
        ? 0
        : (wildlifeDonationBaseFee * tax) / 100
        : 0

      let observerList = data.observerList ? data.observerList.map(item => {
        return {
          id: item.businessCustomerId !== "000000000000000000000000" ? item.businessCustomerId : '000000000000000000000000',
          name: item.name,
          customerType: "observer",
          fee: observerFee,
          isTaxed: isObserverFeeTaxed,
          baseFee: observerBaseFee,
          typeFeeTaxed: observerFee,
          typeFee: observerBaseFee,
          baseDonation: !!item.isWildlifeDonation ? wildlifeDonationBaseFee : 0,
          donation: !!item.isWildlifeDonation ? wildlifeDonationFee : 0,
          baseFeeWithType: observerBaseFee,
          tax: observerFeeRawTax + (!!item.isWildlifeDonation ? wildlifeDonationFeeRawTax : 0)
        }
      }) : [];

      let juniorHunterFeeFinal = juniorHunterFee;
      let juniorRawTax = 0
      if (isTax) {
        juniorHunterFeeFinal = !isJuniorFeeTaxed
          ? juniorHunterFee
          : juniorHunterFee + (juniorHunterFee * tax) / 100;
        juniorRawTax = !isJuniorFeeTaxed
          ? 0
          : (juniorHunterFee * tax) / 100;
      }

      //Set Leader Fee
      let leaderBaseFeeWithType = 0;
      let leaderBaseTypeFee = 0;
      let leaderBaseTypeFeeTaxed = 0;
      let leaderTax = 0

      if (partyLeader.customerType === "fisherman") {
        if (isJuniorHunterFee) {
          if (isPartyLeaderAJunior) {
            leaderFee = basePrice + fishermanFee + juniorHunterFeeFinal
            leaderBaseFeeWithType = price + fishermanBaseFee + juniorHunterFee;
            leaderBaseTypeFee = fishermanBaseFee + juniorHunterFee;
            leaderBaseTypeFeeTaxed = fishermanFee + juniorHunterFeeFinal;
            leaderTax = fishermanFeeRawTax + juniorRawTax;

            if (isJuniorFeeTaxed === true) {
              isMemberTaxed = isJuniorFeeTaxed;
            } else if (isFishermanFeeTaxed === true) {
              isMemberTaxed = isFishermanFeeTaxed;
            } else {
              isMemberTaxed = false;
            }
          } else {
            leaderFee = basePrice + fishermanFee;
            leaderBaseFeeWithType = price + fishermanBaseFee;
            leaderBaseTypeFee = fishermanBaseFee;
            leaderBaseTypeFeeTaxed = fishermanFee;
            leaderTax = fishermanFeeRawTax;

            isMemberTaxed = isFishermanFeeTaxed;
          }
        } else {
          leaderFee = basePrice + fishermanFee;
          leaderBaseFeeWithType = price + fishermanBaseFee;
          leaderBaseTypeFee = fishermanBaseFee;
          leaderBaseTypeFeeTaxed = fishermanFee;
          leaderTax = fishermanFeeRawTax;

          isMemberTaxed = isFishermanFeeTaxed;
        }
      } else if (partyLeader.customerType === "hunter") {
        if (isJuniorHunterFee) {
          if (isPartyLeaderAJunior) {
            leaderFee = basePrice + hunterFee + juniorHunterFeeFinal
            leaderBaseFeeWithType = price + hunterBaseFee + juniorHunterFee;
            leaderBaseTypeFee = hunterBaseFee + juniorHunterFee;
            leaderBaseTypeFeeTaxed = hunterFee + juniorHunterFeeFinal;
            leaderTax = hunterFeeRawTax + juniorRawTax;

            if (isJuniorFeeTaxed === true) {
              isMemberTaxed = isJuniorFeeTaxed;
            } else if (isHunterFeeTaxed === true) {
              isMemberTaxed = isHunterFeeTaxed;
            } else {
              isMemberTaxed = false;
            }
          } else {
            leaderFee = basePrice + hunterFee
            leaderBaseFeeWithType = price + hunterBaseFee;
            leaderBaseTypeFee = hunterBaseFee;
            leaderBaseTypeFeeTaxed = hunterFee;
            leaderTax = hunterFeeRawTax;

            isMemberTaxed = isHunterFeeTaxed;
          }
        } else {
          leaderFee = basePrice + hunterFee
          leaderBaseFeeWithType = price + hunterBaseFee;
          leaderBaseTypeFee = hunterBaseFee;
          leaderBaseTypeFeeTaxed = hunterFee;
          leaderTax = hunterFeeRawTax;

          isMemberTaxed = isHunterFeeTaxed;
        }
      }
      // else if (partyLeader.customerType === "both") {
      //   if (isJuniorHunterFee) {
      //     if (isPartyLeaderAJunior) {
      //       leaderFee = basePrice + hunterFee + fishermanFee + juniorHunterFeeFinal
      //       leaderBaseFeeWithType = price + hunterBaseFee + fishermanBaseFee + juniorHunterFee;
      //       leaderBaseTypeFee = hunterBaseFee + fishermanBaseFee + juniorHunterFee;
      //       leaderBaseTypeFeeTaxed = hunterFee + fishermanFee + juniorHunterFeeFinal
      //       leaderTax = hunterFeeRawTax + fishermanFeeRawTax + juniorRawTax;

      //       if (isJuniorFeeTaxed === true) {
      //         isMemberTaxed = isJuniorFeeTaxed;
      //       } else if (isHunterFeeTaxed === true) {
      //         isMemberTaxed = isHunterFeeTaxed;
      //       } else if (isFishermanFeeTaxed === true) {
      //         isMemberTaxed = isFishermanFeeTaxed;
      //       } else {
      //         isMemberTaxed = false;
      //       }
      //     } else {
      //       leaderFee = basePrice + hunterFee + fishermanFee
      //       leaderBaseFeeWithType = price + hunterBaseFee + fishermanBaseFee;
      //       leaderBaseTypeFee = hunterBaseFee + fishermanBaseFee;
      //       leaderBaseTypeFeeTaxed = hunterFee + fishermanFee;
      //       leaderTax = hunterFeeRawTax + fishermanFeeRawTax;

      //       if (isHunterFeeTaxed === true) {
      //         isMemberTaxed = isHunterFeeTaxed;
      //       } else if (isFishermanFeeTaxed === true) {
      //         isMemberTaxed = isFishermanFeeTaxed;
      //       } else {
      //         isMemberTaxed = false;
      //       }
      //     }
      //   } else {
      //     leaderFee = basePrice + hunterFee + fishermanFee
      //     leaderBaseFeeWithType = price + hunterBaseFee + fishermanBaseFee;
      //     leaderBaseTypeFee = hunterBaseFee + fishermanBaseFee;
      //     leaderBaseTypeFeeTaxed = hunterFee + fishermanFee;
      //     leaderTax = hunterFeeRawTax + fishermanFeeRawTax;

      //     if (isHunterFeeTaxed === true) {
      //       isMemberTaxed = isHunterFeeTaxed;
      //     } else if (isFishermanFeeTaxed === true) {
      //       isMemberTaxed = isFishermanFeeTaxed;
      //     } else {
      //       isMemberTaxed = false;
      //     }
      //   }
      // } 
      else {
        if (isJuniorHunterFee) {
          if (isPartyLeaderAJunior) {
            leaderFee = basePrice + juniorHunterFeeFinal
            leaderBaseFeeWithType = price + juniorHunterFee;
            leaderBaseTypeFee = juniorHunterFee;
            leaderBaseTypeFeeTaxed = juniorHunterFeeFinal;
            leaderTax = juniorRawTax;

            isMemberTaxed = isJuniorFeeTaxed;
          } else {
            leaderFee = basePrice
            leaderBaseFeeWithType = price;
            leaderBaseTypeFee = 0;
            leaderBaseTypeFeeTaxed = 0;
            leaderTax = 0;
          }
        } else {
          leaderBaseFeeWithType = price;
          leaderFee = basePrice
          leaderBaseTypeFee = 0;
          leaderBaseTypeFeeTaxed = 0;
          leaderTax = 0;
        }
      }
      //----------------------------------------------

      //Assign Leader to an Array
      let toBePartyArray = [];

      if (partyLeader) {
        toBePartyArray = [
          {
            junior: isPartyLeaderAJunior,
            leader: true,
            value: partyLeader._id ? partyLeader._id : "",
            label: `${partyLeader.firstName ? partyLeader.firstName : ""} ${partyLeader.lastName ? partyLeader.lastName : ""
              }`,
            name: `${partyLeader.firstName ? partyLeader.firstName : ""} ${partyLeader.lastName ? partyLeader.lastName : ""
              }`,
            customerType: partyLeader.customerType
              ? partyLeader.customerType
              : "",
            fee: leaderFee,
            baseFee: price,
            baseFeeWithType: leaderBaseFeeWithType,
            typeFeeTaxed: leaderBaseTypeFeeTaxed,
            typeFee: leaderBaseTypeFee,
            baseDonation: !!isPartyLeaderDonate ? wildlifeDonationBaseFee : 0,
            donation: !!isPartyLeaderDonate ? wildlifeDonationFee : 0,
            id: partyLeader._id ? partyLeader._id : "",
            isTaxed: isMemberTaxed,
            tax: leaderTax + (!!isPartyLeaderDonate ? wildlifeDonationFeeRawTax : 0)
          },
        ];
      }
      //----------------------------------------------

      //Set Leader Fee
      const tempPartyArray = data.party
        ? data.party.map((item) => {
          let fee = 0;
          let baseFee = 0;
          let typeFee = 0;
          let typeFeeTaxed = 0;
          let partyTax = 0;

          if (item.customerType === "fisherman") {
            if (isJuniorHunterFee) {
              if (item.isJuniorHunter) {
                //Fee
                fee = basePrice + fishermanFee + juniorHunterFeeFinal
                baseFee = price + fishermanBaseFee + juniorHunterFee;
                typeFee = fishermanBaseFee + juniorHunterFee;
                typeFeeTaxed = fishermanFee + juniorHunterFeeFinal;
                partyTax = fishermanFeeRawTax + juniorRawTax;

                //Fisherman Tax Status
                if (isJuniorFeeTaxed === true) {
                  isMemberTaxed = isJuniorFeeTaxed;
                } else if (isFishermanFeeTaxed === true) {
                  isMemberTaxed = isFishermanFeeTaxed;
                } else {
                  isMemberTaxed = false;
                }
              } else {
                //Fee
                fee = basePrice + fishermanFee
                baseFee = price + fishermanBaseFee;
                typeFee = fishermanBaseFee;
                typeFeeTaxed = fishermanFee;
                partyTax = fishermanFeeRawTax;

                //Fisherman Tax Status
                isMemberTaxed = isFishermanFeeTaxed;
              }
            } else {
              //Fee
              fee = basePrice + fishermanFee
              baseFee = price + fishermanBaseFee;
              typeFee = fishermanBaseFee;
              typeFeeTaxed = fishermanFee;
              partyTax = fishermanFeeRawTax;

              //Fisherman Tax Status
              isMemberTaxed = isFishermanFeeTaxed;
            }
          } else if (item.customerType === "hunter") {
            if (isJuniorHunterFee) {
              if (item.isJuniorHunter) {
                //Fee
                fee = basePrice + hunterFee + juniorHunterFeeFinal
                baseFee = price + hunterBaseFee + juniorHunterFee;
                typeFee = hunterBaseFee + juniorHunterFee;
                typeFeeTaxed = hunterFee + juniorHunterFeeFinal;
                partyTax = hunterFeeRawTax + juniorRawTax;

                //Hunter Tax Status
                if (isJuniorFeeTaxed === true) {
                  isMemberTaxed = isJuniorFeeTaxed;
                } else if (isHunterFeeTaxed === true) {
                  isMemberTaxed = isHunterFeeTaxed;
                } else {
                  isMemberTaxed = false;
                }
              } else {
                //Fee
                fee = basePrice + hunterFee
                baseFee = price + hunterBaseFee;
                typeFee = hunterBaseFee;
                typeFeeTaxed = hunterFee;
                partyTax = hunterFeeRawTax;

                //Hunter Tax Status
                isMemberTaxed = isHunterFeeTaxed;
              }
            } else {
              //Fee
              fee = basePrice + hunterFee
              baseFee = price + hunterBaseFee;
              typeFee = hunterBaseFee;
              typeFeeTaxed = hunterFee;
              partyTax = hunterFeeRawTax;

              //Hunter Tax Status
              isMemberTaxed = isHunterFeeTaxed;
            }
          }
          // else if (item.customerType === "both") {
          //   if (isJuniorHunterFee) {
          //     if (item.isJuniorHunter) {
          //       //Fee
          //       fee = basePrice + fishermanFee + hunterFee + juniorHunterFeeFinal
          //       baseFee = price + hunterBaseFee + fishermanBaseFee + juniorHunterFee;
          //       typeFee = hunterBaseFee + fishermanBaseFee + juniorHunterFee;
          //       typeFeeTaxed = fishermanFee + hunterFee + juniorHunterFeeFinal;
          //       partyTax = hunterFeeRawTax + fishermanFeeRawTax + juniorRawTax;

          //       //Both Tax Status
          //       if (isJuniorFeeTaxed === true) {
          //         isMemberTaxed = isJuniorFeeTaxed;
          //       } else if (isHunterFeeTaxed === true) {
          //         isMemberTaxed = isHunterFeeTaxed;
          //       } else if (isFishermanFeeTaxed === true) {
          //         isMemberTaxed = isFishermanFeeTaxed;
          //       } else {
          //         isMemberTaxed = false
          //       }
          //     } else {
          //       //Fee
          //       fee = basePrice + fishermanFee + hunterFee;
          //       baseFee = price + hunterBaseFee + fishermanBaseFee;
          //       typeFee = hunterBaseFee + fishermanBaseFee;
          //       typeFeeTaxed = fishermanFee + hunterFee;
          //       partyTax = hunterFeeRawTax + fishermanFeeRawTax;

          //       //Both Tax Status
          //       if (isHunterFeeTaxed === true) {
          //         isMemberTaxed = isHunterFeeTaxed;
          //       } else if (isFishermanFeeTaxed === true) {
          //         isMemberTaxed = isFishermanFeeTaxed;
          //       } else {
          //         isMemberTaxed = false;
          //       }
          //     }
          //   } 
          //   else {
          //     //Fee
          //     fee = basePrice + fishermanFee + hunterFee;
          //     baseFee = price + hunterBaseFee + fishermanBaseFee;
          //     typeFee = hunterBaseFee + fishermanBaseFee;
          //     typeFeeTaxed = fishermanFee + hunterFee;
          //     partyTax = hunterFeeRawTax + fishermanFeeRawTax;

          //     //Both Tax Status
          //     if (isHunterFeeTaxed === true) {
          //       isMemberTaxed = isHunterFeeTaxed;
          //     } else if (isFishermanFeeTaxed === true) {
          //       isMemberTaxed = isFishermanFeeTaxed;
          //     } else {
          //       isMemberTaxed = false
          //     }
          //   }
          // } 
          else {
            if (isJuniorHunterFee) {
              if (item.isJuniorHunter) {
                //Fee
                fee = basePrice + juniorHunterFeeFinal
                baseFee = price + juniorHunterFee;
                typeFee = juniorHunterFee;
                typeFeeTaxed = juniorHunterFeeFinal;
                partyTax = juniorRawTax;

                //No Type Tax Status
                isMemberTaxed = isJuniorFeeTaxed;
              } else {
                fee = basePrice;
                baseFee = price;
                typeFee = 0;
                typeFeeTaxed = 0;
                partyTax = 0;
              }
            } else {
              fee = basePrice
              baseFee = price;
              typeFee = 0;
              typeFeeTaxed = 0;
              partyTax = 0;
            }
          }

          return {
            leader: false,
            value: item._id,
            label: `${item.firstName} ${item.lastName}`,
            name: `${item.firstName} ${item.lastName}`,
            customerType: item.customerType ? item.customerType : "",
            fee: fee,
            baseFee: price,
            baseFeeWithType: baseFee,
            typeFeeTaxed,
            typeFee,
            id: item._id,
            junior: item.isJuniorHunter ? item.isJuniorHunter : false,
            baseDonation: !!item.isWildlifeDonation ? wildlifeDonationBaseFee : 0,
            donation: !!item.isWildlifeDonation ? wildlifeDonationFee : 0,
            isTaxed: isMemberTaxed,
            tax: partyTax + (!!item.isWildlifeDonation ? wildlifeDonationFeeRawTax : 0)
          };
        })
        : [];
      //----------------------------------------------

      toBePartyArray = [...toBePartyArray, ...tempPartyArray];

      //Total Tax
      let totalTax = basePriceRawTax;
      for (let i = 0; i < toBePartyArray.length; i++) {
        totalTax = totalTax + toBePartyArray[i].tax;
      }

      for (let i = 0; i < observerList.length; i++) {
        totalTax = totalTax + observerList[i].tax;
      }

      //Total Service Fee
      let totalFee = 0;
      for (let i = 0; i < toBePartyArray.length; i++) {
        totalFee = totalFee + toBePartyArray[i].fee;
      }

      let totalTypeFee = 0;
      for (let i = 0; i < toBePartyArray.length; i++) {
        totalTypeFee = totalTypeFee + toBePartyArray[i].typeFee;
      }

      let totalTypeFeeTaxed = 0;
      for (let i = 0; i < toBePartyArray.length; i++) {
        totalTypeFeeTaxed = totalTypeFeeTaxed + toBePartyArray[i].typeFeeTaxed;
      }

      let totalServiceFee = 0;
      for (let i = 0; i < toBePartyArray.length; i++) {
        totalServiceFee = totalServiceFee + toBePartyArray[i].baseFeeWithType;
      }
      //------------------

      //Total Observer
      let totalObserver = 0
      for (let i = 0; i < observerList.length; i++) {
        totalObserver = totalObserver + observerList[i].fee;
      }

      let totalBaseObserver = 0
      for (let i = 0; i < observerList.length; i++) {
        totalBaseObserver = totalBaseObserver + observerList[i].baseFee;
      }
      //------------------

      //Total Donation
      let totalBaseDonation = 0;
      for (let i = 0; i < toBePartyArray.length; i++) {
        totalBaseDonation = totalBaseDonation + toBePartyArray[i].baseDonation;
      }

      let totalDonation = 0;
      for (let i = 0; i < toBePartyArray.length; i++) {
        totalDonation = totalDonation + toBePartyArray[i].donation;
      }
      //------------------

      let parties = [];

      if (data.party) {
        for (let i = 0; i < data.party.length; i++) {
          parties.push(data.party[i]);
        }
      }

      let additionalFee = data.additionalFee ? Number(data.additionalFee) : 0;

      const baseTotal = totalTypeFee + totalPrice + additionalFee + totalBaseDonation + totalBaseObserver
      // const total = totalFee + additionalFee + totalObserver + totalDonation;
      let total = isTax
        ? isBasePriceTaxed
          ? totalPriceTaxed + totalTypeFeeTaxed + additionalFee + totalObserver + totalDonation
          : totalPrice + totalTypeFeeTaxed + additionalFee + totalObserver + totalDonation
        : totalPrice + totalFee + additionalFee + totalObserver + totalDonation;
      let remainingBalance = total - Number(totalDeposit);

      this.setEditedData({
        startTime: moment(
          new Date(_.get(data, "activitySession.startTime", ""))
        ).format("DD MMMM YYYY HH:mm"),
        endTime: moment(
          new Date(_.get(data, "activitySession.endTime", ""))
        ).format("DD MMMM YYYY HH:mm"),
        quantity: _.get(data, "quantity", ""),
        status: _.get(data, "status", ""),
        hunt: _.get(data, "activitySession.name", ""),
        bookingID: _.get(data, "_id", ""),
        customer:
          _.get(data, "businessCustomer.firstName", "") +
          " " +
          _.get(data, "businessCustomer.lastName", ""),
        phoneNumber: data.businessCustomer.phoneNumber
          ? data.businessCustomer.phoneNumber
          : "",
        guide: {
          label:
            _.get(data, "guide.firstName", "") +
            " " +
            _.get(data, "guide.lastName", ""),
          value: _.get(data, "guide._id", ""),
        },
        speciesGuided: data.customFieldBooking
          ? data.customFieldBooking.speciesGuided
          : "",
        huntUnit: data.customFieldBooking
          ? data.customFieldBooking.huntUnit
          : "",
        customBookingId: data.customFieldBooking
          ? data.customFieldBooking._id
          : "",
        huntDetail: data.customFieldBooking
          ? data.customFieldBooking.huntDetail
          : [],
        paying: data.customFieldBooking ? data.customFieldBooking.paying : true,
        note: data.note ? data.note : "",
        party: data.party ? data.party : [],
        partyLeader: data.partyLeader ? data.partyLeader : "",
        deposit: data.deposit ? data.deposit : [],
        totalDeposit: remainingBalance,
        tempTotalDeposit: remainingBalance,
        totalPrice: isTax
          ? isBasePriceTaxed
            ? totalPriceTaxed
            : totalPrice
          : totalPrice,
        basePrice: totalPrice,
        total: total,
        leaderVoid: data.party
          ? {
            label: `${data.partyLeader && data.partyLeader.firstName} ${data.partyLeader && data.partyLeader.lastName
              }`,
            value: data.party && data.partyLeader,
          }
          : "",
        partiesForDetailReservation: parties.map((data) => {
          return {
            label: `${data.firstName} ${data.lastName}`,
            value: data,
          };
        }),
        void: [],
        partyArray: toBePartyArray,
        currLeader: partyLeader._id ? partyLeader._id : "",
        subTotalFee: totalFee,
        isTax: isTax,
        tax: tax,
        hunterFee: hunterFee,
        fishermanFee: fishermanFee,
        observerFee: observerFee,
        wildlifeDonation: wildlifeDonationFee,
        juniorHunterFee: data.business.juniorHunterFee,
        bothFee: data.business.fishermanFee + data.business.hunterFee,
        isWildlifeDonationTaxed: isWildlifeDonationTaxed,
        isObserverFeeTaxed: isObserverFeeTaxed,
        isFishermanFeeTaxed: isFishermanFeeTaxed,
        isHunterFeeTaxed: isHunterFeeTaxed,
        isJuniorFeeTaxed: isJuniorFeeTaxed,
        isAdditionalFeeTaxed: isAdditionalFeeTaxed,
        isBasePriceTaxed: isBasePriceTaxed,
        additionalFee: data.additionalFee ? String(data.additionalFee) : "",
        observers: observerList,
        baseJuniorFee: juniorHunterFee,
        juniorFeeFinal: juniorHunterFeeFinal,
        isJuniorHunter: isJuniorHunterFee,
        serviceFee: totalServiceFee,
        totalBaseDonation: totalBaseDonation,
        totalBaseObserver: totalBaseObserver,
        totalDonation: totalDonation,
        totalObserver: totalObserver,
        baseTotal: baseTotal,
        price: price,
        hunterBaseFee,
        fishermanBaseFee,
        observerBaseFee,
        wildlifeDonationBaseFee,
        taxedPrice: basePrice,
        totalTypeFee,
        totalTypeFeeTaxed,
        totalTax,
        juniorRawTax,
        basePriceRawTax,
        hunterFeeRawTax,
        observerFeeRawTax,
        fishermanFeeRawTax,
        wildlifeDonationFeeRawTax
      });

      if (currentState === "Nevada") {
        this.speciesArray = data.customFieldBooking
          ? data.customFieldBooking.huntDetail.map((data, idx) => {
            const species =
              data && data.speciesTaken ? data.speciesTaken : "";
            const amount =
              data && data.numberOfSpeciesTaken
                ? data.numberOfSpeciesTaken
                : "";
            const date =
              data && data.dateTaken
                ? moment(data.dateTaken).format("MMM DD, YYYY")
                : "";
            const antlerPointLeft =
              data && data.antlerPointLeft ? data.antlerPointLeft : "";
            const antlerPointRight =
              data && data.antlerPointRight ? data.antlerPointRight : "";
            const index = idx;
            return {
              ...data,
              species,
              amount,
              date,
              antlerPointLeft,
              antlerPointRight,
              index,
            };
          })
          : [];
      } else if (currentState === "Wyoming") {
        this.wyomingArray = data.customFieldBooking
          ? data.customFieldBooking.huntDetail.map((data, idx) => {
            const gameHuntedSpecies =
              data && data.speciesTaken ? data.speciesTaken : "";
            const gameHuntedArea =
              data && data.speciesArea ? data.speciesArea : "";
            const date =
              data && data.dateTaken
                ? moment(data.dateTaken).format("MM/DD/YY")
                : "";
            const killArea = data && data.killArea ? data.killArea : "";
            const sex = data && data.sex ? data.sex : "";
            const index = idx;
            return {
              ...data,
              gameHuntedArea,
              gameHuntedSpecies,
              date,
              killArea,
              sex,
              index,
            };
          })
          : [];
      }
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "You don't have any permission to View Booking's data"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  postBooking = async (values, businessId) => {
    const bookingDTO = {
      customer: values.customer.value,
      guide: values.guide.value,
      activitySession: values.huntSession._id,
      quantity: values.quantity,
      email: values.email,
      timezone: momentTimezone.tz.guess(),
      speciesGuided: "",
      huntUnit: "",
      paying: false,
    };
    this.isLoading = true;

    const { data, error } = await postBooking(bookingDTO, businessId);

    if (data) {
      let party = modalStore.editedData.party;
      let partyLeader = modalStore.editedData.partyLeader;

      if (data.changedCustomerData) {
        if (partyLeader === data.changedCustomerData.previousId) {
          modalStore.setEditedData({
            partyLeader: data.changedCustomerData.newCustomerId,
          });
        }

        for (let i = 0; i < party.length; i++) {
          if (party[i] === data.changedCustomerData.previousId) {
            party[i] = data.changedCustomerData.newCustomerId;
          }
        }
      }
      modalStore.setEditedData({ party: party });

      return { data };
    } else {
      this.isLoading = false;

      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
      return false;
    }
  };

  putBooking = async (values, businessId, bookingId) => {
    const bookingDTO = {
      quantity: values.quantity,
      status: values.status,
      timezone: momentTimezone.tz.guess(),
      guide: values.guide.value,
      speciesGuided: values.speciesGuided,
      huntUnit: values.huntUnit,
      paying: values.paying,
      note: values.note,
      isWildlifeDonationTaxed: this.editedData.isWildlifeDonationTaxed,
      isObserverFeeTaxed: this.editedData.isObserverFeeTaxed,
      isFishermanFeeTaxed: this.editedData.isFishermanFeeTaxed,
      isHunterFeeTaxed: this.editedData.isHunterFeeTaxed,
      isJuniorFeeTaxed: this.editedData.isJuniorFeeTaxed,
      isAdditionalFeeTaxed: this.editedData.isAdditionalFeeTaxed,
      isBasePriceTaxed: this.editedData.isBasePriceTaxed,
      additionalFee: this.editedData.additionalFee ? Number(this.editedData.additionalFee) : 0,
    };

    this.isLoading = true;

    const { data, error } = await putBooking(bookingDTO, businessId, bookingId);

    if (data) {
      await new Promise((resolve, reject) => {
        CommonStore.showSuccessMessage("Data updated!");
        setTimeout(resolve, 500);
      });
      return true;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
      return false;
    }
  };

  deleteBooking = async (businessId, bookingId) => {
    this.deleteLoading = true;
    const { error } = await deleteBooking(businessId, bookingId);
    this.deleteLoading = false;

    if (!error) {
      await new Promise((resolve, reject) => {
        CommonStore.showSuccessMessage("Data deleted!");
        setTimeout(resolve, 500);
      });
      return true;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
      return false;
    }
  };

  getBookingByHuntSession = async (businessId, huntId) => {
    const { data, error } = await getBookingByHuntSession(businessId, huntId);

    if (data) {
      this.calendarArray = data;
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "You don't have any permission to View Booking's data"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  //   const schema = Joi.object({
  //     arg: Joi.string().valid('firstname', 'lastname', 'title', 'company', 'jobtitle'),
  //     value: Joi.string(),
  // }).pattern(/firstname|lastname/, Joi.string().min(2));

  get joiFormErrors() {
    if (this.editedData) {
      return validateEditedData({
        editedData: this.editedData,
        joiSchema: {
          customer: Joi.object({
            label: Joi.string(),
            value: Joi.string(),
          })
            .label("Customer")
            .required(),
          guide: Joi.object({
            label: Joi.string(),
            value: Joi.string(),
          })
            .label("Guide")
            .required(),
        },
      });
    }
    return { error: true };
  }

  addSpecies = async (values, businessId, customBooking, bookingId) => {
    this.isLoading = true;

    const { data, error } = await addSpecies(
      values,
      businessId,
      customBooking,
      bookingId
    );

    if (data) {
      this.isLoading = false;
    } else {
      this.isLoading = false;

      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
      return false;
    }
  };

  addPartyMember = async (businessId, bookingId, value) => {
    const { data, error } = await addPartyMember(businessId, bookingId, value);

    if (data) {
      this.isLoading = false;
      return true;
    } else {
      this.isLoading = false;

      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
      return false;
    }
  };

  editPartyMember = async (businessId, bookingId, value) => {
    const { data, error } = await editPartyMember(businessId, bookingId, value);

    if (data) {
      this.isLoading = false;
      return true;
    } else {
      this.isLoading = false;

      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
      return false;
    }
  };

  addDeposit = async (businessId, bookingId, value) => {
    this.isLoading = true;

    const { data, error } = await addDeposit(businessId, bookingId, value);

    if (data) {
      this.isLoading = false;
      return { data };
    } else {
      this.isLoading = false;

      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
      return false;
    }
  };

  updateDeposit = async (businessId, depositId, value) => {
    this.updatePaymentLoading = true;

    const { data, error } = await updateDeposit(businessId, depositId, value);

    if (data) {
      this.updatePaymentLoading = false;
      CommonStore.showSuccessMessage("Payment History Updated!");
      return { data };
    } else {
      this.updatePaymentLoading = false;

      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
      return false;
    }
  };

  createEvent = async (businessId, value) => {
    this.isLoading = true;

    const { data, error } = await createEvent(businessId, value);

    this.isLoading = false;

    if (data) {
      return true;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  updateEvent = async (businessId, value, eventId) => {
    this.isLoading = true;

    const { data, error } = await updateEvent(businessId, value, eventId);

    this.isLoading = false;

    if (data) {
      return true;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  deleteEvent = async (businessId, eventId) => {
    this.isLoading = true;

    const { data, error } = await deleteEvent(businessId, eventId);

    this.isLoading = false;

    if (!data) {
      return true;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  //-----------------------------------------------------------Super Admin--------------------------------------------------------------

  setCurrentAdminPage = (businessId) => (value) => {
    this.currentPage = value;
    this.getAllBookingForAdmin(businessId);
  };

  getAllBookingForAdmin = async (businessId) => {
    this.isLoading = true;
    const { data, error } = await getAllBookingForAdmin({
      businessId: businessId,
      currentPage: this.currentPage + 1,
      pageLimit: this.pageSize,
      search: this.searchValue,
      hunt: this.editedData.huntType,
    });

    if (data) {
      this.bookingArray = data.bookings
        ? data.bookings.map((data) => {
          const _id = _.get(data, "_id", "Empty");
          const bookingID = _.get(data, "_id", "Empty");
          const huntType = _.get(
            data,
            "activitySession.activity.name",
            "Empty"
          );
          const hunt = _.get(data, "activitySession.name", "Empty");
          const tempPrice = data.price
            ? "$ " + data.price * data.quantity
            : "";
          const price = tempPrice
            ? tempPrice.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : "";
          const status = _.get(data, "status", "Empty");
          const startTime = _.get(data, "activitySession.startTime", "");
          const endTime = _.get(data, "activitySession.endTime", "");
          const customer =
            _.get(data, "businessCustomer.firstName", "") +
            " " +
            _.get(data, "businessCustomer.lastName", "Empty");

          return {
            ...data,
            _id,
            huntType,
            hunt,
            price,
            status,
            startTime,
            endTime,
            customer,
            bookingID,
          };
        })
        : [];
      this.totalCount = data.totalData;
      this.isLoading = false;
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        CommonStore.showErrorMessage(this.errorMessage);
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getBookingByIdForAdmin = async (businessId, bookingId) => {
    this.isLoading = true;
    const { data, error } = await getBookingByIdForAdmin(businessId, bookingId);
    this.isLoading = false;

    if (data) {
      const price = data.price ? Number(data.price) : 0;
      const quantity = data.quantity ? Number(data.quantity) : 0;
      const totalPrice = price * quantity;
      const isTax =
        data.activitySession && data.activitySession.isSalesTaxActive
          ? data.activitySession.isSalesTaxActive
          : false;
      const tax =
        data.business && data.business.salesTax ? data.business.salesTax : 0;
      const totalPriceTaxed = totalPrice + (totalPrice * tax) / 100;

      let totalDeposit = 0;
      data.deposit &&
        data.deposit.map((data) => {
          return (totalDeposit = totalDeposit + Number(data.amount));
        });
      const partyLeader = data.partyLeader ? data.partyLeader : "";
      let leaderFee = 0;

      const isWildlifeDonationTaxed = data.business && data.business.isWildlifeDonationTaxed
        ? data.business.isWildlifeDonationTaxed
        : data._savedBusiness && data._savedBusiness.isWildlifeDonationTaxed
          ? data._savedBusiness.isWildlifeDonationTaxed
          : false;
      const isObserverFeeTaxed = data.business && data.business.isObserverFeeTaxed
        ? data.business.isObserverFeeTaxed
        : data._savedBusiness && data._savedBusiness.isObserverFeeTaxed
          ? data._savedBusiness.isObserverFeeTaxed
          : false;
      const isFishermanFeeTaxed = data.business && data.business.isFishermanFeeTaxed
        ? data.business.isFishermanFeeTaxed
        : data._savedBusiness && data._savedBusiness.isFishermanFeeTaxed
          ? data._savedBusiness.isFishermanFeeTaxed
          : false;
      const isHunterFeeTaxed = data.business && data.business.isHunterFeeTaxed
        ? data.business.isHunterFeeTaxed
        : data._savedBusiness && data._savedBusiness.isHunterFeeTaxed
          ? data._savedBusiness.isHunterFeeTaxed
          : false;
      const isJuniorFeeTaxed = data.business && data.business.isJuniorFeeTaxed
        ? data.business.isJuniorFeeTaxed
        : data._savedBusiness && data._savedBusiness.isJuniorFeeTaxed
          ? data._savedBusiness.isJuniorFeeTaxed
          : false;
      const isAdditionalFeeTaxed = data.business && data.business.isAdditionalFeeTaxed
        ? data.business.isAdditionalFeeTaxed
        : data._savedBusiness && data._savedBusiness.isAdditionalFeeTaxed
          ? data._savedBusiness.isAdditionalFeeTaxed
          : false;
      const isBasePriceTaxed = data.business && data.business.isBasePriceTaxed
        ? data.business.isBasePriceTaxed
        : data._savedBusiness && data._savedBusiness.isBasePriceTaxed
          ? data._savedBusiness.isBasePriceTaxed
          : false;

      const hunterBaseFee = data._savedBusiness && data._savedBusiness.hunterFee ? data._savedBusiness.hunterFee : data.business && data.business.hunterFee ? data.business.hunterFee : 0;
      const fishermanBaseFee = data._savedBusiness && data._savedBusiness.fishermanFee ? data._savedBusiness.fishermanFee : data.business && data.business.fishermanFee ? data.business.fishermanFee : 0;
      const observerBaseFee = data._savedBusiness && data._savedBusiness.observerFee ? data._savedBusiness.observerFee : data.business && data.business.observerFee ? data.business.observerFee : 0;

      let hunterFee = isTax ? !isHunterFeeTaxed
        ? hunterBaseFee
        : hunterBaseFee + (hunterBaseFee * tax) / 100
        : hunterBaseFee

      let fishermanFee = isTax ? !isFishermanFeeTaxed
        ? fishermanBaseFee
        : fishermanBaseFee + (fishermanBaseFee * tax) / 100
        : fishermanBaseFee

      let observerFee = isTax ? !isObserverFeeTaxed
        ? observerBaseFee
        : observerBaseFee + (observerBaseFee * tax) / 100
        : observerBaseFee

      let observerList = data.observerList ? data.observerList.map(item => {
        return {
          id: item.businessCustomerId !== "000000000000000000000000" ? item.businessCustomerId : '000000000000000000000000',
          name: item.name,
          customerType: "observer",
          fee: observerFee,
        }
      }) : [];


      if (partyLeader.customerType === "fisherman") {
        leaderFee = fishermanFee;
      } else if (partyLeader.customerType === "hunter") {
        leaderFee = hunterFee;
      } else if (partyLeader.customerType === "both") {
        leaderFee = hunterFee + fishermanFee;
      } else {
        leaderFee = 0;
      }
      let toBePartyArray = [];

      if (partyLeader) {
        toBePartyArray = [
          {
            leader: true,
            value: partyLeader._id ? partyLeader._id : "",
            label: `${partyLeader.firstName ? partyLeader.firstName : ""} ${partyLeader.lastName ? partyLeader.lastName : ""
              }`,
            name: `${partyLeader.firstName ? partyLeader.firstName : ""} ${partyLeader.lastName ? partyLeader.lastName : ""
              }`,
            customerType: partyLeader.customerType
              ? partyLeader.customerType
              : "",
            fee: leaderFee,
            id: partyLeader._id ? partyLeader._id : "",
          },
        ];
      }

      const tempPartyArray = data.party
        ? data.party.map((item) => {
          let fee = 0;

          if (item.customerType === "fisherman") {
            fee = fishermanFee;
          } else if (item.customerType === "hunter") {
            fee = hunterFee;
          } else if (item.customerType === "both") {
            fee = fishermanFee + hunterFee;
          } else {
            fee = 0;
          }

          return {
            leader: false,
            value: item._id,
            label: `${item.firstName} ${item.lastName}`,
            name: `${item.firstName} ${item.lastName}`,
            customerType: item.customerType ? item.customerType : "",
            fee: fee,
            id: item._id,
          };
        })
        : [];
      toBePartyArray = [...toBePartyArray, ...tempPartyArray];

      let totalFee = 0;
      for (let i = 0; i < toBePartyArray.length; i++) {
        totalFee = totalFee + toBePartyArray[i].fee;
      }

      for (let i = 0; i < observerList.length; i++) {
        totalFee = totalFee + observerList[i].fee;
      }

      let parties = [];

      if (data.party) {
        for (let i = 0; i < data.party.length; i++) {
          parties.push(data.party[i]);
        }
      }

      let additionalFee = data.additionalFee ? Number(data.additionalFee) : 0;
      let total = isTax
        ? isBasePriceTaxed
          ? totalPriceTaxed + totalFee + additionalFee
          : totalPrice + totalFee + additionalFee
        : totalPrice + totalFee + additionalFee;
      let remainingBalance = total - Number(totalDeposit);

      this.setEditedData({
        startTime: moment(
          new Date(_.get(data, "activitySession.startTime", ""))
        ).format("DD MMMM YYYY hh:mm"),
        endTime: moment(
          new Date(_.get(data, "activitySession.endTime", ""))
        ).format("DD MMMM YYYY hh:mm"),
        quantity: _.get(data, "quantity", ""),
        status: _.get(data, "status", ""),
        hunt: _.get(data, "activitySession.name", ""),
        bookingID: _.get(data, "_id", ""),
        customer:
          _.get(data, "businessCustomer.firstName", "") +
          " " +
          _.get(data, "businessCustomer.lastName", ""),
        guide: {
          label:
            _.get(data, "guide.firstName", "") +
            " " +
            _.get(data, "guide.lastName", ""),
          value: _.get(data, "guide._id", ""),
        },
        speciesGuided: data.customFieldBooking
          ? data.customFieldBooking.speciesGuided
          : "",
        huntUnit: data.customFieldBooking
          ? data.customFieldBooking.huntUnit
          : "",
        customBookingId: data.customFieldBooking
          ? data.customFieldBooking._id
          : "",
        huntDetail: data.customFieldBooking
          ? data.customFieldBooking.huntDetail
          : [],
        paying: data.customFieldBooking ? data.customFieldBooking.paying : true,
        note: data.note ? data.note : "",
        party: data.party ? data.party : [],
        partyLeader: data.partyLeader ? data.partyLeader : "",
        deposit: data.deposit ? data.deposit : [],
        totalDeposit: remainingBalance,
        tempTotalDeposit: remainingBalance,
        totalPrice: isTax
          ? isBasePriceTaxed
            ? totalPriceTaxed
            : totalPrice
          : totalPrice,
        basePrice: totalPrice,
        total: total,
        leaderVoid: data.party
          ? {
            label: `${data.partyLeader && data.partyLeader.firstName} ${data.partyLeader && data.partyLeader.lastName
              }`,
            value: data.party && data.partyLeader,
          }
          : "",
        partiesForDetailReservation: parties.map((data) => {
          return {
            label: `${data.firstName} ${data.lastName}`,
            value: data,
          };
        }),
        void: [],
        partyArray: toBePartyArray,
        currLeader: partyLeader._id ? partyLeader._id : "",
        subTotalFee: totalFee,
        isTax: isTax,
        tax: tax,
        hunterFee: hunterFee,
        fishermanFee: fishermanFee,
        observerFee: observerFee,
        wildlifeDonation: data.business.wildlifeDonation,
        juniorHunterFee: data.business.juniorHunterFee,
        bothFee: data.business.fishermanFee + data.business.hunterFee,
        isWildlifeDonationTaxed: isTax ? isWildlifeDonationTaxed : false,
        isObserverFeeTaxed: isTax ? isObserverFeeTaxed : false,
        isFishermanFeeTaxed: isTax ? isFishermanFeeTaxed : false,
        isHunterFeeTaxed: isTax ? isHunterFeeTaxed : false,
        isJuniorFeeTaxed: isTax ? isJuniorFeeTaxed : false,
        isAdditionalFeeTaxed: isTax ? isAdditionalFeeTaxed : false,
        isBasePriceTaxed: isTax ? isBasePriceTaxed : false,
        additionalFee: data.additionalFee ? String(data.additionalFee) : "",
        observers: observerList,
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        CommonStore.showErrorMessage(this.errorMessage);
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  putBookingForAdmin = async (
    values,
    bookingId,
    ownerId,
    businessId,
    history
  ) => {
    const bookingDTO = {
      quantity: values.quantity,
      status: values.status,
      timezone: momentTimezone.tz.guess(),
      guide: values.guide.value,
      isWildlifeDonationTaxed: this.editedData.isWildlifeDonationTaxed,
      isObserverFeeTaxed: this.editedData.isObserverFeeTaxed,
      isFishermanFeeTaxed: this.editedData.isFishermanFeeTaxed,
      isHunterFeeTaxed: this.editedData.isHunterFeeTaxed,
      isJuniorFeeTaxed: this.editedData.isJuniorFeeTaxed,
      isAdditionalFeeTaxed: this.editedData.isAdditionalFeeTaxed,
      isBasePriceTaxed: this.editedData.isBasePriceTaxed,
      additionalFee: this.editedData.additionalFee
        ? Number(this.editedData.additionalFee)
        : 0,
      //hunt: values.hunt,
    };
    this.isLoading = true;

    const { data, error } = await putBookingForAdmin(bookingDTO, bookingId);

    this.isLoading = false;

    if (data) {
      CommonStore.showSuccessMessage("Data updated!");
      tabStore.setActiveTab("Reservations");
      history.push(
        "/admin/businessowner/ownerdetail/" +
        ownerId +
        "/business/" +
        businessId
      );
      return true;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
      return false;
    }
  };
}

decorate(BookingStore, {
  editedData: observable,
  bookingArray: observable,
  isLoading: observable,
  totalCount: observable,
  pageSize: observable,
  currentPage: observable,
  searchValue: observable,
  deleteLoading: observable,
  calendarArray: observable,
  employeeEnum: observable,
  huntTypeEnum: observable,
  calendarLoading: observable,
  statusBook: observable,
  speciesArray: observable,
  wyomingArray: observable,
  updatePaymentLoading: observable,
  year: observable,
  years: observable,
  serviceYear: observable,

  getBookingById: action,
  getAllBooking: action,
});
export default new BookingStore();
