import axios from "axios";
// import { reactLocalStorage } from "reactjs-localstorage";

export async function PostImg(files, path) {
  try {
    const formData = new FormData();

    const filename = files[0].name.substring(0, files[0].name.lastIndexOf("."));
    const timestamp = new Date().getTime();

    formData.append("file", files[0]);
    formData.append("upload_preset", "ml_default"); // Replace the preset name with your own
    formData.append("api_key", "738833288531947"); // Replace API key with your own Cloudinary key
    formData.append("api_secret", "kmw3vPqyrdjCa14TeU4Q-qdb8B0");
    formData.append("public_id", `${timestamp}---${filename}`);
    formData.append("timestamp", (Date.now() / 1000) | 0);
    formData.append("folder", path);

    let uploaders = await axios
      .post(
        "https://api.cloudinary.com/v1_1/worksprings/image/upload",
        formData,
        {
          headers: { "X-Requested-With": "XMLHttpRequest" }
        }
      )
      .then(response => {
        return response;
      });

    if (uploaders.status !== 200) {
      return { error: uploaders.message };
    }
    return { data: uploaders.data };
  } catch (e) {
    return { error: e };
  }
}
