/* eslint-disable no-template-curly-in-string */
import commonStore from "../stores/CommonStore";
const Joi = require("joi-browser");
const joiToForms = require("joi-errors-for-forms").form;
const convertToForms = joiToForms({
  "any.required": () => '"${label}" Must be Filled.',
  "any.empty": () => '"${label}" Must be Filled.',
  "number.max": () => '"${label}" Exceeded the Limit.',
  "string.email": () => '"${label}" Not Valid.',
  "string.regex.base": () => '"${label}" Not Valid',
  "object.without": () => '"${label}" Must be filled',
  "any.invalid": () => '"${label}" Must be Filled.'

  // 'string.regex.base': (context) => {
  //   switch (context.pattern.toString()) {
  //     case /^[\sa-zA-Z0-9]{5,30}$/.toString():
  //       return i18n('"${key}" must consist of letters, digits or spaces.');
  //   }
  // }
});

export const truncateText = (s, maxLength) => {
  if (!s) {
    return "";
  }

  if (s.length < maxLength) {
    return s;
  }

  return s.substring(0, maxLength) + "...";
};

export const validateFormValuesUsingJoiSchema = ({ values, joiSchema }) => {
  const joiResult = Joi.validate(values, joiSchema, {
    convert: true,
    abortEarly: false,
    stripUnknown: true
  });

  if (!joiResult.error) {
    return {};
  } else if (joiResult.error) {
    commonStore.showErrorMessage("Add required fields below to continue");
    return convertToForms(joiResult.error);
  }
};

export const numberFormatter = n => {
  return new Intl.NumberFormat().format(n);
};

export const validateEditedData = ({ editedData, joiSchema }) => {
  const joiResult = Joi.validate(editedData, joiSchema, {
    convert: true,
    abortEarly: false,
    stripUnknown: true
  });
  if (!joiResult.error) {
    return null;
  }

  return convertToForms(joiResult.error);
};
