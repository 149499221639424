import React, { Component } from "react";

class Texts extends Component {
  render() {
    const { textType, weight } = this.props;

    return (
      <React.Fragment>
        {textType === "h1" && (
          <span
            className={weight === "medium" ? "h1med" : "h1reg"}
            style={this.props.style}
            onClick={this.props.onClick}
            value={this.props.value}
          >
            {this.props.text}
          </span>
        )}
        {textType === "h2" && (
          <span
            className={weight === "medium" ? "h2med" : "h2reg"}
            style={this.props.style}
            onClick={this.props.onClick}
            value={this.props.value}
          >
            {this.props.text}
          </span>
        )}
        {textType === "h3" && (
          <span
            className={weight === "medium" ? "h3med" : "h3reg"}
            style={this.props.style}
            onClick={this.props.onClick}
            value={this.props.value}
          >
            {this.props.text}
          </span>
        )}
        {textType === "h4" && (
          <span
            className={weight === "medium" ? "h4med" : "h4reg"}
            style={this.props.style}
            onClick={this.props.onClick}
            value={this.props.value}
          >
            {this.props.text}
          </span>
        )}
        {textType === "body1" && (
          <span
            className={weight === "medium" ? "body1med" : "body1reg"}
            style={this.props.style}
            onClick={this.props.onClick}
            value={this.props.value}
          >
            {this.props.text}
          </span>
        )}
        {textType === "body2" && (
          <span
            className={weight === "medium" ? "body2med" : "body2reg"}
            style={this.props.style}
            onClick={this.props.onClick}
            value={this.props.value}
          >
            {this.props.text}
          </span>
        )}
        {textType === "body3" && (
          <span
            className={weight === "medium" ? "body3med" : "body3reg"}
            style={this.props.style}
            onClick={this.props.onClick}
            value={this.props.value}
          >
            {this.props.text}
          </span>
        )}
        {textType === "caption" && (
          <span
            className={weight === "medium" ? "captionmed" : "captionreg"}
            style={this.props.style}
            onClick={this.props.onClick}
            required={this.props.required}
            value={this.props.value}
          >
            <span>{this.props.text}</span>
            <span
              style={{ color: "red" }}
              className={weight === "medium" ? "captionmed" : "captionreg"}
            >
              {this.props.required}
            </span>
          </span>
        )}
      </React.Fragment>
    );
  }
}

export default Texts;
