import { observable, action, decorate } from "mobx";
import { reactLocalStorage } from "reactjs-localstorage";
import commonStore from "./CommonStore";
import tabStore from "./TabStore";
import moment from "moment";
import React from "react";
import {
  getAllCustomer,
  getCustomerById,
  postCustomer,
  updateCustomer,
  deleteCustomer,
  getAllCustomerForAdmin,
  getCustomerByIdForAdmin,
  updateCustomerForAdmin,
  getMessage,
  sendMessage,
  sendMessageWhenEmpty,
  getCustomerNotification,
  offerSubscription,
} from "../services/CustomerService";
import csc from "country-state-city";

class CustomerStore {
  editedData = {};
  states = "";
  customerArray = [];
  messages = [];
  customerNotification = [];
  customerLength = 0;
  unreadData = 0;
  pageSize = 10;
  currentPage = 0;
  searchValue = "";
  countryId = "";
  stateId = "";
  cityId = "";
  deleteLoading = false;
  isLoading = false;
  sendLoading = false;
  valueTransformer = {
    customerName: (data) => {
      return (
        <span>
          {data.firstName} {data.lastName}
        </span>
      );
    },
    phoneNumber: (data) => {
      return <a href={`tel:${data.phoneNumber}`}>{data.phoneNumber}</a>;
    },
  };
  columns = [
    {
      name: "customerName",
      title: "Customer Name",
      valueTransformer: this.valueTransformer["customerName"],
    },
    { name: "email", title: "Email" },
    { name: "city", title: "City" },
    {
      name: "phoneNumber",
      title: "Phone Number",
      valueTransformer: this.valueTransformer["phoneNumber"],
    },
    { name: "customerId", title: "Customer ID" },
  ];

  columnWidths = [
    { columnName: "customerName", width: 200 },
    { columnName: "email", width: 200 },
    { columnName: "city", width: 200 },
    { columnName: "phoneNumber", width: 200 },
    { columnName: "customerId", width: 250 },
  ];

  setInitialData = () => {
    this.customerArray = [];
  };

  setPageLimit = (value) => {
    this.pageSize = value;
  };

  setCurrentPage = (currentPage) => {
    this.currentPage = currentPage;
    this.getAllCustomer();
  };

  setLoading = (value) => {
    this.isLoading = value;
  };

  initialCurrentPage = (value) => {
    this.currentPage = 0;
  };

  setSearchValue = (value) => {
    this.currentPage = 0;
    this.searchValue = value;
  };

  setEditedData = (editedData) => {
    this.editedData = {
      ...this.editedData,
      ...editedData,
    };
  };

  initialValue = () => {
    this.setEditedData({
      id: "",
      customerId: "",
      firstName: "",
      lastName: "",
      email: "",
      businessName: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      postalCode: "",
      country: "",
      phoneNumber: "",
      emergencyContactFirstName: "",
      emergencyContactLastName: "",
      emergencyContactPhoneNumber: "",
      note: "",
      licenseArray: [],
      voucherArray: [],
      idfgArray: [],
      licensePaperwork: [],
      voucherPaperwork: [],
      IDFG: [],
      isOffered: true,
      isGuest: false,
      subscription: false,
      alsNumber: "",
      licenseType: [],
      huntingLicense: "",
      dietaryRestriction: "",
      medicalRestriction: "",
      gender: "",
      height1: "",
      height2: "",
      weight: "",
      fitnessLevel: "",
      stateWildlifeAccount: "",
      stateWildlifeLogin: "",
      stateWildlifePassword: "",
      stateWildlifeLicenseNumber: "",
      legalToCarryFireArms: "",
      horsemanshipSkillLevel: "",
      huntingUnitNumber: "",
      customerType: "",
      isJuniorHunter: false
    });
  };

  setCountryId = (value) => {
    this.countryId = value;
  };

  setStateId = (value) => {
    this.states = value;
  };

  getAllCustomer = async () => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;

    let { data, error } = await getAllCustomer({
      businessId: businessId,
      pageLimit: this.pageSize,
      currentPage: this.currentPage + 1,
      search: this.searchValue,
    });

    if (data) {
      this.isLoading = false;
      this.customerArray = data.customers
        ? data.customers.map((data) => {
          const customerId = data.customer ? data.customer._id : "-";
          const firstName = data.firstName ? data.firstName : "-";
          const lastName = data.lastName ? data.lastName : "-";
          const email = data.customer ? data.customer.email : "-";
          const city = data.city ? data.city : "-";
          const phoneNumber = data.phoneNumber ? data.phoneNumber : "-";

          return {
            ...data,
            customerId,
            firstName,
            lastName,
            email,
            city,
            phoneNumber,
          };
        })
        : [];

      this.customerLength = data.totalData;
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View Customer's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getCustomerById = async (customerId) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;

    let { data, error } = await getCustomerById(businessId, customerId);
    this.isLoading = false;

    if (data) {
      if (data.country === "United States") {
        let country = csc.getAllCountries();
        let tempCountry = country.map((data) => {
          return data.name;
        });

        let tempCountryId = tempCountry.indexOf(data.country);

        this.countryId = country[tempCountryId].isoCode;

        let state = csc.getStatesOfCountry(String(this.countryId));
        let tempState = state.map((data) => {
          return data.name;
        });

        let tempId = "";
        if (!!data.state) {
          tempId = tempState.indexOf(data.state);
          this.stateId = state[tempId].isoCode;
        }
      }

      let accountStateId = "";

      if (data.stateWildlifeAccount) {
        let state = csc.getStatesOfCountry("US");
        let tempState = state.map((data) => {
          return data.name;
        });

        let tempId = "";
        tempId = tempState.indexOf(data.stateWildlifeAccount);
        accountStateId = state[tempId].isoCode;
      }

      this.setEditedData({
        customerId: data.customer && data.customer._id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.customer && data.customer.email,
        businessName: data.businessName,
        address1: data.address1,
        address2: data.address2,
        city: data.city ? data.city : "",
        state:
          data.country !== "United States"
            ? data.state
            : { label: data.state, value: String(this.stateId) },
        postalCode: data.postalCode,
        country: {
          label: data.country,
          value: String(this.countryId),
        },
        birthDate: data.birthDate ? data.birthDate : "",
        phoneNumber: data.phoneNumber,
        emergencyContactFirstName: data.emergencyContactFirstName,
        emergencyContactLastName: data.emergencyContactLastName,
        emergencyContactPhoneNumber: data.emergencyContactPhoneNumber,
        note: data.note,
        id: data._id,
        clientId: data.customFieldBusinessCustomer
          ? data.customFieldBusinessCustomer.clientID
          : "",
        tagNumber: data.customFieldBusinessCustomer
          ? data.customFieldBusinessCustomer.tagNumber
          : "",
        licenseType: data.customFieldBusinessCustomer
          ? data.customFieldBusinessCustomer.licenseType.map((data) => {
            return { value: Number(data), label: data };
          })
          : [],
        huntingLicense: data.customFieldBusinessCustomer
          ? data.customFieldBusinessCustomer.huntingLicense
          : "",
        species: data.interestSpecies ? data.interestSpecies : [],
        tripType: data.interestTripType ? data.interestTripType : "",
        season: data.interestSeason ? data.interestSeason : [],
        tripYear: data.interestTripYear ? data.interestTripYear : [],
        dietaryRestriction: data.dietaryRestriction
          ? data.dietaryRestriction
          : "",
        medicalRestriction: data.medicalRestriction
          ? data.medicalRestriction
          : "",
        licensePaperwork: data.licensePaperwork ? data.licensePaperwork : [],
        voucherPaperwork: data.voucherPaperwork ? data.voucherPaperwork : [],
        IDFG: data.IDFG ? data.IDFG : [],
        licenseArray: data.licensePaperwork ? data.licensePaperwork : [],
        voucherArray: data.voucherPaperwork ? data.voucherPaperwork : [],
        idfgArray: data.IDFG ? data.IDFG : [],
        subscription: data && data.subscription ? data.subscription : false,
        isOffered: data && data.isOffered ? data.customer.isOffered : false,
        isGuest: data.customer.isGuest ? data.customer.isGuest : false,
        alsNumber:
          data &&
            data.customFieldBusinessCustomer &&
            data.customFieldBusinessCustomer.alsNumber
            ? data.customFieldBusinessCustomer.alsNumber
            : "",
        gender: data.gender ? data.gender : "",
        height1: data.height1 ? data.height1 : "",
        height2: data.height2 ? data.height2 : "",
        weight: data.weight ? data.weight : "",
        fitnessLevel: data.fitnessLevel ? String(data.fitnessLevel) : "",
        stateWildlifeAccount: {
          label: data.stateWildlifeAccount,
          value: String(accountStateId),
        },
        stateWildlifeLogin: data.stateWildlifeLogin
          ? data.stateWildlifeLogin
          : "",
        stateWildlifePassword: data.stateWildlifePassword
          ? data.stateWildlifePassword
          : "",
        stateWildlifeLicenseNumber: data.stateWildlifeLicenseNumber
          ? data.stateWildlifeLicenseNumber
          : "",
        legalToCarryFireArms: data.legalToCarryFireArms
          ? data.legalToCarryFireArms
          : "",
        horsemanshipSkillLevel: data.horsemanshipSkillLevel
          ? data.horsemanshipSkillLevel
          : "",
        huntingUnitNumber: data.huntingUnitNumber ? data.huntingUnitNumber : "",
        customerType: data.customerType ? data.customerType : '',
        isJuniorHunter: data.isJuniorHunter ? data.isJuniorHunter : false,
        // isWildlifeDonation: data.isWildlifeDonation ? data.isWildlifeDonation : false
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View Customer's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  postCustomer = async (customer) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;

    const { data, error } = await postCustomer({
      businessId: businessId,
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      businessName: customer.businessName,
      address1: customer.address1,
      address2: customer.address2,
      city: customer.city ? customer.city : "",
      state: customer.state.label ? customer.state.label : customer.state,
      postalCode: customer.postalCode,
      country: customer.country.label,
      phoneNumber: customer.phoneNumber,
      birthDate: customer.birthDate ? customer.birthDate : "",
      emergencyContactFirstName: customer.emergencyContactFirstName,
      emergencyContactLastName: customer.emergencyContactLastName,
      emergencyContactPhoneNumber: customer.emergencyContactPhoneNumber,
      note: customer.note,
      clientId: customer.clientId,
      tagNumber: customer.tagNumber,
      licenseType: customer.licenseType,
      huntingLicense: customer.huntingLicense,
      dietaryRestriction: customer.dietaryRestriction,
      medicalRestriction: customer.medicalRestriction,
      licensePaperwork: customer.licensePaperwork,
      voucherPaperwork: customer.voucherPaperwork,
      IDFG: customer.IDFG,
      isOffered: customer.isOffered,
      isGuest: customer.isGuest,
      alsNumber: customer.alsNumber,
      gender: customer.gender ? customer.gender : "",
      height1: customer.height1 ? customer.height1 : "",
      height2: customer.height2 ? customer.height2 : "",
      weight: customer.weight ? customer.weight : "",
      fitnessLevel: customer.fitnessLevel ? Number(customer.fitnessLevel) : "",
      stateWildlifeAccount: customer.stateWildlifeAccount.label
        ? customer.stateWildlifeAccount.label
        : "",
      stateWildlifeLogin: customer.stateWildlifeLogin
        ? customer.stateWildlifeLogin
        : "",
      stateWildlifePassword: customer.stateWildlifePassword
        ? customer.stateWildlifePassword
        : "",
      stateWildlifeLicenseNumber: customer.stateWildlifeLicenseNumber
        ? customer.stateWildlifeLicenseNumber
        : "",
      legalToCarryFireArms: customer.legalToCarryFireArms
        ? customer.legalToCarryFireArms
        : "",
      horsemanshipSkillLevel: customer.horsemanshipSkillLevel
        ? customer.horsemanshipSkillLevel
        : "",
      huntingUnitNumber: customer.huntingUnitNumber
        ? customer.huntingUnitNumber
        : "",
      customerType: customer.customerType ? customer.customerType : '',
      isJuniorHunter: customer.isJuniorHunter ? customer.isJuniorHunter : false,
      // isWildlifeDonation: customer.isWildlifeDonation ? customer.isWildlifeDonation : false,
    });
    this.isLoading = false;

    if (data) {
      if (localStorage.getItem("newTab")) {
        if (customer.isOffered) {
          const { success } = await this.offerSubscription(
            data && data.businessCustomer && data.businessCustomer._id,
            { subscription: false }
          );

          if (success) {
            this.getAllCustomer();
            localStorage.removeItem("newTab");
            window.close();
          }
        } else {
          this.getAllCustomer();
          localStorage.removeItem("newTab");
          window.close();
        }
      } else if (!localStorage.getItem("newTab")) {
        if (customer.isOffered) {
          const { success } = await this.offerSubscription(
            data && data.businessCustomer && data.businessCustomer._id,
            { subscription: false }
          );

          if (success) {
            window.location.href = "/businesses/" + businessId + "/customer";
          }
        } else {
          window.location.href = "/businesses/" + businessId + "/customer";
        }
      }
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  updateCustomer = async (customer, customerId) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;

    const { data, error } = await updateCustomer({
      businessId: businessId,
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      businessName: customer.businessName,
      address1: customer.address1,
      address2: customer.address2,
      city: customer.city ? customer.city : "",
      state: customer.state.label ? customer.state.label : customer.state,
      postalCode: customer.postalCode,
      country: customer.country.label,
      phoneNumber: customer.phoneNumber,
      birthDate: customer.birthDate ? customer.birthDate : "",
      emergencyContactFirstName: customer.emergencyContactFirstName,
      emergencyContactLastName: customer.emergencyContactLastName,
      emergencyContactPhoneNumber: customer.emergencyContactPhoneNumber,
      note: customer.note,
      customerId: customerId,
      clientId: customer.clientId,
      tagNumber: customer.tagNumber,
      licenseType: customer.licenseType,
      huntingLicense: customer.huntingLicense,
      dietaryRestriction: customer.dietaryRestriction,
      medicalRestriction: customer.medicalRestriction,
      licensePaperwork: customer.licensePaperwork,
      voucherPaperwork: customer.voucherPaperwork,
      IDFG: customer.IDFG,
      isOffered: customer.isOffered,
      isGuest: customer.isGuest,
      alsNumber: customer.alsNumber,
      gender: customer.gender ? customer.gender : "",
      height1: customer.height1 ? customer.height1 : "",
      height2: customer.height2 ? customer.height2 : "",
      weight: customer.weight ? customer.weight : "",
      fitnessLevel: customer.fitnessLevel ? Number(customer.fitnessLevel) : "",
      stateWildlifeAccount: customer.stateWildlifeAccount.label
        ? customer.stateWildlifeAccount.label
        : "",
      stateWildlifeLogin: customer.stateWildlifeLogin
        ? customer.stateWildlifeLogin
        : "",
      stateWildlifePassword: customer.stateWildlifePassword
        ? customer.stateWildlifePassword
        : "",
      stateWildlifeLicenseNumber: customer.stateWildlifeLicenseNumber
        ? customer.stateWildlifeLicenseNumber
        : "",
      legalToCarryFireArms: customer.legalToCarryFireArms
        ? customer.legalToCarryFireArms
        : "",
      horsemanshipSkillLevel: customer.horsemanshipSkillLevel
        ? customer.horsemanshipSkillLevel
        : "",
      huntingUnitNumber: customer.huntingUnitNumber
        ? customer.huntingUnitNumber
        : "",
      customerType: customer.customerType ? customer.customerType : '',
      isJuniorHunter: customer.isJuniorHunter ? customer.isJuniorHunter : false,
      // isWildlifeDonation: customer.isWildlifeDonation ? customer.isWildlifeDonation : false,
    });
    this.isLoading = false;

    if (data) {
      window.location.href = "/businesses/" + businessId + "/customer";
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  deleteCustomer = async (customerId) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    const { data, error } = await deleteCustomer(businessId, customerId);

    if (!data) {
      window.location.href = "/businesses/" + businessId + "/customer";
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  deleteCustomer = async (customerId) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.deleteLoading = true;
    const { data, error } = await deleteCustomer(businessId, customerId);
    this.deleteLoading = false;

    if (!data) {
      window.location.href = "/businesses/" + businessId + "/customer";
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getMessage = async (customerId) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    const today = new Date();
    today.setHours(0, 0, 0);
    this.isLoading = true;

    let { data, error } = await getMessage(businessId, customerId);

    if (data) {
      this.isLoading = false;
      // this.messages.push(data.data);

      this.messages = data
        ? data.map((data) => {
          const author = data.author ? data.author : "";
          const body = data.body ? data.body : "";
          const dateCreated = !data.dateCreated
            ? ""
            : moment(data.dateCreated).format("DD-MM-yyyy HH:mm") <
              moment(today).format("DD-MM-yyyy HH:mm")
              ? moment(data.dateCreated).format("ddd DD")
              : moment(data.dateCreated).format("HH:mm");

          return {
            author,
            body,
            dateCreated,
          };
        })
        : [];
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View Customer's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  sendMessage = async (value, customerId) => {
    this.sendLoading = true;
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    this.messages.push({
      author: JSON.parse(localStorage.getItem("currentBusinessName")),
      body: value.message,
      dateCreated: "",
    });

    const { data, error } = await sendMessage(
      value,
      JSON.parse(businessId),
      customerId
    );

    this.sendLoading = false;

    if (data) {
      this.getMessage(customerId);
      // this.messages[this.messages.length].dateCreated = data.dateCreated;

      // const dateCreated = moment(data.dateCreate).format("HH:mm");
      // this.messages.push({ ...data, dateCreated });
      // this.messages.push(data)
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  sendMessageWhenEmpty = async (value, customerId) => {
    this.sendLoading = true;
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    this.messages.push({
      author: JSON.parse(localStorage.getItem("currentBusinessName")),
      body: value.message,
      dateCreated: "",
    });

    const { data, error } = await sendMessageWhenEmpty(
      value,
      JSON.parse(businessId),
      customerId
    );

    this.sendLoading = false;

    if (data) {
      this.getMessage(customerId);
      // this.messages[this.messages.length].dateCreated = data.dateCreated;

      // const dateCreated = moment(data.dateCreate).format("HH:mm");
      // this.messages.push({ ...data, dateCreated });
      // this.messages.push(data)
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getCustomerNotification = async (customerId) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    const { data, error } = await getCustomerNotification(
      businessId,
      customerId
    );

    if (data) {
      this.customerNotification = data;
      this.unreadData = 0;

      data.find((data) => {
        if (!data.read) {
          this.unreadData = this.unreadData + 1;
        }
        return true;
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  offerSubscription = async (customerId, value) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const { data, error } = await offerSubscription(
      businessId,
      customerId,
      value
    );
    this.isLoading = false;

    if (data) {
      return { success: data };
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  //-----------------------------------------------------------Super Admin--------------------------------------------------------------

  adminCustomerArray = [];
  adminCustomerColumn = [
    {
      name: "name",
      title: "Customer Name",
      valueTransformer: this.valueTransformer["customerName"],
    },
    {
      name: "email",
      title: "Email",
    },
    {
      name: "city",
      title: "City",
    },
    {
      name: "phoneNumber",
      title: "Phone Numebr",
    },
    {
      name: "customerId",
      title: "Customer ID",
    },
  ];
  adminCustomerColumnWidths = [
    {
      columnName: "name",
      width: 200,
    },
    {
      columnName: "email",
      width: 200,
    },
    {
      columnName: "city",
      width: 200,
    },
    {
      columnName: "phoneNumber",
      width: 200,
    },
    {
      columnName: "customerId",
      width: 200,
    },
  ];

  setCurrentAdminPage = (currentPage) => {
    this.currentPage = currentPage;
    this.getAllCustomerForAdmin(this.editedData.businessId);
  };

  getAllCustomerForAdmin = async (id) => {
    this.isLoading = true;
    const { data, error } = await getAllCustomerForAdmin({
      businessId: id,
      currentPage: this.currentPage + 1,
      pageLimit: this.pageSize,
      search: this.searchValue,
    });
    this.isLoading = false;

    if (data) {
      this.adminCustomerArray =
        data.customers &&
        data.customers.map((data) => {
          const customerId = data.customer ? data.customer._id : "-";
          const firstName = data.firstName ? data.firstName : "-";
          const lastName = data.lastName ? data.lastName : "-";
          const email = data.customer ? data.customer.email : "-";
          const city = data.city ? data.city : "-";
          const phoneNumber = data.phoneNumber ? data.phoneNumber : "-";

          return {
            ...data,
            customerId,
            firstName,
            lastName,
            email,
            city,
            phoneNumber,
          };
        });
      this.customerLength = data.totalData;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getCustomerByIdForAdmin = async (customerId) => {
    this.isLoading = true;

    let { data, error } = await getCustomerByIdForAdmin(customerId);
    this.isLoading = false;

    if (data) {
      if (data.country === "United States") {
        let country = csc.getAllCountries();
        let tempCountry = country.map((data) => {
          return data.name;
        });

        let tempCountryId = tempCountry.indexOf(data.country);

        this.countryId = country[tempCountryId].isoCode;

        let state = csc.getStatesOfCountry(String(this.countryId));
        let tempState = state.map((data) => {
          return data.name;
        });

        let tempId = "";
        if (!!data.state) {
          tempId = tempState.indexOf(data.state);
          this.stateId = state[tempId].isoCode;
        }

        // let city = csc.getCitiesOfState(String(this.stateId));
        // let tempCity = city.map(data => {
        //   return data.name;
        // });
        // let tempCityId = tempCity.indexOf(String(data.city));

        // this.cityId = city[tempCityId].id;
      }

      let accountStateId = "";

      if (data.stateWildlifeAccount) {
        let state = csc.getStatesOfCountry("US");
        let tempState = state.map((data) => {
          return data.name;
        });

        let tempId = "";
        tempId = tempState.indexOf(data.stateWildlifeAccount);
        accountStateId = state[tempId].isoCode;
      }

      this.setEditedData({
        customerId: data._id,
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.customer && data.customer.email,
        businessName: data.businessName,
        address1: data.address1,
        address2: data.address2,
        city: data.city ? data.city : "",
        state:
          data.country !== "United States"
            ? data.state
            : { label: data.state, value: String(this.stateId) },
        postalCode: data.postalCode,
        country: {
          label: data.country,
          value: String(this.countryId),
        },
        phoneNumber: data.phoneNumber,
        emergencyContactFirstName: data.emergencyContactFirstName,
        emergencyContactLastName: data.emergencyContactLastName,
        emergencyContactPhoneNumber: data.emergencyContactPhoneNumber,
        note: data.note,
        id: data._id,
        gender: data.gender ? data.gender : "",
        height1: data.height1 ? data.height1 : "",
        height2: data.height2 ? data.height2 : "",
        weight: data.weight ? data.weight : "",
        fitnessLevel: data.fitnessLevel ? String(data.fitnessLevel) : "",
        stateWildlifeAccount: {
          label: data.stateWildlifeAccount,
          value: String(accountStateId),
        },
        stateWildlifeLogin: data.stateWildlifeLogin
          ? data.stateWildlifeLogin
          : "",
        stateWildlifePassword: data.stateWildlifePassword
          ? data.stateWildlifePassword
          : "",
        stateWildlifeLicenseNumber: data.stateWildlifeLicenseNumber
          ? data.stateWildlifeLicenseNumber
          : "",
        legalToCarryFireArms: data.legalToCarryFireArms
          ? data.legalToCarryFireArms
          : "",
        horsemanshipSkillLevel: data.horsemanshipSkillLevel
          ? data.horsemanshipSkillLevel
          : "",
        huntingUnitNumber: data.huntingUnitNumber ? data.huntingUnitNumber : "",
        isJuniorHunter: data.isJuniorHunter ? data.isJuniorHunter : false,
        // isWildlifeDonation: data.isWildlifeDonation ? data.isWildlifeDonation : false
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View Customer's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  updateCustomerForAdmin = async ({
    customer,
    customerId,
    history,
    ownerId,
    businessId,
  }) => {
    this.isLoading = true;

    const { data, error } = await updateCustomerForAdmin({
      firstName: customer.firstName,
      lastName: customer.lastName,
      email: customer.email,
      businessName: customer.businessName,
      address1: customer.address1,
      address2: customer.address2,
      city: customer.city ? customer.city : "",
      state: customer.state.label ? customer.state.label : customer.state,
      postalCode: customer.postalCode,
      country: customer.country.label,
      phoneNumber: customer.phoneNumber,
      emergencyContactFirstName: customer.emergencyContactFirstName,
      emergencyContactLastName: customer.emergencyContactLastName,
      emergencyContactPhoneNumber: customer.emergencyContactPhoneNumber,
      note: customer.note,
      customerId: customerId,
      gender: customer.gender ? customer.gender : "",
      height1: customer.height1 ? customer.height1 : "",
      height2: customer.height2 ? customer.height2 : "",
      weight: customer.weight ? customer.weight : "",
      fitnessLevel: customer.fitnessLevel ? Number(customer.fitnessLevel) : "",
      stateWildlifeAccount: customer.stateWildlifeAccount.label
        ? customer.stateWildlifeAccount.label
        : "",
      stateWildlifeLogin: customer.stateWildlifeLogin
        ? customer.stateWildlifeLogin
        : "",
      stateWildlifePassword: customer.stateWildlifePassword
        ? customer.stateWildlifePassword
        : "",
      stateWildlifeLicenseNumber: customer.stateWildlifeLicenseNumber
        ? customer.stateWildlifeLicenseNumber
        : "",
      legalToCarryFireArms: customer.legalToCarryFireArms
        ? customer.legalToCarryFireArms
        : "",
      horsemanshipSkillLevel: customer.horsemanshipSkillLevel
        ? customer.horsemanshipSkillLevel
        : "",
      huntingUnitNumber: customer.huntingUnitNumber
        ? customer.huntingUnitNumber
        : "",
      customerType: customer.customerType ? customer.customerType : '',
      isJuniorHunter: customer.isJuniorHunter ? customer.isJuniorHunter : false,
      // isWildlifeDonation: customer.isWildlifeDonation ? customer.isWildlifeDonation : false,
    });
    this.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Update Success");
      tabStore.setActiveTab("Customers");
      history.push(
        "/admin/businessowner/ownerdetail/" +
        ownerId +
        "/business/" +
        businessId
      );
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };
}
decorate(CustomerStore, {
  editedData: observable,
  customerArray: observable,
  customerLength: observable,
  currentPage: observable,
  countryId: observable,
  stateId: observable,
  cityId: observable,
  searchValue: observable,
  isLoading: observable,
  deleteLoading: observable,
  adminCustomerArray: observable,
  messages: observable,
  sendLoading: observable,
  customerNotification: observable,
  unreadData: observable,
  pageSize: observable,

  setCountryId: action,
  getAllCustomer: action,
  updateCustomerForAdmin: action,
});
export default new CustomerStore();
