import { observable, action, decorate } from "mobx";
import {
  getAllEmployee,
  getEmployeeId,
  postEmployee,
  putUpdateEmployee,
  postEmployeeRole,
  putUpdateEmployeeRole,
  getEmployeeRole,
  getEmployeeRoleById,
  deleteEmployee,
  deleteEmployeeRole,
  changePassword,
  getAllEmployeeForAdmin,
  getEmployeeIdForAdmin,
  getEmployeeRoleForAdmin,
  putUpdateEmployeeForAdmin,
  getAllRolesForAdmin,
  getSingleRolesForAdmin,
  updateRolesForAdmin
} from "../services/EmployeeService";
import moment from "moment";
import {
  getEmployeeLicense,
  getEmployeeLicenseById,
  createLicense,
  updateLicense,
  deleteLicense
} from "../services/LicenseService";
import {
  getEmployeeCPR,
  getEmployeeCPRById,
  createCPR,
  updateCPR,
  deleteCPR
} from "../services/CertificationService";
import changePasswordStore from "./ChangePasswordStore"
import tabStore from "./TabStore";
import { reactLocalStorage } from "reactjs-localstorage";
import commonStore from "./CommonStore";
import React from "react";
import warning from "./DeleteWarningStore";
import errorPop from "./ErrorMessageModalStore";
import csc from "country-state-city";
import CommonStore from "./CommonStore";

class EmployeeStore {
  editedData = {};
  roleArray = [];
  dataArray = [];
  pageSize = 50;
  currentPage = 0;
  employeeLength = 0;
  searchValue = "";
  errorMessage = "";
  isLoading = false;
  deleteLoading = false;
  modalErrorMessage = "";
  valueTransformer = {
    employeeName: data => {
      return (
        <span>
          {data.firstName} {data.lastName}
        </span>
      );
    },
    status: data => {
      if (data.isActive === true) {
        return <span className="activeEmp">Active</span>;
      } else if (data.isActive === false) {
        return <span className="inactiveEmp">Inactive</span>;
      }
    },
    phoneNumber: data => {
      return <a href={`tel:${data.phoneNumber}`}>{data.phoneNumber}</a>;
    }
  };
  columns = [
    {
      name: "employeeName",
      title: "Employee Name",
      valueTransformer: this.valueTransformer["employeeName"]
    },
    {
      name: "email",
      title: "Email Address"
    },
    {
      name: "phoneNumber",
      title: "Phone Number",
      valueTransformer: this.valueTransformer["phoneNumber"]
    },
    {
      name: "role",
      title: "Role"
    },
    {
      name: "status",
      title: "Status",
      valueTransformer: this.valueTransformer["status"]
    },
    {
      name: "employeeID",
      title: "Employee ID"
    }
  ];
  columnWidths = [
    { columnName: "employeeName", width: 200 },
    { columnName: "email", width: 190 },
    { columnName: "phoneNumber", width: 190 },
    { columnName: "role", width: 190 },
    { columnName: "status", width: 190 },
    { columnName: "employeeID", width: 190 }
  ];

  columnInput = [
    { name: "content", title: "Page" },
    { name: "create", title: "Create" },
    { name: "view", title: "View" },
    { name: "modify", title: "Modify" },
    { name: "delete", title: "Delete" }
  ];

  columnRole = [
    { name: "roleId", title: "Role ID" },
    { name: "name", title: "Role Name" }
  ];

  columnRoleWidths = [
    { columnName: "roleId", width: 300 },
    { columnName: "name", width: 300 }
  ];

  employeeLicenseArray = [];
  employeeLicenseColumns = [
    {
      name: "number",
      title: "License Number"
    },
    {
      name: "state",
      title: "State of Registration"
    },
    {
      name: "date",
      title: "Registration Date"
    }
  ];
  employeeLicenseColumnWidths = [
    {
      columnName: "number",
      width: 200
    },
    {
      columnName: "state",
      width: 200
    },
    {
      columnName: "date",
      width: 200
    }
  ];

  employeeCPRArray = [];
  employeeCPRColumns = [
    {
      name: "number",
      title: "CPR/First-Aid Certification Number"
    },
    {
      name: "state",
      title: "State of Certification"
    },
    {
      name: "expiredDate",
      title: "Certification Expiration"
    },
    {
      name: "organization",
      title: "Certification Organization"
    }
  ];
  employeeCPRColumnWidths = [
    {
      columnName: "number",
      width: 200
    },
    {
      columnName: "state",
      width: 200
    },
    {
      columnName: "expiredDate",
      width: 200
    },
    {
      columnName: "organization",
      width: 200
    }
  ];

  setInitialData = () => {
    this.dataArray = [];
  };

  setEditedData = editedData => {
    this.editedData = {
      ...this.editedData,
      ...editedData
    };
  };

  setInitialData = () => {
    this.contactArray = [];
  };

  setPageLimit = value => {
    this.pageSize = value;
  };

  setDeleteLoading = value => {
    this.deleteLoading = value;
  };

  setSearchValue = value => {
    this.currentPage = 0;
    this.searchValue = value;
  };

  setCurrentPage = value => {
    this.currentPage = value;
    this.getAllEmployee();
  };

  initialCurrentPage = value => {
    this.currentPage = 0;
  };

  setErrorMessage = value => {
    this.errorMessage = value;
  };

  setInitialValue = () => {
    this.setEditedData({
      name: "",
      dashboardCreate: false,
      dashboardView: false,
      dashboardModify: false,
      dashboardDelete: false,
      huntTypeCreate: false,
      huntTypeView: false,
      huntTypeModify: false,
      huntTypeDelete: false,
      huntCreate: false,
      huntView: false,
      huntModify: false,
      huntDelete: false,
      employeeCreate: false,
      employeeView: false,
      employeeModify: false,
      employeeDelete: false,
      roleCreate: false,
      roleView: false,
      roleModify: false,
      roleDelete: false,
      bookingCreate: false,
      bookingView: false,
      bookingModify: false,
      bookingDelete: false,
      customerCreate: false,
      customerView: false,
      customerModify: false,
      customerDelete: false,
      reportCreate: false,
      reportView: false,
      reportModify: false,
      reportDelete: false,
      contactCreate: false,
      contactView: false,
      contactModify: false,
      contactDelete: false
    });
  };

  setToggleRoleCheckbox = checkedData => {
    this.role = {
      ...this.role,
      ...checkedData
    };
  };

  getAllEmployee = async () => {
    this.isLoading = true;
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    const { data, error } = await getAllEmployee({
      businessId: JSON.parse(businessId),
      pageLimit: this.pageSize,
      currentPage: this.currentPage + 1,
      search: this.searchValue
    });
    if (data) {
      this.isLoading = false;
      this.dataArray = data.employees
        ? data.employees.map(data => {
          const employeeID = data._id ? data._id : "";
          const employeeFirstName = data.firstName;
          const employeeLastName = data.lastName;
          const email = data.email ? data.email : "";
          const phoneNumber = data.phoneNumber ? data.phoneNumber : "";
          const role = data.role
            ? data.role.name
            : data.isOwner === true
              ? "Business Owner"
              : "-";
          const status = data.role ? data.role.isActive : "";

          return {
            ...data,
            employeeID,
            employeeFirstName,
            employeeLastName,
            email,
            phoneNumber,
            role,
            status
          };
        })
        : [];

      this.employeeLength = data.totalData;
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getEmployeeById = async employeeId => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const { data, error } = await getEmployeeId(employeeId, businessId);
    this.isLoading = false;

    if (data) {
      this.setEditedData({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        role: data.role && data.role._id,
        password: data.password,
        isActive: data.isActive,
        isOwner: data.isOwner
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  postAddEmployee = async (employee, id) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const { data, error } = await postEmployee(employee, businessId);
    if (data) {
      this.isLoading = false;
      window.location.href = "/businesses/" + id + "/employees";
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  putUpdateEmployee = async (employee, employeeId) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;

    const employeeData = {
      firstName: employee.firstName,
      lastName: employee.lastName,
      email: employee.email,
      phoneNumber: employee.phoneNumber,
      role: employee.role,
      isActive: employee.isActive
    };

    const { data, error } = await putUpdateEmployee(
      employeeData,
      businessId,
      employeeId
    );

    if (data) {
      this.isLoading = false;
      window.location.href = "/businesses/" + businessId + "/employees";
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  deleteEmployee = async employeeId => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    const { data, error } = await deleteEmployee(businessId, employeeId);
    this.deleteLoading = false;
    if (data) {
      window.location.href = "/businesses/" + businessId + "/employees";
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getEmployeeRole = async () => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const { data, error } = await getEmployeeRole(businessId);
    this.isLoading = false;
    if (data) {
      this.roleArray = data.map(data => {
        const name = data.name ? data.name : "-";
        const roleId = data._id ? data._id : "-";
        const value = data._id ? data._id : "-";
        return {
          ...data,
          name,
          roleId,
          value
        };
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View Role's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getEmployeeRoleById = async roleId => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const { data, error } = await getEmployeeRoleById(businessId, roleId);
    this.isLoading = false;

    if (data) {
      this.setEditedData({
        name: data.name ? data.name : "",
        dashboardCreate: data.permission.dashboard
          ? data.permission.dashboard.create
          : false,
        dashboardView: data.permission.dashboard
          ? data.permission.dashboard.read
          : false,
        dashboardModify: data.permission.dashboard
          ? data.permission.dashboard.update
          : false,
        dashboardDelete: data.permission.dashboard
          ? data.permission.dashboard.delete
          : false,
        huntTypeCreate: data.permission.huntType
          ? data.permission.huntType.create
          : false,
        huntTypeView: data.permission.huntType
          ? data.permission.huntType.read
          : false,
        huntTypeModify: data.permission.huntType
          ? data.permission.huntType.update
          : false,
        huntTypeDelete: data.permission.huntType
          ? data.permission.huntType.delete
          : false,
        huntCreate: data.permission.hunt ? data.permission.hunt.create : false,
        huntView: data.permission.hunt ? data.permission.hunt.read : false,
        huntModify: data.permission.hunt ? data.permission.hunt.update : false,
        huntDelete: data.permission.hunt ? data.permission.hunt.delete : false,
        employeeCreate: data.permission.employee
          ? data.permission.employee.create
          : false,
        employeeView: data.permission.employee
          ? data.permission.employee.read
          : false,
        employeeModify: data.permission.employee
          ? data.permission.employee.update
          : false,
        employeeDelete: data.permission.employee
          ? data.permission.employee.delete
          : false,
        roleCreate: data.permission.role ? data.permission.role.create : false,
        roleView: data.permission.role ? data.permission.role.read : false,
        roleModify: data.permission.role ? data.permission.role.update : false,
        roleDelete: data.permission.role ? data.permission.role.delete : false,
        bookingCreate: data.permission.booking
          ? data.permission.booking.create
          : false,
        bookingView: data.permission.booking
          ? data.permission.booking.read
          : false,
        bookingModify: data.permission.booking
          ? data.permission.booking.update
          : false,
        bookingDelete: data.permission.booking
          ? data.permission.booking.delete
          : false,
        customerCreate: data.permission.customer
          ? data.permission.customer.create
          : false,
        customerView: data.permission.customer
          ? data.permission.customer.read
          : false,
        customerModify: data.permission.customer
          ? data.permission.customer.update
          : false,
        customerDelete: data.permission.customer
          ? data.permission.customer.delete
          : false,
        reportCreate: data.permission.report
          ? data.permission.report.create
          : false,
        reportView: data.permission.report
          ? data.permission.report.read
          : false,
        reportModify: data.permission.report
          ? data.permission.report.update
          : false,
        reportDelete: data.permission.report
          ? data.permission.report.delete
          : false,
        contactCreate: data.permission.contact
          ? data.permission.contact.create
          : false,
        contactView: data.permission.contact
          ? data.permission.contact.read
          : false,
        contactModify: data.permission.contact
          ? data.permission.contact.update
          : false,
        contactDelete: data.permission.contact
          ? data.permission.contact.delete
          : false
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View Role's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  postAddEmployeeRole = async id => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const {
      name,
      dashboardCreate,
      dashboardView,
      dashboardModify,
      dashboardDelete,
      huntTypeCreate,
      huntTypeView,
      huntTypeModify,
      huntTypeDelete,
      huntCreate,
      huntView,
      huntModify,
      huntDelete,
      employeeCreate,
      employeeView,
      employeeModify,
      employeeDelete,
      roleCreate,
      roleView,
      roleModify,
      roleDelete,
      bookingCreate,
      bookingView,
      bookingModify,
      bookingDelete,
      customerCreate,
      customerView,
      customerModify,
      customerDelete,
      reportCreate,
      reportView,
      reportModify,
      reportDelete,
      contactCreate,
      contactView,
      contactModify,
      contactDelete
    } = this.editedData;

    const permission = {
      dashboard: {
        create: dashboardCreate,
        update: dashboardModify,
        delete: dashboardDelete,
        read: dashboardView
      },
      customer: {
        create: customerCreate,
        update: customerModify,
        delete: customerDelete,
        read: customerView
      },
      booking: {
        create: bookingCreate,
        update: bookingModify,
        delete: bookingDelete,
        read: bookingView
      },
      employee: {
        create: employeeCreate,
        update: employeeModify,
        delete: employeeDelete,
        read: employeeView
      },
      huntType: {
        create: huntTypeCreate,
        update: huntTypeModify,
        delete: huntTypeDelete,
        read: huntTypeView
      },
      report: {
        create: reportCreate,
        update: reportModify,
        delete: reportDelete,
        read: reportView
      },
      hunt: {
        create: huntCreate,
        update: huntModify,
        delete: huntDelete,
        read: huntView
      },
      role: {
        create: roleCreate,
        update: roleModify,
        delete: roleDelete,
        read: roleView
      },
      contact: {
        create: contactCreate,
        update: contactModify,
        delete: contactDelete,
        read: contactView
      }
    };

    const { data, error } = await postEmployeeRole(
      name,
      permission,
      businessId
    );
    this.isLoading = false;
    if (data) {
      commonStore.showSuccessMessage("Role Added");
      window.location.href = "/businesses/" + id + "/employees/employeeRole";
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  putUpdateEmployeeRole = async roleId => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const {
      name,
      dashboardCreate,
      dashboardView,
      dashboardModify,
      dashboardDelete,
      huntTypeCreate,
      huntTypeView,
      huntTypeModify,
      huntTypeDelete,
      huntCreate,
      huntView,
      huntModify,
      huntDelete,
      employeeCreate,
      employeeView,
      employeeModify,
      employeeDelete,
      roleCreate,
      roleView,
      roleModify,
      roleDelete,
      bookingCreate,
      bookingView,
      bookingModify,
      bookingDelete,
      customerCreate,
      customerView,
      customerModify,
      customerDelete,
      reportCreate,
      reportView,
      reportModify,
      reportDelete,
      contactCreate,
      contactView,
      contactModify,
      contactDelete
    } = this.editedData;

    const permission = {
      dashboard: {
        create: dashboardCreate,
        update: dashboardModify,
        delete: dashboardDelete,
        read: dashboardView
      },
      customer: {
        create: customerCreate,
        update: customerModify,
        delete: customerDelete,
        read: customerView
      },
      booking: {
        create: bookingCreate,
        update: bookingModify,
        delete: bookingDelete,
        read: bookingView
      },
      employee: {
        create: employeeCreate,
        update: employeeModify,
        delete: employeeDelete,
        read: employeeView
      },
      huntType: {
        create: huntTypeCreate,
        update: huntTypeModify,
        delete: huntTypeDelete,
        read: huntTypeView
      },
      report: {
        create: reportCreate,
        update: reportModify,
        delete: reportDelete,
        read: reportView
      },
      hunt: {
        create: huntCreate,
        update: huntModify,
        delete: huntDelete,
        read: huntView
      },
      role: {
        create: roleCreate,
        update: roleModify,
        delete: roleDelete,
        read: roleView
      },
      contact: {
        create: contactCreate,
        update: contactModify,
        delete: contactDelete,
        read: contactView
      }
    };

    const { data, error } = await putUpdateEmployeeRole(
      name,
      permission,
      businessId,
      roleId
    );
    this.isLoading = false;
    if (data) {
      window.location.href =
        "/businesses/" + businessId + "/employees/employeeRole";
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  deleteEmployeeRole = async roleId => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.deleteLoading = true;
    const { data, error } = await deleteEmployeeRole(businessId, roleId);
    this.deleteLoading = false;

    if (data) {
      window.location.href =
        "/businesses/" + businessId + "/employees/employeeRole";
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (
          this.errorMessage ===
          "Please ensure that this role is not assigned to any employee."
        ) {
          this.modalErrorMessage =
            "Please ensure that this role is not assigned to any employee.";
          warning.hideWarning();
          errorPop.openModal();
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  changePassword = async (employeeId, password) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    const { data, error } = await changePassword(
      businessId,
      employeeId,
      password
    );

    if (data) {
      await new Promise((resolve, reject) => {
        commonStore.showSuccessMessage(
          "Password has been successfully changed "
        );
        setTimeout(resolve, 7000);
      });
      window.location.href =
        "/businesses/" + businessId + "/employees/" + employeeId;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getEmployeeLicense = async employeeId => {
    this.isLoading = true;
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    const { data, error } = await getEmployeeLicense({
      businessId: JSON.parse(businessId),
      employeeId: employeeId
    });
    if (data) {
      this.isLoading = false;
      this.employeeLicenseArray = data.map(data => {
        const number = data.number ? data.number : "";
        const date = data.expiredDate
          ? moment(data.expiredDate).format("MMM DD, YYYY")
          : "";
        const state = data.state ? data.state : "";

        return {
          ...data,
          number,
          date,
          state
        };
      });
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  createLicense = async ({ value, history, employeeId }) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const { data, error } = await createLicense(value, businessId);
    if (data) {
      this.isLoading = false;
      CommonStore.showSuccessMessage("License Added");
      let status = JSON.parse(reactLocalStorage.get("account"));

      let temp = JSON.parse(status.isOwner === false ? localStorage.getItem("employeeLicense") : localStorage.getItem("license"));
      temp.push(data);

      if (status.isOwner === false) {
        if (status._id === employeeId) {

          localStorage.setItem("employeeLicense", JSON.stringify(temp));
        }
      } else {
        localStorage.setItem("license", JSON.stringify(temp));
      }

      // localStorage.setItem(status.isOwner === false ? "employeeLicense" : "license", JSON.stringify(temp));

      history.push("/businesses/" + businessId + "/employees/" + employeeId);
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  updateLicense = async ({ value, history, employeeId, licenseId }) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const { data, error } = await updateLicense(value, businessId, licenseId);
    if (data) {
      this.isLoading = false;
      CommonStore.showSuccessMessage("License Updated");
      let status = JSON.parse(reactLocalStorage.get("account"));

      let temp = JSON.parse(status.isOwner === false ? localStorage.getItem("employeeLicense") : localStorage.getItem("license"));
      for (let i = 0; i < temp.length; i++) {
        if (temp[i]._id === data._id) {
          temp[i].state = data.state;
        }
      }

      if (status.isOwner === false) {
        if (status._id === employeeId) {

          localStorage.setItem("employeeLicense", JSON.stringify(temp));
        }
      } else {
        localStorage.setItem("license", JSON.stringify(temp));
      }

      history.push("/businesses/" + businessId + "/employees/" + employeeId);
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  deleteLicense = async ({ history, employeeId, licenseId }) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const { data, error } = await deleteLicense(businessId, licenseId);
    if (data) {
      this.isLoading = false;
      CommonStore.showSuccessMessage("License Deleted");
      let status = JSON.parse(reactLocalStorage.get("account"));

      let temp = JSON.parse(status.isOwner === false ? localStorage.getItem("employeeLicense") : localStorage.getItem("license"));
      let temp2 = [];
      // eslint-disable-next-line array-callback-return
      temp.filter(tempData => {
        if (tempData._id !== data._id) {
          temp2.push(tempData);
        }
      });

      if (status.isOwner === false) {
        if (status._id === employeeId) {

          localStorage.setItem("employeeLicense", JSON.stringify(temp));
        }
      } else {
        localStorage.setItem("license", JSON.stringify(temp));
      }

      history.push("/businesses/" + businessId + "/employees/" + employeeId);
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getEmployeeLicenseById = async licenseId => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const { data, error } = await getEmployeeLicenseById({
      businessId: businessId,
      licenseId: licenseId
    });
    this.isLoading = false;

    if (data) {
      let state = csc.getStatesOfCountry("US");

      let tempState = state.map(data => {
        return data.name;
      });

      let tempId = tempState.indexOf(data.state);

      let stateId = state[tempId].isoCode;

      this.setEditedData({
        number: data.number ? data.number : "",
        expiredDate: data.expiredDate ? data.expiredDate : "",
        state: { label: data.state, value: String(stateId) }
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getEmployeeCPR = async employeeId => {
    this.isLoading = true;
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    const { data, error } = await getEmployeeCPR({
      businessId: JSON.parse(businessId),
      employeeId: employeeId
    });
    if (data) {
      this.isLoading = false;
      this.employeeCPRArray = data.map(data => {
        const number = data.number ? data.number : "";
        const expiredDate = data.expiredDate
          ? moment(data.expiredDate).format("MMM DD, YYYY")
          : "";
        const state = data.state ? data.state : "";
        const organization = data.organization ? data.organization : "";

        return {
          ...data,
          number,
          expiredDate,
          state,
          organization
        };
      });
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getEmployeeCPRById = async cprId => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const { data, error } = await getEmployeeCPRById({
      businessId: businessId,
      cprId: cprId
    });
    this.isLoading = false;

    if (data) {
      let state = csc.getStatesOfCountry("US");

      let tempState = state.map(data => {
        return data.name;
      });

      let tempId = tempState.indexOf(data.state);

      let stateId = state[tempId].isoCode;

      this.setEditedData({
        number: data.number ? data.number : "",
        expiredDate: data.expiredDate ? data.expiredDate : "",
        state: { label: data.state, value: String(stateId) },
        organization: data.organization || ""
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  createCPR = async ({ value, history, employeeId }) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    value.employeeId = employeeId;
    this.isLoading = true;
    const { data, error } = await createCPR(value, businessId);
    if (data) {
      this.isLoading = false;
      CommonStore.showSuccessMessage("CPR Added");
      this.editedData = {};
      history.push("/businesses/" + businessId + "/employees/" + employeeId);
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  updateCPR = async ({ value, history, employeeId, cprId }) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const { data, error } = await updateCPR(value, businessId, cprId);
    if (data) {
      this.isLoading = false;
      CommonStore.showSuccessMessage("CPR Updated");
      this.editedData = {};
      history.push("/businesses/" + businessId + "/employees/" + employeeId);
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  deleteCPR = async ({ history, employeeId, cprId }) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const { data, error } = await deleteCPR(businessId, cprId);
    if (data) {
      this.isLoading = false;
      CommonStore.showSuccessMessage("CPR Deleted");

      history.push("/businesses/" + businessId + "/employees/" + employeeId);
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  //-----------------------------------------------------------Super Admin--------------------------------------------------------------

  setCurrentAdminPage = value => {
    this.currentPage = value;
    this.getAllEmployeeForAdmin(this.editedData.businessId);
  };

  getAllEmployeeForAdmin = async businessId => {
    this.isLoading = true;

    const { data, error } = await getAllEmployeeForAdmin({
      businessId: businessId,
      pageLimit: this.pageSize,
      currentPage: this.currentPage + 1,
      search: this.searchValue
    });
    if (data) {
      this.isLoading = false;
      this.dataArray = data.employees
        ? data.employees.map(data => {
          const employeeID = data._id ? data._id : "";
          const employeeFirstName = data.firstName;
          const employeeLastName = data.lastName;
          const email = data.email ? data.email : "";
          const phoneNumber = data.phoneNumber ? data.phoneNumber : "";
          const role = data.role ? data.role.name : "-";
          const status = data.role ? data.role.isActive : "";

          return {
            ...data,
            employeeID,
            employeeFirstName,
            employeeLastName,
            email,
            phoneNumber,
            role,
            status
          };
        })
        : [];

      this.employeeLength = data.totalData;
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;

        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getEmployeeIdForAdmin = async employeeId => {
    this.isLoading = true;
    const { data, error } = await getEmployeeIdForAdmin(employeeId);
    this.isLoading = false;

    if (data) {
      this.setEditedData({
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        phoneNumber: data.phoneNumber,
        role: data.role && data.role._id,
        password: data.password,
        isActive: data.isActive
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getEmployeeRoleForAdmin = async businessId => {
    this.isLoading = true;
    const { data, error } = await getEmployeeRoleForAdmin(businessId);
    this.isLoading = false;
    if (data) {
      this.roleArray = data.map(data => {
        const name = data.name ? data.name : "-";
        const roleId = data._id ? data._id : "-";
        const value = data._id ? data._id : "-";
        return {
          ...data,
          name,
          roleId,
          value
        };
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  putUpdateEmployeeForAdmin = async (
    employee,
    employeeId,
    ownerId,
    businessId,
    history
  ) => {
    this.isLoading = true;

    const employeeData = {
      firstName: employee.firstName,
      lastName: employee.lastName,
      email: employee.email,
      phoneNumber: employee.phoneNumber,
      role: employee.role,
      isActive: employee.isActive
    };

    const { data, error } = await putUpdateEmployeeForAdmin(
      employeeData,
      employeeId
    );

    if (data) {
      this.isLoading = false;
      commonStore.showSuccessMessage("Employee Updated");
      tabStore.setActiveTab("Employees");
      history.push(
        "/admin/businessowner/ownerdetail/" +
        ownerId +
        "/business/" +
        businessId
      );
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getAllRolesForAdmin = async (businessId) => {
    this.isLoading = true;
    const { data, error } = await getAllRolesForAdmin(businessId);
    this.isLoading = false;
    if (data) {
      this.roleArray = data.map(data => {
        const name = data.name ? data.name : "-";
        const roleId = data._id ? data._id : "-";
        const value = data._id ? data._id : "-";
        return {
          ...data,
          name,
          roleId,
          value
        };
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View Role's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getSingleRolesForAdmin = async roleId => {

    this.isLoading = true;
    const { data, error } = await getSingleRolesForAdmin(roleId);
    this.isLoading = false;

    if (data) {
      this.setEditedData({
        name: data.name ? data.name : "",
        dashboardCreate: data.permission.dashboard
          ? data.permission.dashboard.create
          : false,
        dashboardView: data.permission.dashboard
          ? data.permission.dashboard.read
          : false,
        dashboardModify: data.permission.dashboard
          ? data.permission.dashboard.update
          : false,
        dashboardDelete: data.permission.dashboard
          ? data.permission.dashboard.delete
          : false,
        huntTypeCreate: data.permission.huntType
          ? data.permission.huntType.create
          : false,
        huntTypeView: data.permission.huntType
          ? data.permission.huntType.read
          : false,
        huntTypeModify: data.permission.huntType
          ? data.permission.huntType.update
          : false,
        huntTypeDelete: data.permission.huntType
          ? data.permission.huntType.delete
          : false,
        huntCreate: data.permission.hunt ? data.permission.hunt.create : false,
        huntView: data.permission.hunt ? data.permission.hunt.read : false,
        huntModify: data.permission.hunt ? data.permission.hunt.update : false,
        huntDelete: data.permission.hunt ? data.permission.hunt.delete : false,
        employeeCreate: data.permission.employee
          ? data.permission.employee.create
          : false,
        employeeView: data.permission.employee
          ? data.permission.employee.read
          : false,
        employeeModify: data.permission.employee
          ? data.permission.employee.update
          : false,
        employeeDelete: data.permission.employee
          ? data.permission.employee.delete
          : false,
        roleCreate: data.permission.role ? data.permission.role.create : false,
        roleView: data.permission.role ? data.permission.role.read : false,
        roleModify: data.permission.role ? data.permission.role.update : false,
        roleDelete: data.permission.role ? data.permission.role.delete : false,
        bookingCreate: data.permission.booking
          ? data.permission.booking.create
          : false,
        bookingView: data.permission.booking
          ? data.permission.booking.read
          : false,
        bookingModify: data.permission.booking
          ? data.permission.booking.update
          : false,
        bookingDelete: data.permission.booking
          ? data.permission.booking.delete
          : false,
        customerCreate: data.permission.customer
          ? data.permission.customer.create
          : false,
        customerView: data.permission.customer
          ? data.permission.customer.read
          : false,
        customerModify: data.permission.customer
          ? data.permission.customer.update
          : false,
        customerDelete: data.permission.customer
          ? data.permission.customer.delete
          : false,
        reportCreate: data.permission.report
          ? data.permission.report.create
          : false,
        reportView: data.permission.report
          ? data.permission.report.read
          : false,
        reportModify: data.permission.report
          ? data.permission.report.update
          : false,
        reportDelete: data.permission.report
          ? data.permission.report.delete
          : false,
        contactCreate: data.permission.contact
          ? data.permission.contact.create
          : false,
        contactView: data.permission.contact
          ? data.permission.contact.read
          : false,
        contactModify: data.permission.contact
          ? data.permission.contact.update
          : false,
        contactDelete: data.permission.contact
          ? data.permission.contact.delete
          : false
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View Role's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  updateRolesForAdmin = async (roleId, ownerId, businessId) => {
    this.isLoading = true;
    const {
      name,
      dashboardCreate,
      dashboardView,
      dashboardModify,
      dashboardDelete,
      huntTypeCreate,
      huntTypeView,
      huntTypeModify,
      huntTypeDelete,
      huntCreate,
      huntView,
      huntModify,
      huntDelete,
      employeeCreate,
      employeeView,
      employeeModify,
      employeeDelete,
      roleCreate,
      roleView,
      roleModify,
      roleDelete,
      bookingCreate,
      bookingView,
      bookingModify,
      bookingDelete,
      customerCreate,
      customerView,
      customerModify,
      customerDelete,
      reportCreate,
      reportView,
      reportModify,
      reportDelete,
      contactCreate,
      contactView,
      contactModify,
      contactDelete
    } = this.editedData;

    const permission = {
      dashboard: {
        create: dashboardCreate,
        update: dashboardModify,
        delete: dashboardDelete,
        read: dashboardView
      },
      customer: {
        create: customerCreate,
        update: customerModify,
        delete: customerDelete,
        read: customerView
      },
      booking: {
        create: bookingCreate,
        update: bookingModify,
        delete: bookingDelete,
        read: bookingView
      },
      employee: {
        create: employeeCreate,
        update: employeeModify,
        delete: employeeDelete,
        read: employeeView
      },
      huntType: {
        create: huntTypeCreate,
        update: huntTypeModify,
        delete: huntTypeDelete,
        read: huntTypeView
      },
      report: {
        create: reportCreate,
        update: reportModify,
        delete: reportDelete,
        read: reportView
      },
      hunt: {
        create: huntCreate,
        update: huntModify,
        delete: huntDelete,
        read: huntView
      },
      role: {
        create: roleCreate,
        update: roleModify,
        delete: roleDelete,
        read: roleView
      },
      contact: {
        create: contactCreate,
        update: contactModify,
        delete: contactDelete,
        read: contactView
      }
    };

    const { data, error } = await updateRolesForAdmin(
      roleId,
      {
        name,
        permission,
      }
    );
    this.isLoading = false;
    if (data) {
      window.location.href = `/admin/businessowner/ownerdetail/${ownerId}/business/${businessId}/roles`
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  changePasswordForAdmin = async (employee, employeeId) => {
    this.isLoading = true;

    const { data, error } = await putUpdateEmployeeForAdmin(
      employee,
      employeeId
    );

    if (data) {
      this.isLoading = false;
      commonStore.showSuccessMessage(
        "Password has been successfully changed "
      );
      changePasswordStore.closeModal();
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

}

decorate(EmployeeStore, {
  editedData: observable,
  dataArray: observable,
  roleArray: observable,
  errorMessage: observable,
  modalErrorMessage: observable,
  currentPage: observable,
  pageSize: observable,
  employeeLength: observable,
  searchValue: observable,
  isLoading: observable,
  deleteLoading: observable,
  employeeLicenseArray: observable,
  employeeCPRArray: observable,

  setEditedData: action,
  getAllEmployee: action
});
export default new EmployeeStore();
