import React, { Component } from "react";
import { FormText } from "reactstrap";

import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    fontFamily: "Roboto",
    fontSize: "14px",
    height: "40px"
  },
  input: {
    padding: "12px"
  }
};

class FormikPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false
    };
  }

  render() {
    const {
      field: { onChange, name, value },
      form: { errors },
      formText,
      classes,
      disabled,
      style,
      ...props
    } = this.props;
    const { showPassword } = this.state;
    const isError = !!errors && !!errors[name];

    return (
      <React.Fragment>
        <TextField
          invalid={isError}
          name={name}
          value={value}
          className={value ? "fieldOnBlur" : "field"}
          onChange={onChange}
          disabled={disabled}
          type={showPassword ? "text" : "password"}
          style={this.props.style}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  style={{ display: disabled && "none" }}
                  aria-label="Toggle password visibility"
                  onClick={() => {
                    this.setState({ showPassword: !this.state.showPassword });
                  }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            classes: {
              root: classes.root,
              input: classes.input
            }
          }}
          {...props}
        />

        {!!isError && <p className="text-danger">{errors[name]}</p>}
        {!!formText && <FormText>{formText}</FormText>}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(FormikPassword);
