import axios from "../helper/Axios.js";
import { adminUrl, superUrl } from "../config/config.js";

export async function getAllContact({
  businessId,
  limit,
  page,
  search,
  interestTripType,
  interestTripYear,
  interestSpecies,
  interestSeason,
}) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/contact?limit=${limit}&page=${page}&search=${search}&interestTripType=${
        interestTripType || ""
      }&interestTripYear=${interestTripYear || ""}&interestSpecies=${
        interestSpecies || ""
      }&interestSeason=${interestSeason || ""}`,
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getContactById(businessId, contactId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/contact/${contactId}`,
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function importContact(value, businessId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/contact/bulk`,
      data: value,
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function postContact(value, businessId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/contact`,
      data: value,
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function updateContact(value, businessId, contactId) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/contact/${contactId}`,
      data: value,
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function deleteContact(businessId, contactId) {
  try {
    let response = await axios.Delete({
      url: `${adminUrl}/business/${businessId}/contact/${contactId}`,
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function deleteContactBulk(businessId, value) {
  try {
    let response = await axios.Delete({
      url: `${adminUrl}/business/${businessId}/contactbulk`,
      data: value,
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function offerSubscription(businessId, contactId, value) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/contact/${contactId}/subscribe`,
      data: value,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function postContactWithoutToken(value, businessId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/free-contact`,
      data: value,
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function checkContact(contactId, businessId, email) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/contact/${contactId}/check-duplicate-email?email=${email}`,
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function mergeContact(contactId, businessId, email) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/contact/${contactId}/combine-contact`,
      data: email,
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

//-----------------------------------------------------------Super Admin--------------------------------------------------------------

export async function getAllContactFromAdmin({
  businessId,
  limit,
  page,
  search,
}) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/contacts?business=${businessId}&limit=${limit}&page=${page}&search=${search}`,
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getContactByIdForAdmin(contactId) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/contact/${contactId}`,
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function updateContactForAdmin(value, contactId) {
  try {
    let response = await axios.Put({
      url: `${superUrl}/contact/${contactId}`,
      data: value,
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}
