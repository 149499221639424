/* eslint-disable array-callback-return */
import React, { Component } from "react";
import { Col, Row } from "reactstrap";
// import { AppBreadcrumb } from "@coreui/react";
// import routes from "../../routes";
import trashCan from "../../assets/trash.svg";
import { inject, observer } from "mobx-react";
// import _ from "lodash";
import Text from "../../components/Texts";
import Table from "../../components/MainListComponent";
import UploadFileModal from "../../components/Modals/UploadFileModal";
// import Buttons from "../../components/Buttons";
// import commonStore from "../../stores/CommonStore";
// import { withStyles } from "@material-ui/core/styles";
import BlockUI from "react-block-ui";
import { Loader } from "react-loaders";
import "react-block-ui/style.css";
import { Redirect } from "react-router-dom";
import "loaders.css/loaders.min.css";
// import { reactLocalStorage } from "reactjs-localstorage";
// import { toJS } from "mobx";

class CustomerSideUpload extends Component {
  state = {
    businessFormSelected: [],
    businessFormBeSend: [],
    customers: []
  };

  componentDidMount = async () => {
    const { formStore } = this.props;
    const params = new URLSearchParams(this.props.location.search);
    const tags = params.get("token");
    formStore.setEditedData({ token: tags });

    await formStore.getCustomerFormByToken(tags);

    // const { tempArray } = formStore;

    // tempArray && tempArray.map(data => {});

    // const { employeeId } = this.props.match.params;
    // let status = JSON.parse(reactLocalStorage.get("account"));
    // let permission = {};
    // this.props.employeeStore.setEditedData({
    //   status: status.isOwner
    // });
    // if (status.isOwner === false) {
    //   permission = JSON.parse(reactLocalStorage.get("permission"));
    //   this.props.employeeStore.setEditedData({
    //     employeeUpdatePermission: permission.employee.update,
    //     employeeDeletePermission: permission.employee.delete,
    //     status: status.isOwner
    //   });
    // }
    // this.props.employeeStore.getEmployeeRole();
    // if (employeeId) {
    //   this.props.employeeStore.getEmployeeById(employeeId);
    // } else {
    //   this.props.employeeStore.setEditedData({
    //     firstName: "",
    //     lastName: "",
    //     email: "",
    //     phoneNumber: "",
    //     role: "",
    //     password: ""
    //   });
    // }
  };

  render() {
    const { formStore } = this.props;
    let {
      businessArray,
      customerFormsColumn3,
      loading,
      customerFormsWidth3,
      editedData,
      uploadLink,
      customerArray,
      customerFormsColumn2,
      customerFormsColumnWidths2,
      text,
      postLoading,
      customerName,
      tempArray,
      tempColumn,
      tempColumnWidths,
      businessName,
      requiredFormTemp,
      tempCompletedFormDelete,
      requiredOptionsPush,
      disabledTempColumn
      // uploadTempColumn
    } = formStore;

    let formArray =
      tempArray &&
      tempArray.map(data => {
        let temp1 = []
        let temp2 = ""
        if (data.url.includes("?")) {
          temp1 = data.url.split("?")
        } else if (data.url.includes("https://storage.cloud.google.com/")) {
          temp2 = data.url.replace("https://storage.cloud.google.com/", "https://storage.googleapis.com/")
        } else {
          temp2 = data.url
        }
        return {
          name: (
            <span
              style={{
                cursor: "pointer",
                textDecoration: "underline",
                color: "#36ae46"
              }}
              onClick={e => {
                e.preventDefault();
                window.open(`/preview?file=${temp1.length !== 0 ? temp1[0] : temp2}&ext=${data.extension}`);
              }}
            >
              {data.form}
            </span>
          ),
          extension: data.extension,
          url: (
            <img
              src={trashCan}
              alt=""
              style={{ cursor: "pointer" }}
              onClick={e => {
                e.preventDefault();

                if (data.requiredForm) {
                  let temp = requiredFormTemp.find(
                    form => form.value === data.requiredForm.form
                  );

                  tempCompletedFormDelete(data.requiredForm.form);

                  requiredOptionsPush(temp);
                } else {
                  let temp = requiredFormTemp.find(
                    form => form.value === data.optionalForm
                  );

                  if (temp) {
                    tempCompletedFormDelete(data.optionalForm);

                    requiredOptionsPush(temp);
                  }
                }

                tempArray.filter((value, idx) => {
                  if (data._id === value._id) {
                    tempArray.splice(idx, 1);
                  }
                });

                // this.deleteForm(data._id);
              }}
            />
          )
        };
      });

    if (editedData.token === null) {
      return <Redirect to="/404" />;
    }

    return (
      <div style={{ verticalAlign: "sub" }}>
        <BlockUI
          tag="div"
          blocking={postLoading}
          loader={
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Loader active type={"ball-spin-fade-loader"} color="#1665d8" />
            </div>
          }
        >
          <Row
            style={{
              marginLeft: "50px",
              marginRight: "50px",
              marginTop: "100px"
            }}
          >
            <Col lg="12" style={{ marginLeft: "25px", marginRight: "25px" }}>
              <Text
                text={`Welcome, ${customerName}`}
                textType="h1"
                weight="medium"
              />
            </Col>

            <Col
              lg="12"
              style={{
                marginLeft: "25px",
                marginRight: "25px",
                marginTop: "10px"
              }}
            >
              <Text
                text={`Thank you for booking your hunt with ${businessName}. Please download your documents and upload all completed documents here.`}
                textType="h4"
                weight="regular"
              />
            </Col>

            <Col lg="12" style={{ marginTop: "50px" }}>
              <Row>
                <Col lg="6">
                  <Row>
                    <Col lg="12" md="12" sm="12" xl="12">
                      <Text text="Documents" textType="body2" weight="medium" />
                      <Table
                        columns={customerFormsColumn2}
                        rows={businessArray}
                        columnWidths={customerFormsColumnWidths2}
                        onRowClicked={this.goToUpdate}
                        pagingPanel={false}
                        // onCurrentPageChange={setCurrentPage}
                        // currentPage={currentPage}
                        // pageSize={pageSize}
                        // totalCount={totalCount}
                        onLoading={loading}
                      // defaultCurrentPage={0}
                      // onChange={this.track}
                      // selection={businessFormSelected}
                      // checkbox={true}
                      // selectAll={false}
                      // selectAll={true}
                      // selection={}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg="6">
                  <Row>
                    <Col lg="12" md="12" sm="12" xl="12">
                      <Text
                        text="Completed Documents"
                        textType="body2"
                        weight="medium"
                      />
                      <Table
                        columns={tempColumn}
                        rows={
                          tempArray.length !== 0
                            ? [...formArray, ...text]
                            : uploadLink
                        }
                        columnWidths={tempColumnWidths}
                        // onRowClicked={this.goToUpdate}
                        disabledColumn={disabledTempColumn}
                        pagingPanel={false}
                        // onCurrentPageChange={setCurrentPage}
                        // currentPage={currentPage}
                        // pageSize={pageSize}
                        // totalCount={totalCount}
                        onLoading={loading}
                        defaultCurrentPage={0}
                      // onChange={this.track}
                      // selection={cus}
                      // checkbox={true}
                      // selectAll={false}
                      // selectAll={true}
                      // selection={}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col lg="6">
                  <Row>
                    <Col lg="12" md="12" sm="12" xl="12">
                      <Text
                        text="Uploaded Documents"
                        textType="body2"
                        weight="medium"
                      />
                      <Table
                        columns={customerFormsColumn3}
                        rows={customerArray}
                        columnWidths={customerFormsWidth3}
                        // onRowClicked={this.goToUpdate}
                        pagingPanel={false}
                        // onCurrentPageChange={setCurrentPage}
                        // currentPage={currentPage}
                        // pageSize={pageSize}
                        // totalCount={totalCount}
                        onLoading={loading}
                        defaultCurrentPage={0}
                      // onChange={this.track}
                      // selection={cus}
                      // checkbox={true}
                      // selectAll={false}
                      // selectAll={true}
                      // selection={}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </BlockUI>
        <UploadFileModal submitType={"customer"} />
      </div>
    );
  }
}
export default inject(
  "formStore",
  "customerStore"
)(observer(CustomerSideUpload));
