import { PostImg } from "../services/CloudinaryService";
import { action, decorate } from "mobx";
class CloudinaryStore {
  imageLoading = false;
  postImage = async (file, path) => {
    this.imageLoading = true;

    const { data, error } = await PostImg(file, path);
    if (data) {
      return { data };
    } else {
      return { error };
    }
  };
}

decorate(CloudinaryStore, {
  postImage: action
});

export default new CloudinaryStore();
