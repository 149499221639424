import { observable, decorate, toJS } from "mobx";
import {
  uploadFile,
  postForm,
  getAllForm,
  sendFormToCustomer,
  deleteForm,
  getBusinessForm,
  sendSMS,
  getCustomerFormByToken,
  updateCustomerForm,
  postCustomerForm,
  getAllFormForAdmin,
  uploadFormBusinessSide
} from "../services/FormService";
import { Input } from "reactstrap";
import Text from "../components/Texts";
import Buttons from "../components/Buttons";
import commonStore from "./CommonStore";
import uploadModal from "./UploadFileModalStore";
import moment from "moment";
import deleteWarning from "./DeleteWarningStore";
import trashCan from "../assets/trash.svg";
import download from "../assets/download.svg";
import React from "react";
import { reactLocalStorage } from "reactjs-localstorage";

class FormStore {
  editedData = {
    requiredForms: []
  };
  requiredFormOptions = [];
  // selectedForm;
  pageSize = 10;
  currentPage = 0;
  searchValue = "";
  totalCount = 0;
  requiredForm = [];
  tempArray = [];
  tempCompletedForm = [];
  requiredFormTemp = [];
  mappedRequiredForms = [];
  loading = false;
  text = [
    {
      name: (
        <div>
          <Buttons
            buttonType={"primary"}
            label={"Submit"}
            style={{
              height: "40px",
              width: "50%",
              marginRight: "15px"
            }}
            onClick={async e => {
              e.preventDefault();

              // let validation = this.requiredFormOptions.length;
              // console.log("test", this.requiredFormOptions);
              await this.updateCustomerForm();

              this.tempArray = [];
            }}
          //   disabled={employeeCreatePermission === false ? true : false}
          />
          <Text
            textType={"body3"}
            text={"+ Upload More Forms"}
            style={{
              textDecoration: "underline",
              color: "#01953A",
              cursor: "pointer"
            }}
            onClick={e => {
              e.preventDefault();
              uploadModal.openModal();
              // this.deleteForm(data._id);
            }}
          />
        </div>
      )
    }
  ];
  formArray = [];
  businessFormArray = [];
  businessArray = [];
  customerArray = [];
  customerFormArray = [];
  customerName = "";
  businessName = "";
  deleteLoading = false;
  // valueTransformer = {
  //   checked: data => {

  //   }
  // }
  uploadLink = [
    {
      name: (
        <Text
          textType={"body3"}
          text={"Upload your signed and completed forms"}
          style={{
            textDecoration: "underline",
            color: "#01953A",
            cursor: "pointer"
          }}
          onClick={e => {
            e.preventDefault();
            uploadModal.openModal();
            // this.deleteForm(data._id);
          }}
        />
      )
    }
  ];
  postLoading = false;
  valueTransformer = {
    deleteData: data => {
      return (
        <img
          src={trashCan}
          alt=""
          style={{ cursor: "pointer" }}
          onClick={e => {
            e.preventDefault();
            this.setEditedData({ id: data._id, formName: data.name });
            deleteWarning.showWarning();
            // this.deleteForm(data._id);
          }}
        />
      );
    },

    url: data => {
      let temp1 = []
      let temp2 = ""
      if (data.url.includes("?")) {
        temp1 = data.url.split("?")
      } else if (data.url.includes("https://storage.cloud.google.com/")) {
        temp2 = data.url.replace("https://storage.cloud.google.com/", "https://storage.googleapis.com/")
      } else {
        temp2 = data.url
      }

      return (
        <a href={temp1.length !== 0 ? temp1[0] : temp2}>
          <img src={download} alt="" style={{ cursor: "pointer" }} />
        </a>
      );
    },
    name: data => {
      let temp1 = []
      let temp2 = ""
      if (data.url.includes("?")) {
        temp1 = data.url.split("?")
      } else if (data.url.includes("https://storage.cloud.google.com/")) {
        temp2 = data.url.replace("https://storage.cloud.google.com/", "https://storage.googleapis.com/")
      } else {
        temp2 = data.url
      }

      return (
        <span
          style={{
            cursor: "pointer",
            textDecoration: "underline",
            color: "#36ae46"
          }}
          onClick={e => {
            e.preventDefault();
            // if(data.)
            window.open(`/preview?file=${temp1.length !== 0 ? temp1[0] : temp2}&ext=${data.extension}`);
            // window.open(data.url);
            // console.log('temp', temp);

            // window.open(temp1.length !== 0 ? temp1[0] : temp2);

          }}
        >
          {data.form}
        </span>
        // <a href={data.url}>
        //   {/* <img src={download} alt="" style={{ cursor: "pointer" }} /> */}
        //   {data.form}

        // </a>
      );
    },
    required: data => (
      <div>
        <Input
          type="checkbox"
          style={{ position: "static", marginLeft: "12px" }}
          checked={data.required}
          disabled={true}
        />
      </div>
    )
  };
  tempColumn = [
    {
      name: "name",
      title: "Document Name"
    },
    {
      name: "extension",
      title: "Type"
    },
    {
      name: "url",
      title: " "
    }
  ];

  disabledTempColumn = [
    {
      columnName: "name",
      sortingEnabled: false
    },
    {
      columnName: "extension",
      sortingEnabled: false
    },
    {
      columnName: "url",
      sortingEnabled: false
    }
  ];

  uploadTempColumn = [
    {
      name: "name",
      title: "Document Name"
    },
    {
      name: "extension",
      title: "Type"
    },
    {
      name: "url",
      title: " "
    }
  ];

  columns = [
    {
      name: "name",
      title: "Documents Name",
      valueTransformer: this.valueTransformer["name"]
    },
    {
      name: "extension",
      title: "Type"
    },
    {
      name: "deleteData",
      title: " ",
      valueTransformer: this.valueTransformer["deleteData"]
    }
  ];
  columns2 = [
    {
      name: "name",
      title: "Document Name",
      valueTransformer: this.valueTransformer["name"]
    },
    {
      name: "extension",
      title: "Type"
    },
    {
      name: "required",
      title: "Required Form"
    }
  ];
  businessFormsColumn = [
    {
      name: "name",
      title: "Document Name",
      valueTransformer: this.valueTransformer["name"]
    },
    {
      name: "extension",
      title: "Type"
    },
    {
      name: "sentDate",
      title: "Date Sent"
    },
    {
      name: "required",
      title: "Required",
      valueTransformer: this.valueTransformer["required"]
    }
  ];
  customerFormsColumn2 = [
    {
      name: "name",
      title: "Document Name",
      valueTransformer: this.valueTransformer["name"]
    },
    {
      name: "extension",
      title: "Type"
    },
    {
      name: "uploadedDate",
      title: "Date Sent"
    },
    {
      name: "required",
      title: "Required",
      valueTransformer: this.valueTransformer["required"]
    },
    {
      name: "url",
      title: " ",
      valueTransformer: this.valueTransformer["url"]
    }
  ];
  customerFormsColumn = [
    {
      name: "name",
      title: "Document Name",
      valueTransformer: this.valueTransformer["name"]
    },
    {
      name: "extension",
      title: "Type"
    },
    {
      name: "uploadedDate",
      title: "Date Sent"
    },
    {
      name: "url",
      title: " ",
      valueTransformer: this.valueTransformer["url"]
    },
    {
      name: "deleteData",
      title: " ",
      valueTransformer: this.valueTransformer["deleteData"]
    },
    {
      name: "required",
      title: "Required",
      valueTransformer: this.valueTransformer["required"]
    }
  ];
  businessColumn = [
    {
      name: "name",
      title: "Document Name",
      valueTransformer: this.valueTransformer["name"]
    },
    {
      name: "extension",
      title: "Type"
    },
    {
      name: "sentDate",
      title: "Date Sent"
    },
    {
      name: "url",
      title: " ",
      valueTransformer: this.valueTransformer["url"]
    }
  ];
  customerColumn = [
    {
      name: "name",
      title: "Document Name",
      valueTransformer: this.valueTransformer["name"]
    },
    {
      name: "extension",
      title: "Type"
    },
    {
      name: "uploadedDate",
      title: "Date Sent"
    }
  ];
  tempColumnWidths = [
    { columnName: "name", width: 315 },
    { columnName: "extension", width: 90 },
    { columnName: "url", width: 190 }
  ];

  columnWidths = [
    { columnName: "name", width: 250 },
    { columnName: "extension", width: 90 },
    { columnName: "deleteData", width: 190 }
  ];
  columnWidths2 = [
    { columnName: "name", width: 250 },
    { columnName: "extension", width: 100 },
    { columnName: "required", width: 100 }
  ];
  businessFormsColumnWidths = [
    {
      columnName: "name",
      width: 150
    },
    {
      columnName: "extension",
      width: 100
    },
    {
      columnName: "sentDate",
      width: 120
    },
    {
      columnName: "required",
      width: 100
    }
  ];
  customerFormsColumnWidths2 = [
    {
      columnName: "name",
      width: 150
    },
    {
      columnName: "extension",
      width: 150
    },
    {
      columnName: "uploadedDate",
      width: 150
    },
    {
      columnName: "required",
      width: 90
    },
    {
      columnName: "url",
      width: 50
    }
  ];
  customerFormsColumnWidths = [
    {
      columnName: "name",
      width: 150
    },
    {
      columnName: "extension",
      width: 80
    },
    {
      columnName: "uploadedDate",
      width: 140
    },
    {
      columnName: "url",
      width: 50
    },
    {
      columnName: "deleteData",
      width: 50
    },
    {
      columnName: "required",
      width: 100
    }
  ];

  customerColumnWidths = [
    {
      columnName: "name",
      width: 300
    },
    {
      columnName: "extension",
      width: 90
    },
    {
      columnName: "sentDate",
      width: 200
    }
  ];

  customerFormsColumn3 = [
    {
      name: "name",
      title: "Document Name",
      valueTransformer: this.valueTransformer["name"]
    },
    {
      name: "extension",
      title: "Type"
    },
    {
      name: "sentDate",
      title: "Date Sent"
    }
  ];
  customerFormsWidth3 = [
    {
      columnName: "name",
      width: 150
    },
    {
      columnName: "extension",
      width: 150
    },
    {
      columnName: "sentDate",
      width: 150
    }
  ];

  setEditedData = editedData => {
    this.editedData = {
      ...this.editedData,
      ...editedData
    };
  };

  setPageLimit = value => {
    this.pageSize = value;
    this.currentPage = 0;
  };

  setSearchValue = value => {
    this.currentPage = 0;
    this.searchValue = value;
  };

  setCurrentPage = value => {
    this.currentPage = value;
    this.getAllForm();
  };

  initialData = () => {
    this.businessFormArray = [];
    this.customerFormArray = [];
  };

  initialCurrentPage = () => {
    this.currentPage = 0;
  };

  requiredOptionsPush = value => {
    this.requiredFormOptions.push(value);
  };

  tempCompletedFormPush = value => {
    this.tempCompletedForm.push(value);
  };

  tempCompletedFormDelete = value => {
    this.tempCompletedForm = this.tempCompletedForm.filter(form => {
      return form !== value;
    });
  };

  getAllForm = async () => {
    this.loading = true;

    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    const { data, error } = await getAllForm({
      businessId: JSON.parse(businessId),
      pageLimit: this.pageSize,
      currentPage: this.currentPage + 1,
      search: this.searchValue
    });

    this.loading = false;

    if (data) {
      this.formArray = data.formData.map(data => {
        const name = data.form ? data.form : "-";
        const extension = data.extension ? data.extension : "-";
        const deleteData = data._id ? data._id : "-";
        const previewFile = data.url ? data.url : "-";
        const checked = false;
        return {
          ...data,
          name,
          extension,
          deleteData,
          previewFile,
          checked
        };
      });

      this.totalCount = data.totalData;
      return { formArray: this.formArray };
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getBusinessForm = async customerId => {
    this.loading = true;
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    const { data, error } = await getBusinessForm(
      JSON.parse(businessId),
      customerId
    );

    this.loading = false;
    if (data) {

      this.businessFormArray = data.businessForms
        ? data.businessForms.map(data => {
          const name = data.form ? data.form : "-";
          const extension = data.extension ? data.extension : "-";
          const sentDate = data.createdDate
            ? moment(data.createdDate).format("MMM DD, YYYY")
            : "-";
          const required = data.required ? data.required : false;
          return {
            ...data,
            name,
            extension,
            sentDate,
            required
          };
        })
        : [];

      this.requiredFormOptions = this.businessFormArray.map(form => {
        return {
          name: form.name ? form.name : "",
          value: form._id ? form._id : ""
        };
      });

      this.requiredFormOptions = [
        ...this.requiredFormOptions,
        { name: "Others", value: "" }
      ];

      this.customerFormArray = data.customerForms
        ? data.customerForms.map(data => {
          const name = data.form ? data.form : "-";
          const extension = data.extension ? data.extension : "-";
          const uploadedDate = data.createdDate
            ? moment(data.createdDate).format("MMM DD, YYYY")
            : "-";
          const url = data.url ? data.url : "-";
          const deleteData = data._id ? data._id : "-";
          const required = data.required ? data.required : false;

          return {
            ...data,
            name,
            extension,
            uploadedDate,
            url,
            deleteData,
            required
          };
        })
        : [];
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  uploadFile = async file => {
    const formData = new FormData();

    formData.append("file", file);

    const { data, error } = await uploadFile(formData);

    if (data) {
      return { data };
    } else {
      return { error };
    }
  };

  postCustomerForm = async (value, token) => {
    this.loading = true;

    const { data, error } = await postCustomerForm(value, token);

    this.loading = false;

    if (data) {
      let values = {};
      // let tempValues = {};
      if (value.requiredForm) {
        values = {
          ...data,
          name: data.form,
          requiredForm: {
            ...value.requiredForm,
            completedRequiredForm: data._id ? data._id : ""
            // oldId: value.requiredForm.form
          }
        };
      } else {
        values = {
          ...data,
          name: data.form,
          optionalForm: value.optionalForm
        };
      }

      commonStore.showSuccessMessage("Form uploaded");
      if (value.requiredForm) {
        this.requiredFormOptions = this.requiredFormOptions.filter(form => {
          return String(value.requiredForm.form) !== String(form.value);
        });
      } else if (value.optionalForm !== "") {
        this.requiredFormOptions = this.requiredFormOptions.filter(form => {
          return String(value.optionalForm) !== String(form.value);
        });
      } else {
        this.requiredFormOptions = this.requiredFormOptions.map(form => {
          return form;
        });
      }

      this.tempArray.push(toJS(values));
      // this.getCustomerFormByToken(this.editedData.token);
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  postBusinessForm = async (value, customerId) => {
    this.loading = true;

    let temp = this.businessFormArray.find(data =>
      value.requiredForm === data._id
    )

    const finalTemp = {
      form: value.form,
      url: value.url,
      extension: value.extension,
      type: value.type
    }

    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    const { data, error } = await postForm(JSON.parse(businessId), finalTemp);


    if (data) {
      // console.log(temp.map(asd => {
      //   return toJS(asd)
      // }));
      const toBeUploaded = {
        businessForm: value.requiredForm,
        customerForm: data._id,
        isRequired: value.requiredForm !== "" ? temp.required : false
      }

      const { result, error } = await this.uploadFormBusinessSide(toBeUploaded, customerId)
      this.loading = false;

      if (result) {
        // this.getBusinessForm(customerId)
        const id = customerId
        this.getBusinessForm(id);
        commonStore.showSuccessMessage("Form uploaded");
      } else {
        if (error.data) {
          this.errorMessage = error.data.message;
          if (this.errorMessage === "Forbidden.") {
            commonStore.showErrorMessage(
              "You don't have any permission to View employee's data"
            );
          } else {
            commonStore.showErrorMessage(this.errorMessage);
          }
        } else {
          commonStore.showErrorMessage(
            "Something went wrong. Please try again later"
          );
        }
      }

    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  updateCustomerForm = async () => {
    this.loading = true;
    let value = {};

    // for (let i = 0; i < this.tempArray.length; i++) {
    //   if (this.tempArray[i].requiredForm) {
    //     this.mappedRequiredForms.push(this.tempArray[i].requiredForm);
    //   }
    // }

    const notRequried = this.tempArray.filter(data => {
      return !data.requiredForm;
    });

    const required = this.tempArray.filter(data => {
      return data.requiredForm;
    });

    const requiredForms = toJS(this.tempArray).filter(data => {
      return data.requiredForm;
    });

    if (requiredForms.length >= 1) {
      this.mappedRequiredForms = toJS(requiredForms).map(data => {
        return toJS({
          form: data.requiredForm.form,
          status: data.requiredForm.status,
          completedRequiredForm: data.requiredForm.completedRequiredForm
        });
      });
    }

    const tempArray1 = this.mappedRequiredForms.map(data => {
      return toJS(data.form);
    });

    const tempArrayBusiness = this.requiredForm.filter(data => {
      return toJS(data);
    });

    const tempArray2 = tempArrayBusiness.map(data => {
      return toJS(data.form);
    });

    const requiredToBeSend = tempArray2.filter(data => {
      return !tempArray1.includes(data);
    });

    // const requiredToBeSendFinal = tempArrayBusiness.filter(data => {
    //   return requiredToBeSend.includes(data.form);
    // });
    const requiredToBeSendFinal = [];

    for (let i = 0; i < tempArrayBusiness.length; i++) {
      if (requiredToBeSend.includes(tempArrayBusiness[i].form)) {
        if (tempArrayBusiness[i].completedRequiredForm) {
          requiredToBeSendFinal.push({
            form: tempArrayBusiness[i].form,
            status: tempArrayBusiness[i].status,
            completedRequiredForm: tempArrayBusiness[i].completedRequiredForm
          });
        } else {
          requiredToBeSendFinal.push({
            form: tempArrayBusiness[i].form,
            status: tempArrayBusiness[i].status
          });
        }
      }
    }

    // console.log("requiredToBeSend", toJS(requiredToBeSend));
    // console.log("requiredToBeSendFinal", toJS(requiredToBeSendFinal));
    // console.log("all", [
    //   toJS(...this.mappedRequiredForms),
    //   toJS(...requiredToBeSendFinal)
    // ]);

    // console.log("mappedRequiredForms", toJS(this.mappedRequiredForms));

    if (notRequried.length >= 1 && required.length === 0) {
      value = {
        forms: toJS(this.tempArray).map(data => {
          return data._id;
        }),
        completedForms: this.tempCompletedForm
      };
    } else {
      value = {
        forms: toJS(this.tempArray).map(data => {
          return data._id;
        }),
        requiredForms: [...this.mappedRequiredForms, ...requiredToBeSendFinal],
        completedForms: this.tempCompletedForm
      };
    }

    const { data, error } = await updateCustomerForm(
      value,
      this.editedData.token
    );

    this.loading = false;

    if (data) {
      commonStore.showSuccessMessage("Update Success");
      this.getCustomerFormByToken(this.editedData.token);
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  postForm = async file => {
    this.loading = true;
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    const { data, error } = await postForm(JSON.parse(businessId), file);

    this.loading = false;

    if (data) {
      commonStore.showSuccessMessage("Upload Success");
      this.getAllForm();
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  sendFormToCustomer = async (value, history) => {
    this.postLoading = true;
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    const { data, error } = await sendFormToCustomer(
      JSON.parse(businessId),
      value
    );

    this.postLoading = false;

    if (data) {
      commonStore.showSuccessMessage("File Sent !");
      history.push(`/businesses/${JSON.parse(businessId)}/document`);
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  sendSMS = async (value, customerId) => {
    this.postLoading = true;
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    const { data, error } = await sendSMS(
      value,
      JSON.parse(businessId),
      customerId
    );

    this.postLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Message Sent !");
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  deleteForm = async formId => {
    this.deleteLoading = true;
    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    const { data, error } = await deleteForm(JSON.parse(businessId), formId);

    this.deleteLoading = false;

    if (!data) {
      commonStore.showSuccessMessage("Delete Success");
      this.getAllForm();
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getCustomerFormByToken = async token => {
    this.loading = true;

    const { data, error } = await getCustomerFormByToken(token);

    this.loading = false;

    if (data) {

      this.businessArray = data.businessForms
        ? data.businessForms.map(data => {
          const name = data.form ? data.form : "-";
          const extension = data.extension ? data.extension : "-";
          const uploadedDate = data.createdDate
            ? moment(data.createdDate).format("MMM DD, YYYY")
            : "-";
          const required = data.required ? data.required : false;
          const url = data.url ? data.url : "-";

          return {
            ...data,
            name,
            extension,
            uploadedDate,
            url,
            required
          };
        })
        : [];

      this.requiredForm = data.requiredForms;

      this.requiredFormTemp = data.businessForms.map(form => {
        return {
          name: form.form ? form.form : "",
          value: form._id ? form._id : ""
        };
      });

      const temp = data.businessForms.filter(tempData => {
        return !data.completedForms.includes(tempData._id);
      });

      this.requiredFormOptions = temp.map(form => {
        return {
          name: form.form ? form.form : "",
          value: form._id ? form._id : ""
        };
      });

      this.requiredFormOptions = [
        ...this.requiredFormOptions,
        { name: "Others", value: "" }
      ];

      // for (let i = 0; i < data.businessForms.length; i++) {
      //   if (!data.completedForms.includes(data.businessForms[i]._id)) {
      //     this.requiredFormOptions.push({
      //       name: data.businessForms[i].form ? data.businessForms[i].form : "",
      //       value: data.businessForms[i]._id ? data.businessForms[i]._id : ""
      //     });
      //   }
      // }

      this.customerArray = data.customerForms
        ? data.customerForms.map(data => {
          const name = data.form ? data.form : "-";
          const extension = data.extension ? data.extension : "-";
          const sentDate = data.createdDate
            ? moment(data.createdDate).format("MMM DD, YYYY")
            : "-";
          return {
            ...data,
            name,
            extension,
            sentDate
          };
        })
        : [];

      if (!this.customerName) this.customerName = data.customerData.firstName;
      if (!this.businessName) this.businessName = data.business.name;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  uploadFormBusinessSide = async (value, customerId) => {

    let businessId = reactLocalStorage.get("huntingCurrentBusiness");

    const { data, error } = await uploadFormBusinessSide(JSON.parse(businessId), customerId, value)

    if (data) {
      return { result: data }
    } else {
      return { error }
    }

  }

  //-----------------------------------------------------------Super Admin--------------------------------------------------------------
  adminColumns = [
    {
      name: "name",
      title: "Documents Name",
      valueTransformer: this.valueTransformer["name"]
    },
    {
      name: "extension",
      title: "Type"
    }
  ];

  adminColumnWidths = [
    { columnName: "name", width: 250 },
    { columnName: "extension", width: 90 }
  ];

  setCurrentAdminPage = value => {
    this.currentPage = value;
    this.getAllFormForAdmin(this.editedData.businessId);
  };

  getAllFormForAdmin = async businessId => {
    this.loading = true;

    const { data, error } = await getAllFormForAdmin({
      businessId: businessId,
      pageLimit: this.pageSize,
      currentPage: this.currentPage + 1,
      search: this.searchValue
    });

    this.loading = false;

    if (data) {
      this.formArray = data.formData.map(data => {
        const name = data.form ? data.form : "-";
        const extension = data.extension ? data.extension : "-";
        return {
          ...data,
          name,
          extension
        };
      });
      this.totalCount = data.totalData;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };
}
decorate(FormStore, {
  editedData: observable,
  loading: observable,
  formArray: observable,
  postLoading: observable,
  deleteLoading: observable,
  businessFormArray: observable,
  customerFormArray: observable,
  customerArray: observable,
  businessArray: observable,
  requiredFormOptions: observable,
  customerColumnWidths: observable,
  customerName: observable,
  pageSize: observable,
  currentPage: observable,
  totalCount: observable,
  searchValue: observable,
  tempArray: observable,
  businessName: observable,
  requiredFormTemp: observable,
  tempCompletedForm: observable,
  mappedRequiredForms: observable,
  requiredForm: observable
});
export default new FormStore();
