import axios from "../helper/Axios.js";
import { adminUrl, superUrl } from "../config/config.js";

export async function getAllEmployee({
  businessId,
  pageLimit,
  currentPage,
  search
}) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/employee?page=${currentPage}&limit=${pageLimit}&search=${search}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getEmployeeId(employeeId, businessId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/employee/${employeeId}`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function postEmployee(employee, businessId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/employee`,
      data: employee
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function putUpdateEmployee(employee, businessId, employeeId) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/employee/${employeeId}`,
      data: employee
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function deleteEmployee(businessId, employeeId) {
  try {
    let response = await axios.Delete({
      url: `${adminUrl}/business/${businessId}/employee/${employeeId}`
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getEmployeeRole(businessId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/role`
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getEmployeeRoleById(businessId, roleId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/role/${roleId}`
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function postEmployeeRole(name, permission, businessId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/role`,
      data: { name, permission }
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function putUpdateEmployeeRole(
  name,
  permission,
  businessId,
  roleId
) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/role/${roleId}`,
      data: { name, permission }
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function deleteEmployeeRole(businessId, roleId) {
  try {
    let response = await axios.Delete({
      url: `${adminUrl}/business/${businessId}/role/${roleId}`
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function changePassword(businessId, employeeId, newPassword) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/employee/${employeeId}/password`,
      data: { newPassword }
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

//-----------------------------------------------------------Super Admin--------------------------------------------------------------

export async function getAllEmployeeForAdmin({
  businessId,
  pageLimit,
  currentPage,
  search
}) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/employees?business=${businessId}&page=${currentPage}&limit=${pageLimit}&search=${search}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getEmployeeIdForAdmin(employeeId) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/employee/${employeeId}`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getEmployeeRoleForAdmin(businessId) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/roles?business=${businessId}`
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function putUpdateEmployeeForAdmin(employee, employeeId) {
  try {
    let response = await axios.Put({
      url: `${superUrl}/employee/${employeeId}`,
      data: employee
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getAllRolesForAdmin(businessId) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/roles?business=${businessId}`
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getSingleRolesForAdmin(roleId) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/roles/${roleId}`
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function updateRolesForAdmin(roleId, value) {
  try {
    let response = await axios.Put({
      url: `${superUrl}/roles/${roleId}`,
      data: value
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}