import React, { Component } from "react";
import Badge from "@material-ui/core/Badge";
import {
  Col,
  Row,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav
} from "reactstrap";
import moment from "moment";
// import PropTypes from "prop-types";
import Text from "../../components/Texts";
import { withStyles } from "@material-ui/core/styles";
import { AppNavbarBrand, AppSidebarToggler } from "@coreui/react";
import { inject, observer } from "mobx-react";
import { reactLocalStorage } from "reactjs-localstorage";
import { Route } from "react-router-dom";
import WorkSpringLogo from "../../assets/Worksprings_Logo.svg";
// import logo from "../../assets/img/brand/logo.png";

// const propTypes = {
//   children: PropTypes.node
// };

const styles = theme => ({
  root: {
    backgroundColor: "#1665d8"
  }
});

class DefaultHeader extends Component {
  state = {
    customer: "",
    notificationId: ""
  };

  logout = async () => {
    if (window.location.pathname.includes("/admin") === true) {
      await this.props.authStore.signOutAdmin();
    } else {
      await this.props.authStore.signOut();
    }
  };

  componentDidMount = async () => {
    let status = reactLocalStorage.get("account")
      ? JSON.parse(reactLocalStorage.get("account"))
      : "";
    const admin = localStorage.getItem("admin")
      ? localStorage.getItem("admin")
      : "";
    window.addEventListener("focus", this.onFocus);

    if (
      window.location.pathname.includes("/admin") === false &&
      admin !== "SUPERADMIN"
    )
      await this.props.notificationStore.getAllNotification();

    this.props.authStore.setEditedData({ status: status.isOwner });
  };

  // changeValue = async (customer, notificationId) => {
  //   const { notificationStore } = this.props;
  //   const currentBusiness = JSON.parse(
  //     reactLocalStorage.get("huntingCurrentBusiness")
  //   );
  //   await this.setState({ customer: customer, notificationId: notificationId });

  //   window.location.href = `/businesses/${currentBusiness}/customer/${this.state.customer}/customerForm`;
  // };

  componentWilUnmount() {
    window.removeEventListener("focus", this.onFocus);
  }

  onFocus = async () => {
    const admin = localStorage.getItem("admin")
      ? localStorage.getItem("admin")
      : "";
    if (
      window.location.pathname.includes("/admin") === false &&
      admin !== "SUPERADMIN"
    )
      await this.props.notificationStore.getAllNotification();
  };

  render() {
    const { authStore, notificationStore } = this.props;
    const currentBusiness = reactLocalStorage.get("huntingCurrentBusiness")
      ? JSON.parse(reactLocalStorage.get("huntingCurrentBusiness"))
      : "";
    let { notificationArray, unreadData } = notificationStore;
    const { editedData } = authStore;
    const { status } = editedData;
    const accountData = reactLocalStorage.get("account")
      ? JSON.parse(reactLocalStorage.get("account"))
      : "";
    // const admin = localStorage.getItem("admin")
    //   ? localStorage.getItem("admin")
    //   : "";
    let username = accountData
      ? `${accountData.firstName} ${accountData.lastName}`
      : "";

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="sm" mobile />
        <AppNavbarBrand
          id="logo-media"
          full={{ src: WorkSpringLogo, width: 120, height: 40, alt: " Logo" }}
          minimized={{
            src: WorkSpringLogo,
            width: 30,
            height: 30,
            alt: " Logo"
          }}
        />
        <AppSidebarToggler className="d-md-down-none" display="lg" />

        <Nav
          id="header-class"
          className="ml-auto"
          navbar
          style={{ marginRight: "35px" }}
        >
          {window.location.pathname.includes("/admin") === false ? (
            <UncontrolledDropdown
              style={{
                marginRight: "10px"
              }}
            >
              <DropdownToggle nav className=" d-md-flex">
                <Badge
                  badgeContent={unreadData}
                  // classes={{ colorSecondary: classes.root }}
                  color="secondary"
                >
                  <div style={{ cursor: "pointer" }} className=" d-md-flex">
                    <i className="fa fa-bell " style={{ fontSize: "24px" }}></i>
                  </div>
                </Badge>
              </DropdownToggle>
              <DropdownMenu className="notification">
                {notificationArray.map(data => {
                  return (
                    <Route
                      render={({ history }) => (
                        <DropdownItem
                          style={{
                            backgroundColor:
                              data.read === false ? "#f6f9fd" : "#F8F8F8"
                          }}
                          onClick={async e => {
                            e.preventDefault();
                            const value = { notification: [data._id] };
                            authStore.setEditedData({
                              notifLoading: true,
                              businessUnit: data.customer.business
                            });
                            reactLocalStorage.setObject(
                              "huntingCurrentBusiness",
                              data.customer.business
                            );

                            notificationStore.setNotificationArray();
                            await notificationStore.readNotification(value);
                            await notificationStore.getAllNotification();
                            authStore.setEditedData({ notifLoading: false });

                            history.push(
                              `/businesses/${currentBusiness}/customer/${data.customer.customer._id}`
                            );

                            // this.changeValue(
                            //   data.customer.customer._id,
                            //   data._id
                            // );
                          }}
                        >
                          <Row
                            style={{
                              padding: "unset"
                            }}
                          >
                            <Col
                              lg="12"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "unset"
                              }}
                            >
                              <Text
                                textType={"body3"}
                                text={data.topic}
                                style={{ marginRight: "50px" }}
                              />
                              <Text
                                textType={"caption"}
                                text={moment(data.createdDate).format(
                                  "MMM DD, YYYY"
                                )}
                              />
                            </Col>
                            <Col lg="12" style={{ padding: "unset" }}>
                              <Text textType={"caption"} text={data.message} />
                            </Col>
                          </Row>
                        </DropdownItem>
                      )}
                    />
                  );
                })}
                <Route
                  render={({ history }) => (
                    <DropdownItem
                      onClick={() => {
                        history.push(
                          "/businesses/" + currentBusiness + "/notification"
                        );
                      }}
                    >
                      See all notification
                    </DropdownItem>
                  )}
                />
              </DropdownMenu>
            </UncontrolledDropdown>
          ) : (
              <div />
            )}

          <UncontrolledDropdown>
            <DropdownToggle nav className=" d-md-flex">
              <label
                className="m-md-auto cursor-point color-white"
                style={{ cursor: "pointer" }}
              >
                {username ? username : "Admin"} &nbsp;
              </label>
              <span
                className="fa fa-angle-down btn-icon-user margin-left-10"
                style={{ width: "10px", height: "10px", marginTop: "4px" }}
              />
            </DropdownToggle>
            <DropdownMenu>
              {status === true ? (
                <Route
                  render={({ history }) => (
                    <DropdownItem
                      onClick={() => {
                        history.push(
                          "/businesses/" +
                          currentBusiness +
                          "/businessInformation"
                        );
                      }}
                    >
                      Account Information
                    </DropdownItem>
                  )}
                />
              ) : (
                  <div />
                )}
              {window.location.pathname.includes("/admin") === false && (
                <DropdownItem>
                  <a
                    style={{ color: "#23282c" }}
                    href="mailto:info@worksprings.com?subject=Worksprings Feedback"
                  >
                    Feedback
                  </a>
                </DropdownItem>
              )}
              {window.location.pathname.includes("/admin") === false && (
                <Route
                  render={({ history }) => (
                    <DropdownItem
                      onClick={() => {
                        history.push("/setting");
                      }}
                    >
                      Settings
                    </DropdownItem>
                  )}
                />
              )}
              {/* <DropdownItem onClick={() => {
                history.push("/setting")
              }}>Setting</DropdownItem> */}
              <DropdownItem onClick={this.logout}>Logout</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(
  inject("authStore", "notificationStore")(observer(DefaultHeader))
);
// export default DefaultHeader;
