import React, { Component } from "react";
import { FormText } from "reactstrap";
import TimezonePicker from "react-bootstrap-timezone-picker";

class FormikTimezonePicker extends Component {
  render() {
    const {
      field: { name, value },
      form: { errors, setFieldValue },
      formText,
      inputType,
      classes,
      style,
      readonly,
      disabled,
      ...props
    } = this.props;

    const isError = !!errors && !!errors[name];

    return (
      <React.Fragment>
        <TimezonePicker
          name={name}
          autoComplete={"off"}
          disabled={disabled}
          value={value}
          absolute={true}
          className="w-100"
          onChange={newValue => setFieldValue(name, newValue)}
          style={this.props.style}
          {...props}
        />

        {!!isError && <p className="text-danger">{errors[name]}</p>}
        {!!formText && <FormText>{formText}</FormText>}
      </React.Fragment>
    );
  }
}

export default FormikTimezonePicker;
