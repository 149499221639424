import React, { Component, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Col, Row } from "reactstrap";
import { getCookie, deleteAllCookies } from "../../helper/cookie";
import { Container } from "reactstrap";
import SideBar from "./SideBar";
import BlockUI from "react-block-ui";
import { Loader } from "react-loaders";
import "react-block-ui/style.css";
import "loaders.css/loaders.min.css";
import {
  AppAside,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader
} from "@coreui/react";
// sidebar nav config
import navigation from "../../_nav";
// routes config
import routes from "../../routes";
import DefaultAside from "./DefaultAside";
import DefaultHeader from "./DefaultHeader";
import { inject, observer } from "mobx-react";
// import { reactLocalStorage } from "reactjs-localstorage";

class DefaultLayout extends Component {
  state = {
    orgId: "",
    token: getCookie("hunting_current_token")
  };
  loading = () => <div className="animated fadeIn pt-1 text-center" />;

  signOut(e) {
    e.preventDefault();
    deleteAllCookies();
    this.props.history.push("/login");
  }
  pushProps = routes => {
    this.props.history.push(routes);
  };

  onUnload() {
    // the method that will be used for both add and remove event
    if (localStorage.getItem("newTab")) localStorage.removeItem("newTab");
  }

  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.onUnload);
  }

  componentDidMount = async () => {
    const { authStore } = this.props;
    authStore.setEditedData({ notifLoading: false });

    window.addEventListener("beforeunload", this.onUnload);
  };

  render() {
    const { authStore } = this.props;
    const { token } = this.state;
    const { pathname } = this.props.location;
    const { editedData } = authStore;
    const { notifLoading } = editedData;
    // const loginType = localStorage.getItem("loginType")
    //   ? localStorage.getItem("loginType")
    //   : "";
    if (window.location.pathname.includes("/admin") === false) {
      if (pathname !== "/login" && pathname !== "/register") {
        if (!this.props.authStore.isAuthenticated) {
          return <Redirect to="/login" />;
        } else if (!token) {
          return <Redirect to="/404" />;
        }
      }
    } else if (window.location.pathname.includes("/admin") === true) {
      if (pathname !== "/admin/login") {
        if (!this.props.authStore.isAdminAuthenticated) {
          return <Redirect to="/admin/login" />;
        } else if (!token) {
          return <Redirect to="/404" />;
        }
      }
    }

    if (!window.location.pathname.includes("/email")) {
      localStorage.removeItem('emailData')
    }

    // else if (pathname !== "/admin/login") {
    //   if (!this.props.authStore.isAdminAuthenticated) {
    //     return <Redirect to="/admin/login" />;
    //   } else if (!token) {
    //     return <Redirect to="/404" />;
    //   }
    // }

    if (notifLoading === true) {
      return (
        <div className="app">
          <Row>
            <Col
              lg="12"
              style={{
                display: "flex",
                height: "700px",
                alignItems: "center",
                justifyContent: "center"
              }}
            >
              <BlockUI
                tag="div"
                blocking={notifLoading}
                className={"transitionLoading"}
                loader={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Loader
                      active
                      type={"ball-spin-fade-loader"}
                      color="#1665d8"
                    />
                  </div>
                }
              >
                <div style={{ height: "2000px", width: "2000px" }} />
              </BlockUI>
            </Col>
          </Row>
        </div>
      );
    }

    return (
      <div className="app">
        <AppHeader
          fixed
          style={{
            borderBottom: " solid 1px #EAEDF3"
          }}
        >
          <Suspense fallback={this.loading()}>
            <DefaultHeader
              onLogout={e => this.signOut(e)}
              pushProps={this.pushProps}
            />
          </Suspense>
        </AppHeader>
        <div className="app-body">
          <AppSidebar fixed display="lg" className="col-md-3 col-sm-3 col-xs-3">
            <AppSidebarHeader />
            <AppSidebarForm />
            <Suspense>
              <SideBar
                navConfig={navigation}
                {...this.props}
                pushProps={this.pushProps}
              />
            </Suspense>
            <AppSidebarFooter />
          </AppSidebar>

          <main className="main" style={{ background: "white" }}>
            <Container fluid>
              <Suspense fallback={this.loading()}>
                <Switch>
                  {routes.map((route, idx) => {
                    return route.component ? (
                      <Route
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => <route.component {...props} />}
                      />
                    ) : null;
                  })}
                </Switch>
              </Suspense>
            </Container>
          </main>

          <AppAside fixed>
            <Suspense fallback={this.loading()}>
              <DefaultAside />
            </Suspense>
          </AppAside>
        </div>
      </div>
    );
  }
}

export default inject(
  "authStore",
  "commonStore",
  "businessStore"
)(observer(DefaultLayout));
// export default DefaultLayout;
