import axios from "../helper/Axios.js";
import { adminUrl, uploadUrl, superUrl } from "../config/config.js";

export async function getAllForm({
  businessId,
  currentPage,
  pageLimit,
  search
}) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/form?page=${currentPage}&limit=${pageLimit}&search=${search}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getCustomerFormByToken(token) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/customerform/token/${token}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getBusinessForm(businessId, customerId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/customerform/${customerId}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function sendSMS(value, businessId, customerId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/customer/${customerId}/sms`,
      data: value
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function uploadFile(file) {
  try {
    let response = await axios.PostFile({
      url: `${uploadUrl}/upload/file`,
      data: file
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function postForm(businessId, data) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/form`,
      data: data
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function sendFormToCustomer(businessId, data) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/formcustomer`,
      data: data
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function postCustomerForm(data, token) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/customerform/token/${token}/form`,
      data: data
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function updateCustomerForm(data, token) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/customerform/token/${token}`,
      data: data
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function deleteForm(businessId, formId) {
  try {
    let response = await axios.Delete({
      url: `${adminUrl}/business/${businessId}/form/${formId}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function uploadFormBusinessSide(businessId, customerId, value) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/customer/${customerId}/formcustomer`,
      data: value
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

//-----------------------------------------------------------Super Admin--------------------------------------------------------------

export async function getAllFormForAdmin({
  businessId,
  currentPage,
  pageLimit,
  search
}) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/form?business=${businessId}&page=${currentPage}&limit=${pageLimit}&search=${search}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}
