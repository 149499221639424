import { observable, decorate } from "mobx";

class ChangePasswordStore {
  editedData = {};

  setEditedData = editedData => {
    this.editedData = {
      ...this.editedData,
      ...editedData
    };
  };

  openModal = () => {
    this.setEditedData({ open: true });
  };

  closeModal = () => {
    this.setEditedData({ open: false });
  };
}
decorate(ChangePasswordStore, {
  editedData: observable
});

export default new ChangePasswordStore();
