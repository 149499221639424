import { observable, decorate } from "mobx";
import {
  getAllContact,
  getContactById,
  importContact,
  postContact,
  updateContact,
  deleteContact,
  deleteContactBulk,
  getAllContactFromAdmin,
  getContactByIdForAdmin,
  updateContactForAdmin,
  offerSubscription,
  postContactWithoutToken,
  checkContact,
  mergeContact,
} from "../services/ContactService";
import tabStore from "./TabStore";
// import _ from "lodash";
import { reactLocalStorage } from "reactjs-localstorage";
import commonStore from "./CommonStore";
import csc from "country-state-city";
import React from "react";
import moment from "moment";

class ContactStore {
  editedData = {};
  isLoading = false;
  deleteLoading = false;
  contactArray = [];
  currentPage = 0;
  dataToBeExported = [];
  dataSelected = [];
  searchValue = "";
  pageSize = 10;
  totalCount = 0;
  contactField = [
    "firstName",
    "lastName",
    "address1",
    "address2",
    "email",
    "city",
    "state",
    "postalCode",
    "country",
    "phone",
    "note",
    "species",
    "tripType",
    "tripYear",
    "season",
    "subscription",
    "isOffered",
  ];

  valueTransformer = {
    phone: (data) => {
      return (
        <a
          style={{ fontWeight: !!data.isNewContact && "bold" }}
          href={`tel:${data.phone}`}
        >
          {data.phone}
        </a>
      );
    },
    firstName: (data) => (
      <span style={{ fontWeight: !!data.isNewContact && "bold" }}>
        {data.firstName}
      </span>
    ),
    lastName: (data) => (
      <span style={{ fontWeight: !!data.isNewContact && "bold" }}>
        {data.lastName}
      </span>
    ),
    email: (data) => (
      <span style={{ fontWeight: !!data.isNewContact && "bold" }}>
        {data.email}
      </span>
    ),
    createdDate: (data) => (
      <span style={{ fontWeight: !!data.isNewContact && "bold" }}>
        {data.createdDate}
      </span>
    ),
  };
  columns = [
    // { name: "selectAll", title: "Select All" },
    {
      name: "firstName",
      title: "First Name",
      valueTransformer: this.valueTransformer["firstName"],
    },
    {
      name: "lastName",
      title: "Last Name",
      valueTransformer: this.valueTransformer["lastName"],
    },
    {
      name: "email",
      title: "Email",
      valueTransformer: this.valueTransformer["email"],
    },
    {
      name: "phone",
      title: "Phone Number",
      valueTransformer: this.valueTransformer["phone"],
    },
    {
      name: "createdDate",
      title: "Contact Created",
      valueTransformer: this.valueTransformer["createdDate"],
    },
  ];
  columnWidths = [
    // { columnName: "selectAll", width: 100 },
    { columnName: "firstName", width: 200 },
    { columnName: "lastName", width: 200 },
    { columnName: "email", width: 200 },
    { columnName: "phone", width: 200 },
    { columnName: "createdDate", width: 200 },
  ];
  pageSizeEnum = [
    { name: "10", value: 10 },
    { name: "50", value: 50 },
    { name: "100", value: 100 },
    { name: "All", value: 100000 },
  ];
  allYear = [{ value: "", name: "All Year" }];
  year = new Date("2019").getFullYear();
  years = Array.from(new Array(20), (val, index) => index + this.year);
  optTripYear = this.allYear.concat(
    this.years.map((year) => {
      return {
        name: year,
        value: year,
      };
    })
  );
  species = [
    { value: "", name: "All" },
    { value: "Antelope", name: "Antelope" },
    { value: "Bass", name: "Bass" },
    { value: "Black Bear", name: "Black Bear" },
    { value: "Big Horn Sheep", name: "Big Horn Sheep" },
    { value: "Bison", name: "Bison" },
    { value: "Deer", name: "Deer" },
    { value: "Duck", name: "Duck" },
    { value: "Elk", name: "Elk" },
    { value: "Goose", name: "Goose" },
    { value: "Trout", name: "Trout" },
    { value: "Moose", name: "Moose" },
    { value: "Mtn. Goat", name: "Mtn. Goat" },
    { value: "Mtn. Lion", name: "Mtn. Lion" },
    { value: "Sheep", name: "Sheep" },
    { value: "Waterfowl", name: "Waterfowl" },
    { value: "WildT urkey", name: "Wild Turkey" },
  ];
  optTripType = [
    { value: "", name: "All" },
    { value: "Hunting", name: "Hunting" },
    { value: "Fishing", name: "Fishing" },
    { value: "Trail Rides", name: "Trail Rides" },
    { value: "Drop Camp", name: "Drop Camp" },
    { value: "Backcountry", name: "Backcountry" },
    { value: "Combination Hunt/Fish", name: "Combination Hunt/Fish" },
    { value: "Rafting", name: "Rafting" },
    { value: "School", name: "School" },
  ];
  optTripSeason = [
    { value: "", name: "All" },
    { value: "Early Rifle", name: "Early Rifle" },
    { value: "Rifle", name: "Rifle" },
    { value: "Late Rifle", name: "Late Rifle" },
    { value: "Early Archery", name: "Early Archery" },
    { value: "Archery", name: "Archery" },
    { value: "Late Archery", name: "Late Archery" },
    { value: "Early Muzzleloader", name: "Early Muzzleloader" },
    { value: "Muzzleloader", name: "Muzzleloader" },
    { value: "Late Muzzleloader", name: "Late Muzzleloader" },
    { value: "Fly Fishing", name: "Fly Fishing" },
    { value: "Rafting", name: "Rafting" },
    { value: "Spring", name: "Spring" },
    { value: "Summer", name: "Summer" },
    { value: "Fall", name: "Fall" },
    { value: "Winter", name: "Winter" },
    { value: "Backcountry", name: "Backcountry" },
    { value: "Youth Hunt", name: "Youth Hunt" },
    { value: "General", name: "General" },
  ];

  setInitialData = () => {
    this.contactArray = [];
  };

  setEditedData = (editedData) => {
    this.editedData = {
      ...this.editedData,
      ...editedData,
    };
  };

  setPageLimit = (value) => {
    this.pageSize = value;
  };

  setSearchValue = (value) => {
    this.currentPage = 0;
    this.searchValue = value;
  };

  deleteSelectedData = () => {
    this.dataSelected = [];
  };

  setCurrentPage = (value) => {
    this.currentPage = value;
    this.dataSelected = [];
    this.getAllContact();
  };

  initialCurrentPage = (value) => {
    this.currentPage = 0;
  };

  setData = (value) => {
    this.dataSelected = value;
  };

  initialValue = () => {
    this.setEditedData({
      firstName: "",
      lastName: "",
      email: "",
      address1: "",
      address2: "",
      postalCode: "",
      phone: "",
      city: "",
      state: "",
      country: "",
      note: "",
      species: [],
      tripType: {},
      tripYear: [],
      season: [],
      isGuest: false,
      // customNames: [],
      // customLength: 0,
      // custom: []
    });
  };

  getAllContact = async () => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.isLoading = true;

    const { data, error } = await getAllContact({
      businessId: businessId,
      limit: 1000,
      page: this.currentPage + 1,
      search: this.searchValue,
      interestTripType: this.editedData.tripType || "",
      interestTripYear: this.editedData.tripYear || "",
      interestSpecies: this.editedData.species || "",
      interestSeason: this.editedData.season || "",
    });

    this.isLoading = false;

    if (data) {
      this.contactArray = data.contacts.map((data) => {
        const firstName = data.firstName ? data.firstName : "-";
        const lastName = data.lastName ? data.lastName : "-";
        const email = data.email ? data.email : "-";
        const phone = data.phone ? data.phone : "-";
        const createdDate = data.createdDate
          ? moment(data.createdDate).format("MM/DD/YYYY")
          : "";

        return {
          ...data,
          firstName,
          lastName,
          email,
          phone,
          createdDate,
        };
      });
      this.totalCount = data.totalData;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  exportContact = async () => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.isLoading = true;

    const { data, error } = await getAllContact({
      businessId: businessId,
      limit: 100000,
      page: 1,
      search: "",
    });

    this.isLoading = false;

    if (data) {
      this.dataToBeExported = data.contacts.map((data) => {
        const firstName = data.firstName ? data.firstName : "-";
        const lastName = data.lastName ? data.lastName : "-";
        const email = data.email ? data.email : "-";
        const phone = data.phone ? data.phone : "-";
        return {
          ...data,
          firstName,
          lastName,
          email,
          phone,
        };
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getContactById = async (contactId) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.isLoading = true;

    const { data, error } = await getContactById(businessId, contactId);

    if (data) {
      if (data.country === "United States") {
        let country = csc.getAllCountries();
        let tempCountry = country.map((data) => {
          return data.name;
        });

        let tempCountryId = tempCountry.indexOf(data.country);

        this.countryId = country[tempCountryId].isoCode;

        let state = csc.getStatesOfCountry(String(this.countryId));
        let tempState = state.map((data) => {
          return data.name;
        });

        let tempId = "";
        if (!!data.state) {
          tempId = tempState.indexOf(data.state);
          this.stateId = state[tempId].isoCode;
        }

        // let city = csc.getCitiesOfState(String(this.stateId));
        // let tempCity = city.map(data => {
        //   return data.name;
        // });
        // let tempCityId = tempCity.indexOf(String(data.city));

        // this.cityId = city[tempCityId].id;
      }
      // let customNames = []

      // if (!!data.custom) {
      //   customNames = data.custom.map(item => {
      //     return Object.keys(item).toString()
      //   })

      //   for (let i = 0; i < customNames.length; i++) {
      //     const temp = data.custom[i];
      //     this.editedData[customNames[i]] = temp[customNames[i]];
      //   }
      // }

      this.setEditedData({
        firstName: data.firstName ? data.firstName : "-",
        lastName: data.lastName ? data.lastName : "-",
        email: data.email ? data.email : "-",
        subscription: data.subscription ? data.subscription : false,
        isOffered: data.isOffered ? data.isOffered : false,
        isGuest: data.isGuest ? data.isGuest : false,
        country: data.country
          ? {
              label: data.country,
              value: String(this.countryId),
            }
          : "",
        address1: data.address1 ? data.address1 : "-",
        address2: data.address2 ? data.address2 : "-",
        city: data.city ? data.city : "",
        state: !data.state
          ? ""
          : data.country !== "United States"
          ? data.state
          : { label: data.state, value: String(this.stateId) },
        postalCode: data.postalCode ? data.postalCode : "-",
        phone: data.phone ? data.phone : "-",
        note: data.note ? data.note : "",
        tripYear: data.interestTripYear
          ? data.interestTripYear.map((year) => {
              return { value: year, label: year };
            })
          : [],
        tripType: data.interestTripType
          ? { value: data.interestTripType, label: data.interestTripType }
          : {},
        season: data.interestSeason
          ? data.interestSeason.map((season) => {
              return { value: season, label: season };
            })
          : [],
        species: data.interestSpecies
          ? data.interestSpecies.map((species) => {
              return { value: species, label: species };
            })
          : [],
        // customNames,
        // customLength: customNames.length,
        // custom: data.custom ? data.custom : []
      });

      // for (let i = 0; i < customNames.length; i++) {
      //   this.formikInitialValues[customNames[i]] = _.get(this.editedData, `${customNames[i]}`, '')
      // }

      if (!!data.isNewContact) {
        await this.updateContact(
          {
            ...this.editedData,
            country: data.country,
            state: data.state,
            isNewContact: false,
            custom: data.custom ? data.custom : [],
          },
          data._id
        );
        await this.checkContact(data.email, data._id);
      }
      this.isLoading = false;
    } else {
      this.isLoading = false;

      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  importContact = async (values) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.isLoading = true;

    const { data, error } = await importContact(values, businessId);

    this.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Data Imported.");
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  postContact = async (values) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.isLoading = true;

    const { data, error } = await postContact(values, businessId);

    this.isLoading = false;

    if (data) {
      if (values.isOffered) {
        const { success } = await this.offerSubscription(data._id, {
          subscription: false,
        });
        if (success) {
          await new Promise((resolve, reject) => {
            commonStore.showSuccessMessage("Data Created");
            setTimeout(resolve, 500);
          });
          window.location.href = "/businesses/" + businessId + "/contact";
        }
      } else {
        await new Promise((resolve, reject) => {
          commonStore.showSuccessMessage("Data Created");
          setTimeout(resolve, 500);
        });
        window.location.href = "/businesses/" + businessId + "/contact";
      }
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  updateContact = async (values, contactId) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.isLoading = true;

    const { data, error } = await updateContact(values, businessId, contactId);

    this.isLoading = false;

    if (data) {
      // await new Promise((resolve, reject) => {
      //   commonStore.showSuccessMessage("Data Updated");
      //   setTimeout(resolve, 500);
      // });
      // window.location.href = "/businesses/" + businessId + "/contact";
      return { data };
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  deleteContact = async (contactId) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.deleteLoading = true;

    const { error } = await deleteContact(businessId, contactId);

    this.deleteLoading = false;

    if (!error) {
      await new Promise((resolve, reject) => {
        commonStore.showSuccessMessage("Data Deleted");
        setTimeout(resolve, 500);
      });
      window.location.href = "/businesses/" + businessId + "/contact";
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  deleteContactBulk = async (value) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );

    this.deleteLoading = true;

    const { error } = await deleteContactBulk(businessId, value);

    this.deleteLoading = false;

    if (!error) {
      await new Promise((resolve, reject) => {
        commonStore.showSuccessMessage("Data Deleted");
        setTimeout(resolve, 500);
      });
      this.getAllContact();
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  offerSubscription = async (contactId, value) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;
    const { data, error } = await offerSubscription(
      businessId,
      contactId,
      value
    );
    this.isLoading = false;

    if (data) {
      return { success: data };
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "You don't have any permission to View employee's data"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  postContactWithoutToken = async (values, businessId) => {
    this.isLoading = true;

    const { data, error } = await postContactWithoutToken(values, businessId);

    this.isLoading = false;

    if (data) {
      return { data };
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  checkContact = async (values, id) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.isLoading = true;

    const { data, error } = await checkContact(id, businessId, values);

    this.isLoading = false;

    if (data) {
      const contact = data.contacts.map((item) => {
        return item.firstName + " " + item.lastName;
      });

      if (!!data.duplicate) {
        this.setEditedData({ isDuplicateWarning: true, contactList: contact });
      }
      return { data };
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  mergeContact = async (values, id) => {
    let businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.deleteLoading = true;

    const { data, error } = await mergeContact(id, businessId, values);

    this.deleteLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Contact Merged!");
      this.setEditedData({ isDuplicateWarning: false });
      return { data };
    } else {
      this.deleteLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  //-----------------------------------------------------------Super Admin--------------------------------------------------------------

  setCurrentAdminPage = (value) => {
    this.currentPage = value;
    this.dataSelected = [];
    this.getAllContactFromAdmin(this.editedData.businessId);
  };

  getAllContactFromAdmin = async (businessId) => {
    this.isLoading = true;

    const { data, error } = await getAllContactFromAdmin({
      businessId: businessId,
      limit: 10000,
      page: this.currentPage + 1,
      search: this.searchValue,
    });

    this.isLoading = false;

    if (data) {
      this.contactArray = data.contacts.map((data) => {
        const firstName = data.firstName ? data.firstName : "-";
        const lastName = data.lastName ? data.lastName : "-";
        const email = data.email ? data.email : "-";
        const phone = data.phone ? data.phone : "-";

        return {
          ...data,
          firstName,
          lastName,
          email,
          phone,
        };
      });
      this.totalCount = data.totalData;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;

        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getContactByIdForAdmin = async (contactId) => {
    this.isLoading = true;

    const { data, error } = await getContactByIdForAdmin(contactId);

    this.isLoading = false;

    if (data) {
      if (data.country === "United States") {
        let country = csc.getAllCountries();
        let tempCountry = country.map((data) => {
          return data.name;
        });

        let tempCountryId = tempCountry.indexOf(data.country);

        this.countryId = country[tempCountryId].isoCode;

        let state = csc.getStatesOfCountry(String(this.countryId));
        let tempState = state.map((data) => {
          return data.name;
        });

        let tempId = "";

        if (!!data.state) {
          tempId = tempState.indexOf(data.state);
          this.stateId = state[tempId].isoCode;
        }

        // let city = csc.getCitiesOfState(String(this.stateId));
        // let tempCity = city.map(data => {
        //   return data.name;
        // });
        // let tempCityId = tempCity.indexOf(String(data.city));

        // this.cityId = city[tempCityId].id;
      }

      // let customNames = []

      // if (!!data.custom) {
      //   customNames = data.custom.map(item => {
      //     return Object.keys(item).toString()
      //   })

      //   for (let i = 0; i < customNames.length; i++) {
      //     const temp = data.custom[i];
      //     this.editedData[customNames[i]] = temp[customNames[i]];
      //   }
      // }

      this.setEditedData({
        firstName: data.firstName ? data.firstName : "-",
        lastName: data.lastName ? data.lastName : "-",
        email: data.email ? data.email : "-",
        isGuest: data.isGuest ? data.isGuest : false,
        country: {
          label: data.country,
          value: String(this.countryId),
        },
        address1: data.address1 ? data.address1 : "-",
        address2: data.address2 ? data.address2 : "-",
        city: data.city ? data.city : "",
        state:
          data.country !== "United States"
            ? data.state
            : { label: data.state, value: String(this.stateId) },
        postalCode: data.postalCode ? data.postalCode : "-",
        phone: data.phone ? data.phone : "-",
        // customNames,
        // customLength: customNames.length,
        // custom: data.custom ? data.custom : []
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  updateContactForAdmin = async (
    values,
    contactId,
    businessId,
    ownerId,
    history
  ) => {
    this.isLoading = true;

    const { data, error } = await updateContactForAdmin(values, contactId);

    this.isLoading = false;

    if (data) {
      commonStore.showSuccessMessage("Data Updated");
      tabStore.setActiveTab("Contacts");
      history.push(
        "/admin/businessowner/ownerdetail/" +
          ownerId +
          "/business/" +
          businessId
      );
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        commonStore.showErrorMessage(this.errorMessage);
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };
}
decorate(ContactStore, {
  editedData: observable,
  isLoading: observable,
  contactArray: observable,
  deleteLoading: observable,
  formikInitialValues: observable,
  pageSize: observable,
  currentPage: observable,
  searchValue: observable,
  totalCount: observable,
  dataToBeExported: observable,
  dataSelected: observable,
  year: observable,
  years: observable,
  optTripSeason: observable,
  optTripType: observable,
  optTripYear: observable,
  species: observable,
});
export default new ContactStore();
