import { Col, Row, InputGroup } from "reactstrap";
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import { Formik, FastField, Form } from "formik";
import Text from "../../components/Texts";
import Buttons from "../../components/Buttons";
import { validateFormValuesUsingJoiSchema } from "../../helper/functions";
import FormikInputField from "../../components/Formik/FormikInputField";
import Locked from "../../assets/locked.svg";
const Joi = require("joi-browser");
Joi.objectId = require("joi-objectid")(Joi);

const joiSchema = {
  email: Joi.string()
    .label("Email")
    .required()
};

class ForgotPassword extends Component {
  submit = async values => {
    this.props.authStore.forgotPassword(values);
  };

  login = () => {
    const { history } = this.props;
    history.push("/login");
  };

  render() {
    const initialValues = {
      email: "",
      password: ""
    };
    return (
      <Formik
        initialValues={initialValues}
        validate={values =>
          validateFormValuesUsingJoiSchema({ values, joiSchema })
        }
        validateOnChange={false}
        enableReinitialize={true}
        onSubmit={this.submit}
        component={({ values, handleSubmit, setFieldValue, isSubmitting }) => {
          return (
            <div
              className="animated fadeIn app "
              style={{
                backgroundColor: "white",
                display: "flex",
                alignItems: "center"
              }}
            >
              <Form>
                <Row>
                  <Col lg="12" md="12" sm="12" xs="12" className="loginCol">
                    <img
                      src={Locked}
                      alt=""
                      style={{
                        marginTop: "100px"
                      }}
                    />
                  </Col>
                  <Col lg="12" md="12" sm="12" xs="12" className="loginCol">
                    <Text
                      text="Forgot Password"
                      textType="h1"
                      weight="medium"
                      style={{ marginTop: "50px" }}
                    />
                  </Col>
                  <Col lg="12" md="12" sm="12" xs="12" className="loginCol">
                    <Text
                      text="Enter the email associated with your account"
                      textType="body1"
                      weight="regular"
                      style={{ marginTop: "10px" }}
                    />
                  </Col>
                  <Col lg="12">
                    <Row>
                      <Col lg="12" style={{ marginTop: "20px" }}>
                        <InputGroup className="loginCol">
                          <FastField
                            name={"email"}
                            id="outlined-name"
                            variant="outlined"
                            placeholder={"Email"}
                            style={{ width: "326px" }}
                            component={FormikInputField}
                          />
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="12" md="12" sm="12" xs="12" className="loginCol">
                    <Buttons
                      buttonType={"secondary"}
                      type="submit"
                      label={"Submit"}
                      style={{
                        height: "40px",
                        width: "326px",
                        marginTop: "20px"
                      }}
                      disabled={isSubmitting}
                    />
                  </Col>

                  <Col lg="12" md="12" sm="12" xs="12" className="loginCol">
                    <Buttons
                      buttonType={"primary"}
                      type="button"
                      onClick={this.login}
                      label={"Back to Login Page"}
                      style={{
                        height: "40px",
                        width: "326px",
                        marginTop: "20px"
                      }}
                    />
                  </Col>
                </Row>
              </Form>
            </div>
          );
        }}
      />
    );
  }
}
export default inject("authStore")(observer(ForgotPassword));
