/* eslint-disable eqeqeq */
import React, { Component } from "react";
// import { Col, Row } from "reactstrap";
import Paper from "@material-ui/core/Paper";
import {
  Getter,
  Plugin,
} from '@devexpress/dx-react-core';
import {
  PagingState,
  CustomPaging,
  SortingState,
  IntegratedSorting,
  IntegratedFiltering,
  IntegratedSelection,
  // FilteringState,
  SearchState,
  SelectionState,
  IntegratedPaging,
} from "@devexpress/dx-react-grid";
import { TableSelectionCell, TableSelectionHeader } from "./TableSelectionCell";
import { green } from "@material-ui/core/colors";
import {
  Grid,
  Table,
  TableHeaderRow,
  Toolbar,
  // TableFilterRow,
  PagingPanel,
  TableColumnResizing,
  TableSelection,
  SearchPanel,
} from "@devexpress/dx-react-grid-material-ui";
// import classNames from "classnames"
import BlockUi from "react-block-ui";
import "react-block-ui/style.css";
import Text from "./Texts";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    color: green[400],
    "&checked": {
      color: green[600]
    }
  },
  customToolbar: {
    padding: "unset"
  }
};

// const ToolbarRootBase = ({ classes, className, ...restProps }) => (
//   <Toolbar.Root
//     className={classNames(className, classes.customToolbar)}

//     {...restProps}
//   />
// );
// const ToolbarRoot = withStyles(styles)(ToolbarRootBase);

class MainListComponent extends Component {

  tableCell = props => {
    const { row, column } = props;
    let { valueTransformer, customClass, name } = column;
    if (valueTransformer) {
      if (!!row.lastBottomBorderBold) {
        return (
          <Table.Cell
            style={{ borderBottom: '2px solid #A8A8A8' }}
          >
            <div className={customClass}>{row ? valueTransformer(row) : ""}</div>
          </Table.Cell>
        )
      }

      if(!!row.noBorder) {
        if (row.disableValueTransformer) {
          return <Table.Cell 
            style={{ borderBottom: 'unset' }} 
            tableRow={props.tableRow} 
            tableColumn={props.tableColumn}
            row={row}
            column={column} 
            value={props.value}
          />
        }

        return (
          <Table.Cell style={{ borderBottom: 'unset' }}>
            <div className={customClass}>{row ? valueTransformer(row) : ""}</div>
          </Table.Cell>
        );
      }

      if (row.disableValueTransformer) {
        return <Table.Cell 
          tableRow={props.tableRow} 
          tableColumn={props.tableColumn}
          row={row}
          column={column} 
          value={props.value}
        />
      }

      return (
        <Table.Cell>
          <div className={customClass}>{row ? valueTransformer(row) : ""}</div>
        </Table.Cell>
      );
    } else if (customClass) {
      return (
        <Table.Cell>
          <div className={customClass}>{row ? row[name] : ""}</div>
        </Table.Cell>
      );
    }

    if (!!row.noBorder) {
      return <Table.Cell 
        style={{ borderBottom: 'unset' }} 
        tableRow={props.tableRow} 
        tableColumn={props.tableColumn}
        row={row}
        column={column} 
        value={props.value}
      />
    }

    return <Table.Cell {...props} />;
  };

  stubCell = props => {
    const { tableRow } = props;
    const { row } = tableRow;
    if (!!row.noBorder) {
      return <Table.Cell style={{ borderBottom: 'unset' }}/>;
    }

    if (!!row.lastBottomBorderBold) {
      return (
        <Table.Cell
          style={{ borderBottom: '2px solid #A8A8A8' }}
        />
      )
    }
    
    return <Table.Cell {...props} />;
  };

  tableRow = ({ row, ...restProps }, onRowClicked) => {
    if (onRowClicked) {
      return (
        <Table.Row
          {...restProps}
          onClick={() => onRowClicked({ row })}
          style={{
            cursor: "pointer"
          }}
          className="cursor-pointer table-row toggle-hover"
        />
      );
    }
    if (row.forFinalColumn) {
      return (
        <Table.Row
          {...restProps}
          style={{
            backgroundColor: "#F6F9FD",
          }}
        />
      );
    }

    return <Table.Row {...restProps} />;
  };

  render() {

    const {
      rows,
      onLoading,
      columns,
      currentPage,
      onCurrentPageChange,
      pageSize,
      totalCount,
      sorting,
      onSortingChange,
      filterCell,
      onRowClicked,
      disabledColumn,
      columnExtensions,
      pagingPanel,
      isConditionalSelect,
      rowSelectionEnabled,
      inputComponent,
      integratedSearch,
      paperClasses,
      noPaddingToolbar,
      // searchValue,
      // searchOnChange,
      ...restProps
    } = this.props;

    // const rowSelectionEnabled = row => row.name !== "Nivek 123";
    let { columnWidths } = this.props;
    if (!columnWidths || columnWidths.length <= 0) {
      columnWidths = columns.map(column => {
        return {
          columnName: column.name,
          width: 160
        };
      });
    }

    return (
      <Paper style={{ marginBottom: "30px", marginTop: "20px" }} classes={paperClasses}>
        <BlockUi tag="div" blocking={onLoading}>
          <Grid rows={rows} columns={columns}>
            {/* <Template name="header">
              <div> */}
            {/* keep existing header template */}
            {/* <TemplatePlaceholder /> */}
            {/* add footer template */}
            {/* <TemplatePlaceholder name="footer" />
              </div>
            </Template> */}

            <PagingState
              currentPage={currentPage}
              defaultCurrentPage={this.props.defaultCurrentPage}
              onCurrentPageChange={onCurrentPageChange}
              pageSize={pageSize}
            />

            {this.props.checkbox && (
              <SelectionState
                selection={this.props.selection}
                onSelectionChange={this.props.onChange}
              />
            )}
            {integratedSearch && <SearchState
            // value={searchValue}
            // onValueChange={searchOnChange}
            />}


            <SortingState
              sorting={sorting}
              onSortingChange={onSortingChange}
              columnExtensions={disabledColumn}
            />
            <CustomPaging totalCount={totalCount} />

            {!isConditionalSelect && integratedSearch && <IntegratedFiltering />}
            {this.props.checkbox && !isConditionalSelect && <IntegratedSelection />}
            {/* {this.props.checkbox && <IntegratedSelection />} */}

            {isConditionalSelect &&
              <Plugin>
                {integratedSearch &&
                  <IntegratedFiltering />}
                <Getter
                  name="rows"
                  computed={({ rows }) => {
                    this.rows = rows;
                    return rows.filter(rowSelectionEnabled);
                  }}
                />
                <IntegratedSelection {...restProps} />

                {/* {integratedSearch &&
                  <IntegratedFiltering />} */}
                {/* <Getter
                  name="rows"
                  computed={() => this.rows}
                /> */}
              </Plugin>
            }
            {/* {integratedSearch &&
              <IntegratedFiltering />} */}

            {this.props.integratedPaging ? (
              <IntegratedPaging />
            ) : (
                <CustomPaging totalCount={totalCount} />
              )}

            <IntegratedSorting />

            <Table
              rowComponent={params => this.tableRow(params, onRowClicked)}
              cellComponent={this.tableCell}
              columnExtensions={columnExtensions}
              stubCellComponent={this.stubCell}
            />
            {/* {filterCell && <FilteringState />} */}
            {/* {filterCell && <IntegratedFiltering />*/}
            {/* {filterCell && <TableFilterRow cellComponent={filterCell} />} */}
            <TableColumnResizing defaultColumnWidths={columnWidths} />
            <TableHeaderRow />
            {integratedSearch && <Toolbar />}
            {/* <TableColumnVisibility hiddenColumnNames={rows.filter(item => {
              return !item.subscription
            })} /> */}
            {integratedSearch && <SearchPanel inputComponent={inputComponent} />}
            {this.props.checkbox && (
              <div>
                {this.props.selectAllLabel === true && (
                  <Text
                    text="Select All"
                    textType="caption"
                    style={{ marginLeft: "10px", marginTop: "5px" }}
                  />
                )}
                <div style={{ marginTop: "-15px" }}>
                  {isConditionalSelect ?
                    <TableSelection
                      showSelectAll={this.props.selectAll}
                      headerCellComponent={TableSelectionHeader}
                      cellComponent={(props) => rowSelectionEnabled(props.tableRow.row) ? (
                        <TableSelection.Cell {...props} />
                      ) : (
                          <Table.StubCell {...props} />
                        )}
                      {...restProps}
                    /> :
                    <TableSelection
                      cellComponent={TableSelectionCell}
                      showSelectAll={this.props.selectAll}
                      children={TableSelectionCell}
                      headerCellComponent={TableSelectionHeader}
                    />}
                </div>
              </div>
            )}
            {pagingPanel == true && <PagingPanel />}

          </Grid>
        </BlockUi>
      </Paper>
    );
  }
}

export default withStyles(styles)(MainListComponent);
