import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function getAllReport({
  businessId,
  pageLimit,
  currentPage,
  guide,
  startDate,
  endDate,
  startDateBooking,
  endDateBooking,
  docType,
}) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/report?limit=${pageLimit}&page=${currentPage}&guide=${
        guide ? guide : ""
      }&startDate=${startDate ? startDate : ""}&endDate=${
        endDate ? endDate : ""
      }&startDateBooking=${
        startDateBooking ? startDateBooking : ""
      }&endDateBooking=${
        endDateBooking ? endDateBooking : ""
      }&docType=${docType}`,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function createReport({ businessId, data }) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/report`,
      data: data,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function deleteReport({ businessId, reportId }) {
  try {
    let response = await axios.Delete({
      url: `${adminUrl}/business/${businessId}/report/${reportId}`,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function createCustomerYearReport(data, businessId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/customeryearreport`,
      data: data,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function createContactYearReport(data, businessId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/contactyearreport`,
      data: data,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function createCustomerStatusReport(data, businessId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/customerstatusreport`,
      data: data,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function createBookingReport(data, businessId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/bookingreport`,
      data: data,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}
