import { observable, decorate } from "mobx";

class DeleteWarningStore {
  editedData = {};

  setEditedData = editedData => {
    this.editedData = {
      ...this.editedData,
      ...editedData
    };
  };

  showWarning = () => {
    this.setEditedData({ open: true });
  };

  hideWarning = () => {
    this.setEditedData({ open: false });
  };
}
decorate(DeleteWarningStore, {
  editedData: observable
});

export default new DeleteWarningStore();
