import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import { inject, observer } from "mobx-react";
import Envelope from "../../assets/envelope.svg";
import BlockUI from "react-block-ui";
import { Loader } from "react-loaders";
import "react-block-ui/style.css";
import "loaders.css/loaders.min.css";
import Buttons from "../../components/Buttons";
import Text from "../../components/Texts";

class VerifyEmail extends Component {
  verify = async () => {
    const { authStore } = this.props;
    const params = new URLSearchParams(this.props.location.search);
    const tags = params.get("email");
    await authStore.verifyEmail(tags);
  };

  backToLogin = () => {
    const { history } = this.props;
    history.push("/login");
  };

  render() {
    const { authStore } = this.props;
    const { authLoading } = authStore;
    return (
      <div
        className="animated fadeIn app "
        style={{
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          padding: "unset"
        }}
      >
        <BlockUI
          tag="div"
          blocking={authLoading}
          loader={
            <div
              style={{
                display: "flex",
                justifyContent: "center"
              }}
            >
              <Loader active type={"ball-spin-fade-loader"} color="#1665d8" />
            </div>
          }
        >
          <Row>
            <Col
              lg="12"
              md="12"
              sm="12"
              xs="12"
              className="loginCol"
              style={{ marginTop: "100px" }}
            >
              <img
                src={Envelope}
                alt=""
                style={{
                  marginTop: "50px"
                }}
              />
            </Col>
            <Col lg="12" md="12" sm="12" xs="12" className="loginCol">
              <Text
                text="Verify Your Email"
                textType="h1"
                weight="medium"
                style={{ marginTop: "50px" }}
              />
            </Col>
            <Col
              lg="12"
              md="12"
              sm="12"
              xs="12"
              className="loginCol"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Text
                text="Thank you for joining Worksprings. To activate your account, you need to verify"
                textType="body1"
                weight="regular"
              />
              <br />
              <Text
                text="your email first. Please check your inbox."
                textType="body1"
                style={{ marginTop: "-20px" }}
                weight="regular"
              />
            </Col>
            <Col lg="12">
              <Row>
                <Col lg="12" className="loginCol" style={{ marginTop: "10px" }}>
                  <Text
                    text="Did not receive the email ?"
                    textType="body3"
                    weight="regular"
                  />
                </Col>
                <Col lg="12" className="loginCol">
                  <Buttons
                    buttonType={"primary"}
                    label={"Resend Email"}
                    style={{
                      height: "40px",
                      width: "326px",
                      marginTop: "15px"
                    }}
                    onClick={this.verify}
                  />
                </Col>
                <Col lg="12" className="loginCol">
                  <Buttons
                    buttonType={"secondary"}
                    label={"Back to Login Page"}
                    style={{
                      height: "40px",
                      width: "326px",
                      marginTop: "15px"
                    }}
                    onClick={this.backToLogin}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </BlockUI>
      </div>
    );
  }
}

export default inject("authStore")(observer(VerifyEmail));
// export default Login;
