import axios from "../helper/Axios.js";
import { adminUrl, superUrl } from "../config/config.js";

export async function getAllHuntType({
  businessId,
  currentPage,
  pageLimit,
  search
}) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/activity?page=${currentPage}&limit=${pageLimit}&search=${search}`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getHuntTypeById(businessId, huntId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/activity/${huntId}`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function postHuntType(huntTypeDTO, businessId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/activity`,
      data: {
        ...huntTypeDTO
      }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function putHuntType(huntTypeDTO, businessId, huntId) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/activity/${huntId}`,
      data: {
        ...huntTypeDTO
      }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function deleteHuntType(businessId, huntId) {
  try {
    let response = await axios.Delete({
      url: `${adminUrl}/business/${businessId}/activity/${huntId}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getAllHuntSession(params = {}, businessId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/activitysession`,
      params: {
        ...params
      }
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getAllHuntSessionByHunt({
  huntId,
  currentPage,
  pageLimit
}) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/activity/${huntId}/activitysession?page=${currentPage}&limit=${pageLimit}`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}
export async function getHuntSessionById(huntId, huntSessionId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/activity/${huntId}/activitysession/${huntSessionId}`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}
export async function postHuntSession(huntSessionDTO, huntId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/activity/${huntId}/activitysession`,
      data: {
        ...huntSessionDTO
      }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function putHuntSession(huntSessionDTO, huntId, huntSessionId) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/activity/${huntId}/activitysession/${huntSessionId}`,
      data: {
        ...huntSessionDTO
      }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}
export async function deleteHuntSession(huntId, huntSessionId) {
  try {
    let response = await axios.Delete({
      url: `${adminUrl}/activity/${huntId}/activitysession/${huntSessionId}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getMonthlySessionQuota(params, businessId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/remainingsession`,
      params: {
        ...params
      }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

//-----------------------------------------------------------Super Admin--------------------------------------------------------------

export async function getAllHuntTypeForAdmin({
  businessId,
  currentPage,
  pageLimit,
  search
}) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/activities?business=${businessId}&page=${currentPage}&limit=${pageLimit}&search=${search}`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getHuntTypeByIdForAdmin(huntId) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/activity/${huntId}`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function putHuntTypeForAdmin(huntTypeDTO, huntId) {
  try {
    let response = await axios.Put({
      url: `${superUrl}/activity/${huntId}`,
      data: {
        ...huntTypeDTO
      }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getHuntSessionByHuntForAdmin({
  huntId,
  currentPage,
  pageLimit
}) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/activitysessions?activity=${huntId}&page=${currentPage}&limit=${pageLimit}`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getHuntSessionByIdForAdmin(huntSessionId) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/activitysession/${huntSessionId}`
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function putHuntSessionForAdmin(huntSessionDTO, huntSessionId) {
  try {
    let response = await axios.Put({
      url: `${superUrl}/activitysession/${huntSessionId}`,
      data: {
        ...huntSessionDTO
      }
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}
