import React from "react";
// import { Col, Row } from "reactstrap";
// import Paper from "@material-ui/core/Paper";
// import { green } from "@material-ui/core/colors";
import { TableSelection, Table } from "@devexpress/dx-react-grid-material-ui";
import { withStyles } from "@material-ui/core/styles";

const styles = () => ({
  colorSecondary: {
    color: "black !important",
    "&checked": {
      color: "black"
    },
    "& span": {
      color: "black"
    }
  }
});

const tableHeaderSelection = ({
  someSelected,
  allSelected,
  onToggle,
  disabled,
  classes
}) => {
  return (
    <TableSelection.HeaderCell
      someSelected={someSelected}
      allSelected={allSelected}
      onToggle={onToggle}
      disabled={disabled}
      className={classes.colorSecondary}
    ></TableSelection.HeaderCell>
  );
};

const tableSelection = ({ row, selected, onToggle, classes }) => {
  return (
    <TableSelection.Cell
      row={row}
      onToggle={onToggle}
      selected={selected}
      colSpan={0}
      className={classes.colorSecondary}
    >
      <Table.Cell className={classes.colorSecondary}></Table.Cell>
    </TableSelection.Cell>
  );
};


export const TableSelectionHeader = withStyles(styles, { name: "MuiCheckbox" })(
  tableHeaderSelection
);

export const TableSelectionCell = withStyles(styles, {
  name: "MuiCheckbox"
})(tableSelection);

