import React, { Component } from "react";
import { FormText } from "reactstrap";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";

const styles = {
  root: {
    fontFamily: "Roboto",
    fontSize: "14px",
    height: "16px"
  },
  select: {
    padding: "12px"
  }
};

class FormikSelectField extends Component {
  onChange = event => {
    const { target } = event;
    const {
      onChange,
      form: { setFieldValue },
      field: { name }
    } = this.props;

    setFieldValue(name, target.value);
    if (typeof onChange === "function") {
      onChange.call(this, event);
    }
  };
  render() {
    const {
      field: { name, value },
      form: { errors },
      formText,
      inputType,
      options,
      classes,
      cscpicker,
      ...props
    } = this.props;

    const isError = !!errors && !!errors[name];
    return (
      <React.Fragment>
        <Select
          {...props} //props put first and onChange last, because there is onChange key which can override the preferred onChange
          invalid={isError.toString()}
          value={value}
          displayEmpty
          input={<OutlinedInput labelWidth={0} name={name} />}
          classes={classes}
          disabled={this.props.disabled}
          onChange={this.onChange}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={!cscpicker ? option.value : option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>

        {!!isError && <p className="text-danger">{errors[name]}</p>}
        {!!formText && <FormText>{formText}</FormText>}
      </React.Fragment>
    );
  }
}
export default withStyles(styles)(FormikSelectField);
