import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import CloseIcon from "@material-ui/icons/Close";
// import green from "@material-ui/core/colors/green";
// import amber from "@material-ui/core/colors/amber";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import WarningIcon from "@material-ui/icons/Warning";
import { withStyles } from "@material-ui/core/styles";
import { inject, observer } from "mobx-react";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const styles1 = theme => ({
  success: {
    backgroundColor: "green"
  },
  error: {
    backgroundColor: "red"
  },
  info: {
    backgroundColor: "blue"
  },
  warning: {
    backgroundColor: "orange"
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: "5px"
  },
  message: {
    display: "flex",
    alignItems: "center"
  }
});

function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>
      ]}
      {...other}
    />
  );
}
MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired
};
const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);
const styles2 = theme => ({
  margin: {
    margin: "10px"
  }
});
class AppSnackbar extends React.Component {
  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.props.commonStore.closeAppSnackbar();
  };
  render() {
    const {
      snackbarShouldOpen,
      snackbarVariant,
      snackbarMessage
    } = this.props.commonStore;
    const isMobile = window.innerWidth <= 500;
    const verticalAnchorOrigin = "top";
    const horizontalAnchorOrigin = isMobile ? "right" : "center";

    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: verticalAnchorOrigin,
            horizontal: horizontalAnchorOrigin
          }}
          open={snackbarShouldOpen}
          autoHideDuration={3000}
          onClose={this.handleClose}
        >
          <MySnackbarContentWrapper
            onClose={this.handleClose}
            variant={snackbarVariant} // error, warning, info, success
            message={snackbarMessage}
          />
        </Snackbar>
      </div>
    );
  }
}
export default withStyles(styles2)(
  inject("commonStore")(observer(AppSnackbar))
);
