import React, { Component } from "react";
import { Input } from "reactstrap";
import { FormText } from "reactstrap";

class TextArea extends Component {
  render() {
    const {
      field: { onChange, name, value },
      form: { errors },
      formText,
      inputType,
      classes,
      style,
      ...props
    } = this.props;
    const isError = !!errors && !!errors[name];
    return (
      <React.Fragment>
        <Input
          type="textarea"
          name={name}
          value={value}
          onChange={onChange}
          style={this.props.style}
          {...props}
        />
        {!!isError && <p className="text-danger">{errors[name]}</p>}
        {!!formText && <FormText>{formText}</FormText>}
      </React.Fragment>
    );
  }
}
export default TextArea;
