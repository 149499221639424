import React, { Component } from 'react';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const styles = {
  switchBase: {
    color: '#fff',
  },
  track: {
    color: '#6C7FE8B',
  },
};

class View extends Component {
  render() {
    const { value, name, classes, label, onChange, onClick, checked } = this.props;

    return (
      <>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                className="switch-wrapper"
                value={value}
                checked={checked}
                color='default'
                name={name}
                onChange={onChange}
                onClick={onClick}
                classes={{
                  switchBase: classes.switchBase,
                  track: classes.track,
                }}
              />
            }
            label={label}
          />
        </FormGroup>
      </>
    );
  }
}

export default withStyles(styles)(View);
