import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function getAllWebform({ businessId }) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/businessform`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getSingleWebform({ businessId, webformId }) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/businessform/${webformId}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function createWebform({ businessId, data }) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/businessform`,
      data
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function updateWebform({ businessId, data, webformId }) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/businessform/${webformId}`,
      data
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function deleteWebform({ businessId, webformId }) {
  try {
    let response = await axios.Delete({
      url: `${adminUrl}/business/${businessId}/businessform/${webformId}`,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function postContactWithoutToken({ value, businessId }) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/free-contact`,
      data: value
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}