import React, { Component } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

class LoadingCircular extends Component {
  render() {
    return (
      <div
        className="loading-container"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <CircularProgress className="loading-circular" />
        <span style={{ marginTop: "16px" }}>Loading</span>
      </div>
    );
  }
}

export default LoadingCircular;
