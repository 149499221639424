import React, { Component } from "react";
import { Col, InputGroup, Row } from "reactstrap";
import { inject, observer } from "mobx-react";
import Locked from "../../assets/locked.svg";
import ReactTooltip from "react-tooltip";
import { Formik, Field, Form } from "formik";
import FormikPassword from "../../components/Formik/FormikPassword";
import Buttons from "../../components/Buttons";
import Text from "../../components/Texts";
import { validateFormValuesUsingJoiSchema } from "../../helper/functions";
// import { Redirect } from "react-router-dom";
import commonStore from "../../stores/CommonStore";

const Joi = require("joi-browser");
Joi.objectId = require("joi-objectid")(Joi);

const joiSchema = {
  password: Joi.string()
    .label("Password")
    .required()
    .regex(
      new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")
    ),
  confirmPassword: Joi.string()
    .label("Confirm Password")
    .required()
    .regex(
      new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")
    )
};
class ResetPassword extends Component {
  state = {
    showPassword: false
  };

  submit = async values => {
    const { password, confirmPassword } = values;
    if (password === confirmPassword) {
      const params = new URLSearchParams(this.props.location.search);
      const tags = params.get("token");
      await this.props.authStore.resetPassword(password, tags);
    } else {
      commonStore.showErrorMessage("Password does not match");
    }
  };

  render() {
    const initialValues = {
      firstName: "",
      lastName: "",
      email: "",
      password: ""
    };
    return (
      <Formik
        initialValues={initialValues}
        validate={values =>
          validateFormValuesUsingJoiSchema({ values, joiSchema })
        }
        validateOnChange={false}
        enableReinitialize={true}
        onSubmit={this.submit}
        component={({ values, handleSubmit, setFieldValue, isSubmitting }) => {
          return (
            <div
              className="animated fadeIn app "
              style={{
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                padding: "unset"
              }}
            >
              <Form>
                <Row>
                  <Col
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    className="loginCol"
                    style={{ marginTop: "100px" }}
                  >
                    <img
                      src={Locked}
                      alt=""
                      style={{
                        marginTop: "50px"
                      }}
                    />
                  </Col>
                  <Col lg="12" md="12" sm="12" xs="12" className="loginCol">
                    <Text
                      text="Change Password"
                      textType="h1"
                      weight="medium"
                      style={{ marginTop: "50px" }}
                    />
                  </Col>
                  <Col
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    className="loginCol"
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    <Text
                      text="Please Enter Your New Password"
                      textType="body1"
                      weight="regular"
                    />
                  </Col>
                  <Col lg="12">
                    <Row>
                      <Col lg="12" style={{ marginTop: "20px" }}>
                        <InputGroup className="loginCol">
                          <Field
                            name={"password"}
                            data-tip="React-tooltip"
                            id="outlined-adornment-password"
                            variant="outlined"
                            placeholder={"Enter New Password"}
                            inputType={"password"}
                            component={FormikPassword}
                            style={{ width: "326px" }}
                          />
                          <ReactTooltip
                            place="right"
                            type="dark"
                            effect="solid"
                          >
                            <p className="a">
                              Password must be minimum 8 character
                            </p>
                            <p className="a">long with no spaces</p>
                            <p className="a">- Use letters and numbers</p>
                            <p className="a">
                              - Use special characters (e.g. $@)
                            </p>
                            <p className="a">
                              - Mix lower and upper case character
                            </p>
                          </ReactTooltip>
                        </InputGroup>
                      </Col>
                      <Col lg="12" style={{ marginTop: "20px" }}>
                        <InputGroup className="loginCol">
                          <Field
                            name={"confirmPassword"}
                            id="outlined-adornment-password"
                            variant="outlined"
                            placeholder={"Confirm New Password"}
                            inputType={"password"}
                            component={FormikPassword}
                            style={{ width: "326px" }}
                          />
                        </InputGroup>
                      </Col>
                      <Col
                        lg="12"
                        md="12"
                        sm="12"
                        xs="12"
                        className="loginCol"
                        style={{ marginTop: "20px" }}
                      >
                        <Buttons
                          buttonType={"primary"}
                          label={"Change Password"}
                          style={{
                            height: "40px",
                            width: "326px",
                            marginTop: "15px"
                          }}
                          disabled={isSubmitting}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Form>
            </div>
          );
        }}
      />
    );
  }
}

export default inject("authStore")(observer(ResetPassword));
// export default Login;
