import {
  getAllHuntSession,
  getAllHuntSessionByHunt,
  getHuntSessionById,
  postHuntSession,
  putHuntSession,
  deleteHuntSession,
  getMonthlySessionQuota,
  getHuntSessionByHuntForAdmin,
  getHuntSessionByIdForAdmin,
  putHuntSessionForAdmin
} from "../services/HuntService";
import { observable, action, decorate } from "mobx";
import moment from "moment";
import CommonStore from "./CommonStore";

class HuntSessionStore {
  editedData = {};
  currentPage = 0;
  pageSize = 10;
  deleteLoading = false;
  totalCount = 0;
  huntId = "";
  huntSessionArray = [];
  huntSessionQuotaArray = [];
  tempArray = [];
  filterArray = [];
  eventArray = [];
  isLoading = false;
  columns = [
    { name: "name", title: "Service Name" },
    { name: "startTime", title: "Start Date & Time" },
    { name: "endTime", title: "End Date & Time" },
    { name: "price", title: "Price" },
    { name: "maxCapacity", title: "Max Capacity" },
    { name: "activitySessionID", title: "Service ID" }
  ];
  columnWidths = [
    { columnName: "name", width: 200 },
    { columnName: "startTime", width: 200 },
    { columnName: "endTime", width: 200 },
    { columnName: "price", width: 200 },
    { columnName: "maxCapacity", width: 200 },
    { columnName: "activitySessionID", width: 200 }
  ];

  initEditedData = () => {
    this.editedData = {};
  };

  setInitialData = () => {
    this.huntSessionArray = [];
  };

  setHuntId = value => {
    this.huntId = value;
  };

  setCurrentPage = value => {
    this.currentPage = value;
    this.getAllHuntSessionByHunt(this.huntId);
  };

  setTempArray = value => {
    this.tempArray = value;
  };

  setFilterArray = value => {
    this.filterArray = value;
  };

  setEventArray = value => {
    this.eventArray = value;
  };

  setEditedData = editedData => {
    this.editedData = {
      ...this.editedData,
      ...editedData
    };
  };

  getAllHuntSession = async ({ params, businessId }) => {
    this.isLoading = true;
    const { data, error } = await getAllHuntSession(params, businessId);
    if (data) {
      this.isLoading = false;
      this.huntSessionArray = data
        ? data.map(data => {
          const _id = data._id ? data._id : "";
          const activitySessionID = data.activitySessionID
            ? data.activitySessionID
            : "";
          const name = data.name ? data.name : "";
          const price = data.price ? data.price : "";
          const remaining = data.remaining ? data.remaining : "";
          const startTime = data.startTime
            ? moment(data.startTime).format("DD-MM-YYYY HH:mm ")
            : "";
          const endTime = data.endTime
            ? moment(data.endTime).format("DD-MM-YYYY HH:mm ")
            : "";
          const title = data.name ? data.name : "";
          const start = data.startTime ? new Date(data.startTime) : "";
          const end = data.endTime ? new Date(data.endTime) : "";

          return {
            ...data,
            _id,
            activitySessionID,
            name,
            price,
            remaining,
            startTime,
            endTime,
            title,
            start,
            end
          };
        })
        : [];
    } else {
      this.isLoading = false;

      if (error.data) {
        this.errorMessage = error.data.message;
        CommonStore.showErrorMessage(this.errorMessage);
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };
  getAllHuntSessionByHunt = async huntId => {
    this.isLoading = true;
    this.setHuntId(huntId);
    const { data, error } = await getAllHuntSessionByHunt({
      huntId: this.huntId,
      currentPage: this.currentPage + 1,
      pageLimit: this.pageSize
    });

    if (data) {
      this.isLoading = false;

      this.huntSessionArray = data.activitySessions.map(data => {
        const _id = data._id ? data._id : "";
        const activitySessionID = data.activitySessionID
          ? data.activitySessionID
          : "";
        const name = data.name ? data.name : "";
        const remaining = data.remaining ? data.remaining : "";
        const startTime = data.startTime
          ? moment(data.startTime).format("MMM D, YYYY HH:mm")
          : "";
        const endTime = data.endTime
          ? moment(data.endTime).format("MMM D, YYYY HH:mm")
          : "";
        const price = data.price
          ? String(data.price).includes(".") ?
            "$ " + data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") :
            "$ " + data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
            ".00"
          : "";

        return {
          ...data,
          _id,
          activitySessionID,
          name,
          remaining,
          startTime,
          endTime,
          price
        };
      });

      this.totalCount = data.totalData;
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "You don't have any permission to View Hunt's data"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getHuntSessionById = async (huntId, huntSessionId) => {
    this.isLoading = true;
    const { data, error } = await getHuntSessionById(huntId, huntSessionId);
    this.isLoading = false;
    if (data) {
      data.employee = data.employee ? data.employee._id : "";
      this.setEditedData({
        ...data,
        ...data.customFieldActivitySession,
        price: String(data.price),
        tripLodgingDeductions: data.customFieldActivitySession
          ? String(data.customFieldActivitySession.tripLodgingDeductions)
          : "",
        tripTransportationDeductions: data.customFieldActivitySession
          ? String(data.customFieldActivitySession.tripTransportationDeductions)
          : ""
      });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "You don't have any permission to View Hunt's data"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  postHuntSession = async (values, huntId) => {
    const huntSessionDTO = {
      name: values.huntName,
      maxCapacity: values.maxCapacity,
      startTime: values.startTime,
      endTime: values.endTime,
      imageUrl: values.imageUrl,
      price: Number(values.price),
      isTimeSet: values.isTimeSet,
      clientDays: values.clientDays,
      tripLodgingDeductions: Number(values.tripLodgingDeductions),
      timeSpentOnPublicLand: values.timeSpentOnPublicLand,
      areaOrRoutes: values.areaOrRoutes,
      tripTransportationDeductions: Number(values.tripTransportationDeductions),
      location: values.location,
      isSalesTaxActive: values.isSalesTaxActive,
      isJuniorHunterFeeActive: values.isJuniorHunterFeeActive
      // employee: values.employee
    };
    this.isLoading = true;

    const { data, error } = await postHuntSession(huntSessionDTO, huntId);

    this.isLoading = false;
    if (data) {
      await new Promise((resolve, reject) => {
        CommonStore.showSuccessMessage("Data added!");
        setTimeout(resolve, 1000);
      });
      return true;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
      return false;
    }
  };

  putHuntSession = async (values, huntId, huntSessionId) => {
    const huntSessionDTO = {
      name: values.huntName,
      maxCapacity: values.maxCapacity,
      startTime: values.startTime,
      endTime: values.endTime,
      price: Number(values.price),
      isTimeSet: values.isTimeSet,
      clientDays: values.clientDays,
      tripLodgingDeductions: values.tripLodgingDeductions,
      timeSpentOnPublicLand: values.timeSpentOnPublicLand,
      areaOrRoutes: values.areaOrRoutes,
      tripTransportationDeductions: values.tripTransportationDeductions,
      location: values.location,
      isSalesTaxActive: values.isSalesTaxActive,
      isJuniorHunterFeeActive: values.isJuniorHunterFeeActive
      // employee: values.employee
    };
    this.isLoading = true;
    const { data, error } = await putHuntSession(
      huntSessionDTO,
      huntId,
      huntSessionId
    );
    this.isLoading = false;
    if (data) {
      await new Promise((resolve, reject) => {
        CommonStore.showSuccessMessage("Data updated!");
        setTimeout(resolve, 500);
      });
      return true;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
      return false;
    }
  };

  deleteHuntSession = async (huntId, huntSessionId) => {
    this.deleteLoading = true;
    const { error } = await deleteHuntSession(huntId, huntSessionId);
    this.deleteLoading = false;
    if (!error) {
      await new Promise((resolve, reject) => {
        CommonStore.showSuccessMessage("Data deleted!");
        setTimeout(resolve, 2000);
      });
      return true;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
      return false;
    }
  };

  getMonthlySessionQuota = async (params, businessId) => {
    this.isLoading = true;

    const { data, error } = await getMonthlySessionQuota(params, businessId);
    this.isLoading = false;
    if (data) {
      this.huntSessionQuotaArray = data.sessionAvailabilityData;

    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        CommonStore.showErrorMessage(this.errorMessage);
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  //-----------------------------------------------------------Super Admin--------------------------------------------------------------
  setCurrentAdminPage = value => {
    this.currentPage = value;
    this.getHuntSessionByHuntForAdmin(this.editedData.huntId);
  };

  getHuntSessionByHuntForAdmin = async huntId => {
    this.isLoading = true;

    const { data, error } = await getHuntSessionByHuntForAdmin({
      huntId: huntId,
      currentPage: this.currentPage + 1,
      pageLimit: this.pageSize
    });

    if (data) {
      this.isLoading = false;

      this.huntSessionArray = data.activitySessions.map(data => {
        const _id = data._id ? data._id : "";
        const activitySessionID = data.activitySessionID
          ? data.activitySessionID
          : "";
        const name = data.name ? data.name : "";
        const remaining = data.remaining ? data.remaining : "";
        const startTime = data.startTime
          ? moment(data.startTime).format("MMM D, YYYY HH:mm")
          : "";
        const endTime = data.endTime
          ? moment(data.endTime).format("MMM D, YYYY HH:mm")
          : "";
        const price = data.price
          ? "$ " +
          data.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
          ".00"
          : "";
        const guide =
          data.employee && data.employee.firstName
            ? `${data.employee.firstName} ${data.employee.lastName}`
            : "Employee Name";

        return {
          ...data,
          _id,
          activitySessionID,
          name,
          remaining,
          startTime,
          endTime,
          guide,
          price
        };
      });

      this.totalCount = data.totalData;
    } else {
      this.isLoading = false;
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          CommonStore.showErrorMessage(
            "You don't have any permission to View Hunt's data"
          );
        } else {
          CommonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  getHuntSessionByIdForAdmin = async huntSessionId => {
    this.isLoading = true;
    const { data, error } = await getHuntSessionByIdForAdmin(huntSessionId);
    this.isLoading = false;
    if (data) {
      // data.employee = data.employee._id;
      this.setEditedData({ ...data, price: String(data.price) });
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        CommonStore.showErrorMessage(this.errorMessage);
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };

  putHuntSessionForAdmin = async (
    values,
    huntId,
    huntSessionId,
    ownerId,
    businessId,
    history
  ) => {
    const huntSessionDTO = {
      name: values.huntName,
      maxCapacity: values.maxCapacity,
      startTime: values.startTime,
      endTime: values.endTime,
      price: Number(values.price),
      isTimeSet: values.isTimeSet,
      location: values.location,
      isSalesTaxActive: values.isSalesTaxActive,
      isJuniorHunterFeeActive: values.isJuniorHunterFeeActive
    };
    this.isLoading = true;
    const { data, error } = await putHuntSessionForAdmin(
      huntSessionDTO,
      huntSessionId
    );
    this.isLoading = false;
    if (data) {
      await new Promise((resolve, reject) => {
        CommonStore.showSuccessMessage("Data updated!");
        history.push(
          `/admin/businessowner/ownerdetail/${ownerId}/business/${businessId}/activity/${huntId}`
        );
      });
      return true;
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        CommonStore.showErrorMessage(this.errorMessage);
      } else {
        CommonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
      return false;
    }
  };
}

decorate(HuntSessionStore, {
  editedData: observable,
  huntSessionArray: observable,
  huntSessionQuotaArray: observable,
  deleteLoading: observable,
  currentPage: observable,
  pageSize: observable,
  totalCount: observable,
  isLoading: observable,
  tempArray: observable,
  filterArray: observable,
  huntId: observable,
  eventArray: observable,

  getAllHuntSession: action,
  getHuntTypeById: action,
  postHuntSession: action,
  putHuntSession: action,
  deleteHuntSession: action,
  getMonthlySessionQuota: action
});
export default new HuntSessionStore();
