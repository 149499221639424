import axios from "../helper/Axios.js";
import { adminUrl, uploadUrl } from "../config/config.js";

export async function getAllEmail(token) {
    try {
        let response = await axios.Get({
            url: `${adminUrl}/integration/gmail${token && `?nextPageToken=${token}`}`,
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function getAllSent(token) {
    try {
        let response = await axios.Get({
            url: `${adminUrl}/integration/gmail?sent=true${token && `&nextPageToken=${token}`}`,
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function sendEmail(value) {
    try {
        let response = await axios.Post({
            url: `${adminUrl}/integration/gmail/send`,
            data: value
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function uploadFile(file) {
    try {
        let response = await axios.PostFile({
            url: `${uploadUrl}/upload/file`,
            data: file
        });

        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function getTriggeredEmail(businessId, search, startDate, endDate, page, limit) {
    try {
        let response = await axios.Get({
            url: `${adminUrl}/business/${businessId}/triggered-email?page=${page}&limit=${limit}&search=${search}&startDate=${startDate}&endDate=${endDate}`,
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function getSingleTriggeredEmail(businessId, emailId) {
    try {
        let response = await axios.Get({
            url: `${adminUrl}/business/${businessId}/triggered-email/${emailId}`,
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function createTriggeredEmail(businessId, value) {
    try {
        let response = await axios.Post({
            url: `${adminUrl}/business/${businessId}/triggered-email`,
            data: value
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function updateTriggeredEmail(businessId, value, emailId) {
    try {
        let response = await axios.Put({
            url: `${adminUrl}/business/${businessId}/triggered-email/${emailId}`,
            data: value
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function deleteTriggeredEmail(businessId, emailId) {
    try {
        let response = await axios.Delete({
            url: `${adminUrl}/business/${businessId}/triggered-email/${emailId}`,
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function getTemplate(businessId, search) {
    try {
        let response = await axios.Get({
            url: `${adminUrl}/business/${businessId}/template-email?search=${search}`,
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function getSingleTemplate(businessId, templateId) {
    try {
        let response = await axios.Get({
            url: `${adminUrl}/business/${businessId}/template-email/${templateId}`,
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function createTemplate(businessId, value) {
    try {
        let response = await axios.Post({
            url: `${adminUrl}/business/${businessId}/template-email`,
            data: value
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function updateTemplate(businessId, value, templateId) {
    try {
        let response = await axios.Put({
            url: `${adminUrl}/business/${businessId}/template-email/${templateId}`,
            data: value
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function bulkdeleteTemplate(businessId, value) {
    try {
        let response = await axios.Delete({
            url: `${adminUrl}/business/${businessId}/template-email-bulk`,
            data: value
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function deleteTemplate(businessId, templateId) {
    try {
        let response = await axios.Delete({
            url: `${adminUrl}/business/${businessId}/template-email/${templateId}`,
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function getCustomerByRange(businessId, startDate, endDate) {
    try {
        let response = await axios.Get({
            url: `${adminUrl}/business/${businessId}/customer-activity-session?startDate=${startDate}&endDate=${endDate}`,
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function Unsubscribe(value) {
    try {
        let response = await axios.Put({
            url: `${adminUrl}/triggered-email/unsubscribe`,
            data: value
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function getReminderSettings(businessId) {
    try {
        let response = await axios.Get({
            url: `${adminUrl}/business/${businessId}/reminder`,
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function setReminderStatus(businessId, id, value) {
    try {
        let response = await axios.Put({
            url: `${adminUrl}/business/${businessId}/reminder/${id}`,
            data: value
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function getReminderList(businessId, page, limit) {
    try {
        let response = await axios.Get({
            url: `${adminUrl}/business/${businessId}/reminder-list?page=${page}&limit=${limit}`,
        });

        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function deleteReminder(businessId, reminderId) {
    try {
        let response = await axios.Delete({
            url: `${adminUrl}/business/${businessId}/reminderlist/${reminderId}`,
        });

        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function bulkDeleteReminder(businessId, value) {
    try {
        let response = await axios.Delete({
            url: `${adminUrl}/business/${businessId}/reminder-list-bulk`,
            data: value
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}

export async function readReminder(businessId, reminderId) {
    try {
        let response = await axios.Put({
            url: `${adminUrl}/business/${businessId}/reminderlist/${reminderId}`,
        });
        if (response.statusCode !== 200) {
            return { error: response.message };
        }
        return { data: response.data };
    } catch (error) {
        if (error.response) {
            return { error: error.response };
        } else {
            return { error: error };
        }
    }
}