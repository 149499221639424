import React, { Component } from "react";
import { FormText } from "reactstrap";
import PhoneNumber from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

class FormikPhoneInput extends Component {
  render() {
    const {
      field: { name, value },
      form: { errors, setFieldValue },
      formText,
      inputType,
      classes,
      style,
      readonly,
      disabled,
      defCountry,
      onClick,
      ...props
    } = this.props;

    const isError = !!errors && !!errors[name];
    return (
      <React.Fragment>
        <PhoneNumber
          invalid={isError.toString()}
          name={name}
          onClick={onClick}
          disabled={disabled}
          value={value}
          className={value ? "fieldOnBlur" : "field"}
          onChange={(value, country, e, formattedValue) => {
            setFieldValue(name, formattedValue)
          }}
          country={defCountry}
          enableAreaCodes
          regions={["america", "europe", "asia", "oceania", "africa"]}
          style={this.props.style}
          {...props}
        />

        {!!isError && <p className="text-danger">{errors[name]}</p>}
        {!!formText && <FormText>{formText}</FormText>}
      </React.Fragment>
    );
  }
}

export default FormikPhoneInput;
