import axios from "../helper/Axios.js";
import { adminUrl } from "../config/config.js";

export async function getEmployeeLicense({ businessId, employeeId }) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/employee/${employeeId}/license`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getEmployeeLicenseById({ businessId, licenseId }) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/license/${licenseId}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function createLicense(value, businessId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/license`,
      data: value
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function updateLicense(value, businessId, licenseId) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/license/${licenseId}`,
      data: value
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function deleteLicense(businessId, licenseId) {
  try {
    let response = await axios.Delete({
      url: `${adminUrl}/business/${businessId}/license/${licenseId}`
    });

    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}
