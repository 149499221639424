import React, { Component } from "react";
import { Col, InputGroup, Row } from "reactstrap";
import { inject, observer } from "mobx-react";
import WorkSpringLogo from "../../../assets/Worksprings_Logo.svg";
import FacebookLogo from "../../../assets/facebook (1).svg";
import GoogleLogo from "../../../assets/google.svg";
import LinkedInLogo from "../../../assets/linkedin (1).svg";
import ReactTooltip from "react-tooltip";
import { Formik, Field, FastField, Form } from "formik";
import FormikInputField from "../../../components/Formik/FormikInputField";
import FormikPassword from "../../../components/Formik/FormikPassword";
import Buttons from "../../../components/Buttons";
import Text from "../../../components/Texts";
import { validateFormValuesUsingJoiSchema } from "../../../helper/functions";
import commonStore from "../../../stores/CommonStore";
import BlockUI from "react-block-ui";
import { Loader } from "react-loaders";
import "react-block-ui/style.css";
import "loaders.css/loaders.min.css";

const Joi = require("joi-browser");
Joi.objectId = require("joi-objectid")(Joi);

const joiSchema = {
  email: Joi.string()
    .label("Email")
    .required()
    .email({ minDomainAtoms: 2 }),
  password: Joi.string()
    .label("Password")
    .required()
    .regex(
      new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})")
    ),
  firstName: Joi.string()
    .label("First Name")
    .required(),
  lastName: Joi.string()
    .label("LastName")
    .required()
};
class Register extends Component {
  state = {
    showPassword: false
  };

  componentDidMount = () => {
    localStorage.setItem("loginType", "Business");
  };

  backToLogin = () => {
    const { history } = this.props;
    history.push("/login");
  };

  submit = async values => {
    const { firstName, lastName, email, password, confirmPassword } = values;
    if (password === confirmPassword) {
      await this.props.authStore.register({
        firstName,
        lastName,
        email,
        password
      });
    } else {
      commonStore.showErrorMessage("Password does not match");
    }
  };

  render() {
    const { authStore } = this.props;
    const { authLoading } = authStore;
    const initialValues = {
      firstName: "",
      lastName: "",
      email: "",
      password: ""
    };
    return (
      <Formik
        initialValues={initialValues}
        validate={values =>
          validateFormValuesUsingJoiSchema({ values, joiSchema })
        }
        validateOnChange={false}
        enableReinitialize={true}
        onSubmit={this.submit}
        component={({ values, handleSubmit, setFieldValue }) => {
          return (
            <div
              className="animated fadeIn app "
              style={{
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
                padding: "unset"
              }}
            >
              <BlockUI
                tag="div"
                blocking={authLoading}
                loader={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center"
                    }}
                  >
                    <Loader
                      active
                      type={"ball-spin-fade-loader"}
                      color="#1665d8"
                    />
                  </div>
                }
              >
                <Form>
                  <Row>
                    <Col lg="12" md="12" sm="12" xs="12" className="loginCol">
                      <img
                        src={WorkSpringLogo}
                        alt=""
                        style={{
                          marginTop: "50px"
                        }}
                      />
                    </Col>
                    <Col lg="12" md="12" sm="12" xs="12" className="loginCol">
                      <Text
                        text="Create New Account"
                        textType="h1"
                        weight="medium"
                        style={{ marginTop: "50px" }}
                      />
                    </Col>
                    <Col lg="12">
                      <Row>
                        <Col lg="12" style={{ marginTop: "20px" }}>
                          <InputGroup className="loginCol">
                            <FastField
                              name={"firstName"}
                              id="outlined-name"
                              variant="outlined"
                              placeholder={"First Name"}
                              style={{ width: "326px" }}
                              component={FormikInputField}
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="12" style={{ marginTop: "20px" }}>
                          <InputGroup className="loginCol">
                            <FastField
                              name={"lastName"}
                              id="outlined-name"
                              variant="outlined"
                              placeholder={"Last Name"}
                              style={{ width: "326px" }}
                              component={FormikInputField}
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="12" style={{ marginTop: "20px" }}>
                          <InputGroup className="loginCol">
                            <FastField
                              name={"email"}
                              id="outlined-name"
                              variant="outlined"
                              placeholder={"Email"}
                              style={{ width: "326px" }}
                              component={FormikInputField}
                            />
                          </InputGroup>
                        </Col>
                        <Col lg="12" style={{ marginTop: "20px" }}>
                          <InputGroup className="loginCol">
                            <Field
                              name={"password"}
                              data-tip="React-tooltip"
                              id="outlined-adornment-password"
                              variant="outlined"
                              placeholder={"Password"}
                              inputType={"password"}
                              component={FormikPassword}
                              style={{ width: "326px" }}
                            />
                            <ReactTooltip
                              place="right"
                              type="dark"
                              effect="solid"
                            >
                              <p className="a">
                                Password must be minimum 8 character
                              </p>
                              <p className="a">long with no spaces</p>
                              <p className="a">- Use letters and numbers</p>
                              <p className="a">
                                - Use special characters (e.g. $@)
                              </p>
                              <p className="a">
                                - Mix lower and upper case character
                              </p>
                            </ReactTooltip>
                          </InputGroup>
                        </Col>
                        <Col lg="12" style={{ marginTop: "20px" }}>
                          <InputGroup className="loginCol">
                            <Field
                              name={"confirmPassword"}
                              id="outlined-adornment-password"
                              variant="outlined"
                              placeholder={"Confirm Password"}
                              inputType={"password"}
                              component={FormikPassword}
                              style={{ width: "326px" }}
                            />
                          </InputGroup>
                        </Col>
                        <Col
                          lg="12"
                          md="12"
                          sm="12"
                          xs="12"
                          className="loginCol"
                          style={{ marginTop: "20px" }}
                        >
                          <Buttons
                            buttonType={"secondary"}
                            label={"Create New Account"}
                            style={{
                              height: "40px",
                              width: "326px",
                              marginTop: "15px"
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>
                <Row>
                  <Col
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    className="loginCol"
                    style={{ marginTop: "35px" }}
                  >
                    <Text text="Already have an account ?" textType="caption" />
                    <Text
                      text="Login"
                      textType="caption"
                      style={{
                        textDecoration: "underline",
                        marginTop: "10px",
                        color: "#36AE46",
                        cursor: "pointer"
                      }}
                      onClick={this.backToLogin}
                    />
                  </Col>
                  <Col
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    className="loginCol"
                    style={{ marginTop: "30px", display: "none" }}
                  >
                    <p className="pa">
                      <span>or Login with</span>
                    </p>
                  </Col>
                  <Col
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    className="socmedCol"
                    style={{ marginBottom: "100px", display: "none" }}
                  >
                    <img
                      src={GoogleLogo}
                      alt=""
                      style={{
                        margin: "10px"
                      }}
                    />
                    <img
                      src={FacebookLogo}
                      alt=""
                      style={{
                        margin: "10px"
                      }}
                    />
                    <img
                      src={LinkedInLogo}
                      alt=""
                      style={{
                        margin: "10px"
                      }}
                    />
                  </Col>
                </Row>
              </BlockUI>
            </div>
          );
        }}
      />
    );
  }
}

export default inject("authStore")(observer(Register));
// export default Login;
