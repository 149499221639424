import React, { Component } from "react";
// import { FormText } from "reactstrap";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import InputAdornment from '@material-ui/core/InputAdornment';

const styles = {
  root: {
    fontFamily: "Roboto !important",
    fontSize: "14px",
    height: "40px"
  },
  input: {
    padding: "12px"
  }
};

class FormikInputField extends Component {

  render() {
    const {
      field: { onChange, name, value },
      form: { errors },
      formText,
      inputType,
      classes,
      style,
      readonly,
      disabled,
      ...props
    } = this.props;

    const isError = !!errors && !!errors[name];

    return (
      <React.Fragment>
        <TextField
          invalid={isError.toString()}
          name={name}
          disabled={disabled}
          value={value}
          className={value ? "fieldOnBlur" : "field"}
          onChange={onChange}
          type={this.props.type}
          style={this.props.style}
          InputProps={{
            classes: {
              root: classes.root,
              input: classes.input
            },
            startAdornment: this.props.startAdornment && <InputAdornment position="start">{this.props.adornment}</InputAdornment>,
            endAdornment: this.props.endAdornment && <InputAdornment position="end">{this.props.adornment}</InputAdornment>,
          }}
          {...props}
        />

        {!!isError && <p className="text-danger">{errors[name]}</p>}
        {/* {!!formText && <FormText>{formText}</FormText>} */}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(FormikInputField);
