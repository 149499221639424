import { Col, Modal, ModalBody, Row } from "reactstrap";
import React, { Component } from "react";
// import { inject, observer } from "mobx-react";
import close from "../../assets/close.svg";
import success from "../../assets/success.svg";
import Text from "../Texts";
import Buttons from "../Buttons";

class Notification extends Component {
  closed = param => e => {
    this.props.close(param);
  };

  render() {
    const { successSign } = this.props;
    return (
      <Modal
        autoFocus={true}
        isOpen={this.props.isOpen}
        toggle={this.toggleAdd}
        size="sm"
        style={{ maxWidth: "400px", width: "80%" }}
      >
        <ModalBody>
          <div className="animated fadeIn">
            <Row>
              <Col
                lg="12"
                style={{
                  display: "flex",
                  justifyContent: "flex-end"
                }}
              >
                <img
                  style={{
                    marginBottom: "3px",
                    paddingLeft: "5px",
                    cursor: "pointer"
                  }}
                  src={close}
                  alt=""
                  onClick={this.closed(false)}
                />
              </Col>
              {successSign && (
                <Col
                  lg="12"
                  style={{
                    display: "flex",
                    justifyContent: "center"
                  }}
                >
                  <img src={success} alt="" />
                </Col>
              )}

              <Col
                lg="12"
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Text text={this.props.title} textType="h2" weight="medium" />
              </Col>
              <Col
                lg="12"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  textAlign: "center",
                  marginTop: "15px"
                }}
              >
                <Text text={this.props.message} textType="body2" />
              </Col>

              <Col
                lg="12"
                style={{
                  marginTop: "10px",
                  padding: "0 23%"
                }}
              >
                <Buttons
                  buttonType={"primary"}
                  label={"OK"}
                  style={{
                    height: "40px",
                    marginTop: "20px",
                    width: "100%"
                  }}
                  onClick={this.closed(false)}
                />
              </Col>
            </Row>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}
export default Notification;
