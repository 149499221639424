import axios from "../helper/Axios.js";
import { superUrl } from "../config/config.js";

export async function getAllAdmin({ page, limit, search }) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/admins?page=${page}&limit=${limit}&search=${search}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getAdminById(admin) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/admin/${admin}`
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function createAdmin(value) {
  try {
    let response = await axios.Post({
      url: `${superUrl}/admin`,
      data: value
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function updateAdmin({ admin, value }) {
  try {
    let response = await axios.Put({
      url: `${superUrl}/admin/${admin}`,
      data: value
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}
