import { observable, decorate } from "mobx";
import {
  createWebform,
  getSingleWebform,
  updateWebform,
  deleteWebform,
  postContactWithoutToken
} from "../services/WebformService"
import { reactLocalStorage } from "reactjs-localstorage";
import csc from "country-state-city";
import commonStore from "./CommonStore";

class WebformStore {
  editedData = {};
  errorMessage = "";
  webformLoading = false;
  countryList = [];
  webformFields = [
    "firstName",
    "lastName",
    "address1",
    "address2",
    "email",
    "city",
    "state",
    "postalCode",
    "country",
    "phone",
    "note",
  ];
  formikInitialValues = {
    email: "",
    firstName: "",
    lastName: "",
    phone: "+1",
    note: "",
    country: { label: "", value: "" },
    state: "",
    city: "",
    address1: "",
    address2: "",
    postalCode: ""
  }

  setEditedData = value => {
    this.editedData = { ...this.editedData, ...value, }
  }

  initialValue = () => {
    this.setEditedData({
      formName: "",
      firstName: false,
      lastName: false,
      address1: false,
      address2: false,
      email: true,
      city: false,
      state: false,
      postalCode: false,
      country: false,
      phone: false,
      note: false,
      useCustom: false,
      custom: [""]
    })
  }

  changeString = value => {
    const temp = value.split('_').map(word =>
      word.charAt(0).toUpperCase() + word.substring(1)
    ).join(' ');

    return temp;
  }

  getAllCountry = () => {
    const countries = csc.getAllCountries();
    let arrayCountries = countries.map(data => {
      const { name, isoCode } = data;
      return {
        label: `${name}`,
        value: `${isoCode}`
      };
    });

    const temp = [arrayCountries[232]];

    arrayCountries.map(array => {
      temp.push(array);
      return true;
    });

    arrayCountries = temp;
    arrayCountries.splice(233, 1);
    this.countryList = arrayCountries;
  }

  createWebform = async ({ value, history }) => {
    const businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.webformLoading = true;
    const { data, error } = await createWebform({ businessId, data: value });
    this.webformLoading = false;

    if (data) {
      history.push("/setting");
      commonStore.showSuccessMessage("Form Created")
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  updateWebform = async ({ value, history, webformId }) => {
    const businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.webformLoading = true;
    const { data, error } = await updateWebform({ businessId, data: value, webformId });
    this.webformLoading = false;

    if (data) {
      history.push("/setting");
      commonStore.showSuccessMessage("Form Updated")
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  deleteWebform = async ({ history, webformId }) => {
    const businessId = JSON.parse(
      reactLocalStorage.get("huntingCurrentBusiness")
    );
    this.webformLoading = true;
    const { data, error } = await deleteWebform({ businessId, webformId });
    this.webformLoading = false;

    if (!data) {
      history.push("/setting");
      commonStore.showSuccessMessage("Form Deleted")
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  getSingleWebform = async ({ businessId, webformId }) => {

    this.webformLoading = true;
    const { data, error } = await getSingleWebform({ businessId, webformId });
    this.webformLoading = false;

    if (data) {
      // let custom = data.custom ?
      //   data.custom.map(item => {
      //     return this.changeString(item)
      //   }) : []

      this.setEditedData({
        formName: data.formName ? data.formName : "",
        firstName: data.firstName ? data.firstName : false,
        lastName: data.lastName ? data.lastName : false,
        address1: data.address1 ? data.address1 : false,
        address2: data.address2 ? data.address2 : false,
        email: data.email ? data.email : true,
        city: data.city ? data.city : false,
        state: data.state ? data.state : false,
        postalCode: data.postalCode ? data.postalCode : false,
        country: data.country ? data.country : false,
        phone: data.phone ? data.phone : false,
        note: data.note ? data.note : false,
        // useCustom: data.useCustom ? data.useCustom : false,
        // custom
      })

      // for (let i = 0; i < data.custom.length; i++) {
      //   this.formikInitialValues[data.custom[i]] = ""
      // }

    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  }

  postContactWithoutToken = async ({ value, businessId }) => {
    this.webformLoading = true;

    const { data, error } = await postContactWithoutToken({ value, businessId });

    this.webformLoading = false;

    if (data) {
      this.setEditedData({ done: true })
      return { data }
    } else {
      if (error.data) {
        this.errorMessage = error.data.message;
        if (this.errorMessage === "Forbidden.") {
          commonStore.showErrorMessage(
            "The permission of this action has been changed by the owner. Please Relogin"
          );
        } else {
          commonStore.showErrorMessage(this.errorMessage);
        }
      } else {
        commonStore.showErrorMessage(
          "Something went wrong. Please try again later"
        );
      }
    }
  };
}
decorate(WebformStore, {
  editedData: observable,
  errorMessage: observable,
  webformLoading: observable,
  countryList: observable,
  formikInitialValues: observable
});
export default new WebformStore();