import React, { Component } from "react";
import { FormText } from "reactstrap";
import cloudinaryStore from "../../stores/CloudinaryStore";
import commonStore from "../../stores/CommonStore";
import LoadingCircular from "../../components/LoadingCircular";
import Buttons from "../../components/Buttons";
import Text from "../../components/Texts";
import Dropzone from "react-dropzone";
import classNames from "classnames";

class FormikImageUpload extends Component {
  editedData = {};

  constructor(props) {
    super(props);
    this.state = { isLoading: false };
  }

  deleteImg = (name, setFieldValue) => {
    setFieldValue(name, "");
    this.setState({ isLoading: false });
  };

  handleDrop = async (accepted, rejected, files, setFieldValue, name) => {
    this.setState({ accepted, rejected });
    if (accepted.length > 0) {
      const file = accepted[0];
      let reader = new FileReader();

      reader.onloadend = async () => {
        this.setState({ isLoading: true });

        const { data, error } = await cloudinaryStore.postImage(
          accepted,
          this.props.folderPath
        );
        this.setState({ isLoading: false });
        if (error) {
          commonStore.showErrorMessage(error);
          return;
        }
        const articleImage = data.secure_url;
        setFieldValue(name, articleImage);
      };
      reader.readAsDataURL(file);
    }
  };

  render() {
    const {
      field: { name, value },
      form: { errors, setFieldValue },
      formText
    } = this.props;
    const isError = !!errors && !!errors[name];
    const image = value;
    const { isLoading } = this.state;
    return (
      <React.Fragment>
        {!image ? (
          <Dropzone
            onDrop={(accepted, rejected, files) =>
              this.handleDrop(accepted, rejected, files, setFieldValue, name)
            }
            multiple={false}
            accept="image/jpeg, image/png, image/jpg"
            maxFileSize="10000"
          >
            {({ getRootProps, getInputProps, isDragActive }) => {
              return (
                <div
                  {...getRootProps()}
                  className={classNames(
                    "dropzone",
                    {
                      "dropzone--isActive": isDragActive
                    },
                    "upload-box"
                  )}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p />
                  ) : (
                    <div className="upload-container">
                      {!!isLoading && <LoadingCircular />}
                      {!isLoading && (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <Buttons
                            buttonType={"default"}
                            onClick={this.handleDrop}
                            label={"Browse File"}
                            type={"button"}
                          >
                            BROWSE
                          </Buttons>
                          <Text
                            textType="caption"
                            text="Upload your photo or logo"
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            }}
          </Dropzone>
        ) : (
          <div
            style={{
              width: "250px",
              overflow: "hidden",
              position: "relative",
              height: "220px"
            }}
          >
            <img
              src={image}
              alt=" "
              responsive="true"
              style={{
                width: "100%",
                height: "auto",
                position: "absolute",
                top: "-9999px",
                bottom: "-9999px",
                left: "-9999px",
                right: "-9999px",
                margin: "auto"
              }}
            />
            <div
              onClick={() => this.deleteImg(name, setFieldValue)}
              style={{
                width: "fit-content",
                position: "absolute",
                bottom: 5,
                right: 5
              }}
            >
              <i
                className="cui-trash icons font-2xl d-block mt-4"
                style={{
                  color: "#ffffff",
                  backgroundColor: "#a10006"
                }}
              />
            </div>
          </div>
        )}
        {/* <span className="upload-image-alert">
          Max file 10MB {this.handleDrop.imageName}
        </span> */}

        {!!isError && <p className="text-danger">{errors[name]}</p>}
        {!!formText && <FormText>{formText}</FormText>}
      </React.Fragment>
    );
  }
}

export default FormikImageUpload;
