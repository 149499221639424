import { observable, decorate } from "mobx";
import { validateEditedData } from "../helper/functions";
// import CommonStore from "./CommonStore";
import BookingStore from "./BookingStore";
import _ from "lodash";

const Joi = require("joi-browser");
Joi.objectId = require("joi-objectid")(Joi);

class BookingConfirmationModalStore {
  editedData = { partyLeader: '' };
  step = 0;

  setEditedData = editedData => {
    this.editedData = {
      ...this.editedData,
      ...editedData
    };
  };

  prepareCreateMode = () => {
    this.setEditedData({
      quantity: 1,
      email: false
    });
    _.omit(this.editedData, "customer");
  };
  setBookingConfirmationStep = step => {
    this.step = step;
  };
  openModal = () => {
    this.setEditedData({ open: true });
  };

  closeModal = () => {
    this.step = 0;
    this.setEditedData({ open: false });
  };

  get joiFormErrors() {
    if (this.editedData) {
      const editedData = BookingStore.editedData;

      return validateEditedData({
        editedData: this.editedData,
        joiSchema: {
          quantity: Joi.number()
            .label("Quantity")
            .greater(0)
            .max(editedData.huntSession ? editedData.huntSession.remaining : 10)
            .required(),
          customer: Joi.object({
            value: Joi.string()
              .label("Customer")
              .required(),
            label: Joi.string()
              .label("Customer")
              .required()
          }),
          guide: Joi.object({
            value: Joi.string()
              .label("Guide")
              .required(),
            label: Joi.string()
              .label("Guide")
              .required()
          })
        }
      });
    }
    return { error: true };
  }
}
decorate(BookingConfirmationModalStore, {
  editedData: observable,
  step: observable
});

export default new BookingConfirmationModalStore();
