import axios from "../helper/Axios.js";
import { adminUrl, superUrl } from "../config/config.js";

export async function getAllBooking({
  businessId,
  currentPage,
  pageLimit,
  search,
  startDate,
  endDate,
  hunt,
  status,
}) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/bookings?page=${currentPage}&limit=${pageLimit}&search=${search}&hunt=${hunt}&status=${
        status || ""
      }&startDate=${startDate || ""}&endDate=${endDate || ""}`,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getBookingById(businessId, bookingId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/booking/${bookingId}`,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function postBooking(bookingDTO, businessId) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/booking`,
      data: {
        ...bookingDTO,
      },
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function putBooking(bookingDTO, businessId, bookingId) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/booking/${bookingId}`,
      data: {
        ...bookingDTO,
      },
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function deleteBooking(businessId, bookingId) {
  try {
    let response = await axios.Delete({
      url: `${adminUrl}/business/${businessId}/booking/${bookingId}`,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getBookingStatusCount(businessId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/bookingStatusCount`,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getBookingByHuntSession(businessId, huntId) {
  try {
    let response = await axios.Get({
      url: `${adminUrl}/business/${businessId}/bookings/activitySession/${huntId}`,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function addSpecies(
  species,
  businessId,
  customBooking,
  bookingId
) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/booking/${bookingId}/custombooking/${
        customBooking ? customBooking : "0"
      }/detail`,
      data: species,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function addPartyMember(businessId, bookingId, data) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/booking/${bookingId}/party`,
      data: data,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function editPartyMember(businessId, bookingId, data) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/booking/${bookingId}/party/edit`,
      data: data,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function addDeposit(businessId, bookingId, data) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/booking/${bookingId}/deposit`,
      data: data,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function updateDeposit(businessId, depositId, data) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/deposit/${depositId}`,
      data: data,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function createEvent(businessId, data) {
  try {
    let response = await axios.Post({
      url: `${adminUrl}/business/${businessId}/events`,
      data: data,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function updateEvent(businessId, data, eventId) {
  try {
    let response = await axios.Put({
      url: `${adminUrl}/business/${businessId}/events/${eventId}`,
      data: data,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function deleteEvent(businessId, eventId) {
  try {
    let response = await axios.Delete({
      url: `${adminUrl}/business/${businessId}/events/${eventId}`,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

//-----------------------------------------------------------Super Admin--------------------------------------------------------------

export async function getAllBookingForAdmin({
  businessId,
  currentPage,
  pageLimit,
  search,
  hunt,
}) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/bookings?business=${businessId}&page=${currentPage}&limit=${pageLimit}&search=${search}&hunt=${
        hunt || ""
      }`,
    });
    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function getBookingByIdForAdmin(bookingId) {
  try {
    let response = await axios.Get({
      url: `${superUrl}/booking/${bookingId}`,
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}

export async function putBookingForAdmin(bookingDTO, bookingId) {
  try {
    let response = await axios.Put({
      url: `${superUrl}/booking/${bookingId}`,
      data: {
        ...bookingDTO,
      },
    });

    if (response.statusCode !== 200) {
      return { error: response.message };
    }
    return { data: response.data };
  } catch (error) {
    if (error.response) {
      return { error: error.response };
    } else {
      return { error: error };
    }
  }
}
