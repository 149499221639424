import { Col, Modal, ModalBody, Row } from "reactstrap";
import React, { Component } from "react";
import { inject, observer } from "mobx-react";
import close from "../../assets/close.svg";
import _ from "lodash";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Formik, FastField, Form } from "formik";
import Text from "../Texts";
import Buttons from "../Buttons";
import ReactTooltip from "react-tooltip";
import { validateFormValuesUsingJoiSchema } from "../../helper/functions";
import FormikInputField from "../Formik/FormikInputField";
import FormikUploadFile from "../Formik/FormikUploadFile";
import FormikSelectField from "../Formik/FormikSelectField";
// import commonStore from "../../stores/CommonStore";
import BlockUI from "react-block-ui";
import { Loader } from "react-loaders";
import "react-block-ui/style.css";
import "loaders.css/loaders.min.css";

const Joi = require("joi-browser");
Joi.objectId = require("joi-objectid")(Joi);

const joiSchema = {
  // form: Joi.string()
  //   .label("Form Name")
  //   .required()
  // file: Joi.string()
  //   .label("File")
  //   .required()
};

class UploadFileModal extends Component {
  submit = async (values, actions) => {
    const { formStore, uploadFileModalStore, businessSide, customerId } = this.props;
    const { editedData, businessArray, tempCompletedFormPush } = formStore;
    let value = {};
    let requriedFormSelected = {};

    const tempForm = businessArray.find(
      data => data._id === values.requiredForm
    );

    if (values.requiredForm || values.requiredForm === "") {
      if (values.requiredForm !== "") {
        if (!businessSide) {
          if (tempForm.required) {
            requriedFormSelected = {
              form: values.requiredForm,
              status: true
            };
            value = {
              form: values.form ? values.form : editedData.fileName,
              url: values.file.publicUrl,
              extension: values.file.extension,
              type: this.props.submitType === "business" ? "BUSINESS" : "CUSTOMER",
              requiredForm: requriedFormSelected
            };
          } else if (!tempForm.required) {
            value = {
              form: values.form ? values.form : editedData.fileName,
              url: values.file.publicUrl,
              extension: values.file.extension,
              type: this.props.submitType === "business" ? "BUSINESS" : "CUSTOMER",
              optionalForm: values.requiredForm
            };
          }
        } else {
          value = {
            form: values.form ? values.form : editedData.fileName,
            url: values.file.publicUrl,
            extension: values.file.extension,
            type: this.props.submitType === "business" ? "BUSINESS" : "CUSTOMER",
            requiredForm: values.requiredForm
          };
        }

      } else if (values.requiredForm === "") {
        if (!businessSide) {
          value = {
            form: values.form ? values.form : editedData.fileName,
            url: values.file.publicUrl,
            extension: values.file.extension,
            type: this.props.submitType === "business" ? "BUSINESS" : "CUSTOMER",
            optionalForm: values.requiredForm
          };

        } else {
          value = {
            form: values.form ? values.form : editedData.fileName,
            url: values.file.publicUrl,
            extension: values.file.extension,
            type: this.props.submitType === "business" ? "BUSINESS" : "CUSTOMER",
            requiredForm: values.requiredForm
          };
        }
      }
    } else {
      value = {
        form: values.form ? values.form : editedData.fileName,
        url: values.file.publicUrl,
        extension: values.file.extension,
        type: this.props.submitType === "business" ? "BUSINESS" : "CUSTOMER"
      };

    }

    if (values.requiredForm !== "") tempCompletedFormPush(values.requiredForm);

    if (this.props.submitType === "business") {
      await formStore.postForm(value);
    } else {
      if (!businessSide) {
        await formStore.postCustomerForm(value, editedData.token);
      } else {
        await formStore.postBusinessForm(value, customerId)
      }
    }

    uploadFileModalStore.closeModal();
  };

  render() {
    const { uploadFileModalStore, formStore, submitType } = this.props;
    const { editedData, loading, requiredFormOptions } = formStore;
    let initialValues = {};

    if (submitType !== "business") {
      initialValues = {
        form: _.get(editedData, "form", ""),
        file: _.get(editedData, "file", ""),
        extension: _.get(editedData, "extension", ""),
        requiredForm: _.get(editedData, "requiredForm", "")
      };
    } else {
      initialValues = {
        form: _.get(editedData, "form", ""),
        file: _.get(editedData, "file", ""),
        extension: _.get(editedData, "extension", ""),
      };
    }

    let modalEditedData = uploadFileModalStore.editedData;

    return (
      <Modal
        autoFocus={true}
        isOpen={modalEditedData.open}
        toggle={uploadFileModalStore.closeModal}
        size="lg"
      >
        <ModalBody>
          <BlockUI
            tag="div"
            blocking={loading}
            loader={
              <div
                style={{
                  display: "flex",
                  justifyContent: "center"
                }}
              >
                <Loader active type={"ball-spin-fade-loader"} color="#1665d8" />
              </div>
            }
          >
            <Formik
              initialValues={initialValues}
              validate={values =>
                validateFormValuesUsingJoiSchema({
                  values,
                  joiSchema
                })
              }
              validateOnChange={false}
              enableReinitialize={true}
              onSubmit={this.submit}
              component={({
                values,
                handleSubmit,
                setFieldValue,
                isSubmitting
              }) => {
                return (
                  <div className="animated fadeIn">
                    <Form>
                      <Row>
                        <Col
                          lg="12"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end"
                          }}
                        >
                          <img
                            style={{
                              marginBottom: "3px",
                              paddingLeft: "5px",
                              cursor: "pointer"
                            }}
                            src={close}
                            alt=""
                            onClick={uploadFileModalStore.closeModal}
                          />
                        </Col>

                        <Col
                          lg="12"
                          style={{
                            marginTop: "10px",
                            paddingLeft: "75px",
                            paddingRight: "75px",
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <Text
                            text="Upload Document"
                            textType="h1"
                            weight="medium"
                          />
                        </Col>

                        <Col
                          lg="12"
                          style={{
                            marginTop: "10px",
                            paddingLeft: "75px",
                            paddingRight: "75px"
                          }}
                        >
                          <Text
                            text="Document"
                            textType="caption"
                            weight="medium"
                          />
                          <FastField
                            name={"form"}
                            id="outlined-name"
                            variant="outlined"
                            className="w-100"
                            placeholder={"Document Name"}
                            component={FormikInputField}
                          />
                        </Col>
                        {submitType === "customer" &&
                          requiredFormOptions.length !== 0 ? (
                            <>
                              {" "}
                              <Col
                                lg="12"
                                style={{
                                  marginTop: "10px",
                                  paddingLeft: "75px",
                                  paddingRight: "75px"
                                }}
                              >
                                <Text
                                  text={`Forms`}
                                  textType="caption"
                                  weight="medium"
                                />
                                <HelpOutlineIcon
                                  fontSize="small"
                                  color={"grey"}
                                  data-tip="React-tooltip"
                                  style={{
                                    verticalAlign: "top"
                                  }}
                                />
                                <ReactTooltip
                                  place="right"
                                  type="dark"
                                  effect="solid"
                                >
                                  <span>
                                    Please complete all the required forms
                                </span>
                                </ReactTooltip>
                                <FastField
                                  name={"requiredForm"}
                                  id="outlined-name"
                                  variant="outlined"
                                  className="w-100"
                                  placeholder={"Document Name"}
                                  options={requiredFormOptions}
                                  component={FormikSelectField}
                                />
                              </Col>
                            </>
                          ) : (
                            <> </>
                          )}

                        <Col
                          lg="12"
                          style={{
                            marginTop: "10px",
                            paddingLeft: "75px",
                            paddingRight: "75px"
                          }}
                        >
                          <Text
                            text="File"
                            textType="caption"
                            weight="medium"
                            required=" *"
                          />
                          <FastField
                            name={"file"}
                            component={FormikUploadFile}
                          />
                        </Col>

                        <Col
                          lg="12"
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "center"
                          }}
                        >
                          <Buttons
                            buttonType={"primary"}
                            label={"Save"}
                            style={{
                              height: "40px",
                              marginTop: "20px",
                              width: "200px",
                              overflow: "hidden",
                              textOverflow: "ellipsis"
                            }}
                            disabled={values.file ? false : true}
                          />
                        </Col>
                      </Row>
                    </Form>
                  </div>
                );
              }}
            />
          </BlockUI>
        </ModalBody>
      </Modal>
    );
  }
}
export default inject(
  "uploadFileModalStore",
  "formStore"
)(observer(UploadFileModal));
