import React from "react";
import DefaultLayout from "./containers/DefaultLayout";
// import businessStore from "./stores/BusinessStore";
// import EditHuntType from "./views/Hunt/EditHuntType";

//-----------------------------dashboard-----------------------------
const Dashboard = React.lazy(() => import("./views/Dashboard/Dashboard"));

//-----------------------------login-----------------------------
const Login = React.lazy(() => import("./views/Pages/Login/Login"));

//-----------------------------Register-----------------------------
// const Register = React.lazy(() => import("./views/Pages/Register/Register"));

//-----------------------------Content-----------------------------
const Hunt = React.lazy(() => import("./views/Hunt/Hunt"));
const EditHunt = React.lazy(() => import("./views/Hunt/EditHuntType"));
const HuntTypeDetail = React.lazy(() => import("./views/Hunt/HuntTypeDetail"));
const EditSession = React.lazy(() => import("./views/Hunt/EditSession"));
const Employees = React.lazy(() => import("./views/Employees/Employees"));
const Booking = React.lazy(() => import("./views/Booking/Booking"));
const AddBooking = React.lazy(() => import("./views/Booking/AddBooking"));
const EditBooking = React.lazy(() => import("./views/Booking/EditBooking"));
const Customer = React.lazy(() => import("./views/Customer/Customer"));
const EditCustomer = React.lazy(() => import("./views/Customer/EditCustomers"));
const CustomerForm = React.lazy(() => import("./views/Customer/CustomerForm"));
const Report = React.lazy(() => import("./views/Report/Report"));
const EditEmployee = React.lazy(() => import("./views/Employees/EditEmployee"));
const EmployeeRole = React.lazy(() => import("./views/Employees/EmployeeRole"));
const CreateRole = React.lazy(() => import("./views/Employees/CreateRole"));
const Contact = React.lazy(() => import("./views/Contact/Contact"));
const EditContact = React.lazy(() => import("./views/Contact/EditContact"));
const Form = React.lazy(() => import("./views/Form/Form"));
const SendForm = React.lazy(() => import("./views/Form/SendForm"));
const SendMessage = React.lazy(() => import("./views/Customer/SendMessage"));
const NotificationList = React.lazy(() =>
  import("./views/Notification/NotificationList")
);
const EditLicense = React.lazy(() => import("./views/Business/EditLicense"));
const EditCertification = React.lazy(() =>
  import("./views/Employees/EditCertification")
);
// const Email = React.lazy(() => import("./views/Communication/Email/Email"))
// const History = React.lazy(() => import("./views/Communication/Email/History"))
// const EmailDetail = React.lazy(() => import("./views/Communication/Email/EmailDetail"))
const Setting = React.lazy(() => import("./views/Setting/Setting"))

const TriggeredEmail = React.lazy(() => import("./views/Communication/TriggeredEmail/TriggeredEmail"))
const EditTriggeredEmail = React.lazy(() => import("./views/Communication/TriggeredEmail/TriggeredEmailEdit"))
const EditTemplateEmail = React.lazy(() => import("./views/Communication/TriggeredEmail/TemplateEdit"))

const Reminder = React.lazy(() => import("./views/Communication/Reminder/Reminder"))
const ReminderOption = React.lazy(() => import("./views/Communication/Reminder/ReminderOption"))

const Webform = React.lazy(() => import("./views/Webform/WebformCreate"))


//---------------------------------Super Admin-------------------------------------

const AdminLogin = React.lazy(() => import("./views/Pages/Login/AdminLogin"));
const AdminList = React.lazy(() => import("./views/Admin/AdminList"));
const AdminDashboard = React.lazy(() =>
  import("./views/Dashboard/AdminDashboard")
);
const AdminBusinessList = React.lazy(() =>
  import("./views/Business/AdminBusinessList")
);
const AdminBusinessOwnerDetail = React.lazy(() =>
  import("./views/Business/AdminBusinessOwnerDetail")
);
const BusinessDetail = React.lazy(() =>
  import("./views/Business/BusinessDetail")
);
const EditAdmin = React.lazy(() => import("./views/Admin/EditAdmin"));
const RolesAdmin = React.lazy(() => import("./views/Employees/EmployeeRole"));
const AdminRolesEdit = React.lazy(() => import("./views/Employees/CreateRole"))
// Profile
const BusinessInformation = React.lazy(() =>
  import("./views/Business/BusinessInformation")
);

const routes = [
  { path: "/", exact: true, name: "", component: DefaultLayout },

  //-----------------------------Dashboard-----------------------------
  {
    path: "/businesses/:businessId/dashboard",
    name: "Dashboard",
    component: Dashboard
  },

  //-----------------------------Login-----------------------------
  {
    path: "/login",
    name: "Login",
    component: Login
  },
  {
    path: "/admin/login",
    name: "Login",
    component: AdminLogin
  },

  //-----------------------------Register----------------------------
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: Register
  // },

  //-----------------------------Content-----------------------------
  {
    path: "/businesses/:businessId/businessInformation",
    name: "Account Information",
    component: BusinessInformation,
    exact: true
  },
  {
    path: "/businesses/:businessId/hunt",
    name: "Service",
    component: Hunt,
    exact: true
  },
  {
    path: "/businesses/:businessId/hunt/addHuntType",
    name: "Add New Service",
    component: EditHunt,
    exact: true
  },
  {
    path: "/businesses/:businessId/hunt/:huntId",
    name: "Service Detail",
    component: HuntTypeDetail,
    exact: true
  },
  {
    path: "/businesses/:businessId/hunt/:huntId/addSession/",
    name: "Add New Service",
    component: EditSession,
    exact: true
  },
  {
    path: "/businesses/:businessId/hunt/:huntId/huntDetail/:huntSessionId",
    name: "Detail Service",
    component: EditSession,
    exact: true
  },
  {
    path: "/businesses/:businessId/employees",
    name: "Employees",
    component: Employees,
    exact: true
  },
  {
    path: "/businesses/:businessId/reservation",
    name: "Reservation",
    component: Booking,
    exact: true
  },
  {
    path: "/businesses/:businessId/reservation/makereservation",
    name: "Make New Reservation",
    component: AddBooking,
    exact: true
  },
  {
    path: "/businesses/:businessId/reservation/:bookingId",
    name: "Reservation Detail",
    component: EditBooking,
    exact: true
  },
  {
    path: "/businesses/:businessId/customer",
    name: "Customer",
    component: Customer,
    exact: true
  },
  {
    path: "/businesses/:businessId/customer/addCustomer",
    name: "Add New Customer",
    component: EditCustomer,
    exact: true
  },
  {
    path: "/businesses/:businessId/customer/:customerId",
    name: "Customer Detail",
    component: EditCustomer,
    exact: true
  },
  {
    path: "/businesses/:businessId/customer/:customerId/customerdocs",
    name: "Customer Document",
    component: CustomerForm,
    exact: true
  },
  {
    path: "/businesses/:businessId/customer/:customerId/sendMessage",
    name: "Send Message",
    component: SendMessage,
    exact: true
  },
  {
    path: "/businesses/:businessId/report",
    name: "Report",
    component: Report
  },
  {
    path: "/businesses/:businessId/employees/addEmployee",
    name: "Add New Employee",
    component: EditEmployee,
    exact: true
  },
  {
    path: "/businesses/:businessId/employees/employeeRole",
    name: "Employee Roles",
    component: EmployeeRole,
    exact: true
  },
  {
    path: "/businesses/:businessId/employees/:employeeId",
    name: "Edit Employee",
    component: EditEmployee,
    exact: true
  },
  {
    path: "/businesses/:businessId/employees/employeeRole/createRole",
    name: "Create Role",
    component: CreateRole,
    exact: true
  },
  {
    path: "/businesses/:businessId/employees/employeeRole/:roleId",
    name: "Employee Detail",
    component: CreateRole,
    exact: true
  },
  {
    path: "/businesses/:businessId/contact",
    name: "Contact",
    component: Contact,
    exact: true
  },
  {
    path: "/businesses/:businessId/contact/addContact",
    name: "Add Contact",
    component: EditContact,
    exact: true
  },
  {
    path: "/businesses/:businessId/contact/:contactId",
    name: "Contact Detail",
    component: EditContact,
    exact: true
  },
  {
    path: "/businesses/:businessId/document",
    name: "Document",
    component: Form,
    exact: true
  },
  {
    path: "/businesses/:businessId/document/senddocument",
    name: "Send Document",
    component: SendForm,
    exact: true
  },
  {
    path: "/businesses/:businessId/notification",
    name: "Notification",
    component: NotificationList,
    exact: true
  },
  {
    path: "/businesses/:businessId/businessInformation/license/addlicense",
    name: "Add New License",
    component: EditLicense,
    exact: true
  },
  {
    path: "/businesses/:businessId/employees/:employeeId/license/addlicense",
    name: "Add New Employee License",
    component: EditLicense,
    exact: true
  },
  {
    path: "/businesses/:businessId/employees/:employeeId/license/:licenseId",
    name: "Detail License",
    component: EditLicense,
    exact: true
  },
  {
    path: "/businesses/:businessId/businessInformation/license/:licenseId",
    name: "Detail License",
    component: EditLicense,
    exact: true
  },
  {
    path: "/businesses/:businessId/employees/:employeeId/certification/add",
    name: "Add New Employee Certification",
    component: EditCertification,
    exact: true
  },
  {
    path:
      "/businesses/:businessId/employees/:employeeId/certification/:cprId",
    name: "Detail Certification",
    component: EditCertification,
  },
  // {
  //   path: "/businesses/:businessId/email",
  //   name: "Email",
  //   component: Email,
  //   exact: true
  // },
  // {
  //   path: "/businesses/:businessId/email/:type/detail/reply",
  //   name: "Reply",
  //   component: Email,
  //   exact: true
  // },
  // {
  //   path: "/businesses/:businessId/email/:type/detail/forward",
  //   name: "Forward",
  //   component: Email,
  //   exact: true
  // },
  // {
  //   path: "/businesses/:businessId/email/:type/detail",
  //   name: "Detail",
  //   component: EmailDetail,
  //   exact: true
  // },
  // {
  //   path: "/businesses/:businessId/email/:type",
  //   name: "History",
  //   component: History,
  //   exact: true
  // },
  {
    path: "/setting",
    name: "Setting",
    component: Setting,
    exact: true
  },
  {
    path: "/businesses/:businessId/scheduled",
    name: "Scheduled Email",
    component: TriggeredEmail,
    exact: true
  },
  {
    path: "/businesses/:businessId/scheduled/template/templateedit/:templateId",
    name: "Template Email Edit",
    component: EditTemplateEmail,
    exact: true
  },
  {
    path: "/businesses/:businessId/scheduled/schedulededit/:emailId",
    name: "Scheduled Email Edit",
    component: EditTriggeredEmail,
    exact: true
  },
  {
    path: "/businesses/:businessId/reminder",
    name: "Reminder",
    component: Reminder,
    exact: true
  },
  {
    path: "/businesses/:businessId/reminder/reminderoption",
    name: "Reminder Option",
    component: ReminderOption,
    exact: true
  },

  {
    path: "/setting/webform-create",
    name: "Create Form",
    component: Webform,
    exact: true
  },
  {
    path: "/setting/webform/:webformId",
    name: "Edit Form",
    component: Webform,
    exact: true
  },

  //-----------------------------------------------------------Super Admin------------------------------------------------------------

  {
    path: "/admin/dashboard",
    name: "Dashboard",
    component: AdminDashboard,
    exact: true
  },
  {
    path: "/admin/adminlist",
    name: "Admin",
    component: AdminList,
    exact: true
  },
  {
    path: "/admin/adminlist/addadmin",
    name: "Add New Admin",
    component: EditAdmin,
    exact: true
  },
  {
    path: "/admin/adminlist/admindetail/:adminId",
    name: "Admin Detail",
    component: EditAdmin,
    exact: true
  },
  {
    path: "/admin/businessowner",
    name: "Business Owner",
    component: AdminBusinessList,
    exact: true
  },
  {
    path: "/admin/businessowner/ownerdetail/:ownerId",
    name: "Business Owner Detail",
    component: AdminBusinessOwnerDetail,
    exact: true
  },
  {
    path: "/admin/businessowner/ownerdetail/:ownerId/business/:businessId",
    name: "Business Detail",
    component: BusinessDetail,
    exact: true
  },
  {
    path:
      "/admin/businessowner/ownerdetail/:ownerId/business/:businessId/customer/:customerId",
    name: "Customer Detail",
    component: EditCustomer,
    exact: true
  },
  {
    path:
      "/admin/businessowner/ownerdetail/:ownerId/business/:businessId/activity/:huntId",
    name: "Service Detail",
    component: HuntTypeDetail,
    exact: true
  },
  {
    path:
      "/admin/businessowner/ownerdetail/:ownerId/business/:businessId/activity/:huntId/activitysession/:huntSessionId",
    name: "Service Session Detail",
    component: EditSession,
    exact: true
  },
  {
    path:
      "/admin/businessowner/ownerdetail/:ownerId/business/:businessId/booking/:bookingId",
    name: "Reservation Detail",
    component: EditBooking,
    exact: true
  },
  {
    path:
      "/admin/businessowner/ownerdetail/:ownerId/business/:businessId/employee/:employeeId",
    name: "Employee Detail",
    component: EditEmployee,
    exact: true
  },
  {
    path:
      "/admin/businessowner/ownerdetail/:ownerId/business/:businessId/contact/:contactId",
    name: "Contact Detail",
    component: EditContact,
    exact: true
  },
  {
    path:
      "/admin/businessowner/ownerdetail/:ownerId/business/:businessId/roles",
    name: "Roles",
    component: RolesAdmin,
    exact: true
  },
  {
    path:
      "/admin/businessowner/ownerdetail/:ownerId/business/:businessId/roles/:roleId",
    name: "Role Detail",
    component: AdminRolesEdit,
    exact: true
  }


];

export default routes;
