import React, { Component } from "react";
import { FormText } from "reactstrap";
import AsyncPaginate from "react-select-async-paginate";
import csc from "country-state-city";
// import { toJS } from "mobx";

class FormikAsyncSelect extends Component {
  render() {
    let {
      field: { name, value },
      form: { errors, setFieldValue },
      formText,
      style,
      options,
      state,
      city,
      countryId,
      stateId,
      isDisabled,
      disableCacheUniq,
      ...props
    } = this.props;

    const sleep = ms =>
      new Promise(resolve => {
        setTimeout(() => {
          resolve();
        }, ms);
      });

    let loadOptions = async (search, prevOptions) => {
      await sleep(1000);

      let filteredOptions;
      if (!search) {
        if (state) {
          let states = [];

          if (countryId === "US") {
            states = csc.getStatesOfCountry(countryId);
            states.splice(2, 1);
            states.splice(4, 1);
            states.splice(8, 1);
            states.splice(10, 1);
            states.splice(11, 1);
            states.splice(15, 1);
            states.splice(15, 1);
            states.splice(17, 1);
            states.splice(22, 1);
            states.splice(25, 1);
            states.splice(25, 1);
            states.splice(33, 1);
            states.splice(36, 1);
            states.splice(37, 1);
            states.splice(42, 1);
            states.splice(42, 1);
          }

          let arrayStates = states.map(data => {
            let { name, isoCode } = data;
            return {
              label: `${name}`,
              value: `${isoCode}`
            };
          });

          filteredOptions = arrayStates;
        } else if (city) {
          let cities = csc.getCitiesOfState(stateId);

          let arrayCity = cities.map(data => {
            let { name, isoCode } = data;
            return {
              label: `${name}`,
              value: `${isoCode}`
            };
          });
          filteredOptions = arrayCity;
        } else {
          filteredOptions = options;
        }
      } else {
        let searchLower = search.toLowerCase();

        if (state) {
          let states = [];

          if (countryId) {
            states = csc.getStatesOfCountry(countryId);
            states.splice(2, 1);
            states.splice(4, 1);
            states.splice(8, 1);
            states.splice(10, 1);
            states.splice(11, 1);
            states.splice(15, 1);
            states.splice(15, 1);
            states.splice(17, 1);
            states.splice(22, 1);
            states.splice(25, 1);
            states.splice(25, 1);
            states.splice(33, 1);
            states.splice(36, 1);
            states.splice(37, 1);
            states.splice(42, 1);
            states.splice(42, 1);
          }

          const arrayStates = states.map(data => {
            const { name, isoCode } = data;
            return {
              label: `${name}`,
              value: `${isoCode}`
            };
          });
          filteredOptions = arrayStates.filter(({ label }) =>
            label.toLowerCase().includes(searchLower)
          );
        } else if (stateId) {
          const cities = csc.getCitiesOfState(stateId);
          const arrayCity = cities.map(data => {
            const { name, isoCode } = data;
            return {
              label: `${name}`,
              value: `${isoCode}`
            };
          });
          filteredOptions = arrayCity.filter(({ label }) =>
            label.toLowerCase().includes(searchLower)
          );
        } else {
          filteredOptions = options.filter(({ label }) =>
            label.toLowerCase().includes(searchLower)
          );
        }
      }
      const hasMore = filteredOptions.length > prevOptions.length + 200;
      const slicedOptions = filteredOptions.slice(
        prevOptions.length,
        prevOptions.length + 200
      );

      return {
        options: slicedOptions,
        hasMore
      };
    };

    const isError =
      !!errors && !!errors[`${name},value`]
        ? !!errors && !!errors[`${name},value`]
        : !!errors && !!errors[`${name},label`];

    return (
      <React.Fragment>
        <AsyncPaginate
          name={name}
          value={value}
          onChange={event => {
            setFieldValue(name, event);
            // if (name === "country") {
            //   if (event.label !== "United States") {
            //     setFieldValue("state", "");
            //     setFieldValue("city", "");
            //   } else if (event.label === "United States") {
            //     setFieldValue("state", "");
            //     setFieldValue("city", "");
            //   }
            // }
          }}
          cacheUniq={loadOptions}
          style={this.props.style}
          loadOptions={loadOptions}
          isDisabled={isDisabled}
          {...props}
        />

        {!!isError && (
          <p className="text-danger">
            {errors[`${name},value`]
              ? errors[`${name},value`]
              : errors[`${name},label`]}
          </p>
        )}
        {!!formText && <FormText>{formText}</FormText>}
      </React.Fragment>
    );
  }
}

export default FormikAsyncSelect;
